import React from "react";
import MyImage from "../images/img-1.png";
import { Button, Image, Row, Col, Container } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../Navbar/Navbar";
import Footer from "../Navbar/Footer";

function About() {
  return (
    <div>
      <Navbar />

      <Container fluid>

        <div style={{ marginTop: 100, marginBottom: 100 }} className='container'>

          <Row className="py-5">

            <Col sm={7}>
              <div>
                <div style={{ background: "#fd5252", width: 70, height: 8, borderColor: "#fd5252", borderRadius: 10 }} />
                <p style={{ fontSize: 35, fontWeight: 700 }}>WELCOME TO E-AROGYA SERVICES</p>

              </div>
              <div>
                <p style={{ color: "grey", marginBottom: 40 }}>A comprehensive medical system providing solution for clinics and hospitals to offer a seamless appointment booking experience to their patients. Get information related to your health services. Book appointments, search hospitals, doctors, laboratories, pharmacies and many more services are available.</p>
              </div>
              <div>
                <Button variant="outline-info" href="#" size="lg">Read More</Button>
              </div>
            </Col>

            <Col sm={5}>
              <div>
                <Image src={MyImage} alt="check" className="img-fluid" />
              </div>
            </Col>

          </Row>

        </div>

      </Container>

      <Footer />
    </div>

  );
}

export default About;