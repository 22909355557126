import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../Navbar/Navbar";
import Footer from "../Navbar/Footer";
import { Button, Row, Col, Container, Form, Image, InputGroup } from "react-bootstrap";
import { EyeFill, EyeSlashFill, Asterisk } from "react-bootstrap-icons";
import { Base64 } from 'js-base64';
// import { API_BASE } from '../../setupProxy';

function RegisterOrganization() {

    const navigate = useNavigate();
    let { state } = useLocation();

    const [files, setFiles] = useState('');
    const [fileSize, setFileSize] = useState("");
    const [imagefile, setImageFile] = useState(null);
    const aRef = useRef(null);

    const [updateMessage, setUpdateMessage] = useState("");

    const strorganizationtype = state.type;
    const [strorganizationname, setStrorganizationname] = useState("");
    const [strwebsite, setStrwebsite] = useState("");
    const [strphoneno1, setStrphoneno1] = useState("");
    const [strphoneno2, setStrphoneno2] = useState("");
    const [straddressline1, setStraddressline1] = useState("");
    const [straddressline2, setStraddressline2] = useState("");
    const [strarea, setStrarea] = useState("");
    const [intcityid, setIntcityid] = useState(null);
    const [intstateid, setIntstateid] = useState(null);
    const intloginid = 1;

    const [cities, setCities] = useState([]);
    const [states, setStates] = useState([]);
    const [filteredCities, setFilteredCities] = useState([]);

    const [strmobileno, setStrMobileNo] = useState("");
    const [strpassword, setStrPassword] = useState("");
    const [strconfirmpassword, setStrConfirmPassword] = useState("");

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const togglePassword = () => {
        setShowPassword(!showPassword);
    };

    const toggleConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    useEffect(() => {
        fetchCities();
        fetchStates();
    }, [])

    const fetchStates = () => {
        fetch("https://e-arogya.com:8443/api/general/states")
            .then(res => res.json())
            .then(states => {
                setStates(states);
            })
            .catch(error => {
                //console.log(error);
            })
    }

    const fetchCities = () => {
        fetch("https://e-arogya.com:8443/api/general/cities")
            .then(res => res.json())
            .then(cities => {
                setCities(cities);
            })
            .catch(error => {
                //console.log(error);
            })
    }

    const handleSubmit = async (e) => {

        e.preventDefault();

        const formData = new FormData();

        if (strphoneno2) {
            if (strphoneno2.length !== 10 || isNaN(strphoneno2)) {
                setUpdateMessage(<span style={{ color: "red" }}>Please Enter Valid 10 digit Phone 2</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
            }
        }

        if (strorganizationname === "") {
            setUpdateMessage(<span style={{ color: "red" }}>Please enter organization name</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
        } else if (strwebsite === "") {
            setUpdateMessage(<span style={{ color: "red" }}>Please enter organization website</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
        } else if (strphoneno1 === "" || strphoneno1.length !== 10 || isNaN(strphoneno1)) {
            setUpdateMessage(<span style={{ color: "red" }}>Please Enter Valid 10 digit Phone 1</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
        } else if (straddressline1 === "") {
            setUpdateMessage(<span style={{ color: "red" }}>Please enter Address Line 1</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
        } else if (straddressline2 === "") {
            setUpdateMessage(<span style={{ color: "red" }}>Please enter Address Line 2</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
        } else if (strarea === "") {
            setUpdateMessage(<span style={{ color: "red" }}>Please enter area</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
        } else if (intcityid === "" || intcityid === null || intcityid === "- Select -") {
            setUpdateMessage(<span style={{ color: "red" }}>Please select city</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
        } else if (intstateid === "" || intstateid === null || intstateid === "- Select -") {
            setUpdateMessage(<span style={{ color: "red" }}>Please select state</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
        } else if (strmobileno === "" || strmobileno.length !== 10 || isNaN(strmobileno)) {
            setUpdateMessage(<span style={{ color: "red" }}>Please Enter Valid 10 digit Mobile Number</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
        } else if (strpassword === "" || strpassword.length < 6) {
            setUpdateMessage(<span style={{ color: "red" }}>Please Enter Valid min 6 digit password</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
        } else if (strconfirmpassword === "" || strconfirmpassword.length < 6) {
            setUpdateMessage(<span style={{ color: "red" }}>Please Enter Valid 6 digit confirm password</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
        } else if (strpassword !== strconfirmpassword) {
            setUpdateMessage(<span style={{ color: "red" }}>Password did not matched. Please re-enter password</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
        } else {

            if (files !== 0) {
                for (let i = 0; i < files.length; i++) {
                    if (files[i].size > 20000) {
                        setFileSize(<span style={{ color: "red" }}>File size exceeded!! Please select filesize less than 20KB.</span>);
                        setTimeout(() => setFileSize(""), 3000);
                        return;
                    }
                    formData.append('files', files[i])
                }
            }

            // console.log(intloginid + " " + strorganizationtype + " " + strorganizationname + " " + strwebsite + " " + strphoneno1 + " " + straddressline1 + " " + straddressline2 + " "
            //     + strarea + " " + intcityid + " " + intstateid + " " + files);

            formData.append('intloginid', intloginid);
            formData.append('strorganizationtype', strorganizationtype);
            formData.append('strorganizationname', strorganizationname);
            formData.append('strwebsite', strwebsite);
            formData.append('strphoneno1', strphoneno1);
            if (strphoneno2) {
                formData.append('strphoneno2', strphoneno2);
            }
            formData.append('straddressline1', straddressline1);
            formData.append('straddressline2', straddressline2);
            formData.append('strarea', strarea);
            formData.append('intcityid', intcityid);
            formData.append('strstatus', "Active");

            addNewOrganization(formData);
            registerNewOrganization(strmobileno, strpassword);

        };

    }

    //ADD NEW ORGANIZATION
    async function addNewOrganization(formData) {

        const requestOptions = {
            method: 'POST',
            body: formData
        };
        fetch("https://e-arogya.com:8443/api/general/organization/addneworganization", requestOptions)
            .then(res => {
                if (res.ok) {
                    setUpdateMessage(<span style={{ color: "green" }}>Organization Added</span>);
                    setTimeout(() => setUpdateMessage(""), 3000);
                    clearFields();

                } else {
                    setUpdateMessage(<span style={{ color: "red" }}>Organization Not Added. Please try again.</span>);
                    setTimeout(() => setUpdateMessage(""), 3000);
                }
            })
            .catch(error => {
                setUpdateMessage(<span style={{ color: "red" }}>Error while Adding Organization! Please try again.</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
            });
    }

    const registerNewOrganization = (strmobileno, strpassword) => {

        const strencryptedpassword = Base64.encode(strpassword);

        const toInput = { strmobileno, strencryptedpassword };
        fetch("https://e-arogya.com:8443/api/general/login/registerneworg", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            redirect: "follow",
            body: JSON.stringify(toInput)
        })
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();

                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);

                }
                //console.log("Login details for Organization Added");
                navigate('/login');
            })
            .catch(error => {
                //console.error('There was an error in adding Login details for organization record!', error);
            });
    }

    const clearFields = () => {

        setStrorganizationname("");
        setStrwebsite("");
        setStrphoneno1("");
        setStrphoneno2("");
        setStraddressline1("");
        setStraddressline2("");
        setStrarea("");
        setIntstateid('- Select -');
        setIntcityid('- Select -');
        setFiles([]);
        setImageFile(null);
        setStrMobileNo("");
        setStrPassword("");
        setStrConfirmPassword("");
    }

    return (

        <div>

            <Navbar />

            <Container fluid>

                <div style={{ marginTop: 50, marginBottom: 50 }} className='container'>

                    <Row>
                        <Col className="text-center justify-content-center">
                            <div style={{ textAlign: "center" }}>
                                <div className="about-border"></div>
                                <p style={{ fontSize: 35, fontWeight: 700 }}>Register {state.type}</p>
                            </div>
                        </Col>
                    </Row>

                    <Row className="py-5 justify-content-center">

                        <Form onSubmit={handleSubmit} >

                            <Row>
                                <Col md={4}>
                                    <Form.Group className="mb-3" controlId="formEnterState">
                                        <Form.Label style={{ fontSize: 13, fontWeight: "bold" }}><Asterisk color="red" size={6} style={{ marginBottom: 6 }} /> {state.type} Name </Form.Label>
                                        <Form.Control type="text" style={{ fontSize: 13 }} value={strorganizationname || ""} onChange={e => { setStrorganizationname(e.target.value) }} required />
                                    </Form.Group>
                                </Col>

                                <Col md={4}>
                                    <Form.Group className="mb-3" controlId="formEnterState">
                                        <Form.Label style={{ fontSize: 13, fontWeight: "bold" }}><Asterisk color="red" size={6} style={{ marginBottom: 6 }} /> Website</Form.Label>
                                        <Form.Control type="" style={{ fontSize: 13 }} value={strwebsite || ""} onChange={e => { setStrwebsite(e.target.value) }} required />
                                    </Form.Group>
                                </Col>

                                <Col md={2}>
                                    <Form.Group className="mb-3" controlId="formEnterState">
                                        <Form.Label style={{ fontSize: 13, fontWeight: "bold" }}><Asterisk color="red" size={6} style={{ marginBottom: 6 }} /> Phone 1</Form.Label>
                                        <Form.Control type="text" style={{ fontSize: 13 }} value={strphoneno1 || ""} onChange={e => { setStrphoneno1(e.target.value) }} required />
                                    </Form.Group>
                                </Col>

                                <Col md={2}>
                                    <Form.Group className="mb-3" controlId="formEnterState">
                                        <Form.Label style={{ fontSize: 13, fontWeight: "bold" }}>Phone 2</Form.Label>
                                        <Form.Control type="text" style={{ fontSize: 13 }} value={strphoneno2 || ""} onChange={e => { setStrphoneno2(e.target.value) }} />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>

                                <Col md={6}>
                                    <Form.Group className="mb-3" controlId="formEnterState">
                                        <Form.Label style={{ fontSize: 13, fontWeight: "bold" }}><Asterisk color="red" size={6} style={{ marginBottom: 6 }} /> Address Line 1</Form.Label>
                                        <Form.Control type="text" style={{ fontSize: 13 }} value={straddressline1 || ""} onChange={e => { setStraddressline1(e.target.value) }} required />
                                    </Form.Group>
                                </Col>

                                <Col md={6}>
                                    <Form.Group className="mb-3" controlId="formEnterState">
                                        <Form.Label style={{ fontSize: 13, fontWeight: "bold" }}><Asterisk color="red" size={6} style={{ marginBottom: 6 }} /> Address Line 2</Form.Label>
                                        <Form.Control type="text" style={{ fontSize: 13 }} value={straddressline2 || ""} onChange={e => { setStraddressline2(e.target.value) }} required />
                                    </Form.Group>
                                </Col>

                            </Row>

                            <Row>

                                <Col md={4}>
                                    <Form.Group className="mb-3" controlId="formEnterState">
                                        <Form.Label style={{ fontSize: 13, fontWeight: "bold" }}><Asterisk color="red" size={6} style={{ marginBottom: 6 }} /> Area</Form.Label>
                                        <Form.Control type="text" style={{ fontSize: 13 }} value={strarea || ""} onChange={e => { setStrarea(e.target.value) }} required />
                                    </Form.Group>
                                </Col>

                                <Col md={4}>
                                    <Form.Group className="mb-3" controlId="formSelectStateStatus">
                                        <Form.Label style={{ fontSize: 13, fontWeight: "bold" }}><Asterisk color="red" size={6} style={{ marginBottom: 6 }} /> State</Form.Label>
                                        <Form.Select
                                            aria-label="Default select example"
                                            value={intstateid || ""}
                                            style={{ fontSize: 13 }}
                                            onChange={e => {
                                                setIntstateid(e.target.value);

                                                let cityList = [];
                                                for (let i = 0; i < cities.length; i++) {
                                                    if (cities[i].intstateid === parseInt(e.target.value)) { //parseInt because the value received (e.target.value) is in string
                                                        //intstateid is equal
                                                        cityList.push(cities[i]);
                                                    }
                                                }

                                                setFilteredCities(cityList);

                                            }}
                                            required
                                        >
                                            <option value="- Select -">- Select -</option>
                                            {states.map((data, key) => <option key={data.intstateid} value={data.intstateid} >{data.strstatename}</option>)}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>

                                <Col md={4}>
                                    <Form.Group className="mb-3" controlId="formSelectStateStatus">
                                        <Form.Label style={{ fontSize: 13, fontWeight: "bold" }}><Asterisk color="red" size={6} style={{ marginBottom: 6 }} /> City</Form.Label>
                                        <Form.Select
                                            aria-label="Default select example"
                                            style={{ fontSize: 13 }}
                                            value={intcityid || ""}
                                            onChange={e => { setIntcityid(e.target.value);  }}
                                            required
                                        >
                                            <option value="- Select -">- Select -</option>
                                            {filteredCities.map((data1, key) => <option key={data1.intcityid} value={data1.intcityid}>{data1.strcityname}</option>)}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>

                            </Row>

                            <Row>

                                <Col md={4}>
                                    <Form.Group className="mb-3" controlId="formEnterState">
                                        <Form.Label style={{ fontSize: 13, fontWeight: "bold" }}>Logo</Form.Label>
                                        <Form.Control
                                            ref={aRef}
                                            type="file"
                                            style={{ fontSize: 13 }}
                                            onChange={e => {
                                                setFiles(e.target.files);
                                                const reader = new FileReader();
                                                reader.addEventListener("load", () => {
                                                    setImageFile(reader.result);
                                                });
                                                reader.readAsDataURL(e.target.files[0]);
                                            }}
                                            accept="image/png, image/gif, image/jpeg"
                                        />
                                    </Form.Group>
                                </Col>


                                {imagefile ?
                                    <Col sm={4}>
                                        <Form.Label style={{ fontSize: 13, fontWeight: "bold" }}>Logo Preview</Form.Label>
                                        <div><Image src={imagefile} width={50} height={50} /></div>
                                    </Col>
                                    :
                                    <Col sm={4}>
                                        <div></div>
                                    </Col>
                                }


                            </Row>

                            <div style={{ textAlign: "center" }}>
                                <p style={{ fontSize: 18, fontWeight: 700 }}>Enter Login Details</p>
                            </div>

                            <Row>
                                <Col md={4}>
                                    <Form.Group className="mb-3" controlId="formEnterState">
                                        <Form.Label style={{ fontSize: 13, fontWeight: "bold" }}><Asterisk color="red" size={6} style={{ marginBottom: 6 }} /> Mobile Number</Form.Label>
                                        <Form.Control type="" style={{ fontSize: 13 }} value={strmobileno || ""} onChange={e => { setStrMobileNo(e.target.value) }} required />
                                    </Form.Group>
                                </Col>

                                <Col md={4}>
                                    <Form.Group className="mb-3" controlId="formEnterState">
                                        <Form.Label style={{ fontSize: 13, fontWeight: "bold" }}><Asterisk color="red" size={6} style={{ marginBottom: 6 }} /> Password</Form.Label>
                                        <InputGroup className="mb-2">
                                            <Form.Control
                                                style={{ fontSize: 13 }}
                                                type={showPassword ? "text" : "password"}
                                                value={strpassword || ""}
                                                onChange={e => { setStrPassword(e.target.value) }}
                                                required />
                                            <InputGroup.Text onClick={togglePassword}>
                                                {showPassword ? <EyeFill size={15} /> : <EyeSlashFill size={15} />}
                                            </InputGroup.Text>
                                        </InputGroup>
                                    </Form.Group>
                                </Col>

                                <Col md={4}>
                                    <Form.Group className="mb-3" controlId="formEnterState">
                                        <Form.Label style={{ fontSize: 13, fontWeight: "bold" }}><Asterisk color="red" size={6} style={{ marginBottom: 6 }} /> Confirm Password</Form.Label>
                                        <InputGroup className="mb-2">
                                            <Form.Control
                                                style={{ fontSize: 13 }}
                                                type={showConfirmPassword ? "text" : "password"}
                                                value={strconfirmpassword || ""}
                                                onChange={e => { setStrConfirmPassword(e.target.value) }}
                                                required />
                                            <InputGroup.Text onClick={toggleConfirmPassword}>
                                                {showConfirmPassword ? <EyeFill size={15} /> : <EyeSlashFill size={15} />}
                                            </InputGroup.Text>
                                        </InputGroup>
                                    </Form.Group>
                                </Col>

                            </Row>


                            <Row>
                                <div style={{ fontSize: 12, fontWeight: "bold", marginBottom: 10, marginTop: 20 }}>{fileSize}</div>
                            </Row>

                            <Row>
                                <div style={{ fontSize: 12, fontWeight: "bold", marginBottom: 10, marginTop: 20 }}>{updateMessage}</div>
                            </Row>

                            <div style={{ textAlign: "center", color: "red", fontSize: 12 }}>
                                <Asterisk color="red" size={8} style={{ marginBottom: 3 }} /> &nbsp; Mandatory Fields
                            </div>

                            <div style={{ textAlign: "center" }}>
                                <Button style={{ marginTop: 20, marginBottom: 20, backgroundColor: "green", borderColor: "green", width: "30%" }} type="submit">Register</Button>
                            </div>

                        </Form>


                        <div style={{ textAlign: "center" }}></div>


                        <Row className="align-items-center justify-content-center">

                            <Col xs="auto">
                                <Form.Label style={{ marginTop: 6 }}> Already have an account? </Form.Label>
                            </Col>

                            <Col xs="auto">
                                <Link to="/login">
                                    <Button variant="outline-danger" size="sm">Login</Button>
                                </Link>
                            </Col>

                        </Row>

                    </Row>

                </div>

            </Container>

            <Footer />

        </div>

    );
}

export default RegisterOrganization;