import React, { useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../Navbar/Navbar";
import Footer from "../Navbar/Footer";
import { Button, Row, Col, Container, Form, ProgressBar, Image } from "react-bootstrap";
import { Base64 } from 'js-base64';
import { Asterisk } from "react-bootstrap-icons";
// import { API_BASE } from '../../setupProxy';

function RegiOrg() {

    const navigate = useNavigate();
    let { state } = useLocation();

    const [updateMessage, setUpdateMessage] = useState("");

    const strorganizationtype = state.type;
    const [strorganizationname, setStrorganizationname] = useState("");
    const [strorganizationregno, setStrorganizationregno] = useState("");
    const [strmobileno, setStrMobileNo] = useState("");
    const [stremailid, setStrEmailId] = useState("");
    const [strstatus] = useState("Active");

    const [files, setFiles] = useState('');
    const [imagefile, setImageFile] = useState(null);
    const aRef = useRef(null);

    const handleSubmit = async (e) => {

        e.preventDefault();

        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;  // eslint-disable-line

        if (strorganizationname === "") {
            setUpdateMessage(<span style={{ color: "red" }}>Please enter organization name</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
        } else if (stremailid === "" || reg.test(stremailid) === false) {
            setUpdateMessage(<span style={{ color: "red" }}>Please enter valid organization email id</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
        } else if (strmobileno === "" || strmobileno.length !== 10 || isNaN(strmobileno)) {
            setUpdateMessage(<span style={{ color: "red" }}>Please enter valid 10 digit mobile number</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
        } else {
            generateRandomPasswordString(10);
        };

    }

    const generateRandomPasswordString = (len) => {
        const char = 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890';
        const random = Array.from({ length: len }, () => char[Math.floor(Math.random() * char.length)]);
        const randomString = random.join("");
        registerNewOrganization(strmobileno, stremailid, randomString);
    }

    //REGISTER LOGIN DETAILS OF NEW ORGANIZATION
    const registerNewOrganization = (strmobileno, stremailid, passwordstring) => {

        const strencryptedpassword = Base64.encode(passwordstring);

        const toInput = { strmobileno, stremailid, strencryptedpassword };
        fetch("https://e-arogya.com:8443/api/general/login/registerneworg", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            redirect: "follow",
            body: JSON.stringify(toInput)
        })
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
                const error = (data && data.message) || response.status;

                if (!response.ok) {
                    if (error === "Duplicate Key") {
                        setUpdateMessage(<span style={{ color: "red" }}>Mobile or Email Id Already Exist</span>);
                        setTimeout(() => setUpdateMessage(""), 3000);
                    }
                    return Promise.reject(error);
                }

                if (data) {
                    //alert("Login Successfull: "+(data[0].intloginid)+ " "+(data[0].strmobileno)+ " "+(data[0].stremailid));
                    //console.log("DataMessage: "+data.loginDetails[0].intloginid);
                    //console.log("" + data.loginDetails[0].intloginid + " " + strorganizationtype + " " + strorganizationname);
                    addOrganizationDetails(data.loginDetails[0].intloginid);
                } else {
                    setUpdateMessage(<span style={{ color: "red" }}>Incorrect mobile. Please try again.</span>);
                    setTimeout(() => setUpdateMessage(""), 3000);
                }

            })
            .catch(error => {
                //console.log("Error Occurred: " + error);
            });

    }

    async function addOrganizationDetails(intloginid) {

        const formData = new FormData();

        if (files !== 0) {
            for (let i = 0; i < files.length; i++) {
                if (files[i].size > 100000) {
                    setUpdateMessage(<span style={{ color: "red" }}>File size exceeded!! Please select filesize less than 20KB.</span>);
                    setTimeout(() => setUpdateMessage(""), 3000);
                    return;
                }
                formData.append('files', files[i])
            }
        }

        formData.append('intloginid', intloginid);
        formData.append('strorganizationtype', strorganizationtype);
        formData.append('strorganizationname', strorganizationname);
        formData.append('strstatus', strstatus);
        if (strorganizationregno !== "") {
            formData.append('strorganizationregno', strorganizationregno);
        }

        addOrgDetails(formData, intloginid);
    }

    function addOrgDetails(formData, intloginid) {

        const requestOptions = {
            method: 'POST',
            body: formData
        };
        fetch("https://e-arogya.com:8443/api/general/organization/addorganizationdetails", requestOptions)
            .then(res => {
                const isJson = res.headers.get('content-type')?.includes('application/json');
                const data = isJson && res.json();
                if (!res.ok) {
                    const error = (data && data.message) || res.status;
                    return Promise.reject(error);
                }
                //console.log("Organization Details Added");
                navigate("/regiorgaddress", { state: { type: strorganizationtype, loginid: intloginid, orgname: strorganizationname } });
            })
            .catch(error => {
                setUpdateMessage(<span style={{ color: "red" }}>Error Occured. Please try again.</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
            });
    }

    return (

        <div>

            <Navbar />

            <Container fluid>

                <div style={{ marginTop: 50, marginBottom: 50 }} className='container'>

                    <Row>
                        <Col className="text-center justify-content-center">
                            <div style={{ textAlign: "center" }}>
                                <div className="about-border"></div>
                                <p style={{ fontSize: 35, fontWeight: 700 }}>Register {state.type}</p>
                            </div>
                        </Col>
                    </Row>
                    <div style={{ marginTop: 30, marginBottom: 30 }}></div>
                    <Row>
                        <Col>
                            <div style={{ textAlign: "center" }}>
                                <p style={{ fontSize: 14, fontWeight: 500 }}>Basic Details</p>
                            </div>
                        </Col>
                        <Col>
                            <div style={{ textAlign: "center" }}>
                                <p style={{ fontSize: 14, fontWeight: 500 }}>Location Details</p>
                            </div>
                        </Col>
                        <Col>
                            <div style={{ textAlign: "center" }}>
                                <p style={{ fontSize: 14, fontWeight: 500 }}>Login Details</p>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <ProgressBar variant="success" animated now={33} style={{ height: 5 }} />
                    </Row>
                    <div style={{ marginTop: 30, marginBottom: 30 }}></div>

                    <Row className="py-5 justify-content-center">
                        <Col md={4}>

                            <Form onSubmit={handleSubmit} >

                                <Form.Group className="mb-3" controlId="formEnterState">
                                    <Form.Label style={{ fontSize: 13, fontWeight: "bold" }}><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />{state.type} Name </Form.Label>
                                    <Form.Control type="text" style={{ fontSize: 13 }} value={strorganizationname || ""} onChange={e => { setStrorganizationname(e.target.value) }} required />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formEnterState">
                                    <Form.Label style={{ fontSize: 13, fontWeight: "bold" }}>{state.type} Registration Number </Form.Label>
                                    <Form.Control type="text" style={{ fontSize: 13 }} value={strorganizationregno || ""} onChange={e => { setStrorganizationregno(e.target.value) }} />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formEnterState">
                                    <Form.Label style={{ fontSize: 13, fontWeight: "bold" }}><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Mobile Number</Form.Label>
                                    <Form.Control type="" style={{ fontSize: 13 }} value={strmobileno || ""} onChange={e => { setStrMobileNo(e.target.value) }} required />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formEnterState">
                                    <Form.Label style={{ fontSize: 13, fontWeight: "bold" }}><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Email </Form.Label>
                                    <Form.Control type="" style={{ fontSize: 13 }} value={stremailid || ""} onChange={e => { setStrEmailId(e.target.value) }} required />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formEnterState">
                                    <Form.Label style={{ fontSize: 13, fontWeight: "bold" }}>{state.type} Registration Certificate </Form.Label>
                                    <Form.Control
                                        ref={aRef}
                                        type="file"
                                        style={{ fontSize: 12 }}
                                        onChange={e => {
                                            setFiles(e.target.files);
                                            const reader = new FileReader();
                                            reader.addEventListener("load", () => {
                                                setImageFile(reader.result);
                                            });
                                            reader.readAsDataURL(e.target.files[0]);
                                        }}
                                        accept="image/png, image/jpeg"
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formSelectStateStatus">

                                    {imagefile ?
                                        <div><Image src={imagefile} width={75} height={75} /></div>
                                        :
                                        <div></div>
                                    }

                                </Form.Group>

                                <Row>
                                    <div style={{ fontSize: 12, fontWeight: "bold", marginBottom: 10, marginTop: 20 }}>{updateMessage}</div>
                                </Row>

                                <div style={{ textAlign: "center" }}>
                                    <Button style={{ marginTop: 20, marginBottom: 20, width: "50%" }} variant="success" type="submit">Next</Button>
                                </div>

                            </Form>
                        </Col>

                    </Row>

                </div>

            </Container>

            <Footer />

        </div>

    );

}


export default RegiOrg;