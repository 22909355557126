import React, { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./admincss.css";
import HospitalSidebar from "../Navbar/HospitalSidebar";
import Adminheader from "../Navbar/Adminheader";
import { CDBCard, CDBCardBody, CDBDataTable } from 'cdbreact';
import { Col, Form, Row, Button, Spinner, Modal } from "react-bootstrap";
import { Asterisk } from "react-bootstrap-icons";
// import { API_BASE } from '../../setupProxy';

const ManageTimeslots = () => {

    const intOrganizationID = localStorage.getItem("orgid");

    const [hospitalTimeslot, setHospitalTimeslot] = useState([]);
    const [loading, setLoading] = useState(false);

    const [intTimeSlotID, setIntTimeSlotID] = useState(null);
    const [strDayName, setStrDayName] = useState("");
    const [strFromTime, setStrFromTime] = useState("");
    const [strToTime, setStrToTime] = useState("");
    const [intTotalAppointmentToBook, setIntTotalAppointmentToBook] = useState(null);
    const [strstatus, setStatus] = useState('Active');

    const [updateMessage, setUpdateMessage] = useState("");

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [deleteThis, setDeleteThis] = useState(null);

    useEffect(() => {
        fetchHospitalTimeslot(intOrganizationID);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //FETCH ALL HOSPITAL TIMESLOT
    const fetchHospitalTimeslot = (orgid) => {
        fetch("https://e-arogya.com:8443/api/general/hospitaltimeslot/list/" + orgid)
            .then(response => response.json())
            .then(hosptsdata => {
                let dataFromApi = hosptsdata.map(hts => {
                    return {
                        id: hts.intTimeSlotID,
                        dayname: hts.strDayName,
                        fromtime: hts.strFromTime,
                        totime: hts.strToTime,
                        totalapptobook: hts.intTotalAppointmentToBook,
                        status: hts.strstatus
                    }
                })
                setHospitalTimeslot(dataFromApi);
                setLoading(true);
            })
            .catch(error => {
                //console.log(error);
            })
    }

    const clearFields = () => {
        setStrDayName('- Select -');
        setStrFromTime("");
        setStrToTime("");
        setIntTotalAppointmentToBook("");
        setStatus('Active');
    }

    //FORM SUBMIT
    const onAddNewHospitalTimeSlotFormSubmit = (event) => {
        event.preventDefault();

        if (strDayName === '- Select -' || strDayName === "" || strFromTime === "" || strToTime === "") {
            setUpdateMessage(<span style={{ color: "red" }}>Please select Day and Timings</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
        } else {

            if (intTimeSlotID) {
                const existingInputs = { intOrganizationID, strDayName, strFromTime, strToTime, intTotalAppointmentToBook, strstatus }
                //console.log(intOrganizationID + " " + strDayName + " " + strFromTime + " " + strToTime + " " + intTotalAppointmentToBook + " " + strstatus);
                updateHospitalTimeslot(existingInputs, intTimeSlotID);
                setIntTimeSlotID(null);
                clearFields();
            } else {
                const toInput = { intOrganizationID, strDayName, strFromTime, strToTime, intTotalAppointmentToBook, strstatus };
                //console.log(intOrganizationID + " " + strDayName + " " + strFromTime + " " + strToTime + " " + intTotalAppointmentToBook + " " + strstatus);
                addNewHospitalTimeslot(toInput);
                clearFields();
            }
        }

    }

    //FETCH HOSPITAL TIMESLOT BY ID (FOR EDIT TIMESLOT)
    const handleEditClick = (e, id) => {
        e.preventDefault();
        fetch("https://e-arogya.com:8443/api/general/hospitaltimeslot/" + id)
            .then(res => res.json())
            .then(data => {
                setIntTimeSlotID(data[0].intTimeSlotID);
                setStrDayName(data[0].strDayName);
                setStrFromTime(data[0].strFromTime);
                setStrToTime(data[0].strToTime)
                setIntTotalAppointmentToBook(data[0].intTotalAppointmentToBook)
                setStatus(data[0].strstatus);
            })
            .catch(error => {
                //console.log(error);
            });
    }

    //ADD NEW HOSPITAL TIMESLOT
    async function addNewHospitalTimeslot(toInput) {
        fetch("https://e-arogya.com:8443/api/general/hospitaltimeslot/addnewhospitaltimeslot", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            redirect: "follow",
            body: JSON.stringify(toInput)
        })
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();

                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }
                setUpdateMessage(<span style={{ color: "green" }}>Hospital Timeslot Added Successfully</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
                fetchHospitalTimeslot(intOrganizationID);
            })
            .catch(error => {
                setUpdateMessage(<span style={{ color: "red" }}>Hospital Timeslot Not Added. Please try again.</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
            });

    }

    //UPDATE EXISTING HOSPITAL TIMESLOT
    async function updateHospitalTimeslot(existingInputs, intTimeSlotID) {
        fetch("https://e-arogya.com:8443/api/general/hospitaltimeslot/update/" + intTimeSlotID, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            redirect: "follow",
            body: JSON.stringify(existingInputs)
        })
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();

                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }
                setUpdateMessage(<span style={{ color: "green" }}>Hospital Timeslot Updated Successfully</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
                fetchHospitalTimeslot(intOrganizationID);
            })
            .catch(error => {
                setUpdateMessage(<span style={{ color: "red" }}>Hospital Timeslot Not Updated. Please try again.</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
            });

    }

    //DELETE HOSPITAL TIMESLOT
    function handleDeleteClick(e, id) {
        setShow(true);
        setDeleteThis(id);
    }

    const deleteThisFunc = (id) => {
        fetch("https://e-arogya.com:8443/api/general/hospitaltimeslot/" + id, { method: 'DELETE' })
            .then(async response => {
                const data = await response.text;

                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }

                setUpdateMessage(<span style={{ color: "green" }}>Hospital Timeslot Deleted Successfully</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
                setDeleteThis(null);
                setIntTimeSlotID(null);
                clearFields();
                fetchHospitalTimeslot(intOrganizationID);
            })
            .catch(error => {
                setUpdateMessage(<span style={{ color: "red" }}>Hospital Timeslot Not Deleted. Please try again.</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
            });
    }

    const data = () => {
        return {
            columns: [
                { label: '#', field: 'srno', },
                {
                    label: 'Days',
                    field: 'dayname',
                    attributes: {
                        'aria-controls': 'DataTable',
                        'aria-label': 'Days',
                    },
                },
                { label: 'From Time', field: 'fromtime', },
                { label: 'To Time', field: 'totime', },
                { label: 'Total Appointments To Book', field: 'totalapptobook', },
                { label: 'Status', field: 'status', },
                { label: '', field: 'edit', },
                { label: '', field: 'delete', },
            ],
            rows: [...hospitalTimeslot.map((data1, index) => (
                {
                    srno: index + 1,
                    dayname: data1.dayname,
                    fromtime: data1.fromtime,
                    totime: data1.totime,
                    totalapptobook: data1.totalapptobook,
                    status: data1.status,
                    edit: <span onClick={(e) => handleEditClick(e, data1.id)}>
                        <Button variant="primary" type="submit" style={{ fontSize: 12 }} size="sm">
                            Edit
                        </Button>
                    </span>,
                    delete: <span onClick={(e) => handleDeleteClick(e, data1.id)}>
                        <Button variant="danger" type="submit" style={{ fontSize: 12 }} size="sm">
                            Delete
                        </Button>
                    </span>
                }
            ))

            ],
        };
    };
    return (

        <div className="main" >

            <HospitalSidebar />

            <div className="page-content">

                <Adminheader />

                <h5>Manage Hospital Timeslots</h5>

                <div className="table-content">

                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title style={{ fontSize: 18 }}>Delete Timeslot</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ fontSize: 14 }}>Are you sure you want to delete this timeslot?</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose} style={{ fontSize: 12 }}>No</Button>
                            <Button variant="primary" onClick={() => {
                                handleClose();
                                deleteThisFunc(deleteThis);
                            }} style={{ fontSize: 12 }}>Yes</Button>
                        </Modal.Footer>
                    </Modal>

                    <Form onSubmit={onAddNewHospitalTimeSlotFormSubmit}>

                        <Row style={{ fontSize: 12, color: "grey" }}>

                            <Col sm={2}>
                                <Form.Group className="mb-3" controlId="formSelectStateStatus">
                                    <Form.Label><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Available Days</Form.Label>
                                    <Form.Select aria-label="Default select example" style={{ fontSize: 12 }} value={strDayName || ""} onChange={e => { setStrDayName(e.target.value) }} required>
                                        <option value="- Select -">- Select -</option>
                                        <option value="All Days">All Days</option>
                                        <option value="All Days - Ex Sun">All Days - Ex Sun</option>
                                        <option value="All Days - Ex Sat & Sun">All Days - Ex Sat & Sun</option>
                                        <option value="Monday">Monday</option>
                                        <option value="Tuesday">Tuesday</option>
                                        <option value="Wednesday">Wednesday</option>
                                        <option value="Thursday">Thursday</option>
                                        <option value="Friday">Friday</option>
                                        <option value="Saturday">Saturday</option>
                                        <option value="Sunday">Sunday</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>

                            <Col sm={2}>
                                <Form.Group className="mb-3" controlId="formEnterState">
                                    <Form.Label><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />From Time</Form.Label>
                                    <Form.Control type="time" style={{ fontSize: 12 }} value={strFromTime || ""} onChange={e => { setStrFromTime(e.target.value) }} />
                                </Form.Group>
                            </Col>
                            <Col sm={2}>
                                <Form.Group className="mb-3" controlId="formEnterState">
                                    <Form.Label><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />To Time</Form.Label>
                                    <Form.Control type="time" style={{ fontSize: 12 }} value={strToTime || ""} onChange={e => { setStrToTime(e.target.value) }} />
                                </Form.Group>
                            </Col>

                            <Col sm={2}>
                                <Form.Group className="mb-3" controlId="formEnterState">
                                    <Form.Label><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Total Appointments to Book </Form.Label>
                                    <Form.Control type="text" style={{ fontSize: 12 }} value={intTotalAppointmentToBook || ""} onChange={e => { setIntTotalAppointmentToBook(e.target.value) }} required />
                                </Form.Group>
                            </Col>

                            <Col sm={2}>
                                <Form.Group className="mb-3" controlId="formSelectStateStatus">
                                    <Form.Label>Status</Form.Label>
                                    <Form.Select aria-label="Default select example" style={{ fontSize: 12 }} value={strstatus || ""} onChange={e => { setStatus(e.target.value) }}>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>

                            <Col sm={2} >
                                <Form.Group className="mb-3" controlId="formSelectStateStatus">
                                    {intTimeSlotID ?
                                        <Button variant="primary" type="submit" style={{ marginTop: 25, fontSize: 12 }}> Update </Button>
                                        :
                                        <Button variant="primary" type="submit" style={{ marginTop: 25, fontSize: 12 }}> Add </Button>
                                    }
                                </Form.Group>
                            </Col>

                        </Row>

                    </Form>

                    <div style={{ fontSize: 12, fontWeight: "bold", marginBottom: 10 }}>{updateMessage}</div>

                    <CDBCard style={{ padding: 20, fontSize: 12, color: "grey" }}>
                        <CDBCardBody>

                            {!loading ? (
                                <div style={{ textAlign: "center" }}><Spinner animation="border" size="sm" variant="primary" /></div>
                            ) :
                                <CDBDataTable
                                    responsive
                                    noRecordsFoundLabel="No Records Found"
                                    noBottomColumn={true}
                                    hover
                                    entriesOptions={[10, 25, 50, 100, 200, 500]}
                                    entries={10}
                                    pagesAmount={4}
                                    data={data()}
                                />
                            }
                        </CDBCardBody>
                    </CDBCard>

                </div>

            </div>
        </div>

    );
};

export default ManageTimeslots;