import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Image, Row, NavDropdown, Nav } from "react-bootstrap";
import PersonImage from "../images/transparant-onlylogo.png";
//import Login from "../pages/Login";
import { useNavigate } from "react-router-dom";

function Adminheader() {

  const loggedinUserMobile = localStorage.getItem("loggedInUserName");
  const roleName = localStorage.getItem("loggedRoleName");
  const orgname = localStorage.getItem("orgname");
  const userfname = localStorage.getItem("userfname");
  const userlname = localStorage.getItem("userlname");


  const [adminRole, setAdminRole] = useState(false);
  const [hospitalAdmin, setHospitalAdmin] = useState(false);
  const [laboratoryAdmin, setLaboratoryAdmin] = useState(false);
  const [pharmacyAdmin, setPharmacyAdmin] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    checkRoles();
  })

  const checkRoles = () => {

    if (roleName === "Hospital Admin") {
      setAdminRole(false);
      setHospitalAdmin(true);
      setLaboratoryAdmin(false);
      setPharmacyAdmin(false);
    } else if (roleName === "Frontend" || roleName === "Medical" || roleName === "Account") {
      setAdminRole(false);
      setHospitalAdmin(false);
      setLaboratoryAdmin(false);
      setPharmacyAdmin(false);
    } else if (roleName === "Super") {
      setAdminRole(true);
      setHospitalAdmin(false);
      setLaboratoryAdmin(false);
      setPharmacyAdmin(false);
    } else if (roleName === "Laboratory Admin") {
      setAdminRole(false);
      setHospitalAdmin(false);
      setLaboratoryAdmin(true);
      setPharmacyAdmin(false);
    } else if (roleName === "Pharmacy Admin") {
      setAdminRole(false);
      setHospitalAdmin(false);
      setLaboratoryAdmin(false);
      setPharmacyAdmin(true);

    }

  }

  const checkLogout = () => {
    localStorage.clear();
    navigate('/login');
  }

  return (
    <div>
      <Row>
        <Col>

          <div style={{ textAlign: "right" }}>

            <Nav className="justify-content-end">

              <Row>
                <div style={{ fontSize: 12, color: "grey", alignSelf: "center", paddingRight: 30 }}>
                  <div>
                    {adminRole ?
                      <div>Admin</div>
                      :
                      <div>
                        {hospitalAdmin || laboratoryAdmin || pharmacyAdmin ?
                          <div>{orgname}</div>
                          :
                          <div>
                            {roleName === "Operator" ? <div>Operator</div> : <div>{userfname} {userlname}</div>}
                          </div>
                        }
                      </div>
                    }
                  </div>
                  <div>{loggedinUserMobile}</div>
                </div>
              </Row>

              <Image src={PersonImage} className="banner_img" alt="check" height={40} />

              <NavDropdown id="nav-dropdown-dark-example" >

                {hospitalAdmin || laboratoryAdmin || pharmacyAdmin ?
                  <NavDropdown.Item href="/organizationeditprofile" style={{ fontSize: 12 }}>Edit Profile</NavDropdown.Item>
                  :
                  <div></div>
                }

                 {adminRole || hospitalAdmin || laboratoryAdmin || pharmacyAdmin ?
                  <NavDropdown.Item href="/organizationchangepassword" style={{ fontSize: 12 }}>Change Password</NavDropdown.Item>
                  :
                  <div></div>
                }

                <NavDropdown.Item onClick={checkLogout} style={{ fontSize: 12 }}>Logout</NavDropdown.Item>

              </NavDropdown>

            </Nav>

          </div>

        </Col>
      </Row>
    </div>
  );
}

export default Adminheader;

