import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./admincss.css";
import Sidebar from "../Navbar/Sidebar";
import Adminheader from "../Navbar/Adminheader";
import { CDBCard, CDBCardBody, CDBDataTable } from 'cdbreact';
import { Spinner, Button } from "react-bootstrap";
// import { API_BASE } from '../../setupProxy';

const ManageUsers = () => {

    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");

    useEffect(() => {
        fetchData()
    }, [])

    //FETCH ALL USERS
    const fetchData = () => {
        fetch("https://e-arogya.com:8443/api/general/login")
            .then(res => res.json())
            .then(data => {
                let dataFromApi = data.map(user => {
                    return {
                        id: user.intloginid,
                        mobile: user.strmobileno,
                        email: user.stremailid,
                        orgname: user.strorganizationname,
                        rolename: user.strrolename,
                        status: user.strstatus,
                    }
                })

                setUsers(dataFromApi);
                setLoading(true);
            })
            .catch(error => {
                //console.log(error);
            });
    }

    const changingUserStatus = (e, id, stat) => {
        e.preventDefault();
        handleChangeStatusClick(id, stat);
    }

    const handleChangeStatusClick = (id, stat) => {
        if (stat === "Active") {
            let st = "Inactive";
            updateUserStatus(id, st);
        } else if (stat === "Inactive") {
            let st = "Active";
            updateUserStatus(id, st);
        }
    }

    const updateUserStatus = (id, st) => {
        let strstatus = st;
        const toInput = { strstatus };
        fetch("https://e-arogya.com:8443/api/general/login/updateuserstatus/" + id, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            redirect: "follow",
            body: JSON.stringify(toInput)
        })
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();

                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    setMessage(<span style={{ color: "red" }}>User Status Not Updated</span>);
                    setTimeout(() => setMessage(""), 3000);
                    return Promise.reject(error);
                }
                setMessage(<span style={{ color: "green" }}>User Status Updated</span>);
                setTimeout(() => setMessage(""), 3000);
                fetchData();
            })
            .catch(error => {
                setMessage(<span style={{ color: "red" }}>Error in updating user status. Please try again.</span>);
                setTimeout(() => setMessage(""), 3000);
            });
    }

    const data = () => {
        return {
            columns: [
                { label: '#', field: 'srno', },
                { label: 'Mobile', field: 'mobile', },
                { label: 'Email', field: 'email', },
                { label: 'Organization', field: 'orgname', },
                { label: 'Role', field: 'rolename', },
                { label: 'Status', field: 'status', },
                { label: '', field: 'changestatus', },
            ],
            rows: [...users.map((data, i) => (
                {
                    srno: i + 1,
                    email: data.email,
                    mobile: data.mobile,
                    orgname: data.orgname,
                    rolename: data.rolename,
                    status: data.status,
                    changestatus: <span onClick={(e) => {
                        changingUserStatus(e, data.id, data.status);
                        // handleChangeStatusClick(e, data.id); setStrstatus(data.status) 
                    }}> <Button variant="primary" type="submit" style={{ fontSize: 12 }} size="sm"> Change Status </Button> </span>,
                }
            ))

            ],
        };
    };
    return (

        <div className="main" >

            <Sidebar />

            <div className="page-content">

                <Adminheader />

                <h5>Manage Users</h5>

                <p style={{ fontSize: 12, color: "grey", marginBottom: 50 }}>Manage Registered Users</p>

                <div style={{ fontSize: 12, fontWeight: "bold", marginBottom: 10, marginTop: 20 }}>{message}</div>

                <div className="table-content">

                    {!loading ? (
                        <div style={{ textAlign: "center" }}><Spinner animation="border" size="sm" variant="primary" /></div>
                    ) :

                        <CDBCard style={{ padding: 20, fontSize: 12, color: "grey" }}>
                            <CDBCardBody>
                                <CDBDataTable
                                    responsive
                                    noRecordsFoundLabel="No Records Found"
                                    noBottomColumn={true}
                                    hover
                                    entriesOptions={[10, 25, 50, 100, 200, 500]}
                                    entries={10}
                                    pagesAmount={4}
                                    data={data()}
                                />
                            </CDBCardBody>
                        </CDBCard>
                    }

                </div>

            </div>
        </div>

    );
};

export default ManageUsers;