import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../Navbar/Navbar";
import Footer from "../Navbar/Footer";
import { Button, Row, Col, Container, Form, ProgressBar } from "react-bootstrap";
import { GoogleMap, Marker, LoadScript } from "@react-google-maps/api";
import { Asterisk } from "react-bootstrap-icons";
// import { API_BASE } from '../../setupProxy';
/* global google */

const RegiOrgAddress = () => {

    const navigate = useNavigate();
    let { state } = useLocation();
    const strorganizationtype = state.type;
    const loginid = state.loginid;
    const orgname = state.orgname;

    const [updateMessage, setUpdateMessage] = useState("");

    const [straddressline1, setStraddressline1] = useState("");
    const [straddressline2, setStraddressline2] = useState("");
    const [strarea, setStrarea] = useState("");
    const [intcityid, setIntcityid] = useState(null);
    const [strpincode, setStrPincode] = useState("");
    const [intstateid, setIntstateid] = useState(null);

    const [cities, setCities] = useState([]);
    const [states, setStates] = useState([]);
    const [filteredCities, setFilteredCities] = useState([]);

    const [markerLatitude, setMarkerLatitude] = useState(22.3039);
    const [markerLongitude, setMarkerLongitude] = useState(70.8022);
    const [locStatus, setLocStatus] = useState(null);

    useEffect(() => {
        fetchCities();
        fetchStates();
        getCurrentLocation();
    }, [])

    const fetchStates = () => {
        fetch("https://e-arogya.com:8443/api/general/states")
            .then(res => res.json())
            .then(states => {
                setStates(states);
            })
            .catch(error => {
                //console.log(error);
            })
    }

    const fetchCities = () => {
        fetch("https://e-arogya.com:8443/api/general/cities")
            .then(res => res.json())
            .then(cities => {
                setCities(cities);
            })
            .catch(error => {
                //console.log(error);
            })
    }

    const getCurrentLocation = () => {
        if (!navigator.geolocation) {
            setLocStatus('Geolocation is not supported by your browser');
        } else {
            setLocStatus('Locating...');
            navigator.geolocation.getCurrentPosition((position) => {
                setLocStatus(null);
                setMarkerLatitude(position.coords.latitude);
                setMarkerLongitude(position.coords.longitude);
                //console.log("curr lat: " + position.coords.latitude + " longi: " + position.coords.longitude);
            }, () => {
                setLocStatus('Unable to retrieve your location');
            });
        }
    }

    const getAddress = (id) => {
        fetch("https://e-arogya.com:8443/api/general/citiesstates/" + id)
            .then(res => res.json())
            .then(data => {
                setMarkerByAddress(data[0].strcityname, data[0].strstatename);
            })
            .catch(error => {
                //console.log(error);
            });
    }

    const setMarkerByAddress = (cityname, statename) => {
        var address = `${orgname}, ${cityname}, ${statename}`;
        //console.log("Address: " + address);
        var geocoder = new google.maps.Geocoder();
        geocoder.geocode({
            'address': address
        }, function (results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
                var Lat = results[0].geometry.location.lat();
                var Lng = results[0].geometry.location.lng();
                setMarkerLatitude(Lat);
                setMarkerLongitude(Lng);
            } else {
                //console.log(" " + status);
                setUpdateMessage(<span style={{ color: "red" }}>Something got wrong</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
            }
        });
    }

    const handleSubmit = async (e) => {

        e.preventDefault();

        const decorglatitude = markerLatitude;
        const decorglongitude = markerLongitude;

        if (straddressline1 === "") {
            setUpdateMessage(<span style={{ color: "red" }}>Please Enter Address Line 1</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
        } else if (straddressline2 === "") {
            setUpdateMessage(<span style={{ color: "red" }}>Please Enter Address Line 2</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
        } else if (strarea === "") {
            setUpdateMessage(<span style={{ color: "red" }}>Please enter area</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
        } else if (strpincode === "" || isNaN(strpincode)) {
            setUpdateMessage(<span style={{ color: "red" }}>Please enter a valid pincode number</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
        } else if (intcityid === "" || intcityid === null || intcityid === "- Select -") {
            setUpdateMessage(<span style={{ color: "red" }}>Please select city</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
        } else if (intstateid === "" || intstateid === null || intstateid === "- Select -") {
            setUpdateMessage(<span style={{ color: "red" }}>Please select state</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
        } else {
            //console.log(loginid + " " + straddressline1 + " " + straddressline2 + " " + strarea + " " + strpincode + " " + intcityid + " " + intstateid);
            updateOrganizationDetails(straddressline1, straddressline2, strarea, strpincode, intcityid, decorglatitude, decorglongitude)
        };

    }

    //ADD NEW ORGANIZATION
    async function updateOrganizationDetails(straddressline1, straddressline2, strarea, strpincode, intcityid, decorglatitude, decorglongitude) {
        const toInput = { straddressline1, straddressline2, strarea, strpincode, intcityid, decorglatitude, decorglongitude };
        fetch("https://e-arogya.com:8443/api/general/organization/updateorganizationdetails/" + loginid, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            redirect: "follow",
            body: JSON.stringify(toInput)
        })
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();

                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }
                //console.log("Organization Details Updated");
                navigate('/regiorglogin', { state: { type: strorganizationtype, loginid: loginid } });
            })
            .catch(error => {
                setUpdateMessage(<span style={{ color: "red" }}>Error Occured. Please try again.</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
            });

    }

    return (

        <div>

            <Navbar />

            <Container fluid>

                <div style={{ marginTop: 50, marginBottom: 50 }} className='container'>

                    <Row>
                        <Col className="text-center justify-content-center">
                            <div style={{ textAlign: "center" }}>
                                <div className="about-border"></div>
                                <p style={{ fontSize: 35, fontWeight: 700 }}>Register {state.type}</p>
                            </div>

                        </Col>
                    </Row>
                    <div style={{ marginTop: 30, marginBottom: 30 }}></div>
                    <Row>
                        <Col>
                            <div style={{ textAlign: "center" }}>
                                <p style={{ fontSize: 14, fontWeight: 500 }}>Basic Details</p>
                            </div>
                        </Col>
                        <Col>
                            <div style={{ textAlign: "center" }}>
                                <p style={{ fontSize: 14, fontWeight: 500 }}>Location Details</p>
                            </div>
                        </Col>
                        <Col>
                            <div style={{ textAlign: "center" }}>
                                <p style={{ fontSize: 14, fontWeight: 500 }}>Login Details</p>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <ProgressBar variant="success" animated now={66} style={{ height: 5 }} />
                    </Row>

                    <div style={{ marginTop: 30, marginBottom: 30 }}></div>

                    <Row className="py-5 justify-content-center">

                        <Col md={4}>

                            <Form onSubmit={handleSubmit} >

                                <div style={{ textAlign: "center" }}>
                                    <p style={{ fontSize: 18, fontWeight: 700 }}>Location Details</p>
                                </div>

                                <Form.Group className="mb-3" controlId="formSelectStateStatus">
                                    <Form.Label style={{ fontSize: 13, fontWeight: "bold" }}><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />State</Form.Label>
                                    <Form.Select
                                        aria-label="Default select example"
                                        value={intstateid || ""}
                                        style={{ fontSize: 13 }}
                                        onChange={e => {
                                            setIntstateid(e.target.value);

                                            let cityList = [];
                                            for (let i = 0; i < cities.length; i++) {
                                                if (cities[i].intstateid === parseInt(e.target.value)) { //parseInt because the value received (e.target.value) is in string
                                                    //intstateid is equal
                                                    cityList.push(cities[i]);
                                                }
                                            }
                                            setFilteredCities(cityList);
                                        }}
                                        required
                                    >
                                        <option value="- Select -">- Select -</option>
                                        {states.map((data, key) => <option key={data.intstateid} value={data.intstateid}>{data.strstatename}</option>)}
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formSelectStateStatus">
                                    <Form.Label style={{ fontSize: 13, fontWeight: "bold" }}><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />City</Form.Label>
                                    <Form.Select
                                        aria-label="Default select example"
                                        style={{ fontSize: 13 }}
                                        value={intcityid || ""}
                                        onChange={e => {
                                            setIntcityid(e.target.value);
                                            getAddress(e.target.value);
                                        }}
                                        required
                                    >
                                        <option value="- Select -">- Select -</option>
                                        {filteredCities.map((data1, key) => <option key={data1.intcityid} value={data1.intcityid}>{data1.strcityname}</option>)}
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formEnterState">
                                    <Form.Label style={{ fontSize: 13, fontWeight: "bold" }}><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Address Line 1</Form.Label>
                                    <Form.Control type="text" style={{ fontSize: 13 }} value={straddressline1 || ""} onChange={e => { setStraddressline1(e.target.value) }} required />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formEnterState">
                                    <Form.Label style={{ fontSize: 13, fontWeight: "bold" }}><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Address Line 2</Form.Label>
                                    <Form.Control type="text" style={{ fontSize: 13 }} value={straddressline2 || ""} onChange={e => { setStraddressline2(e.target.value) }} required />
                                </Form.Group>

                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="formEnterState">
                                            <Form.Label style={{ fontSize: 13, fontWeight: "bold" }}><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Area</Form.Label>
                                            <Form.Control type="text" style={{ fontSize: 13 }} value={strarea || ""} onChange={e => { setStrarea(e.target.value) }} required />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="formEnterState">
                                            <Form.Label style={{ fontSize: 13, fontWeight: "bold" }}><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Pincode</Form.Label>
                                            <Form.Control type="text" style={{ fontSize: 13 }} value={strpincode || ""} onChange={e => { setStrPincode(e.target.value) }} required />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <div style={{ fontSize: 12, fontWeight: "bold", marginBottom: 10, marginTop: 20 }}>{updateMessage}</div>
                                </Row>

                                <Row>
                                    <Col>
                                        <div style={{ textAlign: "center" }}>
                                            <Link to="/regiorglogin" state={{ type: strorganizationtype, loginid: loginid }} style={{ textDecoration: "none" }}>
                                                <Button style={{ marginTop: 20, marginBottom: 20, width: "50%" }} variant="outline-secondary">Skip</Button>
                                            </Link>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div style={{ textAlign: "center" }}>
                                            <Button style={{ marginTop: 20, marginBottom: 20, width: "50%" }} variant="success" type="submit">Next</Button>
                                        </div>
                                    </Col>
                                </Row>

                            </Form>

                            <div style={{ textAlign: "center" }}></div>

                        </Col>

                        <Col md={8}>
                            <div style={{ textAlign: "center" }}>{locStatus}</div>

                            <LoadScript googleMapsApiKey='AIzaSyBzW45N5jxqiWtMZ6Ez_bmE0J70N6Z6XKA'>
                                <GoogleMap
                                    defaultCenter={{ lat: 22.3039, lng: 70.8022 }}
                                    mapContainerStyle={{ width: "100%", height: "450px", }}
                                    center={{ lat: markerLatitude, lng: markerLongitude }}
                                    zoom={13}
                                    onClick={(e) => {
                                        //console.log(e.latLng.lat(), e.latLng.lng())
                                    }}

                                >
                                    <Marker
                                        position={{ lat: markerLatitude, lng: markerLongitude }}
                                        label={{ color: "white", text: "H" }}
                                        draggable={true}

                                        onDragEnd={(e) => {
                                            //console.log("Marker Latitude: " + e.latLng.lat() + " Longitude: " + e.latLng.lng());
                                            setMarkerLatitude(e.latLng.lat());
                                            setMarkerLongitude(e.latLng.lng());
                                        }}

                                        onClick={e => { 
                                            //console.log(e) 
                                        }}
                                    >
                                    </Marker>

                                </GoogleMap>

                            </LoadScript>

                        </Col>

                    </Row>

                </div>

            </Container>

            <Footer />

        </div>

    );
}
export default RegiOrgAddress