import React from "react";
import UserImageInsta from "../images/img-12.png";
import { Button, Image, Row, Col, Form, Container } from "react-bootstrap";
import { GeoAlt, Phone, Envelope, Facebook, Instagram, Twitter, Youtube } from 'react-bootstrap-icons';
import { Link } from "react-router-dom";

function Footer() {
    return (
        <Container fluid>

            <Row className="px-5 bg-dark justify-content-md-center">
                <Col className=" py-5" md="2">
                    <p style={{ color: "#fff", fontSize: 25 }}>Contact Us</p>
                    <Row xs="auto">
                        <GeoAlt color="white" size={16} /><p style={{ color: "#fff", fontSize: 14 }}>Locations</p>
                    </Row>
                    <Row xs="auto">
                        <Phone color="white" size={16} /><p style={{ color: "#fff", fontSize: 14 }}>+01 9876543210</p>
                    </Row>
                    <Row xs="auto">
                        <Envelope color="white" size={16} /><p style={{ color: "#fff", fontSize: 14 }}>streettoonline@gmail.com</p>
                    </Row>

                </Col>

                <Col className=" py-5" md="2">
                    <p style={{ color: "#fff", fontSize: 25 }}>About Us</p>
                    <p style={{ color: "#fff", fontSize: 14 }}>A comprehensive medical system providing solution for clinics and hospitals to offer a seamless appointment booking experience to their patients.</p>
                </Col>

                <Col className=" py-5" md="2">
                    <p style={{ color: "#fff", fontSize: 25 }}>Instagram</p>
                    <Row className="py-2">
                        <Col xs="auto">
                            <Image src={UserImageInsta} className="banner_img" alt="check" width={50} height={40} />
                        </Col>
                        <Col xs="auto">
                            <Image src={UserImageInsta} className="banner_img" alt="check" width={50} height={40} />
                        </Col>
                    </Row>
                    <Row className="py-2">
                        <Col xs="auto">
                            <Image src={UserImageInsta} className="banner_img" alt="check" width={50} height={40} />
                        </Col>
                        <Col xs="auto">
                            <Image src={UserImageInsta} className="banner_img" alt="check" width={50} height={40} />
                        </Col>
                    </Row>
                    <Row className="py-2">
                        <Col xs="auto">
                            <Image src={UserImageInsta} className="banner_img" alt="check" width={50} height={40} />
                        </Col>
                        <Col xs="auto">
                            <Image src={UserImageInsta} className="banner_img" alt="check" width={50} height={40} />
                        </Col>
                    </Row>
                </Col>

                <Col className=" py-5" md="2">
                    <p style={{ color: "#fff", fontSize: 25 }}>NewsLetter</p>
                    <Form.Control type="email" placeholder="Enter your email" style={{ fontSize: 14 }} />

                    <Button href="#" size="sm" style={{ marginTop: 20, marginBottom: 20, backgroundColor: "#fd5252", borderColor: "#fd5252" }}>Subscribe</Button>
                    <Row xs="auto">
                        <Facebook color="white" size={20} />
                        <Instagram color="white" size={20} />
                        <Twitter color="white" size={20} />
                        <Youtube color="white" size={20} />
                    </Row>
                </Col>

            </Row>

            <Row className="bg-dark justify-content-center">
                <Col xxl="5" className="text-center">
                    <hr style={{ color: "#fff", borderColor: "#fff", borderWidth: 2, }} />
                    <p style={{ color: "#fff", fontSize: 14 }}>Copyright 2024 All Right Reserved By E-Arogya</p>
                    <Link to="/privacypolicy"><p style={{ fontSize: 14 }} >Privacy Policy</p></Link>

                </Col>
            </Row>

        </Container>
    )
}

export default Footer;