import React, { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./admincss.css";
import HospitalSidebar from "../Navbar/HospitalSidebar";
import Adminheader from "../Navbar/Adminheader";
import { CDBCard, CDBCardBody, CDBDataTable } from 'cdbreact';
import { Col, Form, Row, Button, Spinner, Modal } from "react-bootstrap";
import { Asterisk } from "react-bootstrap-icons";
// import { API_BASE } from '../../setupProxy';
import * as moment from 'moment';

const HospitalHolidays = () => {

    const intOrganizationID = localStorage.getItem("orgid");

    const [orgHolidays, setOrgHolidays] = useState([]);
    const [loading, setLoading] = useState(false);

    const [intHolidayID, setIntHolidayID] = useState(null);
    const [dtmDate, setDtmDate] = useState("");
    const [strOffFromTime, setStrOffFromTime] = useState("");
    const [strOffToTime, setStrOffToTime] = useState("");
    const [strReason, setStrReason] = useState("");
    const [strstatus, setStatus] = useState('Active');

    const [updateMessage, setUpdateMessage] = useState("");

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [deleteThis, setDeleteThis] = useState(null);

    useEffect(() => {
        fetchOrgHolidays(intOrganizationID);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //FETCH ALL ORG HOLIDAYS
    const fetchOrgHolidays = (orgid) => {
        fetch("https://e-arogya.com:8443/api/general/orgholidays/list/" + orgid)
            .then(response => response.json())
            .then(orgholidaydata => {
                let dataFromApi = orgholidaydata.map(orgholi => {
                    return {
                        id: orgholi.intHolidayID,
                        date: orgholi.dtmDate,
                        fromtime: orgholi.strOffFromTime,
                        totime: orgholi.strOffToTime,
                        reason: orgholi.strReason,
                        status: orgholi.strstatus
                    }
                })
                setOrgHolidays(dataFromApi);
                setLoading(true);
            })
            .catch(error => {
                //console.log(error);
            })
    }

    const clearFields = () => {
        setDtmDate("");
        setStrOffFromTime("");
        setStrOffToTime("");
        setStrReason("");
        setStatus('Active');
    }

    //FORM SUBMIT
    const onAddNewOrgHolidaysFormSubmit = (event) => {
        event.preventDefault();

        if (dtmDate === '' || strOffFromTime === "" || strOffToTime === "") {
            setUpdateMessage(<span style={{ color: "red" }}>Please select Date and Time</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
        }
        else {
            if (intHolidayID) {
                const existingInputs = { intOrganizationID, dtmDate, strOffFromTime, strOffToTime, strReason, strstatus }
                //console.log(intOrganizationID + " " + dtmDate + " " + strOffFromTime + " " + strOffToTime + " " + strReason + " " + strstatus);
                updateOrgHoliday(existingInputs, intHolidayID);
                setIntHolidayID(null);
                clearFields();
            } else {
                const toInput = { intOrganizationID, dtmDate, strOffFromTime, strOffToTime, strReason, strstatus }
                //console.log(intOrganizationID + " " + dtmDate + " " + strOffFromTime + " " + strOffToTime + " " + strReason + " " + strstatus);
                addNewOrgHoliday(toInput);
                clearFields();
            }
        }

    }

    //FETCH HOSPITAL HOLIDAYS BY ID (FOR EDIT HOLIDAYS)
    const handleEditClick = (e, id) => {
        e.preventDefault();
        fetch("https://e-arogya.com:8443/api/general/orgholidays/" + id)
            .then(res => res.json())
            .then(data => {
                setIntHolidayID(data[0].intHolidayID);
                setDtmDate(data[0].dtmDate);
                setStrOffFromTime(data[0].strOffFromTime);
                setStrOffToTime(data[0].strOffToTime)
                setStrReason(data[0].strReason)
                setStatus(data[0].strstatus);
            })
            .catch(error => {
                //console.log(error);
            });
    }

    //ADD NEW HOSPITAL HOLIDAYS
    async function addNewOrgHoliday(toInput) {
        fetch("https://e-arogya.com:8443/api/general/orgholidays/addnewOrgHoliday", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            redirect: "follow",
            body: JSON.stringify(toInput)
        })
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();

                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }
                setUpdateMessage(<span style={{ color: "green" }}>Org Holidays Added Successfully</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
                fetchOrgHolidays(intOrganizationID);
            })
            .catch(error => {
                setUpdateMessage(<span style={{ color: "red" }}>Org Holidays Not Added. Please try again.</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
            });

    }

    //UPDATE EXISTING HOSPITAL HOLIDAYS
    async function updateOrgHoliday(existingInputs, intHolidayID) {
        fetch("https://e-arogya.com:8443/api/general/orgholidays/update/" + intHolidayID, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            redirect: "follow",
            body: JSON.stringify(existingInputs)
        })
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();

                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }
                setUpdateMessage(<span style={{ color: "green" }}>Org Holidays Updated Successfully</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
                fetchOrgHolidays(intOrganizationID);
            })
            .catch(error => {
                setUpdateMessage(<span style={{ color: "red" }}>Org Holidays Not Updated. Please try again.</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
            });

    }

    //DELETE HOSPITAL HOLIDAYS
    function handleDeleteClick(e, id) {
        setShow(true);
        setDeleteThis(id);
    }

    const deleteThisFunc = (id) => {
        fetch("https://e-arogya.com:8443/api/general/orgholidays/" + id, { method: 'DELETE' })
            .then(async response => {
                const data = await response.text;

                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }

                setUpdateMessage(<span style={{ color: "green" }}>Org Holidays Deleted Successfully</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
                setDeleteThis(null);
                setIntHolidayID(null);
                clearFields();
                fetchOrgHolidays(intOrganizationID);
            })
            .catch(error => {
                setUpdateMessage(<span style={{ color: "red" }}>Org Holidays Not Deleted. Please try again.</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
            });
    }

    const data = () => {
        return {
            columns: [
                { label: '#', field: 'srno', },
                {
                    label: 'Date',
                    field: 'date',
                    attributes: {
                        'aria-controls': 'DataTable',
                        'aria-label': 'Days',
                    },
                },
                { label: 'From Time', field: 'fromtime', },
                { label: 'To Time', field: 'totime', },
                { label: 'Reason', field: 'reason', },
                { label: 'Status', field: 'status', },
                { label: '', field: 'edit', },
                { label: '', field: 'delete', },

            ],
            rows: [...orgHolidays.map((data1, index) => (
                {
                    srno: index + 1,
                    date: moment(data1.date).format('DD-MMM-YYYY'),
                    fromtime: data1.fromtime,
                    totime: data1.totime,
                    reason: data1.reason,
                    status: data1.status,
                    edit: <span onClick={(e) => handleEditClick(e, data1.id)}>
                        <Button variant="primary" type="submit" style={{ fontSize: 12 }} size="sm">
                            Edit
                        </Button>
                    </span>,
                    delete: <span onClick={(e) => handleDeleteClick(e, data1.id)}>
                        <Button variant="danger" type="submit" style={{ fontSize: 12 }} size="sm">
                            Delete
                        </Button>
                    </span>
                }
            ))

            ],
        };
    };

    return (

        <div className="main" >

            <HospitalSidebar />

            <div className="page-content">

                <Adminheader />

                <h5>Hospital Holidays</h5>

                <div className="table-content">

                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title style={{ fontSize: 18 }}>Delete Holiday</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ fontSize: 14 }}>Are you sure you want to delete this holiday?</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose} style={{ fontSize: 12 }}>No</Button>
                            <Button variant="primary" onClick={() => {
                                handleClose();
                                deleteThisFunc(deleteThis);
                            }} style={{ fontSize: 12 }}>Yes</Button>
                        </Modal.Footer>
                    </Modal>

                    <Form onSubmit={onAddNewOrgHolidaysFormSubmit}>

                        <Row style={{ fontSize: 12, color: "grey" }}>

                            <Col sm={2}>
                                <Form.Group className="mb-3" controlId="formEnterState">
                                    <Form.Label><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Date</Form.Label>
                                    <Form.Control type="date" style={{ fontSize: 12 }} value={dtmDate || ""} onChange={e => { setDtmDate(e.target.value) }} required />
                                </Form.Group>
                            </Col>

                            <Col sm={2}>
                                <Form.Group className="mb-3" controlId="formEnterState">
                                    <Form.Label><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Off From Time</Form.Label>
                                    <Form.Control type="time" style={{ fontSize: 12 }} value={strOffFromTime || ""} onChange={e => { setStrOffFromTime(e.target.value) }} required />
                                </Form.Group>
                            </Col>
                            <Col sm={2}>
                                <Form.Group className="mb-3" controlId="formEnterState">
                                    <Form.Label><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Off To Time</Form.Label>
                                    <Form.Control type="time" style={{ fontSize: 12 }} value={strOffToTime || ""} onChange={e => { setStrOffToTime(e.target.value) }} required />
                                </Form.Group>
                            </Col>

                            <Col sm={2}>
                                <Form.Group className="mb-3" controlId="formEnterState">
                                    <Form.Label>Reason</Form.Label>
                                    <Form.Control type="text" style={{ fontSize: 12 }} value={strReason || ""} onChange={e => { setStrReason(e.target.value) }} />
                                </Form.Group>
                            </Col>

                            <Col sm={2}>
                                <Form.Group className="mb-3" controlId="formSelectStateStatus">
                                    <Form.Label>Status</Form.Label>
                                    <Form.Select aria-label="Default select example" style={{ fontSize: 12 }} value={strstatus || ""} onChange={e => { setStatus(e.target.value) }}>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>

                            <Col sm={2} >
                                <Form.Group className="mb-3" controlId="formSelectStateStatus">
                                    {intHolidayID ?
                                        <Button variant="primary" type="submit" style={{ marginTop: 25, fontSize: 12 }}> Update </Button>
                                        :
                                        <Button variant="primary" type="submit" style={{ marginTop: 25, fontSize: 12 }}> Add </Button>
                                    }
                                </Form.Group>
                            </Col>

                        </Row>

                    </Form>

                    <div style={{ fontSize: 12, fontWeight: "bold", marginBottom: 10 }}>{updateMessage}</div>

                    <CDBCard style={{ padding: 20, fontSize: 12, color: "grey" }}>
                        <CDBCardBody>

                            {!loading ? (
                                <div style={{ textAlign: "center" }}><Spinner animation="border" size="sm" variant="primary" /></div>
                            ) :
                                <CDBDataTable
                                    responsive
                                    noRecordsFoundLabel="No Records Found"
                                    noBottomColumn={true}
                                    hover
                                    entriesOptions={[10, 25, 50, 100, 200, 500]}
                                    entries={10}
                                    pagesAmount={4}
                                    data={data()}
                                />
                            }
                        </CDBCardBody>
                    </CDBCard>

                </div>

            </div>
        </div>

    );
};

export default HospitalHolidays;