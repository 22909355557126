import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./admincss.css";
import Sidebar from "../Navbar/Sidebar";
import Adminheader from "../Navbar/Adminheader";
import { CDBCard, CDBCardBody, CDBDataTable } from 'cdbreact';
import { Col, Row, Form, Button, Image } from "react-bootstrap";
// import { API_BASE } from '../../setupProxy';

const ManageFileUpload = () => {

    const [files, setFiles] = useState('');
    const [fileSize, setFileSize] = useState(true);
    const [fileUploadProgress, setFileUploadProgress] = useState(false);
    const [fileUploadResponse, setFileUploadResponse] = useState(null);

    const [fileList, setFileList] = useState([]);
    const [imagefile, setImageFile] = useState(null);

    useEffect(() => {
        fetchData()
    }, [])

    // //FETCH ALL USERS
    // const fetchData = () => {

    //     fetch("https://e-arogya.com:8443/organization/type/Laboratory")
    //         .then(res => res.json())
    //         .then(data => {
    //             console.log(data, "data")
    //             let dataFromApi = data.map(org => {
    //                 return {
    //                     id: org.intorganizationid,
    //                     name: org.strorganizationname,
    //                     logo: org.strlogo,
    //                     website: org.strwebsite,
    //                     address1: org.straddressline1,
    //                     address2: org.straddressline2,
    //                     area: org.strarea,
    //                     phone: org.strphoneno1,
    //                     type: org.strorganizationtype,
    //                     status: org.strstatus,


    //                 }
    //             })

    //             setOrganization(dataFromApi);
    //             setLoading(true);
    //         })
    //         .catch(error => {
    //             console.log(error);
    //         });

    // }

    //FETCH ALL FILE LIST
    const fetchData = () => {
        fetch("https://e-arogya.com:8443/api/general/fileslist")
            .then(res => res.json())
            .then(data => {
                let dataFromApi = data.map(file => {
                    return {
                        id: file.intfileid,
                        name: file.strfilename,
                        type: file.strfiletype,
                        imagedata: file.grpdata
                    }
                })
                setFileList(dataFromApi);
            })
            .catch(error => {
                //console.log(error);
            });

    }

    const handleViewClick = (e, imagedata) => {
        e.preventDefault();

        setImageFile(imagedata);
    }

    const fileSubmitHandler = (event) => {
        event.preventDefault();
        setFileSize(true);
        setFileUploadProgress(true);
        setFileUploadResponse(null);

        const formData = new FormData();

        for (let i = 0; i < files.length; i++) {
            if (files[i].size > 60000) {
                setFileSize(false);
                setFileUploadProgress(false);
                setFileUploadResponse(null);

                return;

            }

            formData.append(`files`, files[i])
        }

        const requestOptions = {
            method: 'POST',
            body: formData
        };
        fetch("https://e-arogya.com:8443/api/general/upload", requestOptions)
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();

                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    setFileUploadResponse(data.message);
                    return Promise.reject(error);
                }

                //console.log(data.message);
                setFileUploadResponse(data.message);

            })
            .catch(error => {
                //console.error('Error while uploading file!', error);
            });
        setFileUploadProgress(false);
    };

    const fileData = () => {
        return {
            columns: [
                { label: '#', field: 'srno', },
                {
                    label: 'Name',
                    field: 'name',
                    attributes: {
                        'aria-controls': 'DataTable',
                        'aria-label': 'Name',
                    },
                },
                { label: 'Type', field: 'type', },
                { label: '', field: 'edit', },

            ],
            rows: [...fileList.map((data, i) => (
                {
                    srno: data.id,
                    name: data.name,
                    type: data.type,
                    edit: <span onClick={(e) => { handleViewClick(e, data.imagedata) }}>
                        <Button variant="primary" type="submit" style={{ fontSize: 12 }} size="sm">
                            View
                        </Button>
                    </span>

                }
            ))

            ],
        };
    };

    // const data = () => {
    //     return {
    //         columns: [
    //             {
    //                 label: '#',
    //                 field: 'srno',
    //             },
    //             {
    //                 label: 'Name',
    //                 field: 'name',
    //                 attributes: {
    //                     'aria-controls': 'DataTable',
    //                     'aria-label': 'Name',
    //                 },
    //             },
    //             {
    //                 label: 'State',
    //                 field: 'state',
    //             },
    //             {
    //                 label: 'City',
    //                 field: 'city',
    //             },
    //             {
    //                 label: 'Contact Number',
    //                 field: 'contactNumber',
    //             },
    //             {
    //                 label: 'Email',
    //                 field: 'email',
    //             },
    //             {
    //                 label: 'Total Bookings',
    //                 field: 'totalBooking',
    //             },
    //             {
    //                 label: 'Total Search',
    //                 field: 'totalSearch',
    //             },
    //             {
    //                 label: 'Register Date',
    //                 field: 'registerDate',
    //             },
    //             {
    //                 label: 'Status',
    //                 field: 'status',
    //             },
    //             {
    //                 label: '',
    //                 field: 'edit',
    //             },
    //             {
    //                 label: '',
    //                 field: 'delete',
    //             },

    //         ],
    //         rows: [
    //             {
    //                 srno: '1',
    //                 name: 'Ravi Sharma',
    //                 state: 'Gujarat',
    //                 city: 'Ahmedabad',
    //                 contactNumber: '9998333333',
    //                 email: 'ravisharma@gmail.com',
    //                 totalBooking: '2',
    //                 totalSearch: '23',
    //                 registerDate: '21-11-2022',
    //                 status: 'Active',
    //                 edit: 'Edit',
    //                 delete: 'Delete',
    //             },
    //             {
    //                 srno: '2',
    //                 name: 'Komal Shah',
    //                 state: 'Gujarat',
    //                 city: 'Ahmedabad',
    //                 contactNumber: '9998333333',
    //                 email: 'komalshah@gmail.com',
    //                 totalBooking: '3',
    //                 totalSearch: '25',
    //                 registerDate: '20-11-2022',
    //                 status: 'Active',
    //                 edit: 'Edit',
    //                 delete: 'Delete',
    //             },

    //         ],
    //     };
    // };


    return (

        <div className="main" >

            <Sidebar />

            <div className="page-content">

                <Adminheader />

                <h5>Manage Users</h5>
                <p style={{ fontSize: 12, color: "grey", marginBottom: 50 }}>Manage Registered Users</p>

                <div className="table-content">

                    <form onSubmit={fileSubmitHandler}>

                        <Row style={{ fontSize: 12, color: "grey" }}>

                            <Col sm={4}>
                                <Form.Group className="mb-3" controlId="formEnterState">
                                    <Form.Label>Upload Prescription</Form.Label>
                                    <Form.Control type="file" style={{ fontSize: 12 }} onChange={e => { setFiles(e.target.files) }} multiple />
                                </Form.Group>
                            </Col>

                            <Col sm={2} >
                                <Form.Group className="mb-3" controlId="formEnterState">
                                    <Button variant="primary" type="submit" style={{ marginTop: 25, fontSize: 12 }}> Add </Button>
                                </Form.Group>
                            </Col>
                        </Row>

                    </form>



                    {!fileSize && <div style={{ color: 'red', fontSize: 12, fontWeight: "bold", marginBottom: 10 }}>File size exceeded!!</div>}
                    {fileUploadProgress && <div style={{ color: 'red', fontSize: 12, fontWeight: "bold", marginBottom: 10 }}>Uploading File(s)</div>}
                    {fileUploadResponse != null && <div style={{ color: 'green', fontSize: 12, fontWeight: "bold", marginBottom: 10 }}>{fileUploadResponse}</div>}

                    <CDBCard style={{ padding: 20, fontSize: 12, color: "grey" }}>
                        <CDBCardBody>
                            <CDBDataTable
                                responsive
                                noRecordsFoundLabel="No Records Found"
                                noBottomColumn={true}
                                hover
                                entriesOptions={[10, 25, 50, 100, 200, 500]}
                                entries={10}
                                pagesAmount={4}
                                data={fileData()}
                            />
                        </CDBCardBody>
                    </CDBCard>

                    {imagefile ?
                        <div><Image src={`data:image/jpeg;escape,${imagefile}`} /></div>
                        :
                        <div></div>
                    }

                    {/* <CDBCard style={{ padding: 20, fontSize: 12, color: "grey" }}>
                        <CDBCardBody>
                            <CDBDataTable
                                responsive
                                noRecordsFoundLabel="No Records Found"
                                noBottomColumn={true}
                                hover
                                entriesOptions={[10, 25, 50, 100, 200, 500]}
                                entries={10}
                                pagesAmount={4}
                                data={data()}
                            />
                        </CDBCardBody>
                    </CDBCard> */}

                </div>

            </div>
        </div>

    );
};

export default ManageFileUpload;