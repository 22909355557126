import React, { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./admincss.css";
import HospitalSidebar from "../Navbar/HospitalSidebar";
import Adminheader from "../Navbar/Adminheader";
import { CDBCard, CDBCardBody, CDBDataTable } from 'cdbreact';
import { Col, Form, Row, Button, Modal, Spinner } from "react-bootstrap";
import { Asterisk } from "react-bootstrap-icons";
// import { API_BASE } from '../../setupProxy';

const HospitalSpecialities = () => {

    const intOrganizationID = localStorage.getItem("orgid");

    const [organizationSpecialities, setOrganizationSpecialities] = useState([]);
    const [specialities, setSpecialities] = useState([]);
    const [loading, setLoading] = useState(false);

    const [intSpecialityOrgID, setIntSpecialityOrgID] = useState(null);
    const [strDetails, setStrDetails] = useState("");
    const [intSpecialityID, setIntSpecialityID] = useState(null);
    const [strstatus, setStatus] = useState('Active');

    const [updateMessage, setUpdateMessage] = useState("");

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [deleteThis, setDeleteThis] = useState(null);

    useEffect(() => {
        fetchSpecialities();
        fetchOrganizationSpecialities(intOrganizationID);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //FETCH ALL ORGANIZATION SPECIALITIES
    const fetchOrganizationSpecialities = (orgid) => {

        fetch("https://e-arogya.com:8443/api/general/organizationspecialities/list/" + orgid)
            .then(response => response.json())
            .then(data => {
                let dataFromApi = data.map(orgspec => {
                    return {
                        id: orgspec.intSpecialityOrgID,
                        name: orgspec.strSpecialityName,
                        status: orgspec.strstatus,
                        details: orgspec.strDetails
                    }
                })
                setOrganizationSpecialities(dataFromApi);
                setLoading(true);
            })
            .catch(error => {
                //console.log(error);
            })
    }

    //FETCH ALL SPECIALITIES (FOR DROPDOWN)
    const fetchSpecialities = () => {
        fetch("https://e-arogya.com:8443/api/general/specialities")
            .then(res => res.json())
            .then(specialitiesData => {
                setSpecialities(specialitiesData);
            })
            .catch(error => {
                //console.log(error);
            })

    }

    const clearFields = () => {
        setIntSpecialityID("");
        setStrDetails("");
        setStatus('Active');
    }

    //FORM SUBMIT
    const onAddNewOrganizationSpecialitiesFormSubmit = (event) => {
        event.preventDefault();
        if (intSpecialityID === "Select" || intSpecialityID === null || intSpecialityID === "") {
            setUpdateMessage(<span style={{ color: "red" }}>Please select speciality</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
        } else {

            if (intSpecialityOrgID) {
                const existingInputs = { intSpecialityID, intOrganizationID, strDetails, strstatus }
                //console.log("Update :" + intSpecialityID + " " + intOrganizationID + " " + strDetails + " " + strstatus + " ");
                updateOrganizationSpecialities(existingInputs, intSpecialityOrgID);
                setIntSpecialityOrgID(null);
                clearFields();
            } else {
                const toInput = { intSpecialityID, intOrganizationID, strDetails, strstatus };
                addNewOrganizationSpecialities(toInput);
                //console.log("Add :" + intSpecialityID + " " + intOrganizationID + " " + strDetails + " " + strstatus + " ");
                clearFields();
            }
        }

    }

    //FETCH SPECIALITIES BY ID (FOR EDIT SPECIALITIES)
    const handleEditClick = (e, id) => {
        e.preventDefault();
        fetch("https://e-arogya.com:8443/api/general/organizationspecialities/" + id)
            .then(res => res.json())
            .then(data => {
                setIntSpecialityOrgID(data[0].intSpecialityOrgID);
                setIntSpecialityID(data[0].intSpecialityID);
                setStrDetails(data[0].strDetails)
                setStatus(data[0].strstatus);
            })
            .catch(error => {
                //console.log(error);
            });
    }

    //ADD NEW ORGANIZATION SPECIALITIES
    async function addNewOrganizationSpecialities(toInput) {
        fetch("https://e-arogya.com:8443/api/general/organizationspecialities/addneworganizationspecialities", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            redirect: "follow",
            body: JSON.stringify(toInput)
        })
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();

                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }
                setUpdateMessage(<span style={{ color: "green" }}>Organization Specialities Added Successfully</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
                fetchOrganizationSpecialities(intOrganizationID);
            })
            .catch(error => {
                setUpdateMessage(<span style={{ color: "red" }}>Organization Specialities Not Added. Please try again.</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
            });
    }

    //UPDATE EXISTING ORGANIZATION SPECIALITIES
    async function updateOrganizationSpecialities(existingInputs, intSpecialityOrgID) {
        fetch("https://e-arogya.com:8443/api/general/organizationspecialities/update/" + intSpecialityOrgID, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            redirect: "follow",
            body: JSON.stringify(existingInputs)
        })
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();

                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }
                setUpdateMessage(<span style={{ color: "green" }}>Organization Specialities Updated  Successfully</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
                fetchOrganizationSpecialities(intOrganizationID);
            })
            .catch(error => {
                setUpdateMessage(<span style={{ color: "red" }}>Organization Specialities Not Updated. Please try again.</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
            });

    }

    //DELETE ORGANIZATION SPECIALITIES
    function handleDeleteClick(e, id) {
        setShow(true);
        setDeleteThis(id);
    }

    const deleteThisFunc = (id) => {
        fetch("https://e-arogya.com:8443/api/general/organizationspecialities/" + id, { method: 'DELETE' })
            .then(async response => {
                const data = await response.text;

                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }

                setUpdateMessage(<span style={{ color: "green" }}>Organization Specialities Deleted Successfully</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
                setDeleteThis(null);
                setIntSpecialityOrgID(null);
                clearFields();
                fetchOrganizationSpecialities(intOrganizationID);
            })
            .catch(error => {
                setUpdateMessage(<span style={{ color: "red" }}>Organization Specialities Not Deleted. Please try again.</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
            });
    }

    const data = () => {
        return {
            columns: [
                { label: '#', field: 'srno', },
                {
                    label: 'Speciality Name',
                    field: 'name',

                    attributes: {
                        'aria-controls': 'DataTable',
                        'aria-label': 'Name',
                    },
                },
                { label: 'Details', field: 'details', },
                { label: 'Status', field: 'status', },
                { label: '', field: 'edit', },
                { label: '', field: 'delete', },

            ],
            rows: [...organizationSpecialities.map((data1, index) => (
                {
                    srno: index + 1,
                    name: data1.name,
                    details: data1.details,
                    status: data1.status,
                    edit: <span onClick={(e) => handleEditClick(e, data1.id)}>
                        <Button variant="primary" type="submit" style={{ fontSize: 12 }} size="sm">
                            Edit
                        </Button>
                    </span>,
                    delete: <span onClick={(e) => handleDeleteClick(e, data1.id)}>
                        <Button variant="danger" type="submit" style={{ fontSize: 12 }} size="sm">
                            Delete
                        </Button>
                    </span>
                }
            ))

            ],
        };
    };
    return (

        <div className="main" >

            <HospitalSidebar />

            <div className="page-content">

                <Adminheader />

                <h5>Manage Hospital Specialities (Department)</h5>

                <div className="table-content">

                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title style={{ fontSize: 18 }}>Delete Speciality</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ fontSize: 14 }}>Are you sure you want to delete this Speciality?</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose} style={{ fontSize: 12 }}>No</Button>
                            <Button variant="primary" onClick={() => {
                                handleClose();
                                deleteThisFunc(deleteThis);
                            }} style={{ fontSize: 12 }}>Yes</Button>
                        </Modal.Footer>
                    </Modal>

                    <Form onSubmit={onAddNewOrganizationSpecialitiesFormSubmit}>

                        <Row style={{ fontSize: 12, color: "grey" }}>

                            <Col sm={2}>
                                <Form.Group className="mb-3" controlId="formSelectStateStatus">
                                    <Form.Label><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Speciality Name</Form.Label>
                                    <Form.Select aria-label="Default select example" style={{ fontSize: 12 }} value={intSpecialityID || ""} onChange={e => { setIntSpecialityID(e.target.value) }}>
                                        <option value="Select">- Select -</option>
                                        {specialities.map((data, key) => <option key={data.intSpecialityID} value={data.intSpecialityID} >{data.strSpecialityName}</option>)}
                                    </Form.Select>
                                </Form.Group>
                            </Col>

                            <Col sm={2}>
                                <Form.Group className="mb-3" controlId="formEnterState">
                                    <Form.Label>Details</Form.Label>
                                    <Form.Control type="text" style={{ fontSize: 12 }} value={strDetails || ""} onChange={e => { setStrDetails(e.target.value) }} />
                                </Form.Group>
                            </Col>

                            <Col sm={2}>
                                <Form.Group className="mb-3" controlId="formSelectStateStatus">
                                    <Form.Label>Status</Form.Label>
                                    <Form.Select aria-label="Default select example" style={{ fontSize: 12 }} value={strstatus || ""} onChange={e => { setStatus(e.target.value) }}>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>

                            <Col sm={2} >
                                <Form.Group className="mb-3" controlId="formEnterState">
                                    {intSpecialityOrgID ?
                                        <Button variant="primary" type="submit" style={{ marginTop: 25, fontSize: 12 }}> Update </Button>
                                        :
                                        <Button variant="primary" type="submit" style={{ marginTop: 25, fontSize: 12 }}> Add </Button>
                                    }
                                </Form.Group>
                            </Col>

                        </Row>

                    </Form>

                    <div style={{ fontSize: 12, fontWeight: "bold", marginBottom: 10 }}>{updateMessage}</div>

                    <CDBCard style={{ padding: 20, fontSize: 12, color: "grey" }}>
                        <CDBCardBody>
                            {!loading ? (
                                <div style={{ textAlign: "center" }}><Spinner animation="border" size="sm" variant="primary" /></div>
                            ) :
                                <CDBDataTable
                                    responsive
                                    noRecordsFoundLabel="No Records Found"
                                    noBottomColumn={true}
                                    hover
                                    entriesOptions={[10, 25, 50, 100, 200, 500]}
                                    entries={10}
                                    pagesAmount={4}
                                    data={data()}
                                />
                            }
                        </CDBCardBody>
                    </CDBCard>

                </div>

            </div>
        </div>

    );
};

export default HospitalSpecialities;