import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./admincss.css";
import Adminheader from "../Navbar/Adminheader";
import { Card, CardGroup, Row, Col, Container } from "react-bootstrap";
import LaboratorySidebar from "../Navbar/LaboratorySidebar";
import { useNavigate } from "react-router-dom";

function LaboratoryDashboard() {

    const navigate = useNavigate();

    //console.log("Laboratory dashboard - Role " + (localStorage.getItem("loggedRoleName")) + " orgid: " + (localStorage.getItem("orgid")));

    const goToPatient = () => { navigate('/hospitalpatientrecords') }
    const goToAppointments = () => { navigate('/hospitalpatientappointments'); }
    const goToBillings = () => { navigate('/hospitalpatientbillings'); }

    return (

        <div className="main" >

            <LaboratorySidebar />

            <div className="page-content">

                <Adminheader />

                <h5>Welcome</h5>

                <CardGroup>
                    <Container fluid>
                        <Row>
                            <Col md>
                                <Card style={{ margin: 5, borderRadius: 10, }} className="cardclass" onClick={goToPatient}>
                                    <Card.Body>
                                        <Card.Text style={{ fontSize: 12, color: "grey" }}>Patients</Card.Text>
                                        <Card.Title style={{ paddingLeft: 10 }}>225</Card.Title>
                                        {/* <Card.Text style={{ fontSize: 12, color: "grey" }}>+1.9% Last week</Card.Text> */}
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md>
                                <Card style={{ margin: 5, borderRadius: 10, }} className="cardclass" onClick={goToAppointments}>
                                    <Card.Body>
                                        <Card.Text style={{ fontSize: 12, color: "grey" }}>Appointments</Card.Text>
                                        <Card.Title style={{ paddingLeft: 10 }}>556</Card.Title>
                                        {/* <Card.Text style={{ fontSize: 12, color: "grey" }}>+18.9% Last week</Card.Text> */}
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md>
                                <Card style={{ margin: 5, borderRadius: 10, }} className="cardclass" onClick={goToBillings}>
                                    <Card.Body>
                                        <Card.Text style={{ fontSize: 12, color: "grey" }}>Billing</Card.Text>
                                        <Card.Title style={{ paddingLeft: 10 }}>45000</Card.Title>
                                        {/* <Card.Text style={{ fontSize: 12, color: "grey" }}>+1.9% Last week</Card.Text> */}
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </CardGroup>

            </div>
        </div>
    );
}

export default LaboratoryDashboard;