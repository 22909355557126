import React, { useEffect, useState, useRef } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./admincss.css";
import HospitalSidebar from "../Navbar/HospitalSidebar";
import Adminheader from "../Navbar/Adminheader";
import { CDBCard, CDBCardBody, CDBDataTable } from 'cdbreact';
import { Col, Form, Row, Button, Spinner, Modal, Image } from "react-bootstrap";
import { Asterisk } from "react-bootstrap-icons";
// import { API_BASE } from '../../setupProxy';
import * as moment from 'moment';

const HospitalDoctors = () => {

    const intOrganizationID = localStorage.getItem("orgid");

    const [specialities, setSpecialities] = useState([]);
    const [intSpecialityID, setIntSpecialityID] = useState(null);
    const [organizationDoctors, setOrganizationDoctors] = useState([]);
    const [loading, setLoading] = useState(false);
    const [strDoctorName, setStrDoctorName] = useState("");
    const [strContactNo, setStrContactNo] = useState("");
    const [strDoctorEmail, setStrDoctorEmail] = useState("");
    const [strGender, setStrGender] = useState("");
    const [dtmBirthdate, setDtmBirthdate] = useState("");
    const [strstatus, setStatus] = useState('Active');
    const [intDoctorID, setIntDoctorID] = useState("");
    const [strMciRegistrationNo, setStrMciRegistrationNo] = useState("");

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [deleteThis, setDeleteThis] = useState(null);

    const [updateMessage, setUpdateMessage] = useState("");
    const [fileSize, setFileSize] = useState("");

    const [sigfiles, setSigFiles] = useState('');
    const [sigimagefile, setSigImageFile] = useState(null);
    const aRefSig = useRef(null);

    const [certifiles, setCertiFiles] = useState('');
    const [certiimagefile, setCertiImageFile] = useState(null);
    const aRefCerti = useRef(null);

    useEffect(() => {
        fetchOrganizationDoctors(intOrganizationID);
        fetchSpecialityByOrganization(intOrganizationID);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //FETCH ALL SPECIALITIES (FOR DROPDOWN)
    const fetchSpecialityByOrganization = (orgid) => {
        fetch("https://e-arogya.com:8443/api/general/organizationspecialities/list/" + orgid)
            .then(res => res.json())
            .then(spec => {
                setSpecialities(spec);
            })
            .catch(error => {
                //console.log(error);
            })
    }

    //FETCH ALL ORGANIZATION DOCTORS
    const fetchOrganizationDoctors = (orgid) => {
        fetch("https://e-arogya.com:8443/api/general/hospitaldoctors/list/" + orgid)
            .then(response => response.json())
            .then(data => {
                let dataFromApi = data.map(orgdoc => {
                    return {
                        id: orgdoc.intDoctorID,
                        name: orgdoc.strDoctorName,
                        mcino: orgdoc.strMciRegistrationNo,
                        mobile: orgdoc.strContactNo,
                        email: orgdoc.strDoctorEmail,
                        gender: orgdoc.strGender,
                        dob: orgdoc.dtmBirthdate,
                        speciality: orgdoc.strSpecialityName,
                        status: orgdoc.strstatus,
                        signature: orgdoc.strDoctorSign,
                        certificate: orgdoc.strDoctorCertificate,
                    }
                })
                setOrganizationDoctors(dataFromApi);
                setLoading(true);
            })
            .catch(error => {
                //console.log(error);
            })
    }

    const clearFields = () => {
        setStrDoctorName("");
        setStrContactNo("");
        setStrDoctorEmail("");
        setStrGender("");
        setDtmBirthdate("");
        setIntSpecialityID('- Select -');
        setStatus('Active');
        setStrMciRegistrationNo("");
        setSigImageFile(null);
        setCertiImageFile(null);
        setSigFiles("");
        setCertiFiles("");
        aRefSig.current.value = null;
        aRefCerti.current.value = null;
    }

    //FORM SUBMIT
    const onAddNewOrganizationDoctorsFormSubmit = (event) => {
        event.preventDefault();

        const formData = new FormData();

        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;  // eslint-disable-line

        if (strDoctorName === "") {
            setUpdateMessage(<span style={{ color: "red" }}>Please Enter Doctor's Name</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
        } else if (strContactNo === "" || strContactNo.length !== 10 || isNaN(strContactNo)) {
            setUpdateMessage(<span style={{ color: "red" }}>Please Enter Valid 10 digit Mobile number</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
        } else if (strDoctorEmail === "" || reg.test(strDoctorEmail) === false) {
            setUpdateMessage(<span style={{ color: "red" }}>Please Enter Valid Email Id</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
        } else if (intSpecialityID === "- Select -" || intSpecialityID === "" || intSpecialityID === null) {
            setUpdateMessage(<span style={{ color: "red" }}>Please Select Speciality</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
        } else if (strGender === "- Select -" || strGender === "") {
            setUpdateMessage(<span style={{ color: "red" }}>Please Select Gender</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
        } else if (dtmBirthdate === "") {
            setUpdateMessage(<span style={{ color: "red" }}>Please Select Birth Date</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
        } else {

            formData.append('strDoctorName', strDoctorName);
            formData.append('strContactNo', strContactNo);
            formData.append('strDoctorEmail', strDoctorEmail);
            formData.append('intSpecialityID', intSpecialityID);
            formData.append('strGender', strGender);
            formData.append('dtmBirthdate', dtmBirthdate);
            formData.append('strstatus', strstatus);
            if (strMciRegistrationNo) { formData.append('strMciRegistrationNo', strMciRegistrationNo); }
            if (sigfiles !== 0) {
                for (let i = 0; i < sigfiles.length; i++) {
                    if (sigfiles[i].size > 20000) {
                        setFileSize(<span style={{ color: "red" }}>Signature File size exceeded!! Please select filesize less than 20KB.</span>);
                        setTimeout(() => setFileSize(""), 3000);
                        return;
                    }
                    formData.append('sigfiles', sigfiles[i])
                }
            }

            if (certifiles !== 0) {
                for (let i = 0; i < certifiles.length; i++) {
                    if (certifiles[i].size > 1000000) {
                        setFileSize(<span style={{ color: "red" }}>Certificate File size exceeded!! Please select filesize less than 20KB.</span>);
                        setTimeout(() => setFileSize(""), 3000);
                        return;
                    }
                    formData.append('certifiles', certifiles[i])
                }
            }

            // console.log(" " + strDoctorName + " " + strContactNo + " " + strDoctorEmail + " " + intSpecialityID + " " + strGender + " " + dtmBirthdate + " " + strstatus +
            //     " " + strMciRegistrationNo + " " + sigfiles + " " + certifiles);

            if (intDoctorID) {
                updateOrganizationDoctors(formData, intDoctorID);
            } else {
                addNewOrganizationDoctors(formData, intOrganizationID);
            }
        }

    }

    //FETCH DOCTORS BY ID (FOR EDIT DOCTORS)
    const handleEditClick = (e, id) => {
        e.preventDefault();
        fetch("https://e-arogya.com:8443/api/general/hospitaldoctors/" + id)
            .then(res => res.json())
            .then(data => {
                setIntDoctorID(data[0].intDoctorID);
                setStrDoctorName(data[0].strDoctorName);
                setStrContactNo(data[0].strContactNo);
                setStrDoctorEmail(data[0].strDoctorEmail);
                setStrGender(data[0].strGender);
                setDtmBirthdate(data[0].dtmBirthdate);
                setIntSpecialityID(data[0].intSpecialityID);
                setStatus(data[0].strstatus);
                setStrMciRegistrationNo(data[0].strMciRegistrationNo);
                setSigImageFile(data[0].strDoctorSign);
                setCertiImageFile(data[0].strDoctorCertificate);
            })
            .catch(error => {
                //console.log(error);
            });
    }

    //ADD NEW ORGANIZATION DOCTORS
    async function addNewOrganizationDoctors(formData, intOrganizationID) {

        const requestOptions = {
            method: 'POST',
            body: formData
        };

        fetch("https://e-arogya.com:8443/api/general/hospitaldoctors/addnewhospitaldoctors/" + intOrganizationID, requestOptions)
            .then(res => {
                if (res.ok) {
                    setUpdateMessage(<span style={{ color: "green" }}>Hospital Doctors Added Successfully</span>);
                    setTimeout(() => setUpdateMessage(""), 3000);
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    fetchOrganizationDoctors(intOrganizationID);
                    clearFields();
                } else {
                    setUpdateMessage(<span style={{ color: "red" }}>Hospital Doctors Not Added</span>);
                    setTimeout(() => setUpdateMessage(""), 3000);
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    clearFields();
                }
            })
            .catch(error => {
                setUpdateMessage(<span style={{ color: "red" }}>Hospital Doctors Not Added. Please Try Again.</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
                window.scrollTo({ top: 0, behavior: 'smooth' });
                clearFields();
            });
    }

    //UPDATE EXISTING ORGANIZATION DOCTORS
    async function updateOrganizationDoctors(formData, intDoctorID) {
        const requestOptions = {
            method: 'PUT',
            body: formData
        };

        fetch("https://e-arogya.com:8443/api/general/hospitaldoctors/update/" + intDoctorID, requestOptions)
            .then(res => {
                if (res.ok) {
                    setUpdateMessage(<span style={{ color: "green" }}>Hospital Doctors Updated Successfully</span>);
                    setTimeout(() => setUpdateMessage(""), 3000);
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    fetchOrganizationDoctors(intOrganizationID);
                    setIntDoctorID(null);
                    clearFields();
                } else {
                    setUpdateMessage(<span style={{ color: "red" }}>Hospital Doctors Not Updated</span>);
                    setTimeout(() => setUpdateMessage(""), 3000);
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    setIntDoctorID(null);
                    clearFields();
                }
            })
            .catch(error => {
                setUpdateMessage(<span style={{ color: "red" }}>Hospital Doctors Not Updated. Please try again.</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
                window.scrollTo({ top: 0, behavior: 'smooth' });
                setIntDoctorID(null);
                clearFields();
            });
    }

    //DELETE ORGANIZATION DOCTORS
    function handleDeleteClick(e, id) {
        setShow(true);
        setDeleteThis(id);
    }

    const deleteThisFunc = (id) => {
        fetch("https://e-arogya.com:8443/api/general/hospitaldoctors/" + id, { method: 'DELETE' })
            .then(async response => {
                const data = await response.text;

                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }
                setUpdateMessage(<span style={{ color: "green" }}>Hospital Doctors Deleted Successfully</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
                setDeleteThis(null);
                setIntDoctorID(null);
                clearFields();
                fetchOrganizationDoctors(intOrganizationID);
            })
            .catch(error => {
                setUpdateMessage(<span style={{ color: "red" }}>Hospital Doctors Not Deleted. Please try again.</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
            });
    }


    const data = () => {
        return {
            columns: [
                { label: '#', field: 'srno', },
                {
                    label: 'Name',
                    field: 'name',
                    attributes: {
                        'aria-controls': 'DataTable',
                        'aria-label': 'Name',
                    },
                },
                { label: 'Mobile', field: 'mobile', },
                { label: 'Email', field: 'email', },
                { label: 'Gender', field: 'gender', },
                { label: 'Date of Birth', field: 'dob', },
                { label: 'Speciality', field: 'speciality', },
                { label: 'MCI Registration No', field: 'mcino', },
                { label: 'Status', field: 'status', },
                { label: '', field: 'edit', },
                { label: '', field: 'delete', },
            ],
            rows: [...organizationDoctors.map((data1, index) => (
                {
                    srno: index + 1,
                    name: data1.name,
                    mobile: data1.mobile,
                    email: data1.email,
                    gender: data1.gender,
                    dob: moment(data1.dob).format('DD-MMM-YYYY'),
                    mcino: data1.mcino,
                    speciality: data1.speciality,
                    status: data1.status,
                    edit: <span onClick={(e) => handleEditClick(e, data1.id)}>
                        <Button variant="primary" type="submit" style={{ fontSize: 12 }} size="sm">
                            Edit
                        </Button>
                    </span>,
                    delete: <span onClick={(e) => handleDeleteClick(e, data1.id)}>
                        <Button variant="danger" type="submit" style={{ fontSize: 12 }} size="sm">
                            Delete
                        </Button>
                    </span>
                }
            ))

            ],
        };
    };
    return (

        <div className="main" >

            <HospitalSidebar />

            <div className="page-content">

                <Adminheader />

                <h5>Manage Hospital Doctors</h5>

                <div className="table-content">

                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title style={{ fontSize: 18 }}>Delete Doctor</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ fontSize: 14 }}>Are you sure you want to delete this Doctor?</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose} style={{ fontSize: 12 }}>No</Button>
                            <Button variant="primary" onClick={() => {
                                handleClose();
                                deleteThisFunc(deleteThis);
                            }} style={{ fontSize: 12 }}>Yes</Button>
                        </Modal.Footer>
                    </Modal>

                    <Form onSubmit={onAddNewOrganizationDoctorsFormSubmit}>

                        <Row style={{ fontSize: 12, color: "grey" }}>

                            <Col sm={2}>
                                <Form.Group className="mb-3" controlId="formEnterState">
                                    <Form.Label><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Doctor's Name</Form.Label>
                                    <Form.Control type="text" style={{ fontSize: 12 }} value={strDoctorName || ""} onChange={e => { setStrDoctorName(e.target.value) }} required />
                                </Form.Group>
                            </Col>

                            <Col sm={2}>
                                <Form.Group className="mb-3" controlId="formEnterState">
                                    <Form.Label><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Mobile</Form.Label>
                                    <Form.Control type="text" style={{ fontSize: 12 }} value={strContactNo || ""} onChange={e => { setStrContactNo(e.target.value) }} required />
                                </Form.Group>
                            </Col>

                            <Col sm={2}>
                                <Form.Group className="mb-3" controlId="formEnterState">
                                    <Form.Label><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Email</Form.Label>
                                    <Form.Control type="text" style={{ fontSize: 12 }} value={strDoctorEmail || ""} onChange={e => { setStrDoctorEmail(e.target.value) }} required />
                                </Form.Group>
                            </Col>

                            <Col sm={2}>
                                <Form.Group className="mb-3" controlId="formSelectStateStatus">
                                    <Form.Label><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Gender</Form.Label>
                                    <Form.Select aria-label="Default select example" style={{ fontSize: 12 }} value={strGender || ""} onChange={e => { setStrGender(e.target.value) }} required>
                                        <option value="- Select -">- Select -</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>

                        </Row>

                        <Row style={{ fontSize: 12, color: "grey" }}>

                            <Col sm={2}>
                                <Form.Group className="mb-3" controlId="formEnterState">
                                    <Form.Label><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Date of Birth</Form.Label>
                                    <Form.Control type="date" max={new Date().toISOString().substring(0, 10)} style={{ fontSize: 12 }} value={dtmBirthdate || ""} onChange={e => { setDtmBirthdate(e.target.value) }} required />
                                </Form.Group>
                            </Col>

                            <Col sm={2}>
                                <Form.Group className="mb-3" controlId="formSelectStateStatus">
                                    <Form.Label><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Speciality</Form.Label>
                                    <Form.Select aria-label="Default select example" style={{ fontSize: 12 }} value={intSpecialityID || ""} onChange={e => { setIntSpecialityID(e.target.value) }} required>
                                        <option value="- Select -">- Select -</option>
                                        {specialities.map((data, key) => <option key={data.intSpecialityID} value={data.intSpecialityID} >{data.strSpecialityName}</option>)}
                                    </Form.Select>
                                </Form.Group>
                            </Col>

                            <Col sm={2}>
                                <Form.Group className="mb-3" controlId="formEnterState">
                                    <Form.Label>MCI Registration No</Form.Label>
                                    <Form.Control type="text" style={{ fontSize: 12 }} value={strMciRegistrationNo || ""} onChange={e => { setStrMciRegistrationNo(e.target.value) }} />
                                </Form.Group>
                            </Col>

                            <Col sm={2}>
                                <Form.Group className="mb-3" controlId="formSelectStateStatus">
                                    <Form.Label>Status</Form.Label>
                                    <Form.Select aria-label="Default select example" style={{ fontSize: 12 }} value={strstatus || ""} onChange={e => { setStatus(e.target.value) }} required>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>

                        </Row>

                        <Row style={{ fontSize: 12, color: "grey" }}>

                            <Col md={4}>
                                <Form.Group className="mb-3" controlId="formEnterState">
                                    <Form.Label>Signature</Form.Label>
                                    <Form.Control
                                        ref={aRefSig}
                                        type="file"
                                        style={{ fontSize: 13 }}
                                        onChange={e => {
                                            setSigFiles(e.target.files);
                                            const reader = new FileReader();
                                            reader.addEventListener("load", () => {
                                                setSigImageFile(reader.result);
                                            });
                                            reader.readAsDataURL(e.target.files[0]);
                                        }}
                                        accept="image/png, image/gif, image/jpeg"
                                    />
                                </Form.Group>
                            </Col>

                            {sigimagefile ?
                                <Col sm={2}>
                                    <Form.Label>Signature Preview</Form.Label>
                                    {intDoctorID ?
                                        <div><Image src={`data:image/jpeg;base64,${(sigimagefile)}`} width={50} height={50} /></div>
                                        :
                                        <div><Image src={sigimagefile} width={50} height={50} /></div>
                                    }
                                </Col>
                                :
                                <Col sm={2}>
                                    <div></div>
                                </Col>
                            }

                            <Col md={4}>
                                <Form.Group className="mb-3" controlId="formEnterState">
                                    <Form.Label>Certificate</Form.Label>
                                    <Form.Control
                                        ref={aRefCerti}
                                        type="file"
                                        style={{ fontSize: 13 }}
                                        onChange={e => {
                                            setCertiFiles(e.target.files);
                                            const reader = new FileReader();
                                            reader.addEventListener("load", () => {
                                                setCertiImageFile(reader.result);
                                            });
                                            reader.readAsDataURL(e.target.files[0]);
                                        }}
                                        accept="image/png, image/gif, image/jpeg"
                                    />
                                </Form.Group>
                            </Col>

                            {certiimagefile ?
                                <Col sm={2}>
                                    <Form.Label>Certificate Preview</Form.Label>
                                    {intDoctorID ?
                                        <div><Image src={`data:image/jpeg;base64,${(certiimagefile)}`} width={50} height={50} /></div>
                                        :
                                        <div><Image src={certiimagefile} width={50} height={50} /></div>
                                    }
                                </Col>
                                :
                                <Col sm={2}>
                                    <div></div>
                                </Col>
                            }

                        </Row>

                        <Row style={{ fontSize: 12, color: "grey" }}>
                            <Col sm={2} >
                                <Form.Group className="mb-3" controlId="formSelectStateStatus">
                                    {intDoctorID ?
                                        <Button variant="primary" type="submit" style={{ marginTop: 25, fontSize: 12 }}> Update </Button>
                                        :
                                        <Button variant="primary" type="submit" style={{ marginTop: 25, fontSize: 12 }}> Add </Button>
                                    }
                                </Form.Group>
                            </Col>
                        </Row>

                    </Form>

                    <div style={{ fontSize: 12, fontWeight: "bold", marginBottom: 10 }}>{updateMessage}</div>
                    <div style={{ fontSize: 12, fontWeight: "bold", marginBottom: 10 }}>{fileSize}</div>

                    <CDBCard style={{ padding: 20, fontSize: 12, color: "grey" }}>
                        <CDBCardBody>
                            {!loading ? (
                                <div style={{ textAlign: "center" }}><Spinner animation="border" size="sm" variant="primary" /></div>
                            ) :
                                <CDBDataTable
                                    responsive
                                    noRecordsFoundLabel="No Records Found"
                                    noBottomColumn={true}
                                    hover
                                    entriesOptions={[10, 25, 50, 100, 200, 500]}
                                    entries={10}
                                    pagesAmount={4}
                                    data={data()}
                                />
                            }
                        </CDBCardBody>
                    </CDBCard>

                </div>

            </div>
        </div>

    );
};

export default HospitalDoctors;