import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../Navbar/Navbar";
import Footer from "../Navbar/Footer";
import { Asterisk } from "react-bootstrap-icons";
import { Button, Row, Col, Container, Form } from "react-bootstrap";
// import { API_BASE } from '../../setupProxy';
import "./mycss.css";

function ForgotPassword() {

    const [strmobileno, setStrMobileNo] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const navigate = useNavigate();

    const handleSubmit = () => {

        if (strmobileno === "" || strmobileno.length !== 10 || isNaN(strmobileno)) {
            setErrorMessage(<span style={{ color: "red" }}>Please enter a valid 10 digit mobile number</span>);
            setTimeout(() => setErrorMessage(""), 3000);
        } else {
            //forgotPasswordFunction(strmobileno);
            navigate("/enterotp", { state: { mobileno: strmobileno } });
        }

        // async function forgotPasswordFunction(strmobileno) {

        //   //console.log("strmobileno: " + strmobileno);

        //   const toInput = { strmobileno };
        //   fetch("https://e-arogya.com:8443/api/general/login/check", {
        //     method: "POST",
        //     headers: { 'Content-Type': 'application/json' },
        //     redirect: "follow",
        //     body: JSON.stringify(toInput),
        //   })
        //     .then(async response => {
        //       const isJson = response.headers.get('content-type')?.includes('application/json');
        //       const data = isJson && await response.json();
        //       if (!response.ok) {
        //         const error = (data && data.message) || response.status;
        //         return Promise.reject(error);
        //       }
        //       if (data) {

        //         localStorage.setItem("loggedInId", data[0].intloginid);
        //         localStorage.setItem("orgid", data[0].intorganizationid);
        //         localStorage.setItem("loggedInUserName", data[0].strmobileno);
        //         localStorage.setItem("loggedRoleName", data[0].strrolename);
        //         localStorage.setItem("orgname", data[0].strorganizationname);
        //         localStorage.setItem("userfname", data[0].strfirstname);
        //         localStorage.setItem("userlname", data[0].strlastname);
        //         //console.log(data);

        //         if ((data[0].strrolename) === "Super") {
        //           navigate('/superadmindashboard');
        //         } else if ((data[0].strrolename) === "Hospital Admin") {
        //           navigate('/hospitaldashboard');
        //         } else if ((data[0].strrolename) === "Laboratory Admin") {
        //           navigate('/laboratorydashboard');
        //         } else if ((data[0].strrolename) === "Pharmacy Admin") {
        //           navigate('/pharmacydashboard');
        //         } else if ((data[0].strrolename) === "Account") {
        //           navigate('/hospitaldashboard');
        //         } else if ((data[0].strrolename) === "Frontend") {
        //           navigate('/hospitaldashboard');
        //         } else if ((data[0].strrolename) === "Medical") {
        //           navigate('/hospitaldashboard');
        //         } else if ((data[0].strrolename) === "Operator") {
        //           navigate('/operatordashboard');
        //         } else {
        //           setErrorMessage(<span style={{ color: "red" }}>Invalid User</span>);
        //           setTimeout(() => setErrorMessage(""), 3000);
        //         }

        //       } else {
        //         setErrorMessage(<span style={{ color: "red" }}>Incorrect mobile or password. Please try again.</span>);
        //         setTimeout(() => setErrorMessage(""), 3000);
        //       }

        //     })
        //     .catch(error => {
        //       setErrorMessage(<span style={{ color: "red" }}>Error Occurred. Please try again.</span>);
        //       setTimeout(() => setErrorMessage(""), 3000);
        //     });
        // }
    }

    return (

        <div>
            <Navbar />

            <Container fluid>

                <div style={{ marginTop: 100, marginBottom: 50 }} className='container'>

                    <Row>
                        <Col className="text-center justify-content-center">
                            <div style={{ textAlign: "center" }}>
                                <div className="about-border"></div>
                                <p style={{ fontSize: 35, fontWeight: 700 }}>FORGOT PASSWORD</p>
                            </div>
                            <div>
                                <p style={{ color: "grey" }}>Please enter your registered mobile number</p>
                            </div>
                        </Col>
                    </Row>

                    <Row className="py-5 justify-content-center">
                        <Col md={4}>
                            <Form>

                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label><Asterisk size={6} style={{ marginBottom: 8, marginRight: 5, color: "red" }} />Mobile Number</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={strmobileno || ""}
                                        onChange={e => { setStrMobileNo(e.target.value) }}
                                        required />
                                </Form.Group>

                                <div style={{ fontSize: 12, fontWeight: "bold", marginBottom: 10 }}>{errorMessage}</div>

                                <Row>
                                    <Col md={6}>
                                        <Button style={{ marginTop: 20, marginBottom: 20, backgroundColor: "#fff", borderColor: "#fd5252", width: "100%", color: "#fd5252" }} onClick={() => navigate(-1) }>Go Back</Button>
                                    </Col>
                                    <Col md={6}>
                                        <Button style={{ marginTop: 20, marginBottom: 20, backgroundColor: "#fd5252", borderColor: "#fd5252", width: "100%", color: "#fff" }} onClick={handleSubmit}>Get Otp</Button>
                                    </Col>
                                </Row>

                            </Form>

                        </Col>
                    </Row>  

                </div>

            </Container>

            <Footer />
        </div>

    );
}

export default ForgotPassword;