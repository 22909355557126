import React, { useState } from "react";
import { Button, Row, Col, Container, Form } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../Navbar/Navbar";
import Footer from "../Navbar/Footer";
import { Asterisk } from "react-bootstrap-icons";

function Contactus() {

  const [strContactName, setStrContactName] = useState("");
  const [strContactMobile, setStrContactMobile] = useState("");
  const [strContactEmail, setStrContactEmail] = useState("");
  const [strContactMessage, setStrContactMessage] = useState("");
  const [updateMessage, setUpdateMessage] = useState("");

  //FORM SUBMIT
  const onContactUsFormSubmit = (event) => {

    event.preventDefault();

    if (strContactName === "" || strContactMobile === "" || strContactEmail === "" || strContactMessage === "") {
      setUpdateMessage(<span style={{ color: "red" }}>Please enter all details</span>);
      setTimeout(() => setUpdateMessage(""), 3000);
    } else if (strContactName === "") {
      setUpdateMessage(<span style={{ color: "red" }}>Please enter name</span>);
      setTimeout(() => setUpdateMessage(""), 3000);
    } else if (strContactMobile === ""  || strContactMobile.length !== 10 || isNaN(strContactMobile)) {
      setUpdateMessage(<span style={{ color: "red" }}>Please enter a valig 10 digit mobile number</span>);
      setTimeout(() => setUpdateMessage(""), 3000);
    } else if (strContactEmail === "") {
      setUpdateMessage(<span style={{ color: "red" }}>Please enter email-id</span>);
      setTimeout(() => setUpdateMessage(""), 3000);
    } else if (strContactMessage === "") {
      setUpdateMessage(<span style={{ color: "red" }}>Please enter message</span>);
      setTimeout(() => setUpdateMessage(""), 3000);
    } else {

      const toInput = { strContactName, strContactMobile, strContactEmail, strContactMessage };

      fetch("https://e-arogya.com:8443/api/general/contactus", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        body: JSON.stringify(toInput)
      })
        .then(async response => {
          const isJson = response.headers.get('content-type')?.includes('application/json');
          const data = isJson && await response.json();

          if (data === 0) {
            setUpdateMessage(<span style={{ color: "red" }}>Message not sent. Please try again.</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
            window.scrollTo({ top: 0, behavior: 'smooth' });
            clearData();
          } else if (data === 1) {
            setUpdateMessage(<span style={{ color: "green" }}>Message Sent</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
            window.scrollTo({ top: 0, behavior: 'smooth' });
            clearData();
          }

        })
        .catch(error => {
          setUpdateMessage(<span style={{ color: "red" }}>Error Occured. Please try again.</span>);
          setTimeout(() => setUpdateMessage(""), 3000);
          window.scrollTo({ top: 0, behavior: 'smooth' });
          clearData();
        });

    }
  }

  const clearData = () => {
    setStrContactName("");
    setStrContactMobile("");
    setStrContactEmail("");
    setStrContactMessage("");
  }


  return (
    <div>
      <Navbar />

      <Container fluid>

        <div style={{ marginTop: 100, marginBottom: 100 }} className='container'>

          <Row>
            <Col className="text-center justify-content-center">
              <div style={{ textAlign: "center" }}>
                <div className="about-border"></div>
                <p style={{ fontSize: 35, fontWeight: 700 }}>CONTACT US</p>
              </div>
              <div>
                <p style={{ color: "grey" }}>For any query, please feel free to contact us.</p>
              </div>
            </Col>
          </Row>

          <Row className="py-5">
            <Col sm={4}>

              <div style={{ fontWeight: "bold", marginBottom: 10 }}>{updateMessage}</div>

              <Form onSubmit={onContactUsFormSubmit}>

                <Form.Group className="mb-3" controlId="formEnterState">
                  <Form.Label><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Name</Form.Label>
                  <Form.Control type="text" value={strContactName || ""} onChange={e => { setStrContactName(e.target.value) }} required />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formEnterState">
                  <Form.Label><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Mobile No</Form.Label>
                  <Form.Control type="tel" maxLength="10" value={strContactMobile || ""} onChange={e => { setStrContactMobile(e.target.value.replace(/\D/g, "")) }} required />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formEnterState">
                  <Form.Label><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Email-Id</Form.Label>
                  <Form.Control type="email" value={strContactEmail || ""} onChange={e => { setStrContactEmail(e.target.value) }} required />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formEnterState">
                  <Form.Label><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Message</Form.Label>
                  <Form.Control as="textarea" rows={3} value={strContactMessage || ""} onChange={e => { setStrContactMessage(e.target.value) }} required />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formEnterState">
                  <Button style={{ marginTop: 10, marginBottom: 10, backgroundColor: "#fd5252", borderColor: "#fd5252", width: 100 }} type="submit">Send</Button>
                </Form.Group>


              </Form>

            </Col>

            <Col sm={8}>
              <div>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3667.6982118312417!2d72.62664403749821!3d23.181212183275015!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395c2a3d714492b9%3A0xffd04caa042f82dc!2sCrucial%20Software%20and%20IT%20Park%20Pvt%20Ltd.!5e0!3m2!1sen!2sin!4v1706182415249!5m2!1sen!2sin"
                  title="map"
                  width="100%"
                  height="500" />
              </div>
            </Col>

          </Row>

        </div>

      </Container>
      <Footer />
    </div>
  );
}

export default Contactus;