import React, { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./admincss.css";
import HospitalSidebar from "../Navbar/HospitalSidebar";
import Adminheader from "../Navbar/Adminheader";
import { Card, CardGroup, Col, Container, Row, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { CurrencyRupee } from "react-bootstrap-icons";
// import { API_BASE } from '../../setupProxy';

import { Chart as ChartJS, CategoryScale, BarElement, LinearScale, Tooltip, Legend, } from 'chart.js';
import { Bar } from 'react-chartjs-2';
ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

function HospitalDashboard() {

    const navigate = useNavigate();
    const roleNameInSideBar = localStorage.getItem("loggedRoleName");

    const intOrganizationID = localStorage.getItem("orgid");

    const goToPatient = () => { navigate('/hospitalpatientrecords') }
    const goToAppointments = () => { navigate('/hospitalpatientappointments'); }
    //const goToBillings = () => { navigate('/hospitalpatientbillings'); }

    const [hospitalAdmin, setHospitalAdmin] = useState(false);
    const [frontend, setFrontend] = useState(false);
    const [medical, setMedical] = useState(false);
    const [account, setAccount] = useState(false);

    const [totalAppointments, setTotalAppointments] = useState(0);
    const [totalCases, setTotalCases] = useState(0);
    const [totalPatients, setTotalPatients] = useState(0);
    const [totalBilling, setTotalBilling] = useState(null);

    const [apptotalMonthlyCount, setAppTotalMonthlyCount] = useState([]);
    const [casetotalMonthlyCount, setCaseAppTotalMonthlyCount] = useState([]);
    const [pattotalMonthlyCount, setPatAppTotalMonthlyCount] = useState([]);
    const [billtotalMonthlyCount, setBillAppTotalMonthlyCount] = useState([]);
    const [loginYears, setLoginYears] = useState([]);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

    const appointmentdata = {
        labels: apptotalMonthlyCount.map(c => c.appCountMonth),
        datasets: [
            {
                label: 'Appointments',
                data: apptotalMonthlyCount.map(c => c.appMonthlyCount),
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
        ],
    };
    const appointmentoptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Chart.js Bar Chart',
            },
        },
    };
    const casedata = {
        labels: casetotalMonthlyCount.map(c => c.caseCountMonth),
        datasets: [
            {
                label: 'Cases',
                data: casetotalMonthlyCount.map(c => c.caseMonthlyCount),
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
        ],
    };
    const caseoptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Chart.js Bar Chart',
            },
        },
    };
    const patientsdata = {
        labels: pattotalMonthlyCount.map(c => c.patCountMonth),
        datasets: [
            {
                label: 'Patients',
                data: pattotalMonthlyCount.map(c => c.patMonthlyCount),
                backgroundColor: 'rgba(102, 255, 51, 0.5)',
            },
        ],
    };
    const patientsoptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Chart.js Bar Chart',
            },
        },
    };
    const billingdata = {
        labels: billtotalMonthlyCount.map(c => c.billCountMonth),
        datasets: [
            {
                label: 'Billing',
                data: billtotalMonthlyCount.map(c => c.billMonthlyCount),
                backgroundColor: 'rgba(255, 153, 51, 0.5)',
            },
        ],
    };
    const billingoptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Chart.js Bar Chart',
            },
        },
    };

    useEffect(() => {
        manageSidebar();
        getHospitalDashboardData(intOrganizationID);
        fetchAppointmentsCountMonthly(intOrganizationID, selectedYear);
        fetchCaseCountMonthly(intOrganizationID, selectedYear);
        fetchPatCountMonthly(intOrganizationID, selectedYear);
        fetchBillCountMonthly(intOrganizationID, selectedYear);
        fetchYear();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //FETCH ALL TOTAL DATA
    const getHospitalDashboardData = (orgid) => {
        fetch("https://e-arogya.com:8443/api/general/hospitaldashboard/" + orgid)
            .then(response => response.json())
            .then(data => {
                setTotalAppointments(data[0].totalAppointments);
                setTotalCases(data[0].totalCases);
                setTotalPatients(data[0].totalPatients);
                if (data[0].totalBilling == null) {
                    setTotalBilling(0.0);
                } else {
                    setTotalBilling(data[0].totalBilling);
                }
            })
            .catch(error => {
                //console.log(error);
            })
    }
    const fetchAppointmentsCountMonthly = (orgid, yr) => {
        fetch("https://e-arogya.com:8443/api/general/appointments/appointmentcountmonthly/" + orgid + "/" + yr)
            .then(response => response.json())
            .then(data => {
                setAppTotalMonthlyCount(data);
            })
            .catch(error => {
                //console.log(error);
            })
    }
    const fetchCaseCountMonthly = (orgid, yr) => {
        fetch("https://e-arogya.com:8443/api/general/case/casecountmonthly/" + orgid + "/" + yr)
            .then(response => response.json())
            .then(data => {
                setCaseAppTotalMonthlyCount(data);
            })
            .catch(error => {
                //console.log(error);
            })
    }
    const fetchPatCountMonthly = (orgid, yr) => {
        fetch("https://e-arogya.com:8443/api/general/patient/patientcountmonthly/" + orgid + "/" + yr)
            .then(response => response.json())
            .then(data => {
                setPatAppTotalMonthlyCount(data);
            })
            .catch(error => {
                //console.log(error);
            })
    }
    const fetchBillCountMonthly = (orgid, yr) => {
        fetch("https://e-arogya.com:8443/api/general/bill/billcountmonthly/" + orgid + "/" + yr)
            .then(response => response.json())
            .then(data => {
                setBillAppTotalMonthlyCount(data);
            })
            .catch(error => {
                //console.log(error);
            })
    }
    const fetchYear = () => {
        fetch("https://e-arogya.com:8443/api/general/user/logincountyear")
            .then(response => response.json())
            .then(data => {
                setLoginYears(data);
                //console.log("Login Years " + loginYears);
            })
            .catch(error => {
                //console.log(error);
            })
    }
    const manageSidebar = () => {
        if (roleNameInSideBar === "Hospital Admin") {
            setHospitalAdmin(true);
            setFrontend(false);
            setMedical(false);
            setAccount(false);
        } else if (roleNameInSideBar === "Frontend") {
            setHospitalAdmin(false);
            setFrontend(true);
            setMedical(false);
            setAccount(false);
        } else if (roleNameInSideBar === "Medical") {
            setHospitalAdmin(false);
            setFrontend(false);
            setMedical(true);
            setAccount(false);
        } else if (roleNameInSideBar === "Account") {
            setHospitalAdmin(false);
            setFrontend(false);
            setMedical(false);
            setAccount(true);
        }
    }

    return (

        <div className="main">

            <HospitalSidebar />

            <div className="page-content">

                <Adminheader />

                <h5>Welcome</h5>

                {hospitalAdmin ?
                    <CardGroup>
                        <Container fluid>
                            <Row>
                                <Col md>
                                    <Card style={{ margin: 5, borderRadius: 10, }} className="cardclass" onClick={goToAppointments}>
                                        <Card.Body>
                                            <Card.Text style={{ fontSize: 12, color: "grey" }}>Appointments</Card.Text>
                                            <Card.Title style={{ paddingLeft: 10 }}>{totalAppointments}</Card.Title>
                                            {/* <Card.Text style={{ fontSize: 12, color: "grey" }}>+18.9% Last week</Card.Text> */}
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md>
                                    <Card style={{ margin: 5, borderRadius: 10, }} className="cardclass" onClick={goToAppointments}>
                                        <Card.Body>
                                            <Card.Text style={{ fontSize: 12, color: "grey" }}>Cases</Card.Text>
                                            <Card.Title style={{ paddingLeft: 10 }}>{totalCases}</Card.Title>
                                            {/* <Card.Text style={{ fontSize: 12, color: "grey" }}>+2.4% Last week</Card.Text> */}
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md>
                                    <Card style={{ margin: 5, borderRadius: 10, }} className="cardclass" onClick={goToPatient}>
                                        <Card.Body>
                                            <Card.Text style={{ fontSize: 12, color: "grey" }}>Patients</Card.Text>
                                            <Card.Title style={{ paddingLeft: 10 }}>{totalPatients}</Card.Title>
                                            {/* <Card.Text style={{ fontSize: 12, color: "grey" }}>+1.9% Last week</Card.Text> */}
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md>
                                    <Card style={{ margin: 5, borderRadius: 10, }} className="cardclass">
                                        <Card.Body>
                                            <Card.Text style={{ fontSize: 12, color: "grey" }}>Billing</Card.Text>
                                            <Card.Title style={{ paddingLeft: 10 }}><CurrencyRupee width={19} height={19} />{totalBilling}</Card.Title>
                                            {/* <Card.Text style={{ fontSize: 12, color: "grey" }}>+1.9% Last week</Card.Text> */}
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>

                            <Row>
                                <Col md>
                                    <div style={{ margin: 10 }}>
                                        <Bar
                                            data={appointmentdata}
                                            options={appointmentoptions}
                                        />
                                    </div>
                                </Col>
                                <Col md>
                                    <div style={{ margin: 10 }}>
                                        <Bar
                                            data={casedata}
                                            options={caseoptions}
                                        />
                                    </div>
                                </Col>
                                <Col md>
                                    <div style={{ margin: 10 }}>
                                        <Bar
                                            data={patientsdata}
                                            options={patientsoptions}
                                        />
                                    </div>
                                </Col>
                                <Col md>
                                    <div style={{ margin: 10 }}>
                                        <Bar
                                            data={billingdata}
                                            options={billingoptions}
                                        />
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col md>
                                    <Form.Group className="mb-3" controlId="formSelectStateStatus">
                                        <Form.Select aria-label="Default select example"
                                            style={{ fontSize: 12 }}

                                            value={selectedYear || ""}
                                            onChange={e => {
                                                setSelectedYear(e.target.value);
                                                fetchAppointmentsCountMonthly(intOrganizationID, e.target.value);
                                                fetchCaseCountMonthly(intOrganizationID, e.target.value);
                                                fetchPatCountMonthly(intOrganizationID, e.target.value);
                                                fetchBillCountMonthly(intOrganizationID, e.target.value);
                                            }}
                                            required
                                        >
                                            {loginYears.map((data, key) => <option key={data.loginyear} value={data.loginyear} >{data.loginyear}</option>)}
                                            {/* <option value="2023">2023</option>
                                        <option value="2022">2022</option> */}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col md></Col>
                                <Col md></Col>
                                <Col md></Col>
                            </Row>

                        </Container>
                    </CardGroup>
                    :
                    <div></div>
                }

                {frontend ?
                    <CardGroup>
                        <Container fluid>
                            <Row>
                                <Col md>
                                    <Card style={{ margin: 5, borderRadius: 10, }} className="cardclass" onClick={goToAppointments}>
                                        <Card.Body>
                                            <Card.Text style={{ fontSize: 12, color: "grey" }}>Appointments</Card.Text>
                                            <Card.Title style={{ paddingLeft: 10 }}>{totalAppointments}</Card.Title>
                                            {/* <Card.Text style={{ fontSize: 12, color: "grey" }}>+18.9% Last week</Card.Text> */}
                                        </Card.Body>
                                    </Card>
                                </Col>
                                {/* <Col md>
                                    <Card style={{ margin: 5, borderRadius: 10, }} className="cardclass" onClick={goToAppointments}>
                                        <Card.Body>
                                            <Card.Text style={{ fontSize: 12, color: "grey" }}>Cases</Card.Text>
                                            <Card.Title style={{ paddingLeft: 10 }}>{totalCases}</Card.Title>
                                            // <Card.Text style={{ fontSize: 12, color: "grey" }}>+2.4% Last week</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col> */}
                                <Col md>
                                    <Card style={{ margin: 5, borderRadius: 10, }} className="cardclass" onClick={goToPatient}>
                                        <Card.Body>
                                            <Card.Text style={{ fontSize: 12, color: "grey" }}>Patients</Card.Text>
                                            <Card.Title style={{ paddingLeft: 10 }}>{totalPatients}</Card.Title>
                                            {/* <Card.Text style={{ fontSize: 12, color: "grey" }}>+1.9% Last week</Card.Text> */}
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md></Col>
                                <Col md></Col>
                                {/* <Col md>
                                    <Card style={{ margin: 5, borderRadius: 10, }} className="cardclass">
                                        <Card.Body>
                                            <Card.Text style={{ fontSize: 12, color: "grey" }}>Billing</Card.Text>
                                            <Card.Title style={{ paddingLeft: 10 }}><CurrencyRupee width={19} height={19} /> {totalBilling}</Card.Title>
                                            // <Card.Text style={{ fontSize: 12, color: "grey" }}>+1.9% Last week</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col> */}
                            </Row>
                        </Container>
                    </CardGroup>
                    :
                    <div></div>
                }

                {medical ?
                    <CardGroup>
                        <Container fluid>
                            <Row>
                                <Col md>
                                    <Card style={{ margin: 5, borderRadius: 10, }} className="cardclass" onClick={goToAppointments}>
                                        <Card.Body>
                                            <Card.Text style={{ fontSize: 12, color: "grey" }}>Appointments</Card.Text>
                                            <Card.Title style={{ paddingLeft: 10 }}>{totalAppointments}</Card.Title>
                                            {/* <Card.Text style={{ fontSize: 12, color: "grey" }}>+18.9% Last week</Card.Text> */}
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md>
                                    <Card style={{ margin: 5, borderRadius: 10, }} className="cardclass" onClick={goToAppointments}>
                                        <Card.Body>
                                            <Card.Text style={{ fontSize: 12, color: "grey" }}>Cases</Card.Text>
                                            <Card.Title style={{ paddingLeft: 10 }}>{totalCases}</Card.Title>
                                            {/* <Card.Text style={{ fontSize: 12, color: "grey" }}>+2.4% Last week</Card.Text> */}
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md>
                                    <Card style={{ margin: 5, borderRadius: 10, }} className="cardclass" onClick={goToPatient}>
                                        <Card.Body>
                                            <Card.Text style={{ fontSize: 12, color: "grey" }}>Patients</Card.Text>
                                            <Card.Title style={{ paddingLeft: 10 }}>{totalPatients}</Card.Title>
                                            {/* <Card.Text style={{ fontSize: 12, color: "grey" }}>+1.9% Last week</Card.Text> */}
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md></Col>
                            </Row>
                        </Container>
                    </CardGroup>
                    :
                    <div></div>
                }

                {account ?
                    <CardGroup>
                        <Container fluid>
                            <Row>
                                <Col md>
                                    <Card style={{ margin: 5, borderRadius: 10, }} className="cardclass">
                                        <Card.Body>
                                            <Card.Text style={{ fontSize: 12, color: "grey" }}>Billing</Card.Text>
                                            <Card.Title style={{ paddingLeft: 10 }}><CurrencyRupee width={19} height={19} /> {totalBilling}</Card.Title>
                                            {/* <Card.Text style={{ fontSize: 12, color: "grey" }}>+1.9% Last week</Card.Text> */}
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md></Col>
                                <Col md></Col>
                                <Col md></Col>
                            </Row>
                        </Container>
                    </CardGroup>
                    :
                    <div></div>
                }



            </div>
        </div>
    );
}

export default HospitalDashboard;