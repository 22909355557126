import React, { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./admincss.css";
import Sidebar from "../Navbar/Sidebar";
import Adminheader from "../Navbar/Adminheader";
import { CDBCard, CDBCardBody, CDBDataTable } from 'cdbreact';
import { Col, Form, Row, Button, Spinner, Modal } from "react-bootstrap";
import { Asterisk } from "react-bootstrap-icons";
// import { API_BASE } from '../../setupProxy';

const ManageHospitalServices = () => {

    const [services, setServices] = useState([]);
    const [loading, setLoading] = useState(false);

    const [intServiceID, setIntServiceId] = useState(null);
    const [strServiceName, setStrServiceName] = useState("");
    // const [strChargeUnit, setStrChargeUnit] = useState('- Select -');
    // const [dtmChargeToDate, setDtmChargeToDate] = useState("");
    // const [dtmChargeFromDate, setDtmChargeFromDate] = useState("");
    const [strstatus, setStatus] = useState('Active');

    const [updateMessage, setUpdateMessage] = useState("");

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [deleteThis, setDeleteThis] = useState(null);

    useEffect(() => {
        fetchData()
    }, [])

    //FETCH ALL SERVICES
    const fetchData = () => {
        fetch("https://e-arogya.com:8443/api/general/services")
            .then(res => res.json())
            .then(data => {
                let dataFromApi = data.map(service => {
                    return {
                        id: service.intServiceID,
                        name: service.strServiceName,
                        // charge: service.strChargeUnit,
                        // chargefromdate: service.dtmChargeFromDate,
                        // chargetodate: service.dtmChargeToDate,
                        status: service.strstatus,
                    }
                })
                setServices(dataFromApi);
                setLoading(true);
            })
            .catch(error => {
                //console.log(error);
            });
    }

    //FETCH SERVICES BY ID (FOR EDIT SERVICES)
    const handleEditClick = (e, id) => {
        e.preventDefault();
        fetch("https://e-arogya.com:8443/api/general/services/" + id)
            .then(res => res.json())
            .then(data => {
                setIntServiceId(data[0].intServiceID);
                setStrServiceName(data[0].strServiceName);
                // setStrChargeUnit(data[0].strChargeUnit)
                // setDtmChargeFromDate(data[0].dtmChargeFromDate);
                // setDtmChargeToDate(data[0].dtmChargeToDate);
                setStatus(data[0].strstatus);
            })
            .catch(error => {
                //console.log(error);
            });
    }

    const clearFields = () => {
        setStrServiceName("");
        // setStrChargeUnit('- Select -');
        // setDtmChargeFromDate("");
        // setDtmChargeToDate("");
        setStatus('Active');
    }

    //FORM SUBMIT
    const onAddNewServiceFormSubmit = (event) => {
        event.preventDefault();

        if (strServiceName === "") {
            setUpdateMessage(<span style={{ color: "red" }}>Please Enter Service Name</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
        } else {

            if (intServiceID) {
                //const existingInputs = { strServiceName, strChargeUnit, dtmChargeFromDate, dtmChargeToDate, strstatus }
                const existingInputs = { strServiceName, strstatus }
                updateService(existingInputs, intServiceID);
                setIntServiceId(null);
                clearFields();
            } else {
                // const toInput = { strServiceName, strChargeUnit, dtmChargeFromDate, dtmChargeToDate, strstatus };
                const toInput = { strServiceName, strstatus };
                addNewService(toInput);
                clearFields();
            }
        }

    }

    //ADD NEW SERVICE
    async function addNewService(toInput) {
        fetch("https://e-arogya.com:8443/api/general/services/addnewservice", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            redirect: "follow",
            body: JSON.stringify(toInput)
        })
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();

                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }
                setUpdateMessage(<span style={{ color: "green" }}>Service Added Successfully</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
                fetchData();
            })
            .catch(error => {
                setUpdateMessage(<span style={{ color: "red" }}>Service Not Added. Please try again.</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
            });

    }

    //UPDATE EXISTING SERVICE
    async function updateService(existingInputs, intServiceID) {
        fetch("https://e-arogya.com:8443/api/general/services/update/" + intServiceID, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            redirect: "follow",
            body: JSON.stringify(existingInputs)
        })
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();

                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }
                setUpdateMessage(<span style={{ color: "green" }}>Service Updated Successfully</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
                fetchData();
            })
            .catch(error => {
                setUpdateMessage(<span style={{ color: "red" }}>Service Not Updated. Please try again.</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
            });

    }

    //DELETE SERVICES
    function handleDeleteClick(e, id) {
        setShow(true);
        setDeleteThis(id);
    }

    const deleteThisFunc = (id) => {
        fetch("https://e-arogya.com:8443/api/general/services/" + id, { method: 'DELETE' })
            .then(async response => {
                const data = await response.text;

                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }

                setUpdateMessage(<span style={{ color: "green" }}>Service Deleted Successfully</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
                setDeleteThis(null);
                setIntServiceId(null);
                clearFields();
                fetchData();
            })
            .catch(error => {
                setUpdateMessage(<span style={{ color: "red" }}>Service Not Deleted. Please try again.</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
            });
    }


    //POPULATE TABLE DATA
    const data = () => {
        return {
            columns: [
                { label: '#', field: 'srno', },
                {
                    label: 'Service Name',
                    field: 'name',
                    attributes: {
                        'aria-controls': 'DataTable',
                        'aria-label': 'Name',
                    },
                },
                // { label: 'Charge Unit', field: 'charge', },
                // { label: 'Charge From Date', field: 'chargefromdate', },
                // { label: 'Charge To Date', field: 'chargetodate', },
                { label: 'Status', field: 'status', },
                { label: '', field: 'edit', },
                { label: '', field: 'delete', },

            ],
            rows: [...services.map((data, i) => (
                {
                    srno: i + 1,
                    name: data.name,
                    // charge: data.charge,
                    // chargefromdate: data.chargefromdate,
                    // chargetodate: data.chargetodate,
                    status: data.status,
                    edit: <span onClick={(e) => handleEditClick(e, data.id)}>
                        <Button variant="primary" type="submit" style={{ fontSize: 12 }} size="sm">
                            Edit
                        </Button>
                    </span>,
                    delete: <span onClick={(e) => handleDeleteClick(e, data.id)}>
                        <Button variant="danger" type="submit" style={{ fontSize: 12 }} size="sm">
                            Delete
                        </Button>
                    </span>
                }
            ))

            ],
        };
    };
    return (

        <div className="main" >

            <Sidebar />

            <div className="page-content">

                <Adminheader />

                <h5>Manage Hospital Services</h5>
                <p style={{ fontSize: 12, color: "grey", marginBottom: 50 }}>Manage Services to Appear to Select at Hospital</p>

                <div className="table-content">

                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title style={{ fontSize: 18 }}>Delete Service</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ fontSize: 14 }}>Are you sure you want to delete this service?</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose} style={{ fontSize: 12 }}>No</Button>
                            <Button variant="primary" onClick={() => {
                                handleClose();
                                deleteThisFunc(deleteThis);
                            }} style={{ fontSize: 12 }}>Yes</Button>
                        </Modal.Footer>
                    </Modal>

                    <Form onSubmit={onAddNewServiceFormSubmit} >

                        <Row style={{ fontSize: 12, color: "grey" }}>

                            <Col sm={2}>
                                <Form.Group className="mb-3" controlId="formEnterState">
                                    <Form.Label><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Service Name</Form.Label>
                                    <Form.Control type="text" style={{ fontSize: 12 }} value={strServiceName || ""} onChange={e => { setStrServiceName(e.target.value) }} required />
                                </Form.Group>
                            </Col>

                            {/* <Col sm={2}>
                                <Form.Group className="mb-3" controlId="formSelectChargeUnit">
                                    <Form.Label><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Charge Unit</Form.Label>
                                    <Form.Select aria-label="Default select example" style={{ fontSize: 12 }} value={strChargeUnit || ""} onChange={e => { setStrChargeUnit(e.target.value) }}>
                                        <option value="select">- Select -</option>
                                        <option value="Per Visit">Per Visit</option>
                                        <option value="Per Day">Per Day</option>
                                        <option value="Per Hour">Per Hour</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>

                            <Col sm={2}>
                                <Form.Label><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Charge From Date</Form.Label>
                                <Form.Group className="mb-3" controlId="formEnterState">
                                    <Form.Control type="date" style={{ fontSize: 12 }} value={dtmChargeFromDate || ""} onChange={e => { setDtmChargeFromDate(e.target.value) }} required />
                                </Form.Group>
                            </Col>

                            <Col sm={2}>
                                <Form.Label><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Charge To Date</Form.Label>
                                <Form.Group className="mb-3" controlId="formEnterState">
                                    <Form.Control type="date" style={{ fontSize: 12 }} value={dtmChargeToDate || ""} onChange={e => { setDtmChargeToDate(e.target.value) }} required />
                                </Form.Group>
                            </Col> */}

                            <Col sm={2}>
                                <Form.Group className="mb-3" controlId="formSelectStateStatus">
                                    <Form.Label>Status</Form.Label>
                                    <Form.Select aria-label="Default select example" style={{ fontSize: 12 }} value={strstatus || ""} onChange={e => { setStatus(e.target.value) }}>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>

                            <Col sm={2} >
                                <Form.Group className="mb-3" controlId="formEnterState">
                                    {intServiceID ?
                                        <Button variant="primary" type="submit" style={{ marginTop: 25, fontSize: 12 }}> Update </Button>
                                        :
                                        <Button variant="primary" type="submit" style={{ marginTop: 25, fontSize: 12 }}> Add </Button>
                                    }

                                </Form.Group>
                            </Col>

                        </Row>

                    </Form>

                    <div style={{ fontSize: 12, fontWeight: "bold", marginBottom: 10 }}>{updateMessage}</div>

                    <CDBCard style={{ padding: 20, fontSize: 12, color: "grey" }}>
                        <CDBCardBody>
                            {!loading ? (
                                <div style={{ textAlign: "center" }}><Spinner animation="border" size="sm" variant="primary" /></div>
                            ) :
                                <CDBDataTable
                                    responsive
                                    noRecordsFoundLabel="No Records Found"
                                    noBottomColumn={true}
                                    hover
                                    entriesOptions={[10, 25, 50, 100, 200, 500]}
                                    entries={10}
                                    pagesAmount={4}
                                    data={data()}
                                />
                            }
                        </CDBCardBody>
                    </CDBCard>

                </div>

            </div>
        </div>

    );
};

export default ManageHospitalServices;