import React, { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./admincss.css";
import Sidebar from "../Navbar/Sidebar";
import Adminheader from "../Navbar/Adminheader";
import { CDBCard, CDBCardBody, CDBDataTable } from 'cdbreact';
import { Col, Form, Row, Button, Spinner, Modal } from "react-bootstrap";
import { Asterisk } from "react-bootstrap-icons";
// import { API_BASE } from '../../setupProxy';

const ManageCities = () => {

    const [cities, setCities] = useState([]);
    const [states, setStates] = useState([]);
    const [loading, setLoading] = useState(false);

    const [intcityid, setIntCityId] = useState(null);
    const [strcityname, setStrCityName] = useState("");
    const [intstateid, setIntStateId] = useState(null);
    const [strstatus, setStatus] = useState('Active');

    const [updateMessage, setUpdateMessage] = useState("");

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [deleteThis, setDeleteThis] = useState(null);

    useEffect(() => {
        fetchStates();
        fetchCities();
    }, []);

    //FETCH ALL CITIES
    const fetchCities = () => {
        fetch("https://e-arogya.com:8443/api/general/cities")
            .then(response => response.json())
            .then(citydata => {
                let dataFromApi = citydata.map(city => {
                    return {
                        id: city.intcityid,
                        name: city.strcityname,
                        status: city.strstatus,
                    }
                })
                setCities(dataFromApi);
                setLoading(true);
            })
            .catch(error => {
                //console.log(error);
            })
    }

    //FETCH ALL STATES (FOR DROPDOWN)
    const fetchStates = () => {
        fetch("https://e-arogya.com:8443/api/general/states")
            .then(res => res.json())
            .then(stateData => {
                setStates(stateData);
            })
            .catch(error => {
                //console.log(error);
            })

    }

    const clearFields = () => {
        setStrCityName("");
        setIntStateId('- Select -');
        setStatus('Active');
    }

    //FORM SUBMIT
    const onAddNewCityFormSubmit = (event) => {
        event.preventDefault();

        if (intstateid === '- Select -' || intstateid == null) {
            setUpdateMessage(<span style={{ color: "red" }}>Please select state</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
        } else {
            if (intcityid) {
                const existingInputs = { strcityname, intstateid, strstatus }
                updateCity(existingInputs, intcityid);
                setIntCityId(null);
                clearFields();
            } else {
                const toInput = { strcityname, intstateid, strstatus };
                addNewCity(toInput);
                clearFields();
            }
        }
    }

    //FETCH CITY BY ID (FOR EDIT CITY)
    const handleEditClick = (e, id) => {
        e.preventDefault();
        fetch("https://e-arogya.com:8443/api/general/cities/" + id)
            .then(res => res.json())
            .then(data => {
                setIntCityId(data[0].intcityid);
                setStrCityName(data[0].strcityname);
                setIntStateId(data[0].intstateid);
                setStatus(data[0].strstatus);
            })
            .catch(error => {
                //console.log(error);
            });
    }

    //DELETE CITY

    function handleDeleteClick(e, id) {
        setShow(true);
        setDeleteThis(id);
    }

    const deleteThisFunc = (id) => {
        fetch("https://e-arogya.com:8443/api/general/cities/" + id, { method: 'DELETE' })
            .then(async response => {
                const data = await response.text;

                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }

                setUpdateMessage(<span style={{ color: "green" }}>City Deleted Successfully</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
                setDeleteThis(null);
                setIntCityId(null);
                clearFields();
                fetchCities();
            })
            .catch(error => {
                setUpdateMessage(<span style={{ color: "red" }}>City Not Deleted. Please try again.</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
            });
    }


    //ADD NEW CITY
    async function addNewCity(toInput) {
        fetch("https://e-arogya.com:8443/api/general/cities/addnewcity", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            redirect: "follow",
            body: JSON.stringify(toInput)
        })
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();

                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }
                setUpdateMessage(<span style={{ color: "green" }}>City Added Successfully</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
                fetchCities();
            })
            .catch(error => {
                setUpdateMessage(<span style={{ color: "red" }}>City Not Added. Please try again.</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
            });

    }

    //UPDATE EXISTING CITY
    async function updateCity(existingInputs, intcityid) {
        fetch("https://e-arogya.com:8443/api/general/cities/update/" + intcityid, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            redirect: "follow",
            body: JSON.stringify(existingInputs)
        })
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();

                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }
                setUpdateMessage(<span style={{ color: "green" }}>City Updated Successfully</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
                fetchCities();
            })
            .catch(error => {
                setUpdateMessage(<span style={{ color: "red" }}>City Not Updated. Please try again.</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
            });

    }

    //POPULATE TABLE DATA
    const data = () => {
        return {
            columns: [
                { label: '#', field: 'srno', },
                {
                    label: 'Name',
                    field: 'name',
                    attributes: {
                        'aria-controls': 'DataTable',
                        'aria-label': 'Name',
                    },
                },
                { label: 'Status', field: 'status', },
                { label: '', field: 'edit', },
                { label: '', field: 'delete', },

            ],
            rows: [...cities.map((data1, index) => (
                {
                    srno: index + 1,
                    name: data1.name,
                    status: 'Active',
                    edit: <span onClick={(e) => handleEditClick(e, data1.id)}>
                        <Button variant="primary" type="submit" style={{ fontSize: 12 }} size="sm">
                            Edit
                        </Button>
                    </span>,
                    delete: <span onClick={(e) => handleDeleteClick(e, data1.id)}>
                        <Button variant="danger" type="submit" style={{ fontSize: 12 }} size="sm">
                            Delete
                        </Button>
                    </span>
                }
            ))

            ],
        };
    };

    return (

        <div className="main" >

            <Sidebar />

            <div className="page-content">

                <Adminheader />

                <h5>Manage Cities</h5>
                <p style={{ fontSize: 12, color: "grey", marginBottom: 50 }}>Manage Cities to Appear in Application</p>

                <div className="table-content">

                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title style={{ fontSize: 18 }}>Delete City</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ fontSize: 14 }}>Are you sure you want to delete this city?</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose} style={{ fontSize: 12 }}>No</Button>
                            <Button variant="primary" onClick={() => {
                                handleClose();
                                deleteThisFunc(deleteThis);
                            }} style={{ fontSize: 12 }}>Yes</Button>
                        </Modal.Footer>
                    </Modal>

                    <Form onSubmit={onAddNewCityFormSubmit}>

                        <Row style={{ fontSize: 12, color: "grey" }}>

                            <Col sm={2}>
                                <Form.Group className="mb-3" controlId="formEnterCity">
                                    <Form.Label><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />City Name</Form.Label>
                                    <Form.Control type="text" style={{ fontSize: 12 }} value={strcityname || ""} onChange={e => { setStrCityName(e.target.value) }} required />
                                </Form.Group>
                            </Col>

                            <Col sm={2}>
                                <Form.Group className="mb-3" controlId="formSelectCityStatus">
                                    <Form.Label><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />State</Form.Label>
                                    <Form.Select aria-label="Default select example" style={{ fontSize: 12 }} value={intstateid || ""} onChange={e => { setIntStateId(e.target.value) }}>
                                        <option value="Select">- Select -</option>
                                        {states.map((data, key) => <option key={data.intstateid} value={data.intstateid} >{data.strstatename}</option>)}
                                    </Form.Select>
                                </Form.Group>
                            </Col>

                            <Col sm={2}>
                                <Form.Group className="mb-3" controlId="formSelectCityStatus">
                                    <Form.Label>Status</Form.Label>
                                    <Form.Select aria-label="Default select example" style={{ fontSize: 12 }} value={strstatus || ""} onChange={e => { setStatus(e.target.value) }}>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>

                            <Col sm={2} >
                                <Form.Group className="mb-3" controlId="formEnterCity">
                                    {intcityid ?
                                        <Button variant="primary" type="submit" style={{ marginTop: 25, fontSize: 12 }}> Update </Button>
                                        :
                                        <Button variant="primary" type="submit" style={{ marginTop: 25, fontSize: 12 }}> Add </Button>
                                    }
                                </Form.Group>
                            </Col>

                        </Row>

                    </Form>

                    <div style={{ fontSize: 12, fontWeight: "bold", marginBottom: 10 }}>{updateMessage}</div>

                    <CDBCard style={{ padding: 20, fontSize: 12, color: "grey" }}>

                        <CDBCardBody>
                            {!loading ? (
                                <div style={{ textAlign: "center" }}><Spinner animation="border" size="sm" variant="primary" /></div>
                            ) :
                                <CDBDataTable
                                    responsive
                                    noRecordsFoundLabel="No Records Found"
                                    noBottomColumn={true}
                                    hover
                                    entriesOptions={[10, 25, 50, 100, 200, 500]}
                                    entries={10}
                                    pagesAmount={4}
                                    data={data()}
                                />
                            }
                        </CDBCardBody>
                    </CDBCard>

                </div>

            </div>

        </div>

    );
};

export default ManageCities;