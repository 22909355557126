import React, { useState, useRef, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./admincss.css";
import Adminheader from "../Navbar/Adminheader";
import OperatorSidebar from "../Navbar/OperatorSidebar";
import { useLocation, useNavigate } from "react-router-dom";
import { Col, Form, Row, Button, Image } from "react-bootstrap";
import { Asterisk } from "react-bootstrap-icons";
// import { API_BASE } from '../../setupProxy';
import { GoogleMap, Marker, LoadScript } from "@react-google-maps/api";
/* global google */

const OperatorEnterOrganizationData = () => {

  let { state } = useLocation();

  const navigate = useNavigate();

  const location = useLocation()
  const { orgid, orgtype } = location.state ?? {}    // added ( ?? {} - to avoid errors when we directly click on patient appointments from sidebar)

  const intloginid = localStorage.getItem("loggedInId");

  const [strorganizationtype, setStrorganizationType] = useState("");
  const [strorganizationname, setStrorganizationname] = useState("");
  const [strmobileno, setStrMobileNo] = useState("");
  const [stremailid, setStrEmailId] = useState("");

  const [straddressline1, setStraddressline1] = useState("");
  const [straddressline2, setStraddressline2] = useState("");
  const [strarea, setStrarea] = useState("");
  const [strpincode, setStrPincode] = useState("");

  const [intstateid, setIntstateid] = useState(null);
  const [states, setStates] = useState([]);

  const [intcityid, setIntcityid] = useState(null);
  const [cities, setCities] = useState([]);
  const [filteredCities, setFilteredCities] = useState([]);

  const [strphoneno1, setStrphoneno1] = useState("");
  const [strphoneno2, setStrphoneno2] = useState("");

  const [strwebsite, setStrwebsite] = useState("");
  const [strstatus, setStatus] = useState("Inactive");
  const [strorganizationregno, setStrorganizationregno] = useState("");
  const [markerLatitude, setMarkerLatitude] = useState(22.3039);
  const [markerLongitude, setMarkerLongitude] = useState(70.8022);
  const [locStatus, setLocStatus] = useState(null);

  const [files, setFiles] = useState('');
  const [imagefile, setImageFile] = useState(null);
  const aRef = useRef(null);
  const [uploadlogofile, setUploadLogoFile] = useState(null);

  const [certifiles, setCertiFiles] = useState('');
  const [certiimagefile, setCertiImageFile] = useState(null);
  const aCertiRef = useRef(null);
  const [uploadcertifile, setUploadCertiFile] = useState(null);

  const [letterpadfiles, setLetterpadFiles] = useState('');
  const [letterpadimagefile, setLetterpadImageFile] = useState(null);
  const aLetterpadRef = useRef(null);
  const [uploadletterpadfile, setUploadLetterpadFile] = useState(null);

  const [updateMessage, setUpdateMessage] = useState("");

  useEffect(() => {

    fetchCities();
    fetchStates();
    getCurrentLocation();

    const fetchOrganizationData = (oid, lid) => {

      fetch(`https://e-arogya.com:8443/api/general/organizationbyoperator/${oid}/${lid}`)
        .then(res => res.json())
        .then(data => {
          setStrorganizationname(data[0].strorganizationname);
          setStrMobileNo(data[0].strmobileno);
          setStrEmailId(data[0].stremailid);
          setStraddressline1(data[0].straddressline1);
          setStraddressline2(data[0].straddressline2);
          setStrarea(data[0].strarea);
          setStrPincode(data[0].strpincode);
          setIntstateid(data[0].intstateid);

          if (data[0].intstateid) {
            fetch("https://e-arogya.com:8443/api/general/cities")
              .then(res => res.json())
              .then(cities => {
                setCities(cities);

                let cityList = [];
                for (let i = 0; i < cities.length; i++) {
                  if (cities[i].intstateid === parseInt(data[0].intstateid)) {
                    cityList.push(cities[i]);
                  }
                }
                setFilteredCities(cityList);
                setIntcityid(data[0].intcityid);
              })
              .catch(error => {
                //console.log(error);
              })
          }

          setStrphoneno1(data[0].strphoneno1);
          setStrphoneno2(data[0].strphoneno2);
          setStrwebsite(data[0].strwebsite);
          setStatus(data[0].strstatus);
          setStrorganizationregno(data[0].strorganizationregno)
          if (data[0].decorglatitude === 0 || data[0].decorglongitude === 0) {
            getCurrentLocation();
          } else {
            setMarkerLatitude(data[0].decorglatitude);
            setMarkerLongitude(data[0].decorglongitude);
          }
          if (data[0].strlogo) { setImageFile(data[0].strlogo) }
          if (data[0].strorganizationregcertificate) { setCertiImageFile(data[0].strorganizationregcertificate) }
          if (data[0].strorgletterpad) { setLetterpadImageFile(data[0].strorgletterpad) }

        })
        .catch(error => {
          //console.log(error);
        });
    }

    if (orgid) {
      setStrorganizationType(orgtype);
      fetchOrganizationData(orgid, intloginid);
    } else {
      setStrorganizationType(state.type);
      clearData();
    }

  }, [orgid, orgtype, state.type, intloginid,])

  const fetchStates = () => {
    fetch("https://e-arogya.com:8443/api/general/states")
      .then(res => res.json())
      .then(states => {
        setStates(states);
      })
      .catch(error => {
        //console.log(error);
      })
  }

  const fetchCities = () => {
    fetch("https://e-arogya.com:8443/api/general/cities")
      .then(res => res.json())
      .then(cities => {
        setCities(cities);
      })
      .catch(error => {
        //console.log(error);
      })
  }

  const getCurrentLocation = () => {
    if (!navigator.geolocation) {
      setLocStatus('Geolocation is not supported by your browser');
    } else {
      setLocStatus('Locating...');
      navigator.geolocation.getCurrentPosition((position) => {
        setLocStatus(null);
        setMarkerLatitude(position.coords.latitude);
        setMarkerLongitude(position.coords.longitude);
        //console.log("curr lat: " + position.coords.latitude + " longi: " + position.coords.longitude);
      }, () => {
        setLocStatus('Unable to retrieve your location');
      });
    }
  }

  const getAddress = (id) => {

    fetch("https://e-arogya.com:8443/api/general/citiesstates/" + id)
      .then(res => res.json())
      .then(data => {
        setMarkerByAddress(data[0].strcityname, data[0].strstatename);
      })
      .catch(error => {
        //console.log(error);
      });

  }

  const setMarkerByAddress = (cityname, statename) => {
    var address = `${strorganizationname}, ${cityname}, ${statename}`;
    //console.log("Address: " + address);
    var geocoder = new google.maps.Geocoder();
    geocoder.geocode({
      'address': address
    }, function (results, status) {
      if (status === google.maps.GeocoderStatus.OK) {
        var Lat = results[0].geometry.location.lat();
        var Lng = results[0].geometry.location.lng();
        setMarkerLatitude(Lat);
        setMarkerLongitude(Lng);
      } else {
        //console.log(" " + status);
        setUpdateMessage(<span style={{ color: "red" }}>Something got wrong</span>);
        setTimeout(() => setUpdateMessage(""), 3000);
      }
    });
  }

  //FORM SUBMIT
  const handleSubmit = (event) => {

    event.preventDefault();

    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;  // eslint-disable-line

    const formData = new FormData();

    if (strphoneno1) {
      if (strphoneno1 === "" || isNaN(strphoneno1)) {
        setUpdateMessage(<span style={{ color: "red" }}>Please Enter Valid 10 digit Phone 1</span>);
        setTimeout(() => setUpdateMessage(""), 3000);
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }
    if (strphoneno2) {
      if (strphoneno2 === "" || isNaN(strphoneno2)) {
        setUpdateMessage(<span style={{ color: "red" }}>Please Enter Valid 10 digit Phone 2</span>);
        setTimeout(() => setUpdateMessage(""), 3000);
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }
    if (strwebsite) {
      if (strwebsite === "") {
        setUpdateMessage(<span style={{ color: "red" }}>Please enter {strorganizationtype} website</span>);
        setTimeout(() => setUpdateMessage(""), 3000);
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }

    if (stremailid) {
      if (stremailid === "" || reg.test(stremailid) === false) {
        setUpdateMessage(<span style={{ color: "red" }}>Please enter valid {strorganizationtype} email id</span>);
        setTimeout(() => setUpdateMessage(""), 3000);
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }

    if (strorganizationname === "") {
      setUpdateMessage(<span style={{ color: "red" }}>Please enter {strorganizationtype} name</span>);
      setTimeout(() => setUpdateMessage(""), 3000);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    else if (strmobileno === "" || strmobileno.length !== 10 || isNaN(strmobileno)) {
      setUpdateMessage(<span style={{ color: "red" }}>Please enter valid 10 digit mobile number</span>);
      setTimeout(() => setUpdateMessage(""), 3000);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    else if (straddressline1 === "") {
      setUpdateMessage(<span style={{ color: "red" }}>Please enter Address Line 1</span>);
      setTimeout(() => setUpdateMessage(""), 3000);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    else if (straddressline2 === "") {
      setUpdateMessage(<span style={{ color: "red" }}>Please enter Address Line 2</span>);
      setTimeout(() => setUpdateMessage(""), 3000);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    else if (strarea === "") {
      setUpdateMessage(<span style={{ color: "red" }}>Please enter area</span>);
      setTimeout(() => setUpdateMessage(""), 3000);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    else if (strpincode === "" || isNaN(strpincode) || strpincode.length !== 6) {
      setUpdateMessage(<span style={{ color: "red" }}>Please enter a valid pincode</span>);
      setTimeout(() => setUpdateMessage(""), 3000);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    else if (intstateid === "" || intstateid === null || intstateid === "- Select -") {
      setUpdateMessage(<span style={{ color: "red" }}>Please select state</span>);
      setTimeout(() => setUpdateMessage(""), 3000);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    else if (intcityid === "" || intcityid === null || intcityid === "- Select -") {
      setUpdateMessage(<span style={{ color: "red" }}>Please select city</span>);
      setTimeout(() => setUpdateMessage(""), 3000);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    else {
      if (files !== 0) {
        for (let i = 0; i < files.length; i++) {
          if (files[i].size > 20000) {
            setUpdateMessage(<span style={{ color: "red" }}>File size exceeded!! Please select logo filesize less than 20KB.</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
            window.scrollTo({ top: 0, behavior: 'smooth' });
            return;
          }
          formData.append('files', files[i])
        }
      }

      if (certifiles !== 0) {
        for (let i = 0; i < certifiles.length; i++) {
          formData.append('certifiles', certifiles[i])
        }
      }

      if (letterpadfiles !== 0) {
        for (let i = 0; i < letterpadfiles.length; i++) {
          formData.append('letterpadfiles', letterpadfiles[i])
        }
      }


      // console.log(intloginid + " " + strorganizationtype + " " + strorganizationname + " " + strmobileno + " " + stremailid + " " + straddressline1 + " " + straddressline2 + " "
      //   + strarea + " " + strpincode + " " + intcityid + " " + intstateid + " " + strphoneno1 + " " + strphoneno2 + " " + strwebsite + " " + strstatus 
      //   + " " + strorganizationregno + " " + markerLatitude + " " + markerLongitude);

      formData.append('strmobileno', strmobileno);
      if (stremailid) { formData.append('stremailid', stremailid); }
      formData.append('strorganizationname', strorganizationname);
      if (strwebsite) { formData.append('strwebsite', strwebsite); }
      formData.append('straddressline1', straddressline1);
      formData.append('straddressline2', straddressline2);
      formData.append('strarea', strarea);
      formData.append('strpincode', strpincode);
      formData.append('intstateid', intstateid);
      formData.append('intcityid', intcityid);
      if (strphoneno1) { formData.append('strphoneno1', strphoneno1); }
      if (strphoneno2) { formData.append('strphoneno2', strphoneno2); }
      formData.append('strorganizationtype', strorganizationtype);
      formData.append('decorglatitude', markerLatitude);
      formData.append('decorglongitude', markerLongitude);
      if (strorganizationregno) { formData.append('strorganizationregno', strorganizationregno) }
      if (strstatus) { formData.append('strstatus', strstatus); }

      if (orgid) {
        updateOrganization(formData, orgid);
        // console.log(intloginid + " " + strorganizationtype + " " + strorganizationname + " " + strmobileno + " " + stremailid + " " + straddressline1 + " " + straddressline2 + " "
        //   + strarea + " " + strpincode + " " + intcityid + " " + intstateid + " " + strphoneno1 + " " + strphoneno2 + " " + strwebsite + " " + strstatus + " " + strorganizationregno + " " + markerLatitude + " " + markerLongitude);
      } else {
        createOrganization(formData);
      }

    }

  }

  //ADD ORGANIZATION DETAILS
  async function createOrganization(formData) {

    const requestOptions = {
      method: 'POST',
      body: formData
    };
    fetch("https://e-arogya.com:8443/api/general/organizationbyoperator/add/" + intloginid, requestOptions)
      .then(async response => {
        if (response.status === 200) {
          setUpdateMessage(<span style={{ color: "green" }}>{strorganizationtype} Added Successfully</span>);
          setTimeout(() => setUpdateMessage(""), 3000);
          window.scrollTo({ top: 0, behavior: 'smooth' });
          setTimeout(() => navigate('/operatordashboard'), 3000);
          clearData();
        } else {
          setUpdateMessage(<span style={{ color: "red" }}>{strorganizationtype} Not Added</span>);
          setTimeout(() => setUpdateMessage(""), 3000);
        }
      })
      .catch(error => {
        //console.log("error: " + error); //DUPLICATE KEY
        setUpdateMessage(<span style={{ color: "red" }}>Mobile or Email Id Already Exist</span>);
        setTimeout(() => setUpdateMessage(""), 3000);
        window.scrollTo({ top: 0, behavior: 'smooth' });
      });
  }

  //UPDATE ORGANIZATION DETAILS
  async function updateOrganization(formData, orgid) {
    const requestOptions = {
      method: 'PUT',
      body: formData
    };
    fetch("https://e-arogya.com:8443/api/general/organizationbyoperator/update/" + orgid + "/" + intloginid, requestOptions)
      .then(async response => {
        if (!response.ok) {
          setUpdateMessage(<span style={{ color: "green" }}>{strorganizationtype} Not Updated</span>);
          setTimeout(() => setUpdateMessage(""), 3000);
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }
        setUpdateMessage(<span style={{ color: "green" }}>{strorganizationtype} Updated Successfully</span>);
        setTimeout(() => setUpdateMessage(""), 3000);
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setTimeout(() => navigate('/operatordashboard'), 3000);
        clearData();
      })
      .catch(error => {
        setUpdateMessage(<span style={{ color: "red" }}>{strorganizationtype} Not Updated. Please try again.</span>);
        setTimeout(() => setUpdateMessage(""), 3000);
        window.scrollTo({ top: 0, behavior: 'smooth' });
      });
  }

  const clearData = () => {
    setStrorganizationname("");
    setStrMobileNo("");
    setStrEmailId("");
    setStraddressline1("");
    setStraddressline2("");
    setStrarea("");
    setStrPincode("");
    setIntstateid(null);
    setIntcityid(null);
    setStrphoneno1("");
    setStrphoneno2("");
    setStrwebsite("");
    setStatus("Inactive");
    setStrorganizationregno("");
    setMarkerLatitude(22.3039);
    setMarkerLongitude(70.8022);
    setLocStatus(null);

    setFiles('');
    aRef.current.value = null;
    setUploadLogoFile(null);
    setImageFile(null);

    setCertiFiles('');
    aCertiRef.current.value = null;
    setUploadCertiFile(null);
    setCertiImageFile(null);

    setLetterpadFiles('');
    aLetterpadRef.current.value = null;
    setUploadLetterpadFile(null);
    setLetterpadImageFile(null);
  }


  return (
    <div className="main" >

      <OperatorSidebar />

      <div className="page-content">

        <Adminheader />

        <h5>{orgid ? `Edit ${strorganizationtype} Data` : `Create ${strorganizationtype} Data`}</h5>

        <div className="table-content">

          <Form onSubmit={handleSubmit}>

            <div style={{ fontSize: 12, fontWeight: "bold", marginBottom: 10 }}>{updateMessage}</div>

            <Form.Group className="mb-3" controlId="formEnterState">
              <Row style={{ fontSize: 12, color: "grey", alignItems: "center" }}>
                <Col lg={2}>
                  <Form.Label style={{ fontSize: 13, fontWeight: "bold", marginBottom: 0 }}><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Organization Type</Form.Label>
                </Col>
                <Col lg={4}>
                  <Form.Control type="text" style={{ fontSize: 12 }} value={strorganizationtype} disabled />
                </Col>
                <Col lg={2}>
                  <Form.Label style={{ fontSize: 13, fontWeight: "bold", marginBottom: 0 }}><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />{strorganizationtype} Name </Form.Label>
                </Col>
                <Col lg={4}>
                  <Form.Control type="text" style={{ fontSize: 13 }} value={strorganizationname || ""} onChange={e => { setStrorganizationname(e.target.value) }} />
                </Col>
              </Row>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formEnterState">
              <Row style={{ fontSize: 12, color: "grey", alignItems: "center" }}>
                <Col lg={2}>
                  <Form.Label style={{ fontSize: 13, fontWeight: "bold", marginBottom: 0 }}><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Mobile Number</Form.Label>
                </Col>
                <Col lg={4}>
                  <Form.Control type="" style={{ fontSize: 13 }} value={strmobileno || ""} onChange={e => { setStrMobileNo(e.target.value) }} />
                </Col>
                <Col lg={2}>
                  <Form.Label style={{ fontSize: 13, fontWeight: "bold", marginLeft: 11, marginBottom: 0 }}>Email</Form.Label>
                </Col>
                <Col lg={4}>
                  <Form.Control type="text" style={{ fontSize: 13 }} value={stremailid || ""} onChange={e => { setStrEmailId(e.target.value) }} />
                </Col>
              </Row>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formEnterState">
              <Row style={{ fontSize: 12, color: "grey", alignItems: "center" }}>
                <Col lg={2}>
                  <Form.Label style={{ fontSize: 13, fontWeight: "bold", marginBottom: 0 }}><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Address Line 1 </Form.Label>
                </Col>
                <Col lg={4}>
                  <Form.Control type="text" style={{ fontSize: 13 }} value={straddressline1 || ""} onChange={e => { setStraddressline1(e.target.value) }} />
                </Col>
                <Col lg={2}>
                  <Form.Label style={{ fontSize: 13, fontWeight: "bold", marginBottom: 0 }}><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Address Line 2 </Form.Label>
                </Col>
                <Col lg={4}>
                  <Form.Control type="text" style={{ fontSize: 13 }} value={straddressline2 || ""} onChange={e => { setStraddressline2(e.target.value) }} />
                </Col>
              </Row>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formEnterState">
              <Row style={{ fontSize: 12, color: "grey", alignItems: "center" }}>
                <Col lg={2}>
                  <Form.Label style={{ fontSize: 13, fontWeight: "bold", marginBottom: 0 }}><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Area </Form.Label>
                </Col>
                <Col lg={4}>
                  <Form.Control type="text" style={{ fontSize: 13 }} value={strarea || ""} onChange={e => { setStrarea(e.target.value) }} />
                </Col>
                <Col lg={2}>
                  <Form.Label style={{ fontSize: 13, fontWeight: "bold", marginBottom: 0 }}><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Pincode </Form.Label>
                </Col>
                <Col lg={4}>
                  <Form.Control type="text" style={{ fontSize: 13 }} value={strpincode || ""} onChange={e => { setStrPincode(e.target.value) }} />
                </Col>
              </Row>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formEnterState">
              <Row style={{ fontSize: 12, color: "grey", alignItems: "center" }}>
                <Col lg={2}>
                  <Form.Label style={{ fontSize: 13, fontWeight: "bold", marginBottom: 0 }}><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />State</Form.Label>
                </Col>
                <Col lg={4}>
                  <Form.Select
                    aria-label="Default select example"
                    value={intstateid || ""}
                    style={{ fontSize: 13 }}
                    onChange={e => {
                      setIntstateid(e.target.value);
                      let cityList = [];
                      for (let i = 0; i < cities.length; i++) {
                        if (cities[i].intstateid === parseInt(e.target.value)) { //parseInt because the value received (e.target.value) is in string
                          //intstateid is equal
                          cityList.push(cities[i]);
                        }
                      }
                      setFilteredCities(cityList);
                    }}
                  >
                    <option value="- Select -">- Select -</option>
                    {states.map((data, key) => <option key={data.intstateid} value={data.intstateid}>{data.strstatename}</option>)}
                  </Form.Select>
                </Col>
                <Col lg={2}>
                  <Form.Label style={{ fontSize: 13, fontWeight: "bold", marginBottom: 0 }}><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />City</Form.Label>
                </Col>
                <Col lg={4}>
                  <Form.Select
                    aria-label="Default select example"
                    style={{ fontSize: 13 }}
                    value={intcityid || ""}
                    onChange={e => {
                      setIntcityid(e.target.value);
                      getAddress(e.target.value);
                    }}
                  >
                    <option value="- Select -">- Select -</option>
                    {filteredCities.map((data1, key) => <option key={data1.intcityid} value={data1.intcityid}>{data1.strcityname}</option>)}
                  </Form.Select>
                </Col>
              </Row>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formEnterState">
              <Row style={{ fontSize: 12, color: "grey", alignItems: "center" }}>
                <Col lg={2}>
                  <Form.Label style={{ fontSize: 13, fontWeight: "bold", marginLeft: 11, marginBottom: 0 }}>Phone 1</Form.Label>
                </Col>
                <Col lg={4}>
                  <Form.Control type="text" style={{ fontSize: 13 }} value={strphoneno1 || ""} onChange={e => { setStrphoneno1(e.target.value) }} />
                </Col>
                <Col lg={2}>
                  <Form.Label style={{ fontSize: 13, fontWeight: "bold", marginLeft: 11, marginBottom: 0 }}>Phone 2</Form.Label>
                </Col>
                <Col lg={4}>
                  <Form.Control type="text" style={{ fontSize: 13 }} value={strphoneno2 || ""} onChange={e => { setStrphoneno2(e.target.value) }} />
                </Col>
              </Row>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formEnterState">
              <Row style={{ fontSize: 12, color: "grey", alignItems: "center" }}>
                <Col lg={2}>
                  <Form.Label style={{ fontSize: 13, fontWeight: "bold", marginLeft: 11, marginBottom: 0 }}>Website</Form.Label>
                </Col>
                <Col lg={4}>
                  <Form.Control type="text" style={{ fontSize: 13 }} value={strwebsite || ""} onChange={e => { setStrwebsite(e.target.value) }} />
                </Col>
                <Col lg={2}>
                  <Form.Label style={{ fontSize: 13, fontWeight: "bold", marginLeft: 11, marginBottom: 0 }}>Status</Form.Label>
                </Col>
                <Col lg={4}>
                  <Form.Select aria-label="Default select example" style={{ fontSize: 12 }} value={strstatus || ""} onChange={e => { setStatus(e.target.value) }} disabled>
                    <option value="Active">Active</option>
                    <option value="Inactive">Inactive</option>
                  </Form.Select>
                </Col>
              </Row>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formEnterState">
              <Row style={{ fontSize: 12, color: "grey", alignItems: "center" }}>
                <Col lg={2}>
                  <Form.Label style={{ fontSize: 13, fontWeight: "bold", marginLeft: 11, marginBottom: 0 }}>Logo</Form.Label>
                </Col>
                <Col lg={4}>
                  <Form.Control
                    ref={aRef}
                    type="file"
                    style={{ fontSize: 12 }}
                    onChange={e => {
                      setFiles(e.target.files);
                      const reader = new FileReader();
                      reader.addEventListener("load", () => {
                        setUploadLogoFile(reader.result);
                      });
                      reader.readAsDataURL(e.target.files[0]);
                    }}
                    accept="image/png, image/jpeg"
                  />
                </Col>
                <Col lg={2}>
                  <Form.Label style={{ fontSize: 13, fontWeight: "bold", marginLeft: 11, marginBottom: 0 }}>Upload Letter Pad</Form.Label>
                </Col>
                <Col lg={4}>
                  <Form.Control
                    ref={aLetterpadRef}
                    type="file"
                    style={{ fontSize: 12 }}
                    onChange={e => {
                      setLetterpadFiles(e.target.files);
                      const reader = new FileReader();
                      reader.addEventListener("load", () => {
                        setUploadLetterpadFile(reader.result);
                      });
                      reader.readAsDataURL(e.target.files[0]);
                    }}
                    accept="image/png, image/jpeg"
                  />
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formEnterState">
              <Row style={{ fontSize: 12, color: "grey", alignItems: "center" }}>
                <Col lg={2}>
                </Col>
                <Col lg={4}>
                  {uploadlogofile ?
                    <Image src={uploadlogofile} width={75} height={75} fluid />
                    :
                    <Image src={`data:image/jpeg;base64,${(imagefile)}`} width={75} height={75} fluid />
                  }
                </Col>
                <Col lg={2}>
                </Col>
                <Col lg={4}>
                  {uploadletterpadfile ?
                    <Image src={uploadletterpadfile} width={75} height={75} fluid />
                    :
                    <Image src={`data:image/jpeg;base64,${(letterpadimagefile)}`} width={75} height={75} fluid />
                  }
                </Col>
              </Row>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formEnterState">
              <Row style={{ fontSize: 12, color: "grey", alignItems: "center" }}>
                <Col lg={2}>
                  <Form.Label style={{ fontSize: 13, fontWeight: "bold", marginLeft: 11, marginBottom: 0 }}>{strorganizationtype} Registration Certificate </Form.Label>
                </Col>
                <Col lg={4}>
                  <Form.Control
                    ref={aCertiRef}
                    type="file"
                    style={{ fontSize: 12 }}
                    onChange={e => {
                      setCertiFiles(e.target.files);
                      const reader = new FileReader();
                      reader.addEventListener("load", () => {
                        setUploadCertiFile(reader.result);
                      });
                      reader.readAsDataURL(e.target.files[0]);
                    }}
                    accept="image/png, image/jpeg"
                  />
                </Col>
                <Col lg={2}>
                  <Form.Label style={{ fontSize: 13, fontWeight: "bold", marginLeft: 11, marginBottom: 0 }}>{strorganizationtype} Regi. No. </Form.Label>
                </Col>
                <Col lg={4}>
                  <Form.Control type="text" style={{ fontSize: 13 }} value={strorganizationregno || ""} onChange={e => { setStrorganizationregno(e.target.value) }} />
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formEnterState">
              <Row style={{ fontSize: 12, color: "grey", alignItems: "center" }}>
                <Col lg={2}>
                </Col>
                <Col lg={4}>
                  {uploadcertifile ?
                    <Image src={uploadcertifile} width={75} height={75} fluid />
                    :
                    <Image src={`data:image/jpeg;base64,${(certiimagefile)}`} width={75} height={75} fluid />
                  }
                </Col>
              </Row>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formEnterState">
              <Row style={{ fontSize: 12, color: "grey", alignItems: "center" }}>
                <Col lg={2}>
                  <Form.Label style={{ fontSize: 13, fontWeight: "bold", marginLeft: 11, marginBottom: 0 }}>Location</Form.Label>
                </Col>
                <Col lg={10}>
                  <div style={{ fontSize: 12, color: "grey", marginTop: 20 }}>{locStatus}</div>
                  <LoadScript googleMapsApiKey='AIzaSyBzW45N5jxqiWtMZ6Ez_bmE0J70N6Z6XKA'>
                    <GoogleMap
                      defaultCenter={{ lat: 22.3039, lng: 70.8022 }}
                      mapContainerStyle={{ width: "100%", height: "300px", }}
                      center={{ lat: markerLatitude, lng: markerLongitude }}
                      zoom={13}
                      onClick={(e) => {
                        //console.log(e.latLng.lat(), e.latLng.lng())
                      }}
                    >
                      <Marker
                        position={{ lat: markerLatitude, lng: markerLongitude }}
                        label={{ color: "white", text: "H" }}
                        draggable={true}
                        onDragEnd={(e) => {
                          //console.log("Marker Latitude: " + e.latLng.lat() + " Longitude: " + e.latLng.lng());
                          setMarkerLatitude(e.latLng.lat());
                          setMarkerLongitude(e.latLng.lng());
                        }}
                        onClick={e => {
                          //console.log(e) 
                        }}
                      >
                      </Marker>

                    </GoogleMap>

                  </LoadScript>
                </Col>
              </Row>
            </Form.Group>


            <Form.Group className="mb-3" controlId="formEnterState">
              <Row style={{ fontSize: 12, color: "grey", alignItems: "center" }}>
                <Col lg={2}>
                </Col>
                <Col lg={4}>
                  <Button style={{ marginTop: 20, marginBottom: 20, width: "50%" }} variant="primary" type="submit">{orgid ? "Update" : "Save"}</Button>
                </Col>
              </Row>
            </Form.Group>


          </Form>

        </div>

      </div>

    </div>
  )
}

export default OperatorEnterOrganizationData
