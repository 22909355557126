import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./admincss.css";
import PharmacySidebar from "../Navbar/PharmacySidebar";
import Adminheader from "../Navbar/Adminheader";
import { Row, Col, Form, Button } from "react-bootstrap";

const PharmacyTimings = () => {

    return (

        <div className="main" >

            <PharmacySidebar />

            <div className="page-content">

                <Adminheader />

                <h5>Pharmacy Timings</h5>

                <div className="table-content">

                    <Row>

                        <Col sm={1} style={{ marginTop: 5, fontSize: 12, color: "grey" }}>
                            Working Days
                        </Col>

                        <Col sm={2}>
                            <Form.Group className="mb-3" controlId="formSelectStateStatus">

                                <Form.Select aria-label="Default select example" style={{ fontSize: 12 }}>
                                    <option value="1">- Select -</option>
                                    <option value="2">All Days</option>
                                    <option value="2">All Days - Ex Sun</option>
                                    <option value="2">All Days - Ex Sat & Sun</option>
                                    <option value="2">Monday</option>
                                    <option value="2">Tuesday</option>
                                    <option value="2">Wednesday</option>
                                    <option value="2">Thursday</option>
                                    <option value="2">Friday</option>
                                    <option value="2">Saturday</option>
                                    <option value="2">Sunday</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>

                    </Row>

                    <Row>

                        <Col sm={1} style={{ marginTop: 5, fontSize: 12, color: "grey" }}>
                            Timings
                        </Col>

                        <Col sm={1}>
                            <Form.Group className="mb-3" controlId="formEnterState">
                                <Form.Control type="time" style={{ fontSize: 12 }} />
                            </Form.Group>
                        </Col>

                        <Col sm={1}>
                            <Form.Group className="mb-3" controlId="formEnterState">
                                <Form.Control type="time" style={{ fontSize: 12, }} />
                            </Form.Group>
                        </Col>

                    </Row>

                    <Row>

                        <Col sm={1} style={{ marginTop: 5 }}>

                        </Col>

                        <Col sm={2}>
                            <Button variant="primary" type="submit" style={{ fontSize: 12 }}> Update </Button>
                        </Col>

                    </Row>

                </div>

            </div>
        </div>

    );
};

export default PharmacyTimings;