import React, { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./admincss.css";
import Sidebar from "../Navbar/Sidebar";
import Adminheader from "../Navbar/Adminheader";
import { CDBCard, CDBCardBody, CDBDataTable } from 'cdbreact';
import { Col, Form, Row, Button, Spinner, Modal } from "react-bootstrap";
import { Asterisk } from "react-bootstrap-icons";
// import { API_BASE } from '../../setupProxy';

const ManageSubReports = () => {

    const [subreports, setSubreports] = useState([]);
    const [reports, setReports] = useState([]);
    const [loading, setLoading] = useState(false);

    const [intSubReportID, setIntSubReportID] = useState(null);
    const [strSubreportName, setStrSubreportName] = useState("");
    const [intReportID, setIntReportID] = useState(null);
    const [strstatus, setStatus] = useState('Active');

    const [updateMessage, setUpdateMessage] = useState("");

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [deleteThis, setDeleteThis] = useState(null);

    useEffect(() => {
        fetchReports();
        fetchSubreports();
    }, []);

    //FETCH ALL SUBREPORTS
    const fetchSubreports = () => {
        fetch("https://e-arogya.com:8443/api/general/subreports")
            .then(response => response.json())
            .then(subreportsdata => {
                let dataFromApi = subreportsdata.map(subreport => {
                    return {
                        id: subreport.intSubReportID,
                        name: subreport.strSubreportName,
                        report: subreport.strReportName,
                        status: subreport.strstatus,
                    }
                })
                setSubreports(dataFromApi);
                setLoading(true);
            })
            .catch(error => {
                //console.log(error);
            })
    }

    //FETCH ALL REPORTS (FOR DROPDOWN)
    const fetchReports = () => {
        fetch("https://e-arogya.com:8443/api/general/reports")
            .then(res => res.json())
            .then(reportsData => {
                setReports(reportsData);
            })
            .catch(error => {
                //console.log(error);
            })

    }

    const clearFields = () => {
        setStrSubreportName("");
        setIntReportID('- Select -');
        setStatus('Active');
    }

    //FORM SUBMIT
    const onAddNewSubreportFormSubmit = (event) => {
        event.preventDefault();

        if (intReportID === '- Select -') {
            setUpdateMessage(<span style={{ color: "red" }}>Please Select Report</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
        } else {
            if (intSubReportID) {
                const existingInputs = { strSubreportName, intReportID, strstatus }
                updateSubreport(existingInputs, intSubReportID);
                setIntSubReportID(null);
                clearFields();
            } else {
                const toInput = { strSubreportName, intReportID, strstatus };
                addNewSubreport(toInput);
                clearFields();
            }
        }
    }

    //FETCH SUBREPORT BY ID (FOR EDIT SUBREPORT)
    const handleEditClick = (e, id) => {
        e.preventDefault();
        fetch("https://e-arogya.com:8443/api/general/subreports/" + id)
            .then(res => res.json())
            .then(data2 => {
                setIntSubReportID(data2[0].intSubReportID);
                setStrSubreportName(data2[0].strSubreportName);
                setIntReportID(data2[0].intReportID);
                setStatus(data2[0].strstatus);
            })
            .catch(error => {
                //console.log(error);
            });
    }

    //DELETE SUBREPORT

    function handleDeleteClick(e, id) {
        setShow(true);
        setDeleteThis(id);
    }

    const deleteThisFunc = (id) => {
        fetch("https://e-arogya.com:8443/api/general/subreports/" + id, { method: 'DELETE' })
            .then(async response => {
                const data = await response.text;
                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }
                setUpdateMessage(<span style={{ color: "green" }}>Subreport Deleted Successfully</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
                setDeleteThis(null);
                setIntSubReportID(null);
                clearFields();
                fetchSubreports();
            })
            .catch(error => {
                setUpdateMessage(<span style={{ color: "red" }}>Subreport Not Deleted. Please try again.</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
            });
    }


    //ADD NEW SUBREPORT
    async function addNewSubreport(toInput) {
        fetch("https://e-arogya.com:8443/api/general/subreports/addnewsubreport", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            redirect: "follow",
            body: JSON.stringify(toInput)
        })
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();

                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }
                setUpdateMessage(<span style={{ color: "green" }}>Subreport Added Successfully</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
                fetchSubreports();
            })
            .catch(error => {
                setUpdateMessage(<span style={{ color: "red" }}>Subreport Not Added. Please try again.</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
            });

    }

    //UPDATE EXISTING SUBREPORT
    async function updateSubreport(existingInputs, intSubReportID) {
        fetch("https://e-arogya.com:8443/api/general/subreports/update/" + intSubReportID, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            redirect: "follow",
            body: JSON.stringify(existingInputs)
        })
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();

                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }
                setUpdateMessage(<span style={{ color: "green" }}>Subreport Updated Successfully</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
                fetchSubreports();
            })
            .catch(error => {
                setUpdateMessage(<span style={{ color: "red" }}>Subreport Not Updated. Please try again.</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
            });

    }

    //POPULATE TABLE DATA
    const data = () => {
        return {
            columns: [
                { label: '#', field: 'srno', },
                {
                    label: 'Name',
                    field: 'name',
                    attributes: {
                        'aria-controls': 'DataTable',
                        'aria-label': 'Name',
                    },
                },
                { label: 'Report', field: 'report', },
                { label: 'Status', field: 'status', },
                { label: '', field: 'edit', },
                { label: '', field: 'delete', },

            ],
            rows: [...subreports.map((data1, index) => (
                {
                    srno: index + 1,
                    name: data1.name,
                    report: data1.report,
                    status: data1.status,
                    edit: <span onClick={(e) => handleEditClick(e, data1.id)}>
                        <Button variant="primary" type="submit" style={{ fontSize: 12 }} size="sm">
                            Edit
                        </Button>
                    </span>,
                    delete: <span onClick={(e) => handleDeleteClick(e, data1.id)}>
                        <Button variant="danger" type="submit" style={{ fontSize: 12 }} size="sm">
                            Delete
                        </Button>
                    </span>
                }
            ))

            ],
        };
    };
    return (

        <div className="main" >

            <Sidebar />

            <div className="page-content">

                <Adminheader />

                <h5>Manage Sub Reports (Tests)</h5>
                <p style={{ fontSize: 12, color: "grey", marginBottom: 50 }}>Manage Sub Reports (Test) to Appear for Laborary Registration</p>

                <div className="table-content">

                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title style={{ fontSize: 18 }}>Delete Sub Report</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ fontSize: 14 }}>Are you sure you want to delete this sub report?</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose} style={{ fontSize: 12 }}>No</Button>
                            <Button variant="primary" onClick={() => {
                                handleClose();
                                deleteThisFunc(deleteThis);
                            }} style={{ fontSize: 12 }}>Yes</Button>
                        </Modal.Footer>
                    </Modal>

                    <Form onSubmit={onAddNewSubreportFormSubmit}>

                        <Row style={{ fontSize: 12, color: "grey" }}>

                            <Col sm={2}>
                                <Form.Group className="mb-3" controlId="formEnterState">
                                    <Form.Label><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Test Name</Form.Label>
                                    <Form.Control type="text" style={{ fontSize: 12 }} value={strSubreportName || ""} onChange={e => { setStrSubreportName(e.target.value) }} required />
                                </Form.Group>
                            </Col>

                            <Col sm={2}>
                                <Form.Group className="mb-3" controlId="formSelectStateStatus">
                                    <Form.Label><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Report Type</Form.Label>
                                    <Form.Select aria-label="Default select example" style={{ fontSize: 12 }} value={intReportID || ""} onChange={e => { setIntReportID(e.target.value) }}>
                                        <option value="Select">- Select -</option>
                                        {reports.map((data, key) => <option key={data.intReportID} value={data.intReportID} >{data.strReportName}</option>)}
                                    </Form.Select>
                                </Form.Group>
                            </Col>

                            <Col sm={2}>
                                <Form.Group className="mb-3" controlId="formSelectStateStatus">
                                    <Form.Label>Status</Form.Label>
                                    <Form.Select aria-label="Default select example" style={{ fontSize: 12 }} value={strstatus || ""} onChange={e => { setStatus(e.target.value) }}>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>

                            <Col sm={2} >
                                <Form.Group className="mb-3" controlId="formSelectStateStatus">
                                    {intSubReportID ?
                                        <Button variant="primary" type="submit" style={{ marginTop: 25, fontSize: 12 }}> Update </Button>
                                        :
                                        <Button variant="primary" type="submit" style={{ marginTop: 25, fontSize: 12 }}> Add </Button>
                                    }
                                </Form.Group>
                            </Col>

                        </Row>

                    </Form>

                    <div style={{ fontSize: 12, fontWeight: "bold", marginBottom: 10 }}>{updateMessage}</div>

                    <CDBCard style={{ padding: 20, fontSize: 12, color: "grey" }}>
                        <CDBCardBody>
                            {!loading ? (
                                <div style={{ textAlign: "center" }}><Spinner animation="border" size="sm" variant="primary" /></div>
                            ) :
                                <CDBDataTable
                                    responsive
                                    noRecordsFoundLabel="No Records Found"
                                    noBottomColumn={true}
                                    hover
                                    entriesOptions={[10, 25, 50, 100, 200, 500]}
                                    entries={10}
                                    pagesAmount={4}
                                    data={data()}
                                />
                            }
                        </CDBCardBody>
                    </CDBCard>

                </div>

            </div>
        </div>

    );
};

export default ManageSubReports;