import React, { useEffect, useState, useRef } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./admincss.css";
import Sidebar from "../Navbar/Sidebar";
import Adminheader from "../Navbar/Adminheader";
import { CDBCard, CDBCardBody, CDBDataTable } from 'cdbreact';
import { Spinner, Image, Col, Form, Row, Button, Modal, Container } from "react-bootstrap";
// import { API_BASE } from '../../setupProxy';

const ManageLaboratories = () => {

    const [organization, setOrganization] = useState([]);
    const [loading, setLoading] = useState(false);

    const [show, setShow] = useState(false);
    const handleClose = () => {setShow(false); setUploadfile(null); clearData();};
    const handleShow = () => setShow(true);

    const [intorganizationid, setIntorganizationid] = useState(null);
    const [intloginid, setIntloginid] = useState(null);
    const [strorganizationtype, setStrorganizationtype] = useState("");
    const [strorganizationname, setStrorganizationname] = useState("");
    const [strwebsite, setStrwebsite] = useState("");
    const [strphoneno1, setStrphoneno1] = useState("");
    const [strphoneno2, setStrphoneno2] = useState("");
    const [straddressline1, setStraddressline1] = useState("");
    const [straddressline2, setStraddressline2] = useState("");
    const [strarea, setStrarea] = useState("");
    const [strpincode, setStrpincode] = useState("");
    const [intcityid, setIntcityid] = useState(null);
    const [intstateid, setIntstateid] = useState(null);
    const [strstatus, setStatus] = useState('Active');

    const [files, setFiles] = useState('');
    const [fileSize, setFileSize] = useState("");
    const [imagefile, setImageFile] = useState(null);
    const aRef = useRef(null);

    const [uploadfile, setUploadfile] = useState(null);

    const [cities, setCities] = useState([]);
    const [states, setStates] = useState([]);
    //const [filteredCities, setFilteredCities] = useState([]);

    const [message, setMessage] = useState("");
    const [updateMessage, setUpdateMessage] = useState("");

    const [showDelete, setShowDelete] = useState(false);
    const handleCloseDelete = () => setShowDelete(false);
    const [deleteThis, setDeleteThis] = useState(null);

    const clearData = () => {
        setIntorganizationid(null);
        setIntloginid(null);
        setStrorganizationtype("");
        setStrorganizationname("");
        setStrwebsite("");
        setStrwebsite("");
        setStrphoneno1("");
        setStrphoneno2("");
        setStraddressline1("");
        setStraddressline2("");
        setStrarea("");
        setStrpincode("");
        setIntcityid(null);
        setIntstateid(null);
        setStatus('Active');
        setFiles('');
        setFileSize("");
        setImageFile(null);
        setUploadfile(null);
    }

    useEffect(() => {
        fetchData()
    }, [])

    //FETCH ALL LABORATORY
    const fetchData = () => {
        fetch("https://e-arogya.com:8443/api/general/organization/type/Laboratory")
            .then(res => res.json())
            .then(data => {
                let dataFromApi = data.map(org => {
                    return {
                        id: org.intorganizationid,
                        name: org.strorganizationname,
                        logo: org.strlogo,
                        website: org.strwebsite,
                        address1: org.straddressline1,
                        address2: org.straddressline2,
                        area: org.strarea,
                        phone: org.strphoneno1,
                        type: org.strorganizationtype,
                        status: org.strstatus,
                        cityname: org.strcityname,
                    }
                })

                setOrganization(dataFromApi);
                setLoading(true);
            })
            .catch(error => {
                //console.log(error);
            });

    }

    const handleEditClick = (e, id) => {
        e.preventDefault();
        fetch("https://e-arogya.com:8443/api/general/organization/" + id)
            .then(res => res.json())
            .then(data => {
                setIntorganizationid(data[0].intorganizationid);
                setIntloginid(data[0].intloginid);
                setStrorganizationname(data[0].strorganizationname);
                setStrorganizationtype(data[0].strorganizationtype);
                setStrwebsite(data[0].strwebsite);
                setStraddressline1(data[0].straddressline1);
                setStraddressline2(data[0].straddressline2);
                setStrarea(data[0].strarea);
                setStrpincode(data[0].strpincode);
                setIntcityid(data[0].intcityid);
                setStrphoneno1(data[0].strphoneno1);
                setStrphoneno2(data[0].strphoneno2);
                setIntstateid(data[0].intstateid);
                setImageFile(data[0].strlogo);
            })
            .catch(error => {
                //console.log(error);
            });
    }

    const fetchStates = () => {
        fetch("https://e-arogya.com:8443/api/general/states")
            .then(res => res.json())
            .then(states => {
                setStates(states);
            })
            .catch(error => {
                //console.log(error);
            })
    }

    const fetchCities = () => {
        fetch("https://e-arogya.com:8443/api/general/cities")
            .then(res => res.json())
            .then(cities => {
                setCities(cities);
            })
            .catch(error => {
                //console.log(error);
            })
    }

    const updateLaboratoryData = () => {
        const formData = new FormData();

        if (strphoneno1) {
            if (strphoneno1 === "" || strphoneno1.length !== 10 || isNaN(strphoneno1)) {
                setMessage(<span style={{ color: "red" }}>Please Enter Valid 10 digit Phone 1</span>);
                setTimeout(() => setMessage(""), 3000);
            }
        }
        if (strphoneno2) {
            if (strphoneno2 === "" || strphoneno2.length !== 10 || isNaN(strphoneno2)) {
                setMessage(<span style={{ color: "red" }}>Please Enter Valid 10 digit Phone 2</span>);
                setTimeout(() => setMessage(""), 3000);
            }
        }
        if (strwebsite) {
            if (strwebsite === "") {
                setMessage(<span style={{ color: "red" }}>Please enter organization website</span>);
                setTimeout(() => setMessage(""), 3000);
            }
        }
        if (strpincode) {
            if (strpincode === "" || strpincode.length !== 6 || isNaN(strpincode)) {
                setMessage(<span style={{ color: "red" }}>Please Enter Valid 6 digit Pincode</span>);
                setTimeout(() => setMessage(""), 3000);
            }
        }

        if (strorganizationtype === "" || strorganizationtype === "- Select -") {
            setMessage(<span style={{ color: "red" }}>Please select organization type</span>);
            setTimeout(() => setMessage(""), 3000);
        } else if (strorganizationname === "") {
            setMessage(<span style={{ color: "red" }}>Please enter organization name</span>);
            setTimeout(() => setMessage(""), 3000);
        } else if (straddressline1 === "") {
            setMessage(<span style={{ color: "red" }}>Please Enter Address Line 1</span>);
            setTimeout(() => setMessage(""), 3000);
        } else if (straddressline2 === "") {
            setMessage(<span style={{ color: "red" }}>Please Enter Address Line 2</span>);
            setTimeout(() => setMessage(""), 3000);
        } else if (strarea === "") {
            setMessage(<span style={{ color: "red" }}>Please enter area</span>);
            setTimeout(() => setMessage(""), 3000);
        } else if (strpincode === "") {
            setMessage(<span style={{ color: "red" }}>Please enter pincode</span>);
            setTimeout(() => setMessage(""), 3000);
        } else if (intcityid === "" || intcityid === null || intcityid === "- Select -") {
            setMessage(<span style={{ color: "red" }}>Please select city</span>);
            setTimeout(() => setMessage(""), 3000);
        } else if (intstateid === "" || intstateid === null || intstateid === "- Select -") {
            setMessage(<span style={{ color: "red" }}>Please select state</span>);
            setTimeout(() => setMessage(""), 3000);
        } else {

            if (files !== 0) {
                for (let i = 0; i < files.length; i++) {
                    if (files[i].size > 20000) {
                        setFileSize(<span style={{ color: "red" }}>File size exceeded!! Please select filesize less than 20KB.</span>);
                        setTimeout(() => setFileSize(""), 3000);
                        return;
                    }
                    formData.append('files', files[i])
                }
            }

            // console.log(" " + intloginid + " " + intorganizationid + " " + strorganizationtype + " " + strorganizationname + " " + straddressline1 + " " + straddressline2 + " "
            //     + strarea + " " + strpincode + " " + intcityid + " " + intstateid + " " + strstatus + " " + files);

            formData.append('intloginid', intloginid);
            formData.append('strorganizationtype', strorganizationtype);
            if (strorganizationname) { formData.append('strorganizationname', strorganizationname); }
            if (strwebsite) { formData.append('strwebsite', strwebsite); }
            if (strphoneno1) { formData.append('strphoneno1', strphoneno1); }
            if (strphoneno2) { formData.append('strphoneno2', strphoneno2); }
            if (straddressline1) { formData.append('straddressline1', straddressline1); }
            if (straddressline2) { formData.append('straddressline2', straddressline2); }
            if (strarea) { formData.append('strarea', strarea); }
            if (strpincode) { formData.append('strpincode', strpincode); }
            if (intcityid) { formData.append('intcityid', intcityid); }
            if (strstatus) { formData.append('strstatus', strstatus); }

            updateLaboratoryDetails(formData, intorganizationid);
        }
    }

    async function updateLaboratoryDetails(formData, intorganizationid) {

        const requestOptions = {
            method: 'PUT',
            body: formData
        };
        fetch("https://e-arogya.com:8443/api/general/organization/update/" + intorganizationid, requestOptions)
            .then(res => {

                if (res.ok) {
                    setUpdateMessage(<span style={{ color: "green" }}>Laboratory Updated Successfully</span>);
                    setTimeout(() => setUpdateMessage(""), 3000);
                    setShow(false);
                    setUploadfile(null);
                    fetchData();
                } else {
                    setMessage(<span style={{ color: "red" }}>Laboratory Not Updated</span>);
                    setTimeout(() => setMessage(""), 3000);
                }
            })
            .catch(error => {
                setUpdateMessage(<span style={{ color: "red" }}>Laboratory Not Updated. Please try again.</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
                window.scrollTo({ top: 0, behavior: 'smooth' });
            });
    }

    function handleDeleteClick(e, id) {
        setShowDelete(true);
        setDeleteThis(id);
    }

    const deleteThisFunc = (id) => {
        fetch("https://e-arogya.com:8443/api/general/organization/" + id, { method: 'DELETE' })
            .then(async response => {
                const data = await response.text;

                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }
                setUpdateMessage(<span style={{ color: "green" }}>Laboratory Deleted Successfully</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
                setDeleteThis(null);
                fetchData();
            })
            .catch(error => {
                setUpdateMessage(<span style={{ color: "red" }}>Laboratory Not Deleted. Please try again.</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
            });
    }

    const data = () => {
        return {
            columns: [
                { label: '#', field: 'srno', },
                {
                    label: 'Laboratory Name',
                    field: 'name',
                    attributes: {
                        'aria-controls': 'DataTable',
                        'aria-label': 'Name',
                    },
                },
                { label: 'Address', field: 'address', },
                { label: 'Logo', field: 'logo', },
                { label: 'Area', field: 'area', },
                { label: 'City', field: 'cityname', },
                { label: 'Type', field: 'type', },
                { label: 'Status', field: 'status', },
                { label: '', field: 'edit', },
                { label: '', field: 'delete', },
            ],
            rows: [...organization.map((data, i) => (
                {
                    srno: i + 1,
                    name: data.name,
                    website: data.website,
                    address: data.address1 + ", " + data.address2,
                    logo: <div>
                        {data.logo ?
                            <Image src={`data:image/jpeg;base64,${(data.logo)}`} width={75} height={75} className="img-fluid" />
                            :
                            <div></div>
                        }
                    </div>,
                    area: data.area,
                    cityname: data.cityname,
                    phone: data.phone,
                    type: data.type,
                    status: data.status,
                    edit: <span onClick={(e) => { handleEditClick(e, data.id); handleShow(); fetchCities(); fetchStates(); }}> <Button variant="primary" type="submit" style={{ fontSize: 12 }} size="sm"> Edit </Button> </span>,
                    delete: <span onClick={(e) => { handleDeleteClick(e, data.id) }}> <Button variant="danger" type="submit" style={{ fontSize: 12 }} size="sm"> Delete </Button> </span>,
                }
            ))

            ],
        };
    };
    return (

        <div className="main" >

            <Sidebar />

            <div className="page-content">

                <Adminheader />

                <h5>Manage Laboratories</h5>

                <p style={{ fontSize: 12, color: "grey", marginBottom: 50 }}>Manage Laboratories to Appear in Application</p>

                <div style={{ fontSize: 12, fontWeight: "bold", marginBottom: 10 }}>{updateMessage}</div>

                <Modal show={showDelete} onHide={handleCloseDelete}>
                    <Modal.Header closeButton>
                        <Modal.Title style={{ fontSize: 18 }}>Delete Laboratory</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ fontSize: 14 }}>Are you sure you want to delete this Laboratory?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseDelete} style={{ fontSize: 12 }}>No</Button>
                        <Button variant="primary" onClick={() => {
                            handleCloseDelete();
                            deleteThisFunc(deleteThis);
                        }} style={{ fontSize: 12 }}>Yes</Button>
                    </Modal.Footer>
                </Modal>

                <Modal size="lg" show={show} onHide={handleClose} aria-labelledby="add-new-patient-modal">

                    <Modal.Header closeButton>
                        <Modal.Title id="add-new-patient-modal" style={{ fontSize: 18 }}>Edit Laboratory</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>

                        <Container>

                            <div style={{ fontSize: 12, fontWeight: "bold", marginBottom: 10 }}>{message}</div>
                            <div style={{ fontSize: 12, fontWeight: "bold", marginBottom: 10 }}>{fileSize}</div>

                            <Row>
                                <Col sm={5}>
                                    <Form.Label style={{ fontSize: 12, color: "grey", fontWeight: "bold" }}>Laboratory Name</Form.Label>
                                    <Form.Group className="mb-3" controlId="formEnterState">
                                        <Form.Control type="text" style={{ fontSize: 12 }} placeholder="Laboratory Name" value={strorganizationname || ""} onChange={e => { setStrorganizationname(e.target.value) }} />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm={3}>
                                    <Form.Label style={{ fontSize: 12, color: "grey", fontWeight: "bold" }}>Phone 1</Form.Label>
                                    <Form.Group className="mb-3" controlId="formEnterState">
                                        <Form.Control type="text" style={{ fontSize: 12 }} placeholder="Phone 1" value={strphoneno1 || ""} onChange={e => { setStrphoneno1(e.target.value) }} />
                                    </Form.Group>
                                </Col>

                                <Col sm={3}>
                                    <Form.Label style={{ fontSize: 12, color: "grey", fontWeight: "bold" }}>Phone 2</Form.Label>
                                    <Form.Group className="mb-3" controlId="formEnterState">
                                        <Form.Control type="text" style={{ fontSize: 12 }} placeholder="Phone 2" value={strphoneno2 || ""} onChange={e => { setStrphoneno2(e.target.value) }} />
                                    </Form.Group>
                                </Col>

                                <Col sm={4}>
                                    <Form.Group className="mb-3" controlId="formEnterState">
                                        <Form.Label style={{ fontSize: 12, color: "grey", fontWeight: "bold" }}>Website</Form.Label>
                                        <Form.Control type="" style={{ fontSize: 12 }} value={strwebsite || ""} placeholder="Website" onChange={e => { setStrwebsite(e.target.value) }} />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Form.Label style={{ fontSize: 12, color: "grey", fontWeight: "bold" }}>Address</Form.Label>

                            <Row>
                                <Col sm={5}>
                                    <Form.Group className="mb-3" controlId="formEnterState">
                                        <Form.Control type="text" style={{ fontSize: 12 }} placeholder="Address Line 1" value={straddressline1 || ""} onChange={e => { setStraddressline1(e.target.value) }} />
                                    </Form.Group>
                                </Col>
                                <Col sm={4}>
                                    <Form.Group className="mb-3" controlId="formEnterState">
                                        <Form.Control type="text" style={{ fontSize: 12 }} placeholder="Address Line 2" value={straddressline2 || ""} onChange={e => { setStraddressline2(e.target.value) }} />
                                    </Form.Group>
                                </Col>
                                <Col sm={3}>
                                    <Form.Group className="mb-3" controlId="formEnterState">
                                        <Form.Control type="text" style={{ fontSize: 12 }} placeholder="Area" value={strarea || ""} onChange={e => { setStrarea(e.target.value) }} />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm={3}>
                                    <Form.Group className="mb-3" controlId="formEnterState">
                                        <Form.Control type="text" style={{ fontSize: 12 }} placeholder="Pincode" value={strpincode || ""} onChange={e => { setStrpincode(e.target.value) }} />
                                    </Form.Group>
                                </Col>
                                <Col sm={3}>
                                    <Form.Group className="mb-3" controlId="formSelectStateStatus">
                                        <Form.Select aria-label="Default select example" style={{ fontSize: 12 }} value={intcityid || ""} onChange={e => { setIntcityid(e.target.value) }} >
                                            <option value="- Select -">- Select City -</option>
                                            {cities.map((data, key) => <option key={data.intcityid} value={data.intcityid} >{data.strcityname}</option>)}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col sm={3}>
                                    <Form.Group className="mb-3" controlId="formSelectStateStatus">
                                        <Form.Select aria-label="Default select example" style={{ fontSize: 12 }} value={intstateid || ""} onChange={e => { setIntstateid(e.target.value) }} >
                                            <option value="- Select -">- Select State -</option>
                                            {states.map((data, key) => <option key={data.intstateid} value={data.intstateid} >{data.strstatename}</option>)}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                {/* <Col sm={3}>
                    <Form.Group className="mb-3" controlId="formSelectStateStatus">
                        <Form.Select
                            aria-label="Default select example"
                            style={{ fontSize: 12 }}
                            value={intstateid || ""}
                            onChange={e => {
                                setIntstateid(e.target.value);
                                console.log(e.target.value);
                                //fetchCitiesById(e.target.value) 
                                let cityList = [];
                                for (let i = 0; i < cities.length; i++) {
                                    if (cities[i].intstateid === parseInt(e.target.value)) { //parseInt because the value received (e.target.value) is in string
                                        //intstateid is equal
                                        cityList.push(cities[i]);
                                    }
                                }
                                console.log("City List: " + cityList);
                                setFilteredCities(cityList);
                            }}
                            required
                        >
                            <option value="- Select -">- Select -</option>
                            {states.map((data, key) => <option key={data.intstateid} value={data.intstateid} >{data.strstatename}</option>)}
                        </Form.Select>
                    </Form.Group>
                </Col>

                <Col sm={3}>
                    <Form.Group className="mb-3" controlId="formSelectStateStatus">
                        <Form.Select
                            aria-label="Default select example"
                            style={{ fontSize: 12 }}
                            value={intcityid || ""}
                            onChange={e => {
                                setIntcityid(e.target.value);
                                console.log(e.target.value);
                            }}
                            required
                        >
                            <option value="- Select -">- Select -</option>
                            {filteredCities.map((data1, key) => <option key={data1.intcityid} value={data1.intcityid}>{data1.strcityname}</option>)}
                        </Form.Select>
                    </Form.Group>
                </Col> */}

                            </Row>

                            <Row>
                                <Col sm={3}>
                                    <Form.Group className="mb-3" controlId="formSelectStateStatus">
                                        <Form.Label style={{ fontSize: 12, color: "grey", fontWeight: "bold" }}>Status</Form.Label>
                                        <Form.Select aria-label="Default select example" style={{ fontSize: 12 }} value={strstatus || ""} onChange={e => { setStatus(e.target.value) }}>
                                            <option value="Active">Active</option>
                                            <option value="Inactive">Inactive</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col sm={4}>
                                    <Form.Group className="mb-3" controlId="formEnterState">
                                        <Form.Label style={{ fontSize: 12, color: "grey", fontWeight: "bold" }}>Logo</Form.Label>
                                        <Form.Control
                                            ref={aRef}
                                            type="file"
                                            style={{ fontSize: 12 }}
                                            onChange={e => {
                                                setFiles(e.target.files);
                                                const reader = new FileReader();
                                                reader.addEventListener("load", () => {
                                                    setImageFile(reader.result);
                                                    setUploadfile(reader.result);
                                                });
                                                reader.readAsDataURL(e.target.files[0]);
                                            }}
                                            accept="image/png, image/jpeg"
                                        />
                                    </Form.Group>
                                </Col>

                                <Col sm={2}>
                                    <Form.Group className="mb-3" controlId="formSelectStateStatus">
                                        {uploadfile ?
                                            <Image src={uploadfile} width={60} height={60} />
                                            :
                                            <Image src={`data:image/jpeg;base64,${(imagefile)}`} width={60} height={60} />
                                        }
                                    </Form.Group>
                                </Col>
                            </Row>

                        </Container>

                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose} style={{ fontSize: 12, fontWeight: "bold" }}> Close </Button>
                        <Button variant="primary" onClick={() => { updateLaboratoryData() }} type="submit" style={{ fontSize: 12, fontWeight: "bold" }}> Update Laboratory </Button>
                    </Modal.Footer>
                </Modal>

                <div className="table-content">

                    {!loading ? (
                        <div style={{ textAlign: "center" }}><Spinner animation="border" size="sm" variant="primary" /></div>
                    ) :
                        <CDBCard style={{ padding: 20, fontSize: 12, color: "grey" }}>
                            <CDBCardBody>
                                <CDBDataTable
                                    responsive
                                    noRecordsFoundLabel="No Records Found"
                                    noBottomColumn={true}
                                    hover
                                    entriesOptions={[10, 25, 50, 100, 200, 500]}
                                    entries={10}
                                    pagesAmount={4}
                                    data={data()}
                                />
                            </CDBCardBody>
                        </CDBCard>
                    }
                </div>

            </div>

        </div>

    );
};

export default ManageLaboratories;