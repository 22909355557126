import React, { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./admincss.css";
import HospitalSidebar from "../Navbar/HospitalSidebar";
import Adminheader from "../Navbar/Adminheader";
import { useLocation } from "react-router-dom";
import { Document, Page, View, PDFDownloadLink, Text, StyleSheet, Image } from "@react-pdf/renderer";
import Form from 'react-bootstrap/Form';
// import { API_BASE } from '../../setupProxy';

const HospitalGeneratePrescription = () => {

    const intOrganizationID = localStorage.getItem("orgid");
    // const medtable = localStorage.getItem("mytable");
    // var medi = JSON.parse(medtable);

    const location = useLocation();

    const [strFirstName, setStrFirstName] = useState("");
    const [strLastName, setStrLastName] = useState("");
    const [intAge, setIntAge] = useState(null);
    const [strBloodGroup, setStrBloodGroup] = useState("");
    const [strGender, setStrGender] = useState("");
    const [strmobileno, setStrmobileno] = useState("");
    const [strcityname, setStrcityname] = useState("");

    const [strorganizationname, setStrorganizationname] = useState("");
    const [strwebsite, setStrwebsite] = useState("");
    const [strphoneno1, setStrphoneno1] = useState("");
    const [straddressline1, setStraddressline1] = useState("");
    const [straddressline2, setStraddressline2] = useState("");
    const [strarea, setStrarea] = useState("");
    const [strpincode, setStrpincode] = useState("");
    const [orgstrcityname, setOrgStrCityName] = useState("");
    const [orgstrstatename, setOrgStrStateName] = useState("");
    const [imagefile, setImageFile] = useState(null);
    const [imagetype, setImageType] = useState("");

    const [appointmentDetails, setAppointmentDetails] = useState([]);

    // const {
    //     appid, caseno,
    //     weight, height, bmi, bsa, pulse, spo2, bloodpressure,
    //     chiefcomplaints,
    //     diagnosis, diagnosisnotes,
    //     clinicalexamination,
    //     investigation,
    //     personalhistory, majorillness, hospitalization, medications, covidho, covidhospitalization, covidvaccine, familyhistory,
    //     menstrualcycle, pain, lmpdate, children, abortion, typeofdelivery,
    // } = location.state ?? {}

    const { appid, patid } = location.state ?? {}

    // var chiefcomplaintsString = chiefcomplaints;
    // var finalChiefComplaints = chiefcomplaintsString.substring(1, chiefcomplaintsString.length - 1);

    // var diagnosisString = diagnosis;
    // var finalDiagnosis = diagnosisString.substring(1, diagnosisString.length - 1);

    // var investigationString = investigation;
    // var finalInvestigation = investigationString.substring(1, investigationString.length - 1);

    const currentdate = new Date();
    const todaydate = `${currentdate.getDate()}/${currentdate.getMonth() + 1}/${currentdate.getFullYear()}`;


    useEffect(() => {
        fetchPatientInfo(appid);
        fetchOrganizationData(intOrganizationID);
        fetchPatientAppointmentDetails(intOrganizationID, patid, appid);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchPatientInfo = (id) => {
        fetch("https://e-arogya.com:8443/api/general/appointments/" + appid)
            .then(res => res.json())
            .then(data => {
                //console.log(data[0] + " " + patid +" " + caseno );
                setStrFirstName(data[0].strFirstName);
                setStrLastName(data[0].strLastName);
                if (data[0].intAge) { setIntAge(data[0].intAge + " years"); }
                setStrBloodGroup(data[0].strBloodGroup);
                setStrGender(data[0].strGender);
                setStrmobileno(data[0].strmobileno);
                setStrcityname(data[0].strcityname);
            })
            .catch(error => {
                //console.log(error);
            });
    }

    const fetchOrganizationData = (id) => {
        fetch("https://e-arogya.com:8443/api/general/organization/" + id)
            .then(res => res.json())
            .then(data => {
                //console.log("phone: " + data[0].strphoneno1);
                setStrorganizationname(data[0].strorganizationname);
                setStrwebsite("Website: " + data[0].strwebsite);
                setStrphoneno1("Phone: " + data[0].strphoneno1);
                setStraddressline1(data[0].straddressline1);
                setStraddressline2(data[0].straddressline2);
                setStrarea(data[0].strarea);
                setStrpincode(data[0].strpincode);
                if (data[0].strlogo) { setImageFile(data[0].strlogo) }
                if (data[0].strlogotype) { setImageType(data[0].strlogotype) }
                setOrgStrCityName(data[0].strcityname);
                getStateName(data[0].intstateid);
            })
            .catch(error => {
                //console.log(error);
            });
    }

    const getStateName = (id) => {
        fetch("https://e-arogya.com:8443/api/general/states/" + id)
            .then(res => res.json())
            .then(data => {
                setOrgStrStateName(data[0].strstatename);
            })
            .catch(error => {
                //console.log(error);
            });
    }

    const fetchPatientAppointmentDetails = (orgid, patid, appid) => {
        fetch("https://e-arogya.com:8443/api/general/patientappointment/getpatientappointmentdetails/" + orgid + "/" + patid + "/" + appid)
            .then(res => res.json())
            .then(data => {
                //console.log(data);
                setAppointmentDetails(data);
            })
            .catch(error => {
                //console.log(error);
            });
    }

    //LetterPad Radio Button 
    const [withLp, setWithLp] = useState("No");
    const [item, setItem] = useState({ letterType: "", another: "another" });
    const { letterType } = item;
    const handleRadioChange = e => {
        e.persist();
        setItem(prevState => ({ ...prevState, letterType: e.target.value }));
        //console.log("val: " + e.target.value);
        if (e.target.value === "With LetterPad") {
            setWithLp("Yes");
        } else if (e.target.value === "Without LetterPad") {
            setWithLp("No");
        }
    };


    return (

        <div className="main" >

            <HospitalSidebar />

            <div className="page-content">

                <Adminheader />

                <h5>Download Prescription</h5>

                <div className="table-content">

                    <Form.Check inline label="With LetterPad" name="group1" type='radio' value="With LetterPad" aria-label="radio 1" style={{ fontSize: 12 }} onChange={handleRadioChange} checked={letterType === "With LetterPad"} />
                    <Form.Check inline label="Without LetterPad" name="group1" type='radio' value="Without LetterPad" aria-label="radio 2" style={{ fontSize: 12 }} onChange={handleRadioChange} checked={letterType === "Without LetterPad"} />

                    {letterType ?

                        <p>
                            <PDFDownloadLink
                                document={
                                    <Document>
                                        <Page size="A4" style={styles.page}>

                                            {withLp === "Yes" ?
                                                <View style={{ height: 80, backgroundColor: "#fff", paddingRight: 20, paddingLeft: 20 }}>

                                                </View>
                                                :
                                                <View style={{ height: 80, backgroundColor: "#fff", paddingRight: 20, paddingLeft: 20 }}>
                                                    <View style={{ flexDirection: "row", alignItems: "center" }}>
                                                        <View style={{ flexDirection: "column", flex: 1 }}>
                                                            <Text style={styles.mainHeading}>{strorganizationname}</Text>
                                                            <Text style={styles.headingLabel}>{straddressline1}, {straddressline2}, {strarea}, {orgstrcityname}, {orgstrstatename} - {strpincode}</Text>
                                                            {!strphoneno1.match("null") ? <Text style={styles.headingLabel}>{strphoneno1}</Text> : <Text style={styles.headingLabel}>Phone:  </Text>}
                                                            {!strwebsite.match("null") ? <Text style={styles.headingLabel}>{strwebsite}</Text> : <Text style={styles.headingLabel}>Website:  </Text>}
                                                            {/* <Text style={styles.headingLabel}>{strwebsite}</Text> */}
                                                        </View>
                                                        <View>
                                                            {imagetype === "image/png" ?
                                                                <Image src={`data:image/png;base64,${imagefile}`} style={styles.headinglogo} />
                                                                :
                                                                <Image src={`data:image/jpg;base64,${imagefile}`} style={styles.headinglogo} />
                                                            }

                                                        </View>

                                                    </View>

                                                    <View style={{ height: 1, backgroundColor: "grey", marginTop: 10 }}></View>
                                                </View>
                                            }

                                            <View style={{ margin: 20 }}>

                                                {appointmentDetails.map((ad, index) => (

                                                    <View key={index + "ad"}>

                                                        <View style={{ height: 20, backgroundColor: "#D6EEEE", marginTop: 10, padding: 5, marginBottom: 10 }}>
                                                            <Text style={styles.mainSectionHeading}>PATIENT DETAILS</Text>
                                                        </View>

                                                        <View style={{ flexDirection: "row", marginHorizontal: 10 }}>

                                                            <View style={{ flexDirection: "column" }}>

                                                                <View style={{ flexDirection: "row", padding: 2 }}>
                                                                    <View style={{ flexDirection: "row" }}>
                                                                        <Text style={styles.label}>Name:</Text>
                                                                        <Text style={styles.labelans}>{strFirstName} {strLastName}</Text>
                                                                    </View>
                                                                    <View style={{ flexDirection: "row" }}>
                                                                        <Text style={styles.label}>Age:</Text>
                                                                        <Text style={styles.labelans}>{intAge}</Text>
                                                                    </View>
                                                                    <View style={{ flexDirection: "row" }}>
                                                                        <Text style={styles.label}>Gender:</Text>
                                                                        <Text style={styles.labelans}>{strGender}</Text>
                                                                    </View>
                                                                </View>

                                                                <View style={{ flexDirection: "row", padding: 2 }}>
                                                                    <View style={{ flexDirection: "row" }}>
                                                                        <Text style={styles.label}>Case No:</Text>
                                                                        <Text style={styles.labelans}>{ad.strCaseNo}</Text>
                                                                    </View>
                                                                    <View style={{ flexDirection: "row" }}>
                                                                        <Text style={styles.label}>Blood Group:</Text>
                                                                        <Text style={styles.labelans}>{strBloodGroup}</Text>
                                                                    </View>
                                                                    <View style={{ flexDirection: "row" }}>
                                                                        <Text style={styles.label}>Mobile:</Text>
                                                                        <Text style={styles.labelans}>{strmobileno}</Text>
                                                                    </View>
                                                                    <View style={{ flexDirection: "row" }}>
                                                                        <Text style={styles.label}>City:</Text>
                                                                        <Text style={styles.labelans}>{strcityname}</Text>
                                                                    </View>
                                                                </View>

                                                            </View>

                                                            <View style={{ flexDirection: "column" }}>
                                                                <View style={{ flexDirection: "row", padding: 2 }}>
                                                                    <Text style={styles.label}>Date:</Text>
                                                                    <Text style={styles.labelans}>{todaydate}</Text>
                                                                </View>
                                                            </View>

                                                        </View>

                                                        {ad.strWeight || ad.strHeight || ad.strBmi || ad.strBsa || ad.strPulse || ad.strSpo || ad.strBloodPressure || ad.strRespiratoryRate || ad.strTemperature !== null ?
                                                            <View>

                                                                {ad.strWeight !== ' kg' || ad.strHeight !== ' ft' || ad.strBmi !== null || ad.strBsa !== null || ad.strPulse !== null || ad.strSpo !== null || ad.strBloodPressure !== null || ad.strRespiratoryRate !== null || ad.strTemperature !== null ?
                                                                    <View style={{ height: 20, backgroundColor: "#D6EEEE", marginTop: 10, padding: 5 }}>
                                                                        <Text style={styles.mainSectionHeading}>VITALS</Text>
                                                                    </View>
                                                                    :
                                                                    <View></View>
                                                                }

                                                                <View style={{ flexDirection: "row", padding: 2, marginTop: 10, marginHorizontal: 10 }}>

                                                                    {ad.strWeight !== ' kg' ?
                                                                        <View style={{ flexDirection: "column", marginRight: 20 }}>
                                                                            <Text style={styles.label}>Weight</Text>
                                                                            <Text style={styles.labelans}>{ad.strWeight}</Text>
                                                                        </View>
                                                                        :
                                                                        <View></View>
                                                                    }
                                                                    {ad.strHeight !== ' ft' ?
                                                                        <View style={{ flexDirection: "column", marginRight: 20 }}>
                                                                            <Text style={styles.label}>Height</Text>
                                                                            <Text style={styles.labelans}>{ad.strHeight}</Text>
                                                                        </View>
                                                                        :
                                                                        <View></View>
                                                                    }
                                                                    {ad.strBmi !== null ?
                                                                        <View style={{ flexDirection: "column", marginRight: 20 }}>
                                                                            <Text style={styles.label}>BMI</Text>
                                                                            <Text style={styles.labelans}>{ad.strBmi} kg/m²</Text>
                                                                        </View>
                                                                        :
                                                                        <View></View>
                                                                    }
                                                                    {ad.strBsa !== null ?
                                                                        <View style={{ flexDirection: "column", marginRight: 20 }}>
                                                                            <Text style={styles.label}>BSA</Text>
                                                                            <Text style={styles.labelans}>{ad.strBsa} m²</Text>
                                                                        </View>
                                                                        :
                                                                        <View></View>
                                                                    }
                                                                    {ad.strPulse !== null ?
                                                                        <View style={{ flexDirection: "column", marginRight: 20 }}>
                                                                            <Text style={styles.label}>Pulse</Text>
                                                                            <Text style={styles.labelans}>{ad.strPulse}/min</Text>
                                                                        </View>
                                                                        :
                                                                        <View></View>
                                                                    }
                                                                    {ad.strSpo !== null ?
                                                                        <View style={{ flexDirection: "column", marginRight: 20 }}>
                                                                            <Text style={styles.label}>SpO2</Text>
                                                                            <Text style={styles.labelans}>{ad.strSpo}%</Text>
                                                                        </View>
                                                                        :
                                                                        <View></View>
                                                                    }
                                                                    {ad.strBloodPressure !== null ?
                                                                        <View style={{ flexDirection: "column", marginRight: 20 }}>
                                                                            <Text style={styles.label}>Blood Pressure</Text>
                                                                            <Text style={styles.labelans}>{ad.strBloodPressure} mm/Hg</Text>
                                                                        </View>
                                                                        :
                                                                        <View></View>
                                                                    }
                                                                    {ad.strRespiratoryRate !== null ?
                                                                        <View style={{ flexDirection: "column", marginRight: 20 }}>
                                                                            <Text style={styles.label}>Respiratory Rate</Text>
                                                                            <Text style={styles.labelans}>{ad.strRespiratoryRate} breaths/min</Text>
                                                                        </View>
                                                                        :
                                                                        <View></View>
                                                                    }
                                                                    {ad.strTemperature !== null ?
                                                                        <View style={{ flexDirection: "column", marginRight: 20 }}>
                                                                            <Text style={styles.label}>Temperature</Text>
                                                                            <Text style={styles.labelans}>{ad.strTemperature} °F</Text>
                                                                        </View>
                                                                        :
                                                                        <View></View>
                                                                    }

                                                                </View>

                                                            </View>
                                                            :
                                                            <View></View>
                                                        }

                                                        {ad.strComplain !== "" ?
                                                            <View>
                                                                <View style={{ height: 20, backgroundColor: "#D6EEEE", marginTop: 10, padding: 5 }}>
                                                                    <Text style={styles.mainSectionHeading}>COMPLAINTS</Text>
                                                                </View>

                                                                <View style={{ flexDirection: "column", padding: 2, marginTop: 10 }}>

                                                                    <View style={{ flexDirection: "row", marginHorizontal: 10 }}>
                                                                        <Text style={styles.label}>Chief Complaints: </Text>
                                                                        <Text style={styles.labelans}>{ad.strComplain}</Text>
                                                                    </View>

                                                                </View>
                                                            </View>
                                                            :
                                                            <View></View>
                                                        }

                                                        {ad.strDiagnosisSearch || ad.strDiagnosis !== null ?
                                                            <View>
                                                                <View style={{ height: 20, backgroundColor: "#D6EEEE", marginTop: 10, padding: 5 }}>
                                                                    <Text style={styles.mainSectionHeading}>DIAGNOSIS</Text>
                                                                </View>

                                                                <View style={{ flexDirection: "column", padding: 2, marginTop: 10 }}>
                                                                    {ad.strDiagnosisSearch !== null ?
                                                                        <View style={{ flexDirection: "row", marginBottom: 2, marginHorizontal: 10 }}>
                                                                            <Text style={styles.label}>Diagnosis: </Text>
                                                                            <Text style={styles.labelans}>{ad.strDiagnosisSearch}</Text>
                                                                        </View>
                                                                        :
                                                                        <View></View>
                                                                    }
                                                                    {ad.strDiagnosis !== null ?
                                                                        <View style={{ flexDirection: "row", marginBottom: 2, marginHorizontal: 10 }}>
                                                                            <Text style={styles.label}>Notes: </Text>
                                                                            <Text style={styles.labelans}>{ad.strDiagnosis}</Text>
                                                                        </View>
                                                                        :
                                                                        <View></View>
                                                                    }
                                                                </View>
                                                            </View>
                                                            :
                                                            <View></View>
                                                        }

                                                        {/* {ad.strClinicalExamination !== '' ?
                                                            <View>
                                                                <View style={{ height: 20, backgroundColor: "#D6EEEE", marginTop: 10, padding: 5 }}>
                                                                    <Text style={styles.mainSectionHeading}>CLIINICAL EXAMINATION</Text>
                                                                </View>

                                                                <View style={{ flexDirection: "column", padding: 2, marginTop: 10 }}>

                                                                    <View style={{ flexDirection: "row", marginHorizontal: 10 }}>
                                                                        <Text style={styles.label}>Clinical Examination: </Text>
                                                                        <Text style={styles.labelans}>{ad.strClinicalExamination}</Text>
                                                                    </View>

                                                                </View>
                                                            </View>
                                                            :
                                                            <View></View>
                                                        }

                                                        {ad.strPersonalHistory || ad.strMajorIllness || ad.strHospitalization || ad.strMedications || ad.strCovidHO || ad.strCovidHospitalization || ad.strCovidVaccine || ad.strFamilyHistory !== null ?
                                                            <View>
                                                                <View style={{ height: 20, backgroundColor: "#D6EEEE", marginTop: 10, padding: 5 }}>
                                                                    <Text style={styles.mainSectionHeading}>HISTORY</Text>
                                                                </View>

                                                                <View style={{ flexDirection: "column", padding: 2, marginTop: 10 }}>

                                                                    {ad.strPersonalHistory !== null ?
                                                                        <View style={{ flexDirection: "row", marginBottom: 2, marginHorizontal: 10 }}>
                                                                            <Text style={styles.label}>Personal History: </Text>
                                                                            <Text style={styles.labelans}>{ad.strPersonalHistory}</Text>
                                                                        </View>
                                                                        :
                                                                        <View></View>
                                                                    }
                                                                    {ad.strMajorIllness !== null ?
                                                                        <View style={{ flexDirection: "row", marginBottom: 2, marginHorizontal: 10 }}>
                                                                            <Text style={styles.label}>Major Illness: </Text>
                                                                            <Text style={styles.labelans}>{ad.strMajorIllness}</Text>
                                                                        </View>
                                                                        :
                                                                        <View></View>
                                                                    }
                                                                    {ad.strHospitalization !== null ?
                                                                        <View style={{ flexDirection: "row", marginBottom: 2, marginHorizontal: 10 }}>
                                                                            <Text style={styles.label}>Hospitalization: </Text>
                                                                            <Text style={styles.labelans}>{ad.strHospitalization}</Text>
                                                                        </View>
                                                                        :
                                                                        <View></View>
                                                                    }
                                                                    {ad.strMedications !== null ?
                                                                        <View style={{ flexDirection: "row", marginBottom: 2, marginHorizontal: 10 }}>
                                                                            <Text style={styles.label}>Medications: </Text>
                                                                            <Text style={styles.labelans}>{ad.strMedications}</Text>
                                                                        </View>
                                                                        :
                                                                        <View></View>
                                                                    }
                                                                    {ad.strCovidHO !== null ?
                                                                        <View style={{ flexDirection: "row", marginBottom: 2, marginHorizontal: 10 }}>
                                                                            <Text style={styles.label}>Covid-19 H/O: </Text>
                                                                            <Text style={styles.labelans}>{ad.strCovidHO}</Text>
                                                                        </View>
                                                                        :
                                                                        <View></View>
                                                                    }
                                                                    {ad.strCovidHospitalization !== null ?
                                                                        <View style={{ flexDirection: "row", marginBottom: 2, marginHorizontal: 10 }}>
                                                                            <Text style={styles.label}>Covid Hospitalization: </Text>
                                                                            <Text style={styles.labelans}>{ad.strCovidHospitalization}</Text>
                                                                        </View>
                                                                        :
                                                                        <View></View>
                                                                    }
                                                                    {ad.strCovidVaccine !== null ?
                                                                        <View style={{ flexDirection: "row", marginBottom: 2, marginHorizontal: 10 }}>
                                                                            <Text style={styles.label}>Covid-19 Vaccine: </Text>
                                                                            <Text style={styles.labelans}>{ad.strCovidVaccine}</Text>
                                                                        </View>
                                                                        :
                                                                        <View></View>
                                                                    }
                                                                    {ad.strFamilyHistory !== null ?
                                                                        <View style={{ flexDirection: "row", marginBottom: 2, marginHorizontal: 10 }}>
                                                                            <Text style={styles.label}>Family History: </Text>
                                                                            <Text style={styles.labelans}>{ad.strFamilyHistory}</Text>
                                                                        </View>
                                                                        :
                                                                        <View></View>
                                                                    }

                                                                </View>
                                                            </View>
                                                            :
                                                            <View></View>
                                                        }

                                                        {ad.strMenstrualCycle || ad.strPain || ad.dtmLmpDate || ad.strChildren || ad.strAbortion || ad.strTypeOfDelivery !== null ?
                                                            <View>
                                                                <View style={{ height: 20, backgroundColor: "#D6EEEE", marginTop: 10, padding: 5 }}>
                                                                    <Text style={styles.mainSectionHeading}>FOR FEMALES</Text>
                                                                </View>

                                                                <View style={{ flexDirection: "row", padding: 2, marginTop: 10, marginHorizontal: 10 }}>

                                                                    {ad.strMenstrualCycle !== null ?
                                                                        <View style={{ flexDirection: "column", marginRight: 20 }}>
                                                                            <Text style={styles.label}>Menstrual Cycle</Text>
                                                                            <Text style={styles.labelans}>{ad.strMenstrualCycle}</Text>
                                                                        </View>
                                                                        :
                                                                        <View></View>
                                                                    }
                                                                    {ad.strPain !== null ?
                                                                        <View style={{ flexDirection: "column", marginRight: 20 }}>
                                                                            <Text style={styles.label}>Pain</Text>
                                                                            <Text style={styles.labelans}>{ad.strPain}</Text>
                                                                        </View>
                                                                        :
                                                                        <View></View>
                                                                    }
                                                                    {ad.dtmLmpDate !== null ?
                                                                        <View style={{ flexDirection: "column", marginRight: 20 }}>
                                                                            <Text style={styles.label}>LMP Date</Text>
                                                                            <Text style={styles.labelans}>{ad.dtmLmpDate}</Text>
                                                                        </View>
                                                                        :
                                                                        <View></View>
                                                                    }
                                                                    {ad.strChildren !== null ?
                                                                        <View style={{ flexDirection: "column", marginRight: 20 }}>
                                                                            <Text style={styles.label}>Children/s</Text>
                                                                            <Text style={styles.labelans}>{ad.strChildren}</Text>
                                                                        </View>
                                                                        :
                                                                        <View></View>
                                                                    }
                                                                    {ad.strAbortion !== null ?
                                                                        <View style={{ flexDirection: "column", marginRight: 20 }}>
                                                                            <Text style={styles.label}>Abortion/s</Text>
                                                                            <Text style={styles.labelans}>{ad.strAbortion}</Text>
                                                                        </View>
                                                                        :
                                                                        <View></View>
                                                                    }
                                                                    {ad.strTypeOfDelivery !== null ?
                                                                        <View style={{ flexDirection: "column", marginRight: 20 }}>
                                                                            <Text style={styles.label}>Type of Delivery</Text>
                                                                            <Text style={styles.labelans}>{ad.strTypeOfDelivery}</Text>
                                                                        </View>
                                                                        :
                                                                        <View></View>
                                                                    }

                                                                </View>
                                                            </View>
                                                            :
                                                            <View></View>
                                                        } */}

                                                        {ad.strMedicationTreatment[0].strMedicineName || ad.strMedicationTreatment[0].strMedicineType || ad.strMedicationTreatment[0].strMedicineDuration ||
                                                            ad.strMedicationTreatment[0].strMedicineFrequency || ad.strMedicationTreatment[0].strMedicineAdvise || ad.strMedicationTreatment[0].strMedFreqMorning ||
                                                            ad.strMedicationTreatment[0].strMedFreqAfternoon || ad.strMedicationTreatment[0].strMedFreqEvening || ad.strMedicationTreatment[0].strMedFreqNight ||
                                                            ad.strMedicationTreatment[0].strMedicineQuantity !== null ?
                                                            <View>

                                                                <View style={{ height: 20, backgroundColor: "#D6EEEE", marginTop: 10, padding: 5, marginBottom: 10 }}>
                                                                    <Text style={styles.mainSectionHeading}>MEDICATION & TREATMENT</Text>
                                                                </View>

                                                                <View>
                                                                    <View style={styles.table}>
                                                                        <View style={styles.tableRowHeader}>
                                                                            <View style={{ width: "3%" }}>
                                                                                <Text style={styles.tableCell}>#</Text>
                                                                            </View>
                                                                            <View style={{ width: "21%" }}>
                                                                                <Text style={styles.tableCell}>Name</Text>
                                                                            </View>
                                                                            <View style={{ width: "10%" }}>
                                                                                <Text style={styles.tableCell}>Type</Text>
                                                                            </View>
                                                                            <View style={{ width: "10%" }}>
                                                                                <Text style={styles.tableCell}>Duration</Text>
                                                                            </View>
                                                                            <View style={{ width: "10%" }}>
                                                                                <Text style={styles.tableCell}>Frequency</Text>
                                                                            </View>
                                                                            {/* <View style={{ width: "4%" }}>
                                                                                <Text style={styles.tableCell}>M</Text>
                                                                            </View>
                                                                            <View style={{ width: "4%" }}>
                                                                                <Text style={styles.tableCell}>A</Text>
                                                                            </View>
                                                                            <View style={{ width: "4%" }}>
                                                                                <Text style={styles.tableCell}>E</Text>
                                                                            </View>
                                                                            <View style={{ width: "4%" }}>
                                                                                <Text style={styles.tableCell}>N</Text>
                                                                            </View> */}
                                                                            <View style={{ width: "10%" }}>
                                                                                <Text style={styles.tableCell}>Quantity</Text>
                                                                            </View>
                                                                            <View style={{ width: "20%" }}>
                                                                                <Text style={styles.tableCell}>Advise</Text>
                                                                            </View>
                                                                        </View>

                                                                        {ad.strMedicationTreatment.map((as, index) =>

                                                                            <View style={styles.tableRowItem} key={index + "as"} id={index}>
                                                                                <View style={{ width: "3%", borderColor: "#e0e0e0", borderLeftWidth: 0.5, borderRightWidth: 0.5 }}>
                                                                                    <Text style={styles.tableCell}>{index + 1}</Text>
                                                                                </View>
                                                                                <View style={{ width: "21%", borderColor: "#e0e0e0", borderRightWidth: 0.5 }}>
                                                                                    <Text style={styles.tableCell}>{as.strMedicineName}</Text>
                                                                                </View>
                                                                                <View style={{ width: "10%", borderColor: "#e0e0e0", borderRightWidth: 0.5 }}>
                                                                                    <Text style={styles.tableCell}>{as.strMedicineType}</Text>
                                                                                </View>
                                                                                <View style={{ width: "10%", borderColor: "#e0e0e0", borderRightWidth: 0.5 }}>
                                                                                    <Text style={styles.tableCell}>{as.strMedicineDuration}</Text>
                                                                                </View>
                                                                                <View style={{ width: "10%", borderColor: "#e0e0e0", borderRightWidth: 0.5 }}>
                                                                                    <Text style={styles.tableCell}>{as.strMedicineFrequency}</Text>
                                                                                </View>
                                                                                {/* <View style={{ width: "4%", borderColor: "#e0e0e0", borderRightWidth: 0.5 }}>
                                                                                    <Text style={styles.tableCell}>{as.strMedFreqMorning}</Text>
                                                                                </View>
                                                                                <View style={{ width: "4%", borderColor: "#e0e0e0", borderRightWidth: 0.5 }}>
                                                                                    <Text style={styles.tableCell}>{as.strMedFreqAfternoon}</Text>
                                                                                </View>
                                                                                <View style={{ width: "4%", borderColor: "#e0e0e0", borderRightWidth: 0.5 }}>
                                                                                    <Text style={styles.tableCell}>{as.strMedFreqEvening}</Text>
                                                                                </View>
                                                                                <View style={{ width: "4%", borderColor: "#e0e0e0", borderRightWidth: 0.5 }}>
                                                                                    <Text style={styles.tableCell}>{as.strMedFreqNight}</Text>
                                                                                </View> */}
                                                                                <View style={{ width: "10%", borderColor: "#e0e0e0", borderRightWidth: 0.5 }}>
                                                                                    <Text style={styles.tableCell}>{as.strMedicineQuantity}</Text>
                                                                                </View>
                                                                                <View style={{ width: "20%", borderColor: "#e0e0e0", borderRightWidth: 0.5 }}>
                                                                                    <Text style={styles.tableCell}>{as.strMedicineAdvise}</Text>
                                                                                </View>
                                                                            </View>

                                                                        )}

                                                                    </View>
                                                                </View>

                                                            </View>
                                                            :
                                                            <View>
                                                                <View style={{ height: 20, backgroundColor: "#D6EEEE", marginTop: 10, padding: 5, marginBottom: 10 }}>
                                                                    <Text style={styles.mainSectionHeading}>MEDICATION & TREATMENT</Text>
                                                                </View>

                                                                <View style={{ flexDirection: "column", padding: 2, marginTop: 10 }}>

                                                                    <View style={{ flexDirection: "row", marginHorizontal: 10 }}>
                                                                        <Text style={styles.labelans}>No Medicines Prescribed</Text>
                                                                    </View>

                                                                </View>
                                                            </View>
                                                        }

                                                        {ad.strInvestigation !== '' ?
                                                            <View>
                                                                <View style={{ height: 20, backgroundColor: "#D6EEEE", marginTop: 10, padding: 5 }}>
                                                                    <Text style={styles.mainSectionHeading}>INVESTIGATION</Text>
                                                                </View>

                                                                <View style={{ flexDirection: "column", padding: 2, marginTop: 10 }}>

                                                                    <View style={{ flexDirection: "row", marginHorizontal: 10 }}>
                                                                        <Text style={styles.label}>Investigation: </Text>
                                                                        <Text style={styles.labelans}>{ad.strInvestigation}</Text>
                                                                    </View>

                                                                </View>
                                                            </View>
                                                            :
                                                            <View></View>
                                                        }

                                                        {ad.strDoctorAdvice !== '' ?
                                                            <View>
                                                                <View style={{ height: 20, backgroundColor: "#D6EEEE", marginTop: 10, padding: 5 }}>
                                                                    <Text style={styles.mainSectionHeading}>ADVICE</Text>
                                                                </View>

                                                                <View style={{ flexDirection: "column", padding: 2, marginTop: 10 }}>

                                                                    <View style={{ flexDirection: "row", marginHorizontal: 10 }}>
                                                                        <Text style={styles.label}>Advice: </Text>
                                                                        <Text style={styles.labelans}>{ad.strDoctorAdvice}</Text>
                                                                    </View>

                                                                </View>
                                                            </View>
                                                            :
                                                            <View></View>
                                                        }

                                                    </View>
                                                ))}

                                                <View style={{ justifyContent: "flex-end", flexDirection: 'row' }}>
                                                    <View style={{ height: 1, backgroundColor: "grey", marginBottom: 5, marginTop: 50, width: "20%", }}></View>
                                                </View>

                                                <View style={styles.signature}>
                                                    <Text style={styles.label}>Signature</Text>
                                                </View>

                                            </View>
                                        </Page>
                                    </Document>
                                }
                                fileName={"Invoice" + new Date().getTime() + ".pdf"}
                            >
                                {({ blob, url, loading, error }) => loading ? "Loading . . ." : "Download"}
                            </PDFDownloadLink>
                        </p>
                        :
                        <p></p>
                    }

                </div>

            </div>
        </div>

    );
};

export default HospitalGeneratePrescription;

const styles = StyleSheet.create({
    page: {
        padding: 10,
        fontSize: 8,
    },
    mainHeading: {
        fontSize: 20,
        marginTop: 10,
        marginBottom: 10,
        color: "#004AAD",
    },
    headingLabel: {
        color: "grey",
    },
    headinglogo: {
        width: 50,
        height: 50,
        color: "blue",
    },
    label: {
        marginRight: 5,
        color: "grey",
    },
    labelans: {
        marginRight: 20,
    },

    mainSectionHeading: {
        fontSize: 8,
    },
    table: {
        display: "table",
        width: "100%",
    },
    tableRowHeader: {
        margin: "auto",
        flexDirection: "row",
        backgroundColor: "#e0e0e0",
        height: 20,
    },
    tableRowItem: {
        margin: "auto",
        flexDirection: "row",
        backgroundColor: "#fff",
        height: 20,
        borderColor: "#e0e0e0",
        borderBottomWidth: 0.5,
    },
    tableCell: {
        margin: "auto",
        fontSize: 8,
        color: "grey",
    },
    signature: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginBottom: 100,
    },

});