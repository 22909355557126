import React, { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./admincss.css";
import HospitalSidebar from "../Navbar/HospitalSidebar";
import Adminheader from "../Navbar/Adminheader";
import { CDBCard, CDBCardBody, CDBDataTable } from 'cdbreact';
import { Form, Col, Row, Button, Spinner, Modal } from 'react-bootstrap';
import { Base64 } from 'js-base64';
import { Asterisk } from "react-bootstrap-icons";
// import { API_BASE } from '../../setupProxy';
import * as moment from 'moment';

const HospitalManageUsers = () => {

    const intOrganizationID = localStorage.getItem("orgid");

    const [orgUsers, setOrgUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [intOrgUserID, setIntOrgUserID] = useState("");
    const [strFirstName, setStrFirstName] = useState("");
    const [strLastName, setStrLastName] = useState("");
    const [strMobileNo, setStrMobileNo] = useState("");
    const [strEmail, setStrEmail] = useState("");
    const [dtmFromDate, setDtmFromDate] = useState("");
    //const [dtmToDate, setDtmToDate] = useState("");
    const [strstatus, setStatus] = useState('Active');

    const [updateMessage, setUpdateMessage] = useState("");

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [deleteThis, setDeleteThis] = useState(null);

    const [roles, setRoles] = useState([]);
    const [introleid, setIntRoleId] = useState(null);

    useEffect(() => {
        fetchOrgUsers(intOrganizationID);
        fetchRoles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //FETCH ALL ROLES (FOR DROPDOWN)
    const fetchRoles = () => {
        fetch("https://e-arogya.com:8443/api/general/roles")
            .then(res => res.json())
            .then(rolesData => {
                setRoles(rolesData);
            })
            .catch(error => {
                //console.log(error);
            })

    }

    //FETCH ALL ORGANIZATION USERS
    const fetchOrgUsers = () => {
        fetch("https://e-arogya.com:8443/api/general/orgusers/list/" + intOrganizationID)
            .then(response => response.json())
            .then(data => {
                let dataFromApi = data.map(orguser => {
                    return {
                        id: orguser.intOrgUserID,
                        firstname: orguser.strFirstName,
                        lastname: orguser.strLastName,
                        mobile: orguser.strMobileNo,
                        email: orguser.strEmail,
                        role: orguser.strrolename,
                        fromdate: orguser.dtmFromDate,
                        //todate: orguser.dtmToDate,
                        status: orguser.strstatus
                    }
                })
                setOrgUsers(dataFromApi);
                setLoading(true);
            })
            .catch(error => {
                //console.log(error);
            })
    }

    const clearFields = () => {
        setStrFirstName("");
        setStrLastName("");
        setStrMobileNo("");
        setStrEmail("");
        setIntRoleId("- Select -");
        setDtmFromDate("");
        //setDtmToDate("");
        setStatus('Active');
    }

    //FORM SUBMIT
    const onAddNewOrgUserFormSubmit = (event) => {
        event.preventDefault();

        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;  // eslint-disable-line

        if (strFirstName === "") {
            setUpdateMessage(<span style={{ color: "red" }}>Please Enter First Name</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
        } else if (strLastName === "") {
            setUpdateMessage(<span style={{ color: "red" }}>Please Enter Last Name</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
        } else if (strMobileNo === "" || strMobileNo.length !== 10) {
            setUpdateMessage(<span style={{ color: "red" }}>Please Enter Valid 10 digit Mobile number</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
        } else if (strEmail === "" || reg.test(strEmail) === false) {
            setUpdateMessage(<span style={{ color: "red" }}>Please Enter Valid Email Id</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
        } else if (introleid === '- Select -') {
            setUpdateMessage(<span style={{ color: "red" }}>Please Select Role</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
        }
        else {

            if (intOrgUserID) {
                const existingInputs = { intOrganizationID, introleid, strFirstName, strLastName, strMobileNo, strEmail, dtmFromDate, strstatus }
                //console.log(" " + intOrganizationID + " " + introleid + " " + strFirstName + " " + strLastName + " " + strMobileNo + " " + strEmail + " " + dtmFromDate + " " + strstatus);
                updateUser(existingInputs, intOrgUserID);
                setIntOrgUserID(null);
                clearFields();
            } else {
                generateRandomPasswordString(10);
            }
        }

    }

    const generateRandomPasswordString = (len) => {
        const char = 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890';
        const random = Array.from({ length: len }, () => char[Math.floor(Math.random() * char.length)]);
        const randomString = random.join("");
        //console.log("randomString: " + randomString);
        addUser(randomString);
    }

    const addUser = (passwordstring) => {
        //const strencryptedpassword = Base64.encode(passwordstring);
        const strencryptedpassword = Base64.encode(strMobileNo);
        const toInput = { intOrganizationID, introleid, strFirstName, strLastName, strMobileNo, strEmail, dtmFromDate, strstatus, strencryptedpassword }
        //console.log(" " + intOrganizationID + " " + introleid + " " + strFirstName + " " + strLastName + " " + strMobileNo + " " + strEmail + " " + dtmFromDate + " " + strstatus);
        addNewUser(toInput);
        clearFields();
    }

    //FETCH DOCTORS BY ID (FOR EDIT DOCTORS)
    const handleEditClick = (e, id) => {
        e.preventDefault();
        fetch("https://e-arogya.com:8443/api/general/orgusers/" + id)
            .then(res => res.json())
            .then(data => {
                setIntOrgUserID(data[0].intOrgUserID);
                setStrFirstName(data[0].strFirstName);
                setStrLastName(data[0].strLastName);
                setStrMobileNo(data[0].strMobileNo);
                setStrEmail(data[0].strEmail);
                setIntRoleId(data[0].introleid);
                setDtmFromDate(data[0].dtmFromDate);
                //setDtmToDate(data[0].dtmToDate);
                setStatus(data[0].strstatus);
            })
            .catch(error => {
                //console.log(error);
            });
    }

    //ADD NEW ORG USER
    async function addNewUser(toInput) {
        fetch("https://e-arogya.com:8443/api/general/orgusers/addnewOrgUser", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            redirect: "follow",
            body: JSON.stringify(toInput)
        })
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
                const error = (data && data.message) || response.status;
                if (!response.ok) {

                    if (error === "Duplicate Key") {
                        setUpdateMessage(<span style={{ color: "red" }}>Mobile or Email Id already exist</span>);
                        setTimeout(() => setUpdateMessage(""), 3000);
                    }
                    return Promise.reject(error);
                }
                setUpdateMessage(<span style={{ color: "green" }}>Org Users Added Successfully</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
                fetchOrgUsers(intOrganizationID);
            })
            .catch(error => {
                setUpdateMessage(<span style={{ color: "red" }}>Org Users Not Added. Please try again.</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
            });

    }

    //UPDATE EXISTING ORG USER
    async function updateUser(existingInputs, intOrgUserID) {
        fetch("https://e-arogya.com:8443/api/general/orgusers/update/" + intOrgUserID, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            redirect: "follow",
            body: JSON.stringify(existingInputs)
        })
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();

                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }
                setUpdateMessage(<span style={{ color: "green" }}>Org Users Updated Successfully</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
                fetchOrgUsers(intOrganizationID);
            })
            .catch(error => {
                setUpdateMessage(<span style={{ color: "red" }}>Org Users Not Updated. Please try again.</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
            });

    }

    //DELETE ORG USER

    function handleDeleteClick(e, id) {
        setShow(true);
        setDeleteThis(id);
    }

    const deleteThisFunc = (id) => {
        fetch("https://e-arogya.com:8443/api/general/orgusers/" + id, { method: 'DELETE' })
            .then(async response => {
                const data = await response.text;

                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }

                setUpdateMessage(<span style={{ color: "green" }}>Org Users Deleted Successfully</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
                setDeleteThis(null);
                setIntOrgUserID(null);
                clearFields();
                fetchOrgUsers(intOrganizationID);
            })
            .catch(error => {
                setUpdateMessage(<span style={{ color: "red" }}>Org Users Not Deleted. Please try again.</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
            });
    }

    const data = () => {
        return {
            columns: [
                { label: '#', field: 'srno', },
                {
                    label: 'Name',
                    field: 'name',
                    attributes: {
                        'aria-controls': 'DataTable',
                        'aria-label': 'Name',
                    },
                },
                { label: 'Role', field: 'role', },
                { label: 'Mobile', field: 'mobile', },
                { label: 'Email', field: 'email', },
                { label: 'From Date', field: 'fromdate', },
                { label: 'Status', field: 'status', },
                { label: '', field: 'edit', },
                { label: '', field: 'delete', },

            ],
            rows: [...orgUsers.map((data1, index) => (
                {
                    srno: index + 1,
                    name: data1.firstname + " " + data1.lastname,
                    role: data1.role,
                    mobile: data1.mobile,
                    email: data1.email,
                    fromdate: moment(data1.fromdate).format('DD-MMM-YYYY'),
                    //todate: data1.todate,
                    status: data1.status,
                    edit: <span onClick={(e) => handleEditClick(e, data1.id)}>
                        <Button variant="primary" type="submit" style={{ fontSize: 12 }} size="sm">
                            Edit
                        </Button>
                    </span>,
                    delete: <span onClick={(e) => handleDeleteClick(e, data1.id)}>
                        <Button variant="danger" type="submit" style={{ fontSize: 12 }} size="sm">
                            Delete
                        </Button>
                    </span>

                }
            ))

            ],
        };
    };
    return (

        <div className="main" >

            <HospitalSidebar />

            <div className="page-content">

                <Adminheader />

                <h5>Manage Users</h5>
                <p style={{ fontSize: 12, color: "grey", marginBottom: 50 }}>Manage Registered Users</p>

                <div className="table-content">

                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title style={{ fontSize: 18 }}>Delete User</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ fontSize: 14 }}>Are you sure you want to delete this User?</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose} style={{ fontSize: 12 }}>No</Button>
                            <Button variant="primary" onClick={() => {
                                handleClose();
                                deleteThisFunc(deleteThis);
                            }} style={{ fontSize: 12 }}>Yes</Button>
                        </Modal.Footer>
                    </Modal>

                    <Form onSubmit={onAddNewOrgUserFormSubmit}>

                        <Row style={{ fontSize: 12, color: "grey" }}>

                            <Col sm={2}>
                                <Form.Group className="mb-3" controlId="formEnterState">
                                    <Form.Label><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />First Name</Form.Label>
                                    <Form.Control type="text" style={{ fontSize: 12 }} value={strFirstName || ""} onChange={e => { setStrFirstName(e.target.value) }} required />
                                </Form.Group>
                            </Col>

                            <Col sm={2}>
                                <Form.Group className="mb-3" controlId="formEnterState">
                                    <Form.Label><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Last Name</Form.Label>
                                    <Form.Control type="text" style={{ fontSize: 12 }} value={strLastName || ""} onChange={e => { setStrLastName(e.target.value) }} required />
                                </Form.Group>
                            </Col>

                            <Col sm={2}>
                                <Form.Group className="mb-3" controlId="formEnterState">
                                    <Form.Label><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Mobile</Form.Label>
                                    <Form.Control type="text" style={{ fontSize: 12 }} value={strMobileNo || ""} onChange={e => { setStrMobileNo(e.target.value) }} required />
                                </Form.Group>
                            </Col>

                            <Col sm={2}>
                                <Form.Group className="mb-3" controlId="formEnterState">
                                    <Form.Label><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Email</Form.Label>
                                    <Form.Control type="text" style={{ fontSize: 12 }} value={strEmail || ""} onChange={e => { setStrEmail(e.target.value) }} required />
                                </Form.Group>
                            </Col>

                            <Col sm={2}>
                                <Form.Group className="mb-3" controlId="formSelectCityStatus">
                                    <Form.Label><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Select Role</Form.Label>
                                    <Form.Select aria-label="Default select example" style={{ fontSize: 12 }} value={introleid || ""} onChange={e => { setIntRoleId(e.target.value) }}>
                                        <option value="Select">- Select -</option>
                                        {roles.slice(1).map((data, key, index) => <option key={data.introleid} value={data.introleid} >{data.strrolename}</option>)}
                                    </Form.Select>
                                </Form.Group>
                            </Col>

                        </Row>

                        <Row style={{ fontSize: 12, color: "grey" }}>

                            <Col sm={2}>
                                <Form.Group className="mb-3" controlId="formEnterState">
                                    <Form.Label><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />From Date</Form.Label>
                                    <Form.Control type="date" style={{ fontSize: 12 }} value={dtmFromDate || ""} onChange={e => { setDtmFromDate(e.target.value) }} required />
                                </Form.Group>
                            </Col>

                            {/* <Col sm={2}>
                                <Form.Group className="mb-3" controlId="formEnterState">
                                    <Form.Label>To Date</Form.Label>
                                    <Form.Control type="date" style={{ fontSize: 12 }} value={dtmToDate || ""} onChange={e => { setDtmToDate(e.target.value) }} required />
                                </Form.Group>
                            </Col> */}

                            <Col sm={2}>
                                <Form.Group className="mb-3" controlId="formSelectStateStatus">
                                    <Form.Label>Status</Form.Label>
                                    <Form.Select aria-label="Default select example" style={{ fontSize: 12 }} value={strstatus || ""} onChange={e => { setStatus(e.target.value) }} required>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>


                            <Col sm={2} >
                                <Form.Group className="mb-3" controlId="formSelectStateStatus">
                                    {intOrgUserID ?
                                        <Button variant="primary" type="submit" style={{ marginTop: 25, fontSize: 12 }}> Update </Button>
                                        :
                                        <Button variant="primary" type="submit" style={{ marginTop: 25, fontSize: 12 }}> Add </Button>
                                    }
                                </Form.Group>
                            </Col>

                        </Row>

                    </Form>

                    <div style={{ fontSize: 12, fontWeight: "bold", marginBottom: 10 }}>{updateMessage}</div>

                    <CDBCard style={{ padding: 20, fontSize: 12, color: "grey" }}>
                        <CDBCardBody>
                            {!loading ? (
                                <div style={{ textAlign: "center" }}><Spinner animation="border" size="sm" variant="primary" /></div>
                            ) :
                                <CDBDataTable
                                    responsive
                                    noRecordsFoundLabel="No Records Found"
                                    noBottomColumn={true}
                                    hover
                                    entriesOptions={[10, 25, 50, 100, 200, 500]}
                                    entries={10}
                                    pagesAmount={4}
                                    data={data()}

                                />
                            }
                        </CDBCardBody>
                    </CDBCard>

                </div>

            </div>
        </div>

    );
};

export default HospitalManageUsers;