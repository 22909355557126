import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../Navbar/Navbar";
import Footer from "../Navbar/Footer";
import { Asterisk, EyeFill, EyeSlashFill } from "react-bootstrap-icons";
import { Button, Row, Col, Container, Form, InputGroup } from "react-bootstrap";
import { Base64 } from 'js-base64';
// import { API_BASE } from '../../setupProxy';
import "./mycss.css";

function Login() {

  const [strmobileno, setStrMobileNo] = useState("");
  const [strpassword, setStrPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const schedule = require('node-schedule');
  //const date = new Date(2023, 5, 23, 17, 26, 0);
  //(year, month, date, hour, minute, second)                                  
  const rule = new schedule.RecurrenceRule();
  rule.hour = 0;
  rule.minute = 0;

  schedule.scheduleJob(rule, function () {
    closeCaseStatus();
  });

  const closeCaseStatus = () => {
    fetch("https://e-arogya.com:8443/api/general/cases/updatecasestatus", {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      redirect: "follow"
    })
      .then(async response => {
        const isJson = response.headers.get('content-type')?.includes('application/json');
        const data = isJson && await response.json();

        if (!response.ok) {
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        console.error('Case Status Updated');
      })
      .catch(error => {
        console.error('There was an error in updating Case Status!', error);
      });
  }

  const handleSubmit = () => {

    if (strmobileno === "" || strmobileno.length !== 10 || isNaN(strmobileno)) {
      setErrorMessage(<span style={{ color: "red" }}>Please enter a valid 10 digit mobile number</span>);
      setTimeout(() => setErrorMessage(""), 3000);
    } else if (strpassword === "") {
      setErrorMessage(<span style={{ color: "red" }}>Please enter a password</span>);
      setTimeout(() => setErrorMessage(""), 3000);
    } else if (strpassword.length < 6) {
      setErrorMessage(<span style={{ color: "red" }}>Password is too short</span>);
      setTimeout(() => setErrorMessage(""), 3000);
    }  
    else {
      const strencryptedpassword = Base64.encode(strpassword);
      loginFunction(strmobileno, strencryptedpassword);
    }

    async function loginFunction(strmobileno, strencryptedpassword) {

      //console.log("strmobileno: " + strmobileno + " strepass: " + strencryptedpassword);

      const toInput = { strmobileno, strencryptedpassword };
      fetch("https://e-arogya.com:8443/api/general/login/check", {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        redirect: "follow",
        body: JSON.stringify(toInput),
      })
        .then(async response => {
          const isJson = response.headers.get('content-type')?.includes('application/json');
          const data = isJson && await response.json();
          if (!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
          }
          if (data) {

            localStorage.setItem("loggedInId", data[0].intloginid);
            localStorage.setItem("orgid", data[0].intorganizationid);
            localStorage.setItem("loggedInUserName", data[0].strmobileno);
            localStorage.setItem("loggedRoleName", data[0].strrolename);
            localStorage.setItem("orgname", data[0].strorganizationname);
            localStorage.setItem("userfname", data[0].strfirstname);
            localStorage.setItem("userlname", data[0].strlastname);
            //console.log(data);

            if ((data[0].strrolename) === "Super") {
              navigate('/superadmindashboard');
            } else if ((data[0].strrolename) === "Hospital Admin") {
              navigate('/hospitaldashboard');
            } else if ((data[0].strrolename) === "Laboratory Admin") {
              navigate('/laboratorydashboard');
            } else if ((data[0].strrolename) === "Pharmacy Admin") {
              navigate('/pharmacydashboard');
            } else if ((data[0].strrolename) === "Account") {
              navigate('/hospitaldashboard');
            } else if ((data[0].strrolename) === "Frontend") {
              navigate('/hospitaldashboard');
            } else if ((data[0].strrolename) === "Medical") {
              navigate('/hospitaldashboard');
            } else if ((data[0].strrolename) === "Operator") {
              navigate('/operatordashboard');
            } else {
              setErrorMessage(<span style={{ color: "red" }}>Invalid User</span>);
              setTimeout(() => setErrorMessage(""), 3000);
            }

          } else {
            setErrorMessage(<span style={{ color: "red" }}>Incorrect mobile or password. Please try again.</span>);
            setTimeout(() => setErrorMessage(""), 3000);
          }

        })
        .catch(error => {
          setErrorMessage(<span style={{ color: "red" }}>Error Occurred. Please try again.</span>);
          setTimeout(() => setErrorMessage(""), 3000);
        });
    }
  }

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (

    <div>
      <Navbar />

      <Container fluid>

        <div style={{ marginTop: 100, marginBottom: 50 }} className='container'>

          <Row>
            <Col className="text-center justify-content-center">
              <div style={{ textAlign: "center" }}>
                <div className="about-border"></div>
                <p style={{ fontSize: 35, fontWeight: 700 }}>LOGIN</p>
              </div>
              <div>
                <p style={{ color: "grey" }}>Gateway for Hospitals</p>
              </div>
            </Col>
          </Row>

          <Row className="py-5 justify-content-center">
            <Col md={4}>
              <Form>

                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label><Asterisk size={6} style={{ marginBottom: 8, marginRight: 5, color: "red" }} />Mobile Number</Form.Label>
                  <Form.Control
                    type="tel"
                    maxLength="10"
                    value={strmobileno || ""}
                    onChange={e => { setStrMobileNo(e.target.value.replace(/\D/g, "")) }}
                    required />
                </Form.Group>

                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label><Asterisk size={6} style={{ marginBottom: 8, marginRight: 5, color: "red" }} />Password</Form.Label>
                  <InputGroup className="mb-2">
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      value={strpassword || ""}
                      onChange={e => { setStrPassword(e.target.value) }}
                      required />
                    <InputGroup.Text onClick={togglePassword}>
                      {showPassword ? <EyeFill size={15} /> : <EyeSlashFill size={15} />}
                    </InputGroup.Text>
                  </InputGroup>
                </Form.Group>

                <Link to="/forgotpassword" style={{textDecoration: 'none', color: "black"}} className="forgotpasswordtext">
                <p style={{textAlign: "right",fontSize: 14 }}   >Forgot Password?</p>
                </Link>

                <div style={{ fontSize: 12, fontWeight: "bold", marginBottom: 10 }}>{errorMessage}</div>
                <Button style={{ marginTop: 20, marginBottom: 20, backgroundColor: "#fd5252", borderColor: "#fd5252", width: "100%" }} onClick={handleSubmit}>Login</Button>

              </Form>

              <Row className="align-items-center justify-content-center">
                <Col xs="auto">
                  <Form.Label style={{ marginTop: 6}}> Register Your Hospital/Clinic? </Form.Label>
                </Col>
                <Col xs="auto">
                  <Link to="/selectorganizationtype">
                    <Button variant="outline-danger" size="sm">Register</Button>
                  </Link>
                </Col>
              </Row>

            </Col>

          </Row>

        </div>

      </Container>

      <Footer />
    </div>

  );
}

export default Login;