import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Image} from "react-bootstrap";
import MyImage from "../images/cropped-transparant-logo.png";
import { CaretDownFill, CaretUpFill } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { CDBSidebar, CDBSidebarHeader, CDBSidebarMenuItem, CDBSidebarContent, CDBSidebarMenu, CDBListGroup, } from 'cdbreact';

var sidebarStat = false;

function Sidebar() {

    const loginid = localStorage.getItem("loggedInId");
    const navigate = useNavigate();

    const [locationMenu, setLocationMenu] = useState(false);
    const [manageMenu, setManageMenu] = useState(false);
    const [hospMenu, setHospMenu] = useState(false);
    const [labMenu, setLabMenu] = useState(false);

    const locationMenuClicked = () => { setLocationMenu(!locationMenu); dashclick("Dashboard") }
    const manageMenuClicked = () => { setManageMenu(!manageMenu); dashclick("Dashboard") }
    const hospitalMenuClicked = () => { setHospMenu(!hospMenu); dashclick("Dashboard") }
    const laboratoryMenuClicked = () => { setLabMenu(!labMenu); dashclick("Dashboard") }

    useEffect(() => {
        checkLoginId();
    })

    const checkLoginId = () => {
        if (loginid === null || loginid === "") {
            navigate('/login');
        }
    }

    const dashclick = (menuItemName) => {
        if(menuItemName === "Dashboard"){
            sidebarStat = false;
        } else if(menuItemName === "Other"){
            sidebarStat = true;
        }
    };

    return (
        <div>

            <div style={{ display: 'flex', height: '100vh', overflow: 'scroll initial' }}>
                <CDBSidebar  textColor="#808080" backgroundColor="#fff" toggled={sidebarStat} >
                    <CDBSidebarHeader prefix={<i className="fa fa-bars" />}>
                        <div style={{ padding: 10 }}>
                            <Link to="/superadmindashboard"><Image src={MyImage} className="d-inline-block align-top" alt="check" height={60} /></Link>
                        </div>
                    </CDBSidebarHeader>
                    <CDBSidebarContent>
                        <CDBSidebarMenu>
                            <span style={{ fontWeight: "bold" }} onClick={() => {dashclick("Dashboard")}}><Link to="/superadmindashboard"><CDBSidebarMenuItem icon="th-large" iconSize="sm" textFontSize="12px" >Dashboard</CDBSidebarMenuItem></Link></span>

                            <span onClick={locationMenuClicked} style={{ fontWeight: "bold" }}>
                                <CDBSidebarMenuItem icon="map-marker-alt" iconSize="sm" textFontSize="12px">
                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                        Location
                                        {locationMenu ? <CaretUpFill size={13} /> : <CaretDownFill size={13} />}
                                    </div>
                                </CDBSidebarMenuItem>
                            </span>
                            {
                                locationMenu ?
                                    <CDBListGroup style={{ paddingLeft: 20 }}>
                                        <div style={{ height: 40 }} onClick={() => {dashclick("Other")}}><Link to="/managestates"><CDBSidebarMenuItem icon="circle-small" iconSize="sm" textFontSize="12px" >State</CDBSidebarMenuItem></Link></div>
                                        <div style={{ height: 40 }} onClick={() => {dashclick("Other")}}><Link to="/managecities"><CDBSidebarMenuItem icon="circle-small" iconSize="sm" textFontSize="12px" >City</CDBSidebarMenuItem></Link></div>
                                    </CDBListGroup>
                                    :
                                    <div></div>
                            }

                            <span onClick={manageMenuClicked} style={{ fontWeight: "bold" }}>
                                <CDBSidebarMenuItem icon="sticky-note" iconSize="sm" textFontSize="12px" >
                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                        Manage
                                        {manageMenu ? <CaretUpFill size={13} /> : <CaretDownFill size={13} />}
                                    </div>
                                </CDBSidebarMenuItem>
                            </span>
                            {
                                manageMenu ?
                                    <CDBListGroup style={{ paddingLeft: 20 }}>
                                        <div style={{ height: 40 }} onClick={() => {dashclick("Other")}}><Link to="/managehospitals"><CDBSidebarMenuItem icon="circle-small" iconSize="sm" textFontSize="12px" >Hospitals</CDBSidebarMenuItem></Link></div>
                                        <div style={{ height: 40 }} onClick={() => {dashclick("Other")}}><Link to="/managelaboratories"><CDBSidebarMenuItem icon="circle-small" iconSize="sm" textFontSize="12px" >Laboratories</CDBSidebarMenuItem></Link></div>
                                        <div style={{ height: 40 }} onClick={() => {dashclick("Other")}}><Link to="/managepharmacies"><CDBSidebarMenuItem icon="circle-small" iconSize="sm" textFontSize="12px" >Pharmacies</CDBSidebarMenuItem></Link></div>
                                        <div style={{ height: 40 }} onClick={() => {dashclick("Other")}}><Link to="/manageusers" ><CDBSidebarMenuItem icon="circle-small" iconSize="sm" textFontSize="12px" >Users</CDBSidebarMenuItem></Link></div>
                                        <div style={{ height: 40 }} onClick={() => {dashclick("Other")}}><Link to="/managerolemaster" ><CDBSidebarMenuItem icon="circle-small" iconSize="sm" textFontSize="12px" >Role Master</CDBSidebarMenuItem></Link></div>
                                        <div style={{ height: 40 }} onClick={() => {dashclick("Other")}}><Link to="/managepermissionmaster" ><CDBSidebarMenuItem icon="circle-small" iconSize="sm" textFontSize="12px" >Permission Master</CDBSidebarMenuItem></Link></div>
                                        <div style={{ height: 40 }} onClick={() => {dashclick("Other")}}><Link to="/managerelationmaster" ><CDBSidebarMenuItem icon="circle-small" iconSize="sm" textFontSize="12px" >Relation Master</CDBSidebarMenuItem></Link></div>
                                    </CDBListGroup>
                                    :
                                    <div></div>
                            }

                            <span onClick={hospitalMenuClicked} style={{ fontWeight: "bold" }}>
                                <CDBSidebarMenuItem icon="hospital" iconSize="sm" textFontSize="12px" >
                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                        Hospital Master Data
                                        {hospMenu ? <CaretUpFill size={13} /> : <CaretDownFill size={13} />}
                                    </div>
                                </CDBSidebarMenuItem>
                            </span>
                            {
                                hospMenu ?
                                    <CDBListGroup style={{ paddingLeft: 20 }}>
                                        <div style={{ height: 40 }} onClick={() => {dashclick("Other")}}><Link to="/managespecialities"><CDBSidebarMenuItem icon="circle-small" iconSize="sm" textFontSize="12px" >Specialities Master</CDBSidebarMenuItem></Link></div>
                                        <div style={{ height: 40 }} onClick={() => {dashclick("Other")}}><Link to="/managehospitalservices"><CDBSidebarMenuItem icon="circle-small" iconSize="sm" textFontSize="12px" >Hospital Services</CDBSidebarMenuItem></Link></div>
                                    </CDBListGroup>
                                    :
                                    <div></div>
                            }

                            <span onClick={laboratoryMenuClicked} style={{ fontWeight: "bold" }}>
                                <CDBSidebarMenuItem icon="flask" iconSize="sm" textFontSize="12px" >
                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                        Laboratory Master Data
                                        {labMenu ? <CaretUpFill size={13} /> : <CaretDownFill size={13} />}
                                    </div>
                                </CDBSidebarMenuItem>
                            </span>
                            {
                                labMenu ?
                                    <CDBListGroup style={{ paddingLeft: 20 }}>
                                        <div style={{ height: 40 }} onClick={() => {dashclick("Other")}}><Link to="/managereports"><CDBSidebarMenuItem icon="circle-small" iconSize="sm" textFontSize="12px" >Reports Master</CDBSidebarMenuItem></Link></div>
                                        <div style={{ height: 40 }} onClick={() => {dashclick("Other")}}><Link to="/managesubreports"><CDBSidebarMenuItem icon="circle-small" iconSize="sm" textFontSize="12px" >Sub Reports Master</CDBSidebarMenuItem></Link></div>
                                    </CDBListGroup>
                                    :
                                    <div></div>
                            }
                        </CDBSidebarMenu>
                    </CDBSidebarContent>
                </CDBSidebar>
            </div>

        </div >

    );
}

export default Sidebar;