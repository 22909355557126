import React from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import MyImage from "../images/cropped-transparant-logo.png";
import { Image } from "react-bootstrap";

function Navbar1() {

  return (
    <Navbar bg="light" expand="lg" style={{ marginLeft: 10 }} >
      <Container fluid>
        <Image src={MyImage} className="d-inline-block align-top" alt="check" height={60} />
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto" style={{ marginLeft: '50px' }}>
            <Nav.Link href="home" style={{ marginRight: 20 }}>HOME</Nav.Link>
            <Nav.Link href="about" style={{ marginRight: 20 }}>ABOUT</Nav.Link>
            <Nav.Link href="services" style={{ marginRight: 20 }}>SERVICES</Nav.Link>
            <Nav.Link href="contactus" style={{ marginRight: 20 }}>CONTACT US</Nav.Link>
            <Nav.Link href="login" style={{ marginRight: 20 }}>LOGIN</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navbar1;