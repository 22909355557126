import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Row, Col, Container, Form, Spinner } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../Navbar/Navbar";
import Footer from "../Navbar/Footer";

function Register() {

  const [loading, setLoading] = useState(false)

  const [form, setForm] = useState({})
  const [errors, setErrors] = useState({})

  const setField = (field, value) => {
    setForm({ ...form, [field]: value })

    if (!!errors[field])
      setErrors({ ...errors, [field]: null })
  }

  const findFormErrors = () => {
    const { name, city, mobile, email, password, confirmPassword, deviceId } = form

    const newErrors = {}

    if (!name || name === '') newErrors.name = 'Please Enter Name'

    if (!city || city === '') newErrors.city = 'Please Enter City'

    if (!mobile || mobile === '') newErrors.mobile = 'Please Enter Mobile'
    else if (mobile.length !== 10) newErrors.mobile = 'Mobile must be of 10 digit'

    if (!email || email === '') newErrors.email = 'Please Enter Email'

    if (!password || password === '') newErrors.password = "Please Enter Password"
    else if (password.length < 8) newErrors.password = 'Password must be of atleast 8 characters'

    if (!confirmPassword || confirmPassword === '') newErrors.confirmPassword = "Please Enter to Confirm your Password"

    if (confirmPassword !== password) newErrors.confirmPassword = 'Password did not matched'

    if (!deviceId || deviceId === '') newErrors.deviceId = 'Please Enter deviceId'

    return newErrors
  }

  const handleSubmit = async (e) => {

    e.preventDefault();

    const newErrors = findFormErrors();

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors)
    }
    else {

      //alert("Name: " + form.name + " City: " + form.city + " Mobile: " + form.mobile + " Email: " + form.email + " Password: " + form.password + " ConfirmPassword: " + form.confirmPassword + " DeviceId: " +form.deviceId);
      try {

        setLoading(true);

        let res = await fetch('https://orchidclinics.in/api/createUser', {
          method: "POST",
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            name: form.name,
            mobile: form.mobile,
            email: form.email,
            password: form.password,
            city: form.city,
            deviceid: form.deviceId
          }),
        });


        //let resJson = await res.json();

        if (res.status === 200) {
          alert("Hospital Registration Successful");
          setLoading(false);
        } else {
          alert("Hospital Registration Unsuccessful. Please try again.");
          setLoading(false);
        }
        //console.log(resJson);

      } catch (err) {
        setLoading(false);
        //console.log(err);
        alert("Something went wrong. Please try again.");
      }

    }
  };

  return (

    <div>

      <Navbar />

      <Container fluid>

        <div style={{ marginTop: 50, marginBottom: 50 }} className='container'>

          <Row>
            <Col className="text-center justify-content-center">
              <div style={{ textAlign: "center" }}>
                <div className="about-border"></div>
                <p style={{ fontSize: 35, fontWeight: 700 }}>Register</p>
              </div>
              <div>
                <p style={{ color: "grey" }}>Gateway for Hospitals</p>
              </div>
            </Col>
          </Row>

          <Row className="py-5 justify-content-center">

            <Col md={4}>

              <Form onSubmit={handleSubmit} >

                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Hospital Name</Form.Label>
                  <Form.Control type="text" onChange={e => setField('name', e.target.value)} isInvalid={!!errors.name} />
                  <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Hospital City</Form.Label>
                  <Form.Control type="text" onChange={e => setField('city', e.target.value)} isInvalid={!!errors.city} />
                  <Form.Control.Feedback type="invalid">{errors.city}</Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Hospital Mobile</Form.Label>
                  <Form.Control type="text" onChange={e => setField('mobile', e.target.value)} isInvalid={!!errors.mobile} />
                  <Form.Control.Feedback type="invalid"> {errors.mobile}</Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="email" onChange={e => setField('email', e.target.value)} isInvalid={!!errors.email} />
                  <Form.Control.Feedback type="invalid"> {errors.email}</Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                  <Form.Label>Password</Form.Label>
                  <Form.Control type="password" onChange={e => setField('password', e.target.value)} isInvalid={!!errors.password} />
                  <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control type="password" onChange={e => setField('confirmPassword', e.target.value)} isInvalid={!!errors.confirmPassword} />
                  <Form.Control.Feedback type="invalid"> {errors.confirmPassword}</Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                  <Form.Label>DeviceId</Form.Label>
                  <Form.Control type="text" onChange={e => setField('deviceId', e.target.value)} isInvalid={!!errors.deviceId} />
                  <Form.Control.Feedback type="invalid"> {errors.deviceId}</Form.Control.Feedback>
                </Form.Group>

                <Button style={{ marginTop: 20, marginBottom: 20, backgroundColor: "#fd5252", borderColor: "#fd5252", width: "100%" }} type="submit">Sign up</Button>

              </Form>

              {loading ?
                (<div style={{ textAlign: "center" }}><Spinner animation="border" variant="primary" size="sm" /></div>)
                :
                (<div style={{ textAlign: "center" }}></div>)
              }

              <Row className="align-items-center justify-content-center">

                <Col xs="auto">
                  <Form.Label style={{ marginTop: 6 }}> Already have an account? </Form.Label>
                </Col>

                <Col xs="auto">
                  <Link to="/login">
                    <Button variant="outline-danger" size="sm">Login</Button>
                  </Link>
                </Col>

              </Row>

            </Col>

          </Row>

        </div>

      </Container>

      <Footer />

    </div>

  );
}

export default Register;