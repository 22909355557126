import React, { useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./navcss.css";
import { Image } from "react-bootstrap";
import MyImage from "../images/cropped-transparant-logo.png";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { CDBSidebar, CDBSidebarHeader, CDBSidebarMenuItem, CDBSidebarContent, CDBSidebarMenu } from 'cdbreact';

var sidebarStat = false;

function OperatorSidebar() {

    const loginid = localStorage.getItem("loggedInId");
    const navigate = useNavigate();

    useEffect(() => {
        checkLoginId();
    })

    const checkLoginId = () => {
        if (loginid === null || loginid === "") {
            navigate('/login');
        }
    }

    const dashclick = (menuItemName) => {
        if (menuItemName === "Dashboard") {
            sidebarStat = false;
        } else if (menuItemName === "Other") {
            sidebarStat = true;
        }
    };

    return (

        <div>

            <div style={{ display: 'flex', height: '100vh', overflow: 'scroll initial' }}>
                <CDBSidebar textColor="#808080" backgroundColor="#fff" toggled={sidebarStat}>
                    <CDBSidebarHeader prefix={<i className="fa fa-bars" />} >
                        <div style={{ padding: 10 }}>
                            <Link to="/operatordashboard"><Image src={MyImage} className="d-inline-block align-top" alt="check" height={60} /></Link>
                        </div>
                    </CDBSidebarHeader>
                    <CDBSidebarContent>
                        <CDBSidebarMenu>
                            <span style={{ fontWeight: "bold" }} onClick={() => { dashclick("Dashboard") }}>
                                <Link to="/operatordashboard">
                                    <CDBSidebarMenuItem icon="th-large" iconSize="sm" textFontSize="12px">Dashboard</CDBSidebarMenuItem>
                                </Link>
                            </span>
                            <span style={{ fontWeight: "bold" }} onClick={() => { dashclick("Other") }}>
                                <Link to="/operatororganization" state={{ type: "Hospital" }}>
                                    <CDBSidebarMenuItem icon="hospital" iconSize="sm" textFontSize="12px">Enter Hospital Data</CDBSidebarMenuItem>
                                </Link>
                            </span>
                            <span style={{ fontWeight: "bold" }} onClick={() => { dashclick("Other") }}>
                                <Link to="/operatororganization" state={{ type: "Laboratory" }}>
                                    <CDBSidebarMenuItem icon="building" iconSize="sm" textFontSize="12px">Enter Laboratory Data</CDBSidebarMenuItem>
                                </Link>
                            </span>
                            <span style={{ fontWeight: "bold" }} onClick={() => { dashclick("Other") }}>
                                <Link to="/operatororganization" state={{ type: "Pharmacy" }}>
                                    <CDBSidebarMenuItem icon="store" iconSize="sm" textFontSize="12px">Enter Pharmacy Data</CDBSidebarMenuItem>
                                </Link>
                            </span>
                            <span style={{ fontWeight: "bold" }} onClick={() => { dashclick("Other") }}>
                                <Link to="/operatororganizationupload" state={{ type: "Hospital" }}>
                                    <CDBSidebarMenuItem icon="upload" iconSize="sm" textFontSize="12px">Upload Hospital Data</CDBSidebarMenuItem>
                                </Link>
                            </span>
                            <span style={{ fontWeight: "bold" }} onClick={() => { dashclick("Other") }}>
                                <Link to="/operatororganizationupload" state={{ type: "Laboratory" }}>
                                    <CDBSidebarMenuItem icon="upload" iconSize="sm" textFontSize="12px">Upload Laboratory Data</CDBSidebarMenuItem>
                                </Link>
                            </span>
                            <span style={{ fontWeight: "bold" }} onClick={() => { dashclick("Other") }}>
                                <Link to="/operatororganizationupload" state={{ type: "Pharmacy" }}>
                                    <CDBSidebarMenuItem icon="upload" iconSize="sm" textFontSize="12px">Upload Pharmacy Data</CDBSidebarMenuItem>
                                </Link>
                            </span>
                        </CDBSidebarMenu>
                    </CDBSidebarContent>
                </CDBSidebar>
            </div>

        </div>
    );
}

export default OperatorSidebar;





