import React, { useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./admincss.css";
import Adminheader from "../Navbar/Adminheader";
import DrPrescriptionImage from "../images/doctor_prescription_sample";
import { Col, Row, Form, Button, Badge, Modal, Image } from "react-bootstrap";
import { CDBCard, CDBCardBody, CDBDataTable } from 'cdbreact';
import LaboratorySidebar from "../Navbar/LaboratorySidebar";
import { Asterisk } from "react-bootstrap-icons";

function LaboratoryViewAppointments() {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const data = () => {
        return {
            columns: [
                {
                    label: 'Id', field: 'id',
                },
                {
                    label: 'Name',
                    field: 'name',
                    attributes: {
                        'aria-controls': 'DataTable',
                        'aria-label': 'Name',
                    },
                },
                { label: 'Time', field: 'time', },
                { label: 'Last Visit', field: 'lastVisit', },
                { label: 'Visit Type', field: 'visitType', },
                { label: 'View', field: 'view', },
                { label: 'Status', field: 'status', },
                { label: '', field: 'edit', },
                { label: '', field: 'delete', },

            ],
            rows: [
                {
                    id: 'PAT001',
                    name: 'Colt Adam',
                    time: '10:00',
                    lastVisit: '14/10/2016',
                    visitType: 'Walk-In',
                    view: <Button variant="primary" type="submit" onClick={handleShow} style={{ fontSize: 12, backgroundColor: "steelblue", borderColor: "steelblue" }} size="sm"> View Prescription </Button>,
                    status: <Badge bg="warning"> Pending </Badge>,
                    edit: 'Edit',
                    delete: 'Delete',
                },
                {
                    id: 'PAT001',
                    name: 'Ivan Guzman',
                    time: '11:00',
                    lastVisit: '09/06/2019',
                    visitType: 'Telephonic',
                    view: <Button variant="primary" type="submit" onClick={handleShow} style={{ fontSize: 12, backgroundColor: "steelblue", borderColor: "steelblue" }} size="sm"> View Prescription </Button>,
                    status: <Badge bg="success"> Completed </Badge>,
                    edit: 'Edit',
                    delete: 'Delete',
                },
                {
                    id: 'PAT003',
                    name: 'Elijah Christian',
                    time: '11:30',
                    lastVisit: '15/07/2017',
                    visitType: 'Walk-In',
                    view: <Button variant="primary" type="submit" onClick={handleShow} style={{ fontSize: 12, backgroundColor: "steelblue", borderColor: "steelblue" }} size="sm"> View Prescription </Button>,
                    status: <Badge bg="success" > Completed </Badge>,
                    edit: 'Edit',
                    delete: 'Delete',
                },
                {
                    id: 'PAT004',
                    name: 'Vladimir Barrera',
                    time: '12:45',
                    lastVisit: '28/01/2022',
                    visitType: 'Telephonic',
                    view: <Button variant="primary" type="submit" onClick={handleShow} style={{ fontSize: 12, backgroundColor: "steelblue", borderColor: "steelblue" }} size="sm"> View Prescription </Button>,
                    status: <Badge bg="warning"> Pending </Badge>,
                    edit: 'Edit',
                    delete: 'Delete',
                },
                {
                    id: 'PAT005',
                    name: 'Hector Campos',
                    time: '14:15',
                    lastVisit: '01/12/2020',
                    visitType: 'Walk-In',
                    view: <Button variant="primary" type="submit" onClick={handleShow} style={{ fontSize: 12, backgroundColor: "steelblue", borderColor: "steelblue" }} size="sm"> View Prescription </Button>,
                    status: <Badge bg="danger"> Cancelled </Badge>,
                    edit: 'Edit',
                    delete: 'Delete',
                },

            ],
        };
    };
    return (


        <div className="main" >

            <LaboratorySidebar />

            <div className="page-content">

                <Adminheader />

                <h5>View Appointments</h5>

                <div className="table-content">

                    <Modal show={show} onHide={handleClose} size="xl">
                        <Modal.Header closeButton>
                            <Modal.Title style={{ fontSize: 16 }}>Manage Prescription</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Image src={DrPrescriptionImage} alt="prescriptionimage" />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>Close</Button>
                        </Modal.Footer>
                    </Modal>

                    <Row style={{ fontSize: 12, color: "grey" }}>

                        <Col sm={2}>
                            <Form.Group className="mb-3" controlId="formEnterState">
                                <Form.Label><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Patient Name</Form.Label>
                                <Form.Control type="text" style={{ fontSize: 12 }} />
                            </Form.Group>
                        </Col>

                        <Col sm={2}>
                            <Form.Group className="mb-3" controlId="formEnterState">
                                <Form.Label><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Mobile Number</Form.Label>
                                <Form.Control type="text" style={{ fontSize: 12 }} />
                            </Form.Group>
                        </Col>

                        <Col sm={2}>
                            <Form.Group className="mb-3" controlId="formSelectStateStatus">
                                <Form.Label><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Visit Type</Form.Label>
                                <Form.Select aria-label="Default select example" style={{ fontSize: 12 }}>
                                    <option value="1">- Select -</option>
                                    <option value="2">Walk-In</option>
                                    <option value="2">Home</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>

                        <Col sm={2}>
                            <Form.Group className="mb-3" controlId="formEnterState">
                                <Form.Label><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Appointment Date</Form.Label>
                                <Form.Control type="date" style={{ fontSize: 12 }} />
                            </Form.Group>
                        </Col>

                        <Col sm={2}>
                            <Form.Group className="mb-3" controlId="formEnterState">
                                <Form.Label><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Appointment Time</Form.Label>
                                <Form.Control type="time" style={{ fontSize: 12 }} />
                            </Form.Group>
                        </Col>

                    </Row>

                    <Row style={{ fontSize: 12, color: "grey" }}>

                        <Col sm={4}>
                            <Form.Group className="mb-3" controlId="formEnterState">
                                <Form.Label><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Upload Prescription</Form.Label>
                                <Form.Control type="file" style={{ fontSize: 12 }} />
                            </Form.Group>
                        </Col>

                        <Col sm={2}>
                            <Form.Group className="mb-3" controlId="formEnterState">
                                <Form.Label>Remarks</Form.Label>
                                <Form.Control type="text" style={{ fontSize: 12 }} />
                            </Form.Group>
                        </Col>

                        <Col sm={2}>
                            <Form.Group className="mb-3" controlId="formSelectStateStatus">
                                <Form.Label>Status</Form.Label>
                                <Form.Select aria-label="Default select example" style={{ fontSize: 12 }}>
                                    <option value="1">Pending</option>
                                    <option value="2">Completed</option>
                                    <option value="1">Cancelled</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>


                        <Col sm={2} >
                            <Form.Group className="mb-3" controlId="formSelectStateStatus">
                                <Button variant="primary" type="submit" style={{ marginTop: 25, fontSize: 12 }}> Add </Button>
                            </Form.Group>
                        </Col>


                    </Row>

                    <CDBCard style={{ padding: 20, fontSize: 12, color: "grey" }}>
                        <CDBCardBody>
                            <CDBDataTable
                                responsive
                                noRecordsFoundLabel="No Records Found"
                                noBottomColumn={true}
                                hover
                                entriesOptions={[10, 25, 50, 100, 200, 500]}
                                entries={5}
                                pagesAmount={4}
                                data={data()}

                            />
                        </CDBCardBody>
                    </CDBCard>


                </div>
            </div>
        </div >
    );
}

export default LaboratoryViewAppointments;