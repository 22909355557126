import React, { useState, useRef, useEffect } from 'react'
import Adminheader from "../Navbar/Adminheader";
import OperatorSidebar from "../Navbar/OperatorSidebar";
import { useLocation } from "react-router-dom";
import { Row, Col, Form, Button, Spinner } from "react-bootstrap";
import * as XLSX from 'xlsx';
import { CDBCard, CDBCardBody, CDBDataTable } from 'cdbreact';
import * as moment from 'moment';
// import { API_BASE } from '../../setupProxy';

const OperatorUploadOrganizationData = () => {

    let { state } = useLocation();

    const intloginid = localStorage.getItem("loggedInId");

    const [strorganizationtype, setStrorganizationType] = useState("");

    const [excelFile, setExcelFile] = useState(null);
    const aRef = useRef(null);
    const [updateMessage, setUpdateMessage] = useState("");
    const [excelData, setExcelData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setStrorganizationType(state.type);
    }, [state.type, intloginid])

    const handleFileUpload = (e) => {
        let selectedFile = e.target.files[0];
        if (selectedFile) {
            setLoading(true);
            let reader = new FileReader();
            reader.readAsArrayBuffer(selectedFile);
            reader.onload = (e) => {
                setExcelFile(e.target.result);
                setLoading(false);
            }
        } else {
            setExcelFile(null);
            setExcelData([]);
            setUpdateMessage(<span style={{ color: "red" }}>No File Selected</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
            setLoading(false);
        }
    };

    const handleSubmit = (e) => {

        e.preventDefault();

        if (!excelFile) {
            setUpdateMessage(<span style={{ color: "red" }}>Please Select File</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
            setLoading(false);
        } else {
            const workbook = XLSX.read(excelFile, { type: 'buffer' });
            const worksheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[worksheetName];
            const data = XLSX.utils.sheet_to_json(worksheet);

            setExcelData(data);
            setLoading(false);

        }
    }

    const myData = () => {
        return {
            columns: [
                { label: 'Id', field: 'srno', },
                { label: 'Name', field: 'name', },
                { label: 'Regi. No.', field: 'registrationno', },
                { label: 'Mobile', field: 'mobile', },
                { label: 'Email', field: 'email', },
                { label: 'Address Line 1', field: 'addressline1', },
                { label: 'Address Line 2', field: 'addressline2', },
                { label: 'Area', field: 'area', },
                { label: 'Pincode', field: 'pincode', },
                { label: 'City', field: 'city', },
                { label: 'State', field: 'state', },
                { label: 'Website', field: 'website', },
                { label: 'Phone 1', field: 'phone1', },
                { label: 'Phone 2', field: 'phone2', },
                { label: 'Latitude', field: 'latitude', },
                { label: 'Longitude', field: 'longitude', },
                { label: 'Created On', field: 'createdon', },
                { label: 'Status', field: 'status', },
            ],
            rows:
                [...excelData.map((data, i) => (
                    {
                        srno: data.srno,
                        name: data.name,
                        registrationno: data.registrationno,
                        mobile: data.mobile,
                        email: data.email,
                        addressline1: data.addressline1,
                        addressline2: data.addressline2,
                        area: data.area,
                        pincode: data.pincode,
                        city: data.city,
                        state: data.state,
                        website: data.website,
                        phone1: data.phone1,
                        phone2: data.phone2,
                        latitude: data.latitude,
                        longitude: data.longitude,
                        createdon: moment(new Date()).format('DD-MMM-YYYY'),
                        status: data.status,
                    }

                ))]
            ,
        };
    };

    const saveAllData = () => {
        console.log("Save All Data: " + intloginid );

        setLoading(true);

        for (let i = 0; i < excelData.length; i++) {

            let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;  // eslint-disable-line

            if (!excelData[i].mobile) {
                Object.assign(excelData[i], { mobile: "NA" });
            }

            if (!excelData[i].email) {
                Object.assign(excelData[i], { email: "NA" });
            }

            if (!excelData[i].website) {
                Object.assign(excelData[i], { website: "NA" });
            }

            if (!excelData[i].addressline1) {
                Object.assign(excelData[i], { addressline1: "NA" });
            }

            if (!excelData[i].addressline2) {
                Object.assign(excelData[i], { addressline2: "NA" });
            }

            if (!excelData[i].area) {
                Object.assign(excelData[i], { area: "NA" });
            }

            if (!excelData[i].pincode) {
                Object.assign(excelData[i], { pincode: "NA" });
            }

            if (!excelData[i].phone1) {
                Object.assign(excelData[i], { phone1: "NA" });
            }

            if (!excelData[i].phone2) {
                Object.assign(excelData[i], { phone2: "NA" });
            }

            if (!excelData[i].latitude) {
                Object.assign(excelData[i], { latitude: "NA" });
            }

            if (!excelData[i].longitude) {
                Object.assign(excelData[i], { longitude: "NA" });
            }

            if (!excelData[i].registrationno) {
                Object.assign(excelData[i], { registrationno: "NA" });
            }

            if (!excelData[i].status) {
                Object.assign(excelData[i], { status: "Inactive" });
            }

            if (!excelData[i].createdon) {
                const todayDate = moment(new Date()).format('DD-MMM-YYYY');
                Object.assign(excelData[i], { createdon: todayDate });
            }
            
        }

        callAPI();

    }

    const callAPI = () => {
        //console.log("JSON: " + JSON.stringify(excelData));

        for (let i = 0; i < excelData.length; i++) {

            if(excelData[i].city){
                fetch("https://e-arogya.com:8443/api/general/cities/cityidbyname/" + excelData[i].city + "/"+ excelData[i].state)
                .then(response => response.json())
                .then(data => {
                    // console.log("received data: " + JSON.stringify(data));
                    // console.log("intcityid: " + data[0].intcityid + " stateid: " + data[0].intstateid);

                    if(data){
                        const formData = new FormData();

                        // console.log(intloginid + " " + strorganizationtype + " " + excelData[i].name + " " + excelData[i].registrationno + " " + excelData[i].mobile + " "
                        //     + excelData[i].email + " " + excelData[i].addressline1 + " " + excelData[i].addressline2 + " " + excelData[i].area + " " + excelData[i].pincode + " " 
                        //     + excelData[i].website + " " + excelData[i].phone1 + " " + excelData[i].phone2 + " " + excelData[i].createdon + " " + excelData[i].status + " " 
                        //     + excelData[i].city + " " + excelData[i].state);
            
                        formData.append('strmobileno', excelData[i].mobile);
                        if (excelData[i].email) { formData.append('stremailid', excelData[i].email); }
                        formData.append('strorganizationname', excelData[i].name);
                        if (excelData[i].website) { formData.append('strwebsite', excelData[i].website); }
                        formData.append('straddressline1', excelData[i].addressline1);
                        formData.append('straddressline2', excelData[i].addressline2);
                        formData.append('strarea', excelData[i].area);
                        formData.append('strpincode', excelData[i].pincode);
                        formData.append('intstateid', data[0].intstateid);
                        formData.append('intcityid',data[0].intcityid);
                        if (excelData[i].phone1) { formData.append('strphoneno1', excelData[i].phone1); }
                        if (excelData[i].phone2) { formData.append('strphoneno2', excelData[i].phone2); }
                        formData.append('strorganizationtype', strorganizationtype);
                        formData.append('decorglatitude', excelData[i].latitude);
                        formData.append('decorglongitude', excelData[i].longitude);
                        if (excelData[i].registrationno) { formData.append('strorganizationregno', excelData[i].registrationno) }
                        if (excelData[i].status) { formData.append('strstatus', excelData[i].status); }
            
                        createOrganization(formData);
                    }

                    

                })
                .catch(error => {
                    //console.log(error);
                })
                
            }

           

        }

    }

    //ADD ORGANIZATION DETAILS
    async function createOrganization(formData) {

        const requestOptions = {
            method: 'POST',
            body: formData
        };
        fetch("https://e-arogya.com:8443/api/general/organizationbyoperator/add/" + intloginid, requestOptions)
            .then(async response => {
                setLoading(false);
                if (response.status === 200) {
                  setUpdateMessage(<span style={{ color: "green" }}>{excelData.length} {strorganizationtype} data Added Successfully</span>);
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                  clearData();
                } else {
                  setUpdateMessage(<span style={{ color: "red" }}>{strorganizationtype} not added. Please try again.</span>);
                }
              })
              .catch(error => {
                //console.log("error: " + error);
                setLoading(false);
              });
            
    }

    const clearData = () => {

        aRef.current.value = null;
        setExcelData([]);
        setLoading(false);
    }

    return (
        <div className="main" >

            <OperatorSidebar />

            <div className="page-content">

                <Adminheader />

                <h5>Upload {strorganizationtype} Data</h5>

                <Form onSubmit={handleSubmit}>

                    <Form.Group className="mb-3, ml-3" controlId="formEnterState">
                        <Row>
                            <Col lg={4}>
                                <Form.Control
                                    ref={aRef}
                                    type="file"
                                    style={{ fontSize: 12 }}
                                    onChange={e => handleFileUpload(e)}
                                    accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={2}>
                                <Button style={{ marginTop: 10, marginBottom: 10, width: "50%" }} size="sm" variant="outline-primary" type="submit">Upload</Button>
                            </Col>
                            {excelData.length !== 0 ?
                                <Col lg={2}>
                                    <Button style={{ marginTop: 10, marginBottom: 10, width: "50%" }} size="sm" variant="primary" onClick={() => { saveAllData() }}>Save Data</Button>
                                </Col> : null
                            }
                        </Row>
                    </Form.Group>

                </Form>

                <div style={{ fontSize: 12, fontWeight: "bold", marginBottom: 10 }}>{updateMessage}</div>

                <CDBCard style={{ padding: 20, fontSize: 12, color: "grey" }}>
                    <CDBCardBody>
                        {loading ? (
                            <div style={{ textAlign: "center" }}><Spinner animation="border" size="sm" variant="primary" /></div>
                        ) :
                            <CDBDataTable
                                responsive
                                noRecordsFoundLabel="No Records Found"
                                noBottomColumn={true}
                                hover
                                entriesOptions={[10, 25, 50, 100, 200, 500]}
                                entries={10}
                                pagesAmount={4}
                                data={myData()}
                            />
                        }

                    </CDBCardBody>
                </CDBCard>

            </div>
        </div>
    )
}

export default OperatorUploadOrganizationData
