import React, { useEffect, useState, useRef } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./admincss.css";
import HospitalSidebar from "../Navbar/HospitalSidebar";
import Adminheader from "../Navbar/Adminheader";
import { Printer, TrashFill, PencilFill } from "react-bootstrap-icons";
import { Card, Col, Container, Row, Stack, Table, Form, Button, Badge, Modal } from "react-bootstrap";
import { useLocation } from "react-router-dom";
//import { format } from 'date-fns';
import { Asterisk } from "react-bootstrap-icons";
//import ReactToPrint from "react-to-print";
import { Document, Page, View, PDFDownloadLink, Text, StyleSheet, Image } from "@react-pdf/renderer";
import rupee from '../images/rupee.png'
// import { API_BASE } from '../../setupProxy';
import * as moment from 'moment';

const HospitalPatientViewBill = () => {

    const intOrganizationID = localStorage.getItem("orgid");

    const location = useLocation()
    const { patid, patmobile, patfname, patlname, patgender, caseid } = location.state ?? {}

    const intCaseID = caseid;

    // const formattedDate = format(new Date(), "dd-MMM-yyyy");

    const [services, setServices] = useState([]);
    const [intServiceByOrgID, setIntServiceByOrgID] = useState(null);
    const [decActualAmount, setDecActualAmount] = useState("");
    const [decBillingAmount, setDecBillingAmount] = useState("");
    const [decBillingTax, setDecBillingTax] = useState("");
    const [decBillingDiscount, setDecBillingDiscount] = useState("");
    const strBillStatus = 'Unpaid';
    const [strRemarks, setStrRemarks] = useState("");
    const [strstatus, setStatus] = useState('Active');

    const [errorMessage, setErrorMessage] = useState("");
    const [addMessage, setAddMessage] = useState("");

    const [newServiceAddForm, setNewServiceAddForm] = useState(false);

    useEffect(() => {
        fetchBillings();
        fetchServicesByOrganization(intOrganizationID);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchServicesByOrganization = (orgid) => {
        fetch("https://e-arogya.com:8443/api/general/organizationservices/list/" + orgid)
            .then(res => res.json())
            .then(ser => {
                setServices(ser);
            })
            .catch(error => {
                //console.log(error);
            })
    }

    const [billings, setBillings] = useState([]);
    const [intBillId, setIntBillId] = useState(null);
    const checkbox = useRef();

    const fetchBillings = () => {
        fetch("https://e-arogya.com:8443/api/general/billings/getallbillings/" + patid + "/" + caseid)
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }
                if (data) {
                    setBillings(data);
                }
            })
            .catch(error => {
                //console.log("Error Occurred: " + error);
            });
    };

    const onOkClicked = () => {
        if (intBillId === null && checkbox.current.checked === false) {
            setErrorMessage(<span style={{ color: "red" }}>Please select any one of the above</span>);
            setTimeout(() => setErrorMessage(""), 3000);
            setNewServiceAddForm(false);
        } else {
            //console.log("IntBillId: "+intBillId + " " +checkbox.current.checked );
            if (intBillId !== null) {
                if (intBillId === '- Select -') {
                    setErrorMessage(<span style={{ color: "red" }}>Please select bill</span>);
                    setTimeout(() => setErrorMessage(""), 3000);
                    setNewServiceAddForm(false);
                    setAppointmentsOnBill([]);
                    clearFields();
                } else {
                    getAppointmentOnBill(intBillId);
                    setNewServiceAddForm(true);
                }
            }
            if (checkbox.current.checked) {
                setNewServiceAddForm(true);
                setAppointmentsOnBill([]);
                clearFields();
            }
        }
    }

    const [appointmentsOnBill, setAppointmentsOnBill] = useState([]);
    const [billCreateDate, setBillCreateDate] = useState("");
    const [invoiceId, setInvoiceId] = useState("");
    const [billStatus, setBillStatus] = useState("");
    const getAppointmentOnBill = (billid) => {
        fetch("https://e-arogya.com:8443/api/general/billings/getappointmentonbill/" + billid)
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }
                if (data) {
                    setAppointmentsOnBill(data);
                    setBillCreateDate(data[0].dtmcreatedate);
                    setBillStatus(data[0].strBillStatus);
                    setInvoiceId("ORG" + data[0].intOrganizationID + "B" + data[0].intBillId);
                }
            })
            .catch(error => {
                //console.log("Error Occurred: " + error);
            });
    }

    //FORM SUBMIT
    const onAddNewAppointmentFormSubmit = (event) => {

        event.preventDefault();

        const formData = new FormData();

        if (intServiceByOrgID === "" || intServiceByOrgID === "- Select -" || intServiceByOrgID === null) {
            setErrorMessage(<span style={{ color: "red" }}>Please Select Service</span>);
            setTimeout(() => setErrorMessage(""), 3000);
        } else if (decBillingAmount === "") {
            setErrorMessage(<span style={{ color: "red" }}>Please Enter Billing Amount</span>);
            setTimeout(() => setErrorMessage(""), 3000);
        } else {
            // console.log(" " + intOrganizationID + " " + intServiceByOrgID + " " + decActualAmount + " " + decBillingAmount + " " + decBillingDiscount + " " + decBillingTax +
            //     " " + strRemarks + " " + strstatus + " " + strBillStatus + " " + intCaseID);

            formData.append('intOrganizationID', intOrganizationID);
            formData.append('intServiceByOrgID', intServiceByOrgID);
            formData.append('decActualAmount', decActualAmount);
            formData.append('decBillingAmount', decBillingAmount);
            formData.append('decBillingTax', decBillingTax);
            formData.append('strstatus', strstatus);
            formData.append('strBillStatus', strBillStatus);
            formData.append('intCaseID', intCaseID);

            if (decBillingDiscount) { formData.append('decBillingDiscount', decBillingDiscount); }
            if (strRemarks) { formData.append('strRemarks', strRemarks); }

            if (intBillId) {
                if (intAppointmentServicesId) {
                    updateAppointmentServices(formData, intAppointmentServicesId);
                    setIntAppointmentServicesId(null);
                } else {
                    addNewAppointmentServices(formData, intBillId);
                }
            } else {
                addNewAppointmentServicesBilling(formData);
            }
        }

    }

    function addNewAppointmentServices(formData, intBillId) {

        const requestOptions = {
            method: 'POST',
            body: formData
        };

        fetch("https://e-arogya.com:8443/api/general/billings/addnewappointmentservices/" + intBillId, requestOptions)
            .then(res => {

                if (res.ok) {
                    setAddMessage(<span style={{ color: "green" }}>Appointment Service Added Successfully</span>);
                    setTimeout(() => setAddMessage(""), 3000);
                    getAppointmentOnBill(intBillId);
                    clearFields();
                } else {
                    setAddMessage(<span style={{ color: "red" }}>Appointment Service Not Added. Please try again.</span>);
                    setTimeout(() => setAddMessage(""), 3000);
                }
            })
            .catch(error => {
                setAddMessage(<span style={{ color: "red" }}>Appointment Service Not Added. Please try again.</span>);
                setTimeout(() => setAddMessage(""), 3000);
                getAppointmentOnBill(intBillId);
                clearFields();
            });
    }

    function addNewAppointmentServicesBilling(formData) {

        const requestOptions = {
            method: 'POST',
            body: formData
        };

        fetch("https://e-arogya.com:8443/api/general/billings/addnewappointmentservicesbilling/", requestOptions)
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }
                if (data) {
                    setAddMessage(<span style={{ color: "green" }}>Appointment Service Added Successfully</span>);
                    setTimeout(() => setAddMessage(""), 3000);
                    clearFields();
                    checkbox.current.checked = false;
                    fetchBillings();
                    billSet(data.newbillid);
                    getAppointmentOnBill(data.newbillid);
                } else {
                    setAddMessage(<span style={{ color: "red" }}>Appointment Service Not Added. Please try again.</span>);
                    setTimeout(() => setAddMessage(""), 3000);
                    clearFields();
                }
            })
            .catch(error => {
                setErrorMessage(<span style={{ color: "red" }}>Error Occurred. Please try again.</span>);
                setTimeout(() => setErrorMessage(""), 3000);
            });

    }

    const billSet = (billid) => {
        setIntBillId(billid);
    }

    function updateAppointmentServices(formData, intAppointmentServicesId) {

        const requestOptions = {
            method: 'PUT',
            body: formData
        };

        fetch("https://e-arogya.com:8443/api/general/billings/updateappointmentservices/" + intAppointmentServicesId, requestOptions)
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();

                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }
                setAddMessage(<span style={{ color: "green" }}>Appointment Service Updated Successfully</span>);
                setTimeout(() => setAddMessage(""), 3000);
                getAppointmentOnBill(intBillId);
                clearFields();
            })
            .catch(error => {
                setAddMessage(<span style={{ color: "red" }}>Appointment Service Not Updated. Please try again.</span>);
                setTimeout(() => setAddMessage(""), 3000);
                getAppointmentOnBill(intBillId);
                clearFields();
            });
    }

    const getActualAmountCharges = (id) => {
        if (id === "- Select -") {
            setDecActualAmount("");
            setDecBillingAmount("");
            setDecBillingTax("");
        } else {
            fetch("https://e-arogya.com:8443/api/general/organizationservices/" + id)
                .then(res => res.json())
                .then(data => {
                    setDecActualAmount(data[0].decCharges);
                    setDecBillingAmount(data[0].decCharges);
                    setDecBillingTax(data[0].decServiceOrgTax);
                })
                .catch(error => {
                    //console.log(error);
                })
        }
    }

    const clearFields = () => {
        setIntServiceByOrgID(null);
        setDecActualAmount("");
        setDecBillingAmount("");
        setDecBillingTax("");
        setDecBillingDiscount("");
        setStrRemarks("");
        setStatus('Active');
        setBillCreateDate("");
        setInvoiceId("");
        setBillStatus("");
        setAppointmentsOnBill([]);
    }

    const totalPrice = appointmentsOnBill.map((as) => as.decBillingTotal).reduce((a, b) => a + b, 0);
    const totalFinalPrice = totalPrice.toFixed(2);

    const [intAppointmentServicesId, setIntAppointmentServicesId] = useState(null);
    const editAppointmentService = (id) => {
        fetch("https://e-arogya.com:8443/api/general/billings/getappointmentservicesbyid/" + id)
            .then(res => res.json())
            .then(data => {
                setIntAppointmentServicesId(data[0].intAppointmentServicesId);
                setIntServiceByOrgID(data[0].intServiceByOrgID);
                setDecActualAmount(data[0].decActualAmount);
                setDecBillingAmount(data[0].decBillingAmount);
                setDecBillingTax(data[0].decBillingTax);
                setDecBillingDiscount(data[0].decBillingDiscount);
                setStrRemarks(data[0].strRemarks);
                setIntBillId(data[0].intBillId);
            })
            .catch(error => {
                //console.log(error);
            });
    }

    const [deleteShow, setDeletShow] = useState(false);
    const handleDeleteClose = () => setDeletShow(false);
    const [deleteThis, setDeleteThis] = useState(null);

    const deleteAppointmentService = (id) => {
        setDeletShow(true);
        setDeleteThis(id);
    }

    const deleteThisFunc = (id) => {
        fetch("https://e-arogya.com:8443/api/general/billings/deleteappointmentonbill/" + id, { method: 'DELETE' })
            .then(async response => {
                const data = await response.text;
                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }
                setAddMessage(<span style={{ color: "green" }}>Appointment Service Deleted Successfully</span>);
                setTimeout(() => setAddMessage(""), 3000);
                getAppointmentOnBill(intBillId);
                setDeleteThis(null);
                setIntAppointmentServicesId(null);
                clearFields();
            })
            .catch(error => {
                setAddMessage(<span style={{ color: "red" }}>Appointment Service Not Deleted. Please try again.</span>);
                setTimeout(() => setAddMessage(""), 3000);
                getAppointmentOnBill(intBillId);
                clearFields();
            });
    }

    return (

        <div className="main" >

            <HospitalSidebar />

            <div className="page-content">

                <Adminheader />

                <h5>View Bill</h5>

                <div className="table-content">

                    <Modal show={deleteShow} onHide={handleDeleteClose}>
                        <Modal.Header closeButton>
                            <Modal.Title style={{ fontSize: 18 }}>Delete Appointment Service</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ fontSize: 14 }}>Are you sure you want to delete this Appointment Service?</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleDeleteClose} style={{ fontSize: 12 }}>No</Button>
                            <Button variant="primary" onClick={() => {
                                handleDeleteClose();
                                deleteThisFunc(deleteThis);
                            }} style={{ fontSize: 12 }}>Yes</Button>
                        </Modal.Footer>
                    </Modal>

                    <div style={{ marginBottom: 20 }}>
                        <Row style={{ fontSize: 12, color: "grey" }} className="align-items-center">
                            <Col md={2}>
                                <Form.Group controlId="formSelectStateStatus">
                                    <Form.Select aria-label="Default select example" style={{ fontSize: 12 }} value={intBillId || ""}
                                        onChange={e => {
                                            setIntBillId(e.target.value);
                                            checkbox.current.checked = false;
                                            clearFields();
                                        }
                                        }>
                                        <option value="- Select -">- Select Bill -</option>
                                        {billings.map((data) => <option key={data.intBillId} value={data.intBillId} >{data.intBillId} - {data.dtmcreatedate}</option>)}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col md={1} style={{ color: "grey", fontWeight: "bold", textAlign: "center" }}>
                                <Form.Label>OR</Form.Label>
                            </Col>
                            <Col md={1}>
                                <Form.Group controlId="formEnterState">
                                    <Form.Check
                                        type="checkbox"
                                        name="js"
                                        label="New Bill"
                                        ref={checkbox}
                                        onChange={() => { setIntBillId(null) }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={2}>
                                <Form.Group controlId="formEnterState">
                                    <Button variant="primary" type="submit" style={{ fontSize: 12 }} onClick={onOkClicked}> Ok </Button>
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>

                    {newServiceAddForm ?
                        <div>
                            <Form onSubmit={onAddNewAppointmentFormSubmit}>

                                <p>Add New Service</p>

                                <div>
                                    <Row style={{ fontSize: 12, color: "grey" }}>

                                        <Col md={2}>
                                            <Form.Group className="mb-3" controlId="formSelectStateStatus">
                                                <Form.Label><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Services</Form.Label>
                                                <Form.Select aria-label="Default select example" style={{ fontSize: 12 }} value={intServiceByOrgID || ""} onChange={e => { setIntServiceByOrgID(e.target.value); getActualAmountCharges(e.target.value); }}>
                                                    <option value="- Select -">- Select -</option>
                                                    {services.map((data) => <option key={data.intServiceByOrgID} value={data.intServiceByOrgID} >
                                                        {data.strSpecialityName !== null ?
                                                            <Form.Text>{data.strServiceName} - {data.strSpecialityName}</Form.Text>
                                                            :
                                                            <Form.Text>{data.strServiceName}</Form.Text>
                                                        }

                                                    </option>)
                                                    }
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>

                                        <Col md={2}>
                                            <Form.Group className="mb-3" controlId="formEnterState">
                                                <Form.Label>Actual Amount</Form.Label>
                                                <Form.Control type="text" style={{ fontSize: 12 }} value={decActualAmount || ""} onChange={e => { setDecActualAmount(e.target.value) }} disabled />
                                            </Form.Group>
                                        </Col>

                                        <Col md={2}>
                                            <Form.Group className="mb-3" controlId="formEnterState">
                                                <Form.Label><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Billing Amount</Form.Label>
                                                <Form.Control type="text" style={{ fontSize: 12 }} value={decBillingAmount || ""} onChange={e => { setDecBillingAmount(e.target.value) }} />
                                            </Form.Group>
                                        </Col>

                                        <Col md={1}>
                                            <Form.Group className="mb-3" controlId="formEnterState">
                                                <Form.Label>Discount</Form.Label>
                                                <Form.Control type="text" style={{ fontSize: 12 }} value={decBillingDiscount || ""} onChange={e => { setDecBillingDiscount(e.target.value) }} />
                                            </Form.Group>
                                        </Col>

                                        <Col md={2}>
                                            <Form.Group className="mb-3" controlId="formEnterState">
                                                <Form.Label>Remarks</Form.Label>
                                                <Form.Control type="text" style={{ fontSize: 12 }} value={strRemarks || ""} onChange={e => { setStrRemarks(e.target.value) }} />
                                            </Form.Group>
                                        </Col>

                                        <Col md={2}>
                                            <Form.Group className="mb-3" controlId="formEnterState">
                                                {intAppointmentServicesId ?
                                                    <Button variant="primary" type="submit" style={{ marginTop: 25, fontSize: 12 }}> Update </Button>
                                                    :
                                                    <Button variant="primary" type="submit" style={{ marginTop: 25, fontSize: 12 }}> Add </Button>
                                                }
                                            </Form.Group>
                                        </Col>

                                    </Row>

                                </div>

                            </Form>
                        </div>

                        :

                        <div> </div>

                    }

                    <div style={{ fontSize: 12, fontWeight: "bold", marginBottom: 10 }}>{errorMessage}</div>
                    <div style={{ fontSize: 12, fontWeight: "bold", marginBottom: 10 }}>{addMessage}</div>

                    <Card style={{ fontSize: 12, color: "grey" }}>

                        <Container style={{ padding: 50 }}>

                            <Row>
                                <Col></Col>
                                <Col md="auto">
                                    <Stack direction="horizontal">
                                        <Printer size={15} style={{ marginRight: 10 }} />
                                        {/* <p style={{ marginTop: 15, color: "steelblue", cursor: "pointer" }} >Print</p> */}
                                        <PDFDownloadLink
                                            document={
                                                <Document>
                                                    <Page size="A4" style={styles.page}>
                                                        <View style={{ margin: 20 }}>

                                                            <Text style={styles.patientName}>{patfname} {patlname}</Text>

                                                            <View style={styles.infoInline}>
                                                                <Text style={styles.label}>Gender: </Text>
                                                                <Text >{patgender}</Text>
                                                            </View >
                                                            <View style={styles.infoInline}>
                                                                <Text style={styles.label}>Mobile: </Text>
                                                                <Text >{patmobile}</Text>
                                                            </View >
                                                            <View style={styles.infoInline}>
                                                                <Text style={styles.label}>Invoice Id: </Text>
                                                                <Text >#123-456</Text>
                                                            </View >
                                                            <View style={styles.infoInline}>
                                                                <Text style={styles.label}>Invoice Date: </Text>
                                                                <Text >                 </Text>
                                                            </View >
                                                            <View style={styles.infoInline}>
                                                                <Text style={styles.label}>Invoice Status: </Text>
                                                                <Text>
                                                                    {billStatus.match("Unpaid") ? <Text style={styles.statusUnpaid}> {billStatus} </Text> : <div></div>}
                                                                    {billStatus.match("Paid") ? <Text style={styles.statusPaid}> {billStatus} </Text> : <div></div>}
                                                                    {billStatus.match("Partial") ? <Text style={styles.statusPartial}> {billStatus} </Text> : <div></div>}
                                                                    {billStatus.match("Cancelled") ? <Text style={styles.statusCancelled}> {billStatus} </Text> : <div></div>}
                                                                </Text>
                                                            </View >

                                                            <View style={styles.tableContainer}>
                                                                <Text style={styles.srNo}>#</Text>
                                                                <Text style={styles.serviceName}>Service Name</Text>
                                                                <Text style={styles.appointmentDate}>App. Date</Text>
                                                                <Text style={styles.charges}>Charges</Text>
                                                                <Text style={styles.discount}>Discount</Text>
                                                                <Text style={styles.tax}>Tax</Text>
                                                                <Text style={styles.remarks}>Remarks</Text>
                                                                <Text style={styles.amount}>Amount</Text>
                                                            </View>

                                                            {appointmentsOnBill.map((as, index) => <View key={index + "pob"}>
                                                                <View style={styles.tableContainerData} id={as.intAppointmentServicesId}>
                                                                    <Text style={styles.srNoData}>{index + 1}</Text>
                                                                    <Text style={styles.serviceNameData}>{as.strServiceName}</Text>
                                                                    <Text style={styles.appointmentDateData}>{as.dtmcreatedate}</Text>
                                                                    <Text style={styles.chargesData}><Image src={rupee} style={styles.rupee} />{as.decBillingAmount}</Text>
                                                                    <Text style={styles.discountData}><Image src={rupee} style={styles.rupee} />{as.decBillingDiscount}</Text>
                                                                    <Text style={styles.taxData}>{as.decBillingTax} %</Text>
                                                                    <Text style={styles.remarksData}>{as.strRemarks}</Text>
                                                                    <Text style={styles.amountData}> <Image src={rupee} style={styles.rupee} />{as.decBillingTotal}</Text>
                                                                </View>
                                                            </View>
                                                            )}

                                                            <View style={styles.tableFooter}>
                                                                <Text style={styles.extraCol}></Text>
                                                                <Text style={styles.totalPriceText}>Total Amount to be Paid: </Text>
                                                                <Text style={styles.totalPrice}><Image src={rupee} style={styles.rupee} /> {totalFinalPrice}</Text>
                                                            </View>

                                                        </View>
                                                    </Page>
                                                </Document>
                                            }
                                            fileName={"Invoice" + new Date().getTime() + ".pdf"}
                                        >
                                            {({ blob, url, loading, error }) => loading ? "Loading . . ." : "Download"}
                                        </PDFDownloadLink>
                                    </Stack>
                                </Col>
                                {/* <Col xs lg="2">
                                    <Stack direction="horizontal">
                                        <BoxArrowInUpRight size={15} />
                                        <p style={{ marginTop: 15, color: "steelblue" }}>Export</p>
                                    </Stack>
                                </Col> */}
                            </Row>

                            <h4 style={{ marginTop: 20, marginBottom: 20, color: "steelblue" }}>{patfname} {patlname}</h4>

                            <Row>
                                <Col>
                                    <Row className="justify-content-left" >
                                        <Col sm={4}> Gender </Col>
                                        <Col style={{ color: "black" }}> {patgender} </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={4}> Mobile </Col>
                                        <Col style={{ color: "black" }}> {patmobile} </Col>
                                    </Row>
                                </Col>

                                <Col>
                                    <Row>
                                        <Col sm={4}> Invoice Id </Col>
                                        <Col style={{ color: "black" }}> {invoiceId} </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={4}> Invoice Date </Col>
                                        {/* <Col style={{ color: "black" }}>{formattedDate}</Col> */}
                                        <Col style={{ color: "black" }}>{billCreateDate ? moment(billCreateDate).format('DD-MMM-YYYY') : ""}</Col>
                                    </Row>
                                    <Row>
                                        <Col sm={4} > Status </Col>
                                        {/* <Col style={{ color: "black" }}> <Badge bg="warning" text="dark"> Unpaid </Badge> </Col> */}
                                        <Col style={{ color: "black" }}>
                                            {billStatus.match("Unpaid") ? <Badge bg="warning" text="dark"> {billStatus} </Badge> : <div></div>}
                                            {billStatus.match("Paid") ? <Badge bg="success" text="dark"> {billStatus} </Badge> : <div></div>}
                                            {billStatus.match("Partial") ? <Badge bg="info" text="dark"> {billStatus} </Badge> : <div></div>}
                                            {billStatus.match("Cancelled") ? <Badge bg="danger" text="dark"> {billStatus} </Badge> : <div></div>}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Table style={{ marginTop: 30, marginBottom: 30, color: "grey" }} responsive  >

                                <thead>
                                    <tr style={{ backgroundColor: "steelblue", color: "white" }}>
                                        <th>#</th>
                                        <th>Service Name</th>
                                        <th>Appointment Date</th>
                                        <th>Charges</th>
                                        <th>Discount</th>
                                        <th>Tax</th>
                                        <th>Remarks</th>
                                        <th>Amount</th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {appointmentsOnBill.map((as, index) => (
                                        <tr key={index + "pb"}>
                                            <td><Form.Label style={{ fontSize: 12, color: "grey" }}>{index + 1}</Form.Label></td>
                                            <td><Form.Label style={{ fontSize: 12, color: "grey" }}>{as.strServiceName}</Form.Label></td>
                                            <td><Form.Label style={{ fontSize: 12, color: "grey" }}>{moment(as.dtmcreatedate).format('DD-MMM-YYYY')}</Form.Label></td>
                                            <td><Form.Label style={{ fontSize: 12, color: "grey" }}>₹ {as.decBillingAmount}</Form.Label></td>
                                            <td><Form.Label style={{ fontSize: 12, color: "grey" }}>₹ {as.decBillingDiscount}</Form.Label></td>
                                            <td><Form.Label style={{ fontSize: 12, color: "grey" }}>{as.decBillingTax} %</Form.Label></td>
                                            <td><Form.Label style={{ fontSize: 12, color: "grey" }}>{as.strRemarks}</Form.Label></td>
                                            <td><Form.Label style={{ fontSize: 12, color: "grey" }}>₹ {as.decBillingTotal}</Form.Label></td>
                                            <td><PencilFill width={12} height={12} style={{ color: "steelblue", cursor: "pointer" }} onClick={() => { editAppointmentService(as.intAppointmentServicesId) }} /></td>
                                            <td><TrashFill width={12} height={12} style={{ color: "red", cursor: "pointer" }} onClick={() => { deleteAppointmentService(as.intAppointmentServicesId) }} /></td>
                                        </tr>
                                    ))}
                                    <tr>
                                        <th colSpan={6}></th>
                                        <th style={{ textAlign: "right", fontSize: 14 }}>Total Amount to be Paid: </th>
                                        <th style={{ fontSize: 14 }}>₹ {totalFinalPrice}</th>
                                    </tr>
                                </tbody>

                            </Table>

                            <Form.Check type="checkbox" label="Mark As Paid" style={{ fontSize: 12, color: "grey" }} />

                            <Button variant="primary" style={{ fontSize: 12, fontWeight: "bold", float: "right", marginRight: 125 }}> Pay Now </Button>

                        </Container>

                    </Card>

                </div>

            </div>
        </div>

    );
};

const styles = StyleSheet.create({
    page: {
        padding: 50,
        paddingTop: 70,
        fontSize: 7,
    },
    patientName: {
        marginTop: 20,
        marginBottom: 20,
        color: "steelblue",
        fontSize: 15,
    },
    infoInline: {
        flexDirection: 'row',
        marginVertical: 5,
    },
    label: {
        color: "grey",
    },
    statusUnpaid: {
        backgroundColor: "#ffc107",
        borderWidth: 1,
        borderColor: '#bff0fd',
        borderRadius: 5,
    },
    statusPaid: {
        backgroundColor: "#198754",
    },
    statusPartial: {
        backgroundColor: "#DC3545",
    },
    statusCancelled: {
        backgroundColor: "#0CCAF0",
    },

    tableContainer: {
        flexDirection: 'row',
        backgroundColor: 'steelblue',
        alignItems: 'center',
        height: 20,
        textAlign: 'left',
        flexGrow: 1,
        marginTop: 10,
        paddingLeft: 5,
        paddingRight: 5,
    },
    srNo: {
        width: '2%',
        color: "white",
    },
    serviceName: {
        width: '20%',
        color: "white",
    },
    appointmentDate: {
        width: '15%',
        color: "white",
    },
    charges: {
        width: '10%',
        color: "white",
    },
    tax: {
        width: '7%',
        color: "white",
    },
    discount: {
        width: '8%',
        color: "white",
    },
    remarks: {
        width: '28%',
        color: "white",
    },
    amount: {
        width: '10%',
        color: "white",
    },
    tableContainerData: {
        flexDirection: 'row',
        alignItems: 'center',
        height: 20,
        textAlign: 'left',
        flexGrow: 1,
        borderBottomWidth: 0.5,
        borderBottomColor: "#e0e0e0",
        paddingLeft: 5,
        paddingRight: 5,
    },
    srNoData: {
        width: '2%',
        color: "grey",
    },
    serviceNameData: {
        width: '20%',
        color: "grey",
    },
    appointmentDateData: {
        width: '15%',
        color: "grey",
    },
    chargesData: {
        width: '10%',
        color: "grey",
    },
    taxData: {
        width: '7%',
        color: "grey",
    },
    discountData: {
        width: '8%',
        color: "grey",
    },
    remarksData: {
        width: '28%',
        color: "grey",
    },
    amountData: {
        width: '10%',
        color: "grey",
    },
    rupee: {
        paddingRight: 10,
        width: 5,
        height: 5,
        color: "red",
    },
    tableFooter: {
        flexDirection: 'row',
        alignItems: 'center',
        height: 20,
        textAlign: 'right',
        flexGrow: 1,
        marginBottom: 10,
        paddingLeft: 5,
        paddingRight: 5,
        borderBottomWidth: 0.5,
        borderBottomColor: "#e0e0e0",
    },
    extraCol: {
        width: '50%',
        color: "#424242",
    },
    totalPriceText: {
        width: '40%',
        color: "#424242",
        paddingRight: 20,
    },
    totalPrice: {
        width: '10%',
        color: "#424242",
        textAlign: "left"
    },
})

export default HospitalPatientViewBill;