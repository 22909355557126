import React, { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./admincss.css";
import HospitalSidebar from "../Navbar/HospitalSidebar";
import Adminheader from "../Navbar/Adminheader";
import { CDBCard, CDBCardBody, CDBDataTable } from 'cdbreact';
import { Col, Form, Row, Button, Modal, Spinner } from "react-bootstrap";
import { Asterisk, CurrencyRupee, Percent } from "react-bootstrap-icons";
// import { API_BASE } from '../../setupProxy';
import * as moment from 'moment';

const HospitalServices = () => {

    const intOrganizationID = localStorage.getItem("orgid");

    const [organizationServices, setOrganizationServices] = useState([]);
    const [services, setServices] = useState([]);
    const [loading, setLoading] = useState(false);

    const [specialities, setSpecialities] = useState([]);
    const [intSpecialityID, setIntSpecialityID] = useState(null);
    const [intServiceByOrgID, setIntServiceByOrgID] = useState(null);
    const [strDetails, setStrDetails] = useState("");
    const [decCharges, setDecCharges] = useState("");
    const [decServiceOrgTax, setDecServiceOrgTax] = useState("");
    const [intServiceID, setIntServiceID] = useState(null);
    const [strstatus, setStatus] = useState('Active');
    const [strChargeUnit, setStrChargeUnit] = useState('- Select -');
    const [dtmChargeFromDate, setDtmChargeFromDate] = useState("");
    const [dtmChargeToDate, setDtmChargeToDate] = useState("");
    

    const [updateMessage, setUpdateMessage] = useState("");

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [deleteThis, setDeleteThis] = useState(null);

    useEffect(() => {
        fetchServices();
        fetchOrganizationServices(intOrganizationID);
        fetchSpecialityByOrganization(intOrganizationID);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //FETCH ALL ORGANIZATION SERVICES
    const fetchOrganizationServices = (orgid) => {
        fetch("https://e-arogya.com:8443/api/general/organizationservices/list/" + orgid)
            .then(response => response.json())
            .then(orgservicesdata => {
                let dataFromApi = orgservicesdata.map(orgser => {
                    return {
                        id: orgser.intServiceByOrgID,
                        name: orgser.strServiceName,
                        dept: orgser.strSpecialityName,
                        status: orgser.strstatus,
                        details: orgser.strDetails,
                        charges: orgser.decCharges,
                        tax: orgser.decServiceOrgTax,
                        charge: orgser.strChargeUnit,
                        cfromdate: orgser.dtmChargeFromDate,
                        ctodate: orgser.dtmChargeToDate,
                    }
                })
                setOrganizationServices(dataFromApi);
                setLoading(true);
            })
            .catch(error => {
                //console.log(error);
            })
    }

    //FETCH ALL SERVICES (FOR DROPDOWN)
    const fetchServices = () => {
        fetch("https://e-arogya.com:8443/api/general/services")
            .then(res => res.json())
            .then(servicesData => {
                setServices(servicesData);
            })
            .catch(error => {
                //console.log(error);
            })

    }

    //FETCH ALL SPECIALITIES (FOR DROPDOWN)
    const fetchSpecialityByOrganization = (orgid) => {
        fetch("https://e-arogya.com:8443/api/general/organizationspecialities/list/" + orgid)
            .then(res => res.json())
            .then(spec => {
                setSpecialities(spec);
            })
            .catch(error => {
                //console.log(error);
            })
    }

    const clearFields = () => {
        setIntServiceID(null);
        setIntSpecialityID(null);
        setStrDetails("");
        setDecCharges("");
        setDecServiceOrgTax("");
        setStatus('Active');
        setStrChargeUnit('- Select -');
        setDtmChargeFromDate("");
        setDtmChargeToDate("");
    }

    //FORM SUBMIT
    const onAddNewOrganizationServiceFormSubmit = (event) => {
        event.preventDefault();

        if (intServiceID === "- Select -" || intServiceID === "" || intServiceID === null) {
            setUpdateMessage(<span style={{ color: "red" }}>Please select service</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
        } else if (strChargeUnit === "- Select -") {
            setUpdateMessage(<span style={{ color: "red" }}>Please select Charge unit</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
        } else if (dtmChargeFromDate === "") {
            setUpdateMessage(<span style={{ color: "red" }}>Please select Charge from date</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
        } else if (dtmChargeToDate === "") {
            setUpdateMessage(<span style={{ color: "red" }}>Please select Charge to date</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
        } else if (decCharges === "" || isNaN(decCharges)) {
            setUpdateMessage(<span style={{ color: "red" }}>Please enter valid charge amount</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
        } else if (decServiceOrgTax === "" || isNaN(decServiceOrgTax)) {
            setUpdateMessage(<span style={{ color: "red" }}>Please enter valid tax amount</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
        } else {
            if (intServiceByOrgID) {
                if (intSpecialityID !== null && intSpecialityID !== "- Select -") {
                    const existingInputs = { intServiceID, intSpecialityID, intOrganizationID, strDetails, decCharges, decServiceOrgTax, strstatus, strChargeUnit, dtmChargeFromDate, dtmChargeToDate }
                    updateOrganizationService(existingInputs, intServiceByOrgID);
                    setIntServiceByOrgID(null);
                    clearFields();
                } else {
                    const existingInputs = { intServiceID, intOrganizationID, strDetails, decCharges, decServiceOrgTax, strstatus, strChargeUnit, dtmChargeFromDate, dtmChargeToDate }
                    updateOrganizationService(existingInputs, intServiceByOrgID);
                    setIntServiceByOrgID(null);
                    clearFields();
                }
            } else {
                if (intSpecialityID !== null && intSpecialityID !== "- Select -") {
                    const toInput = { intServiceID, intSpecialityID, intOrganizationID, strDetails, decCharges, decServiceOrgTax, strstatus, strChargeUnit, dtmChargeFromDate, dtmChargeToDate };
                    addNewOrganizationService(toInput);
                    clearFields();
                } else {
                    const toInput = { intServiceID, intOrganizationID, strDetails, decCharges, decServiceOrgTax, strstatus, strChargeUnit, dtmChargeFromDate, dtmChargeToDate };
                    addNewOrganizationService(toInput);
                    clearFields();
                }
            }
        }

    }

    //FETCH SERVICES BY ID (FOR EDIT SERVICE)
    const handleEditClick = (e, id) => {
        e.preventDefault();
        fetch("https://e-arogya.com:8443/api/general/organizationservices/" + id)
            .then(res => res.json())
            .then(data => {
                setIntServiceByOrgID(data[0].intServiceByOrgID);
                setIntServiceID(data[0].intServiceID);
                setStrDetails(data[0].strDetails);
                setDecCharges(data[0].decCharges);
                setDecServiceOrgTax(data[0].decServiceOrgTax);
                setStatus(data[0].strstatus);
                setStrChargeUnit(data[0].strChargeUnit)
                setDtmChargeFromDate(data[0].dtmChargeFromDate);
                setDtmChargeToDate(data[0].dtmChargeToDate);
                if (data[0].intSpecialityID !== null) { setIntSpecialityID(data[0].intSpecialityID); }
            })
            .catch(error => {
                //console.log(error);
            });
    }

    //ADD NEW ORGANIZATION SERVICE
    async function addNewOrganizationService(toInput) {
        fetch("https://e-arogya.com:8443/api/general/organizationservices/addneworganizationservice", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            redirect: "follow",
            body: JSON.stringify(toInput)
        })
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();

                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }
                setUpdateMessage(<span style={{ color: "green" }}>Organization Service Added Successfully</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
                fetchOrganizationServices(intOrganizationID);
            })
            .catch(error => {
                setUpdateMessage(<span style={{ color: "red" }}>Organization Service Not Added. Please try again.</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
            });

    }

    //UPDATE EXISTING ORGANIZATION SERVICE
    async function updateOrganizationService(existingInputs, intServiceByOrgID) {
        fetch("https://e-arogya.com:8443/api/general/organizationservices/update/" + intServiceByOrgID, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            redirect: "follow",
            body: JSON.stringify(existingInputs)
        })
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();

                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }
                setUpdateMessage(<span style={{ color: "green" }}>Organization Service Updated Successfully</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
                fetchOrganizationServices(intOrganizationID);
            })
            .catch(error => {
                setUpdateMessage(<span style={{ color: "red" }}>Organization Service Not Updated. Please try again.</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
            });

    }

    //DELETE ORGANIZATION SERVICE
    function handleDeleteClick(e, id) {
        setShow(true);
        setDeleteThis(id);
    }

    const deleteThisFunc = (id) => {
        fetch("https://e-arogya.com:8443/api/general/organizationservices/" + id, { method: 'DELETE' })
            .then(async response => {
                const data = await response.text;

                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }

                setUpdateMessage(<span style={{ color: "green" }}>Organization Service Deleted Successfully</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
                setDeleteThis(null);
                setIntServiceByOrgID(null);
                clearFields();
                fetchOrganizationServices(intOrganizationID);
            })
            .catch(error => {
                setUpdateMessage(<span style={{ color: "red" }}>Organization Service Not Deleted. Please try again.</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
            });
    }

    const data = () => {
        return {
            columns: [
                { label: '#', field: 'srno', },
                {
                    label: 'Service Name',
                    field: 'name',

                    attributes: {
                        'aria-controls': 'DataTable',
                        'aria-label': 'Name',
                    },
                },
                { label: 'Department', field: 'dept', },
                { label: 'Charge Unit', field: 'charge', },
                { label: 'Charge From Date', field: 'chargefromdate', },
                { label: 'Charge To Date', field: 'chargetodate', },
                { label: 'Charges', field: 'charges', },
                { label: 'Tax', field: 'tax', },
                { label: 'Details', field: 'details', },
                { label: 'Status', field: 'status', },
                { label: '', field: 'edit', },
                { label: '', field: 'delete', },
            ],
            rows: [...organizationServices.map((data1, index) => (
                {
                    srno: index + 1,
                    name: data1.name,
                    dept: data1.dept,
                    charge: data1.charge,
                    chargefromdate: moment(data1.cfromdate).format('DD-MMM-YYYY'),
                    chargetodate: moment(data1.ctodate).format('DD-MMM-YYYY'),
                    charges: <span><CurrencyRupee width={12} height={12} style={{marginBottom: 2}} /> {data1.charges}</span>,
                    tax: <span>{data1.tax} <Percent width={12} height={12} style={{marginBottom: 2}} /></span>,
                    details: data1.details,
                    status: data1.status,
                    edit: <span onClick={(e) => handleEditClick(e, data1.id)}>
                        <Button variant="primary" type="submit" style={{ fontSize: 12 }} size="sm">
                            Edit
                        </Button>
                    </span>,
                    delete: <span onClick={(e) => handleDeleteClick(e, data1.id)}>
                        <Button variant="danger" type="submit" style={{ fontSize: 12 }} size="sm">
                            Delete
                        </Button>
                    </span>
                }
            ))

            ],
        };
    };

    return (

        <div className="main" >

            <HospitalSidebar />

            <div className="page-content">

                <Adminheader />

                <h5>Manage Hospital Services</h5>

                <div className="table-content">

                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title style={{ fontSize: 18 }}>Delete Service</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ fontSize: 14 }}>Are you sure you want to delete this service?</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose} style={{ fontSize: 12 }}>No</Button>
                            <Button variant="primary" onClick={() => {
                                handleClose();
                                deleteThisFunc(deleteThis);
                            }} style={{ fontSize: 12 }}>Yes</Button>
                        </Modal.Footer>
                    </Modal>

                    <Form onSubmit={onAddNewOrganizationServiceFormSubmit}>

                        <Row style={{ fontSize: 12, color: "grey" }}>

                            <Col sm={2}>
                                <Form.Group className="mb-3" controlId="formSelectStateStatus">
                                    <Form.Label><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Service Name</Form.Label>
                                    <Form.Select aria-label="Default select example" style={{ fontSize: 12 }} value={intServiceID || ""} onChange={e => { setIntServiceID(e.target.value) }}>
                                        <option value="- Select -">- Select -</option>
                                        {services.map((data, key) => <option key={data.intServiceID} value={data.intServiceID} >{data.strServiceName}</option>)}
                                    </Form.Select>
                                </Form.Group>
                            </Col>

                            <Col sm={2}>
                                <Form.Group className="mb-3" controlId="formSelectStateStatus">
                                    <Form.Label>Department</Form.Label>
                                    <Form.Select aria-label="Default select example" style={{ fontSize: 12 }} value={intSpecialityID || ""} onChange={e => { setIntSpecialityID(e.target.value) }} >
                                        <option value="- Select -">- Select -</option>
                                        {specialities.map((data, key) => <option key={data.intSpecialityID} value={data.intSpecialityID} >{data.strSpecialityName}</option>)}
                                    </Form.Select>
                                </Form.Group>
                            </Col>

                            <Col sm={2}>
                                <Form.Group className="mb-3" controlId="formSelectChargeUnit">
                                    <Form.Label><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Charge Unit</Form.Label>
                                    <Form.Select aria-label="Default select example" style={{ fontSize: 12 }} value={strChargeUnit || ""} onChange={e => { setStrChargeUnit(e.target.value) }}>
                                        <option value="- Select -">- Select -</option>
                                        <option value="Per Visit">Per Visit</option>
                                        <option value="Per Day">Per Day</option>
                                        <option value="Per Hour">Per Hour</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>

                            <Col sm={2}>
                                <Form.Label><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Charge From Date</Form.Label>
                                <Form.Group className="mb-3" controlId="formEnterState">
                                    <Form.Control type="date" style={{ fontSize: 12 }} value={dtmChargeFromDate || ""} onChange={e => { setDtmChargeFromDate(e.target.value) }} required />
                                </Form.Group>
                            </Col>

                            <Col sm={2}>
                                <Form.Label><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Charge To Date</Form.Label>
                                <Form.Group className="mb-3" controlId="formEnterState">
                                    <Form.Control type="date" style={{ fontSize: 12 }} value={dtmChargeToDate || ""} onChange={e => { setDtmChargeToDate(e.target.value) }} required />
                                </Form.Group>
                            </Col>

                        </Row>

                        <Row style={{ fontSize: 12, color: "grey" }}>

                            <Col sm={2}>
                                <Form.Group className="mb-3" controlId="formEnterState">
                                    <Form.Label><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Charges</Form.Label>
                                    <Form.Control type="text" style={{ fontSize: 12 }} value={decCharges || ""} onChange={e => { setDecCharges(e.target.value) }} required />
                                </Form.Group>
                            </Col>

                            <Col sm={2}>
                                <Form.Group className="mb-3" controlId="formEnterState">
                                    <Form.Label><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Tax</Form.Label>
                                    <Form.Control type="text" style={{ fontSize: 12 }} value={decServiceOrgTax || ""} onChange={e => { setDecServiceOrgTax(e.target.value) }} required />
                                </Form.Group>
                            </Col>

                            <Col sm={2}>
                                <Form.Group className="mb-3" controlId="formEnterState">
                                    <Form.Label>Details</Form.Label>
                                    <Form.Control type="text" style={{ fontSize: 12 }} value={strDetails || ""} onChange={e => { setStrDetails(e.target.value) }} />
                                </Form.Group>
                            </Col>

                            <Col sm={2}>
                                <Form.Group className="mb-3" controlId="formSelectStateStatus">
                                    <Form.Label>Status</Form.Label>
                                    <Form.Select aria-label="Default select example" style={{ fontSize: 12 }} value={strstatus || ""} onChange={e => { setStatus(e.target.value) }}>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>

                            <Col sm={2} >
                                <Form.Group className="mb-3" controlId="formSelectStateStatus">
                                    {intServiceByOrgID ?
                                        <Button variant="primary" type="submit" style={{ marginTop: 25, fontSize: 12 }}> Update </Button>
                                        :
                                        <Button variant="primary" type="submit" style={{ marginTop: 25, fontSize: 12 }}> Add </Button>
                                    }
                                </Form.Group>
                            </Col>

                        </Row>

                    </Form>

                    <div style={{ fontSize: 12, fontWeight: "bold", marginBottom: 10 }}>{updateMessage}</div>

                    <CDBCard style={{ padding: 20, fontSize: 12, color: "grey" }}>
                        <CDBCardBody>
                            {!loading ? (
                                <div style={{ textAlign: "center" }}><Spinner animation="border" size="sm" variant="primary" /></div>
                            ) :
                                <CDBDataTable
                                    responsive
                                    noRecordsFoundLabel="No Records Found"
                                    noBottomColumn={true}
                                    hover
                                    entriesOptions={[10, 25, 50, 100, 200, 500]}
                                    entries={10}
                                    pagesAmount={4}
                                    data={data()}
                                />
                            }
                        </CDBCardBody>
                    </CDBCard>

                </div>

            </div>
        </div>

    );
};

export default HospitalServices;