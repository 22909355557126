import React from "react";
import { Link } from "react-router-dom";
import { Button, Row, Col, Card, Container, CardGroup } from "react-bootstrap";
import { Person, Hospital, CapsulePill, Clipboard2Pulse } from 'react-bootstrap-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../Navbar/Navbar";
import Footer from "../Navbar/Footer";

function Temp() {
  return (

    <div>
      <Navbar />

      <Container fluid>

        <div style={{ marginTop: 100, marginBottom: 100 }} className='container'>

          <Row>
            <Col className="text-center justify-content-center">
              <div style={{ textAlign: "center" }}>
                <div className="about-border"></div>
                <p style={{ fontSize: 35, fontWeight: 700 }}>THIS PAGE IS TEMPORARY</p>
              </div>
              <div>
                <p style={{ color: "grey" }}>User will be redicted automatically to relevant Dashboard as per user type</p>
              </div>
            </Col>
          </Row>

          <CardGroup>

            <Card className="custom-class" style={{ margin: 10, borderRadius: 10 }}>
              <Card.Body>
                <Person color="white" size={60} style={{ marginTop: 30, marginBottom: 30 }} />
                <Card.Title style={{ color: "#fff" }}>SUPER ADMIN</Card.Title>
                <Card.Text style={{ color: "#fff", fontWeight: 100 }}>Search health services in your city, area or at desided locations.</Card.Text>
                <Link to="/superadmindashboard">
                  <Button variant="outline-light" style={{ marginTop: 10, marginBottom: 10 }}>Super Admin Dashboard</Button>
                </Link>
              </Card.Body>
            </Card>

            <Card className="custom-class" style={{ margin: 10, borderRadius: 10 }}>
              <Card.Body>
                <Hospital color="white" size={60} style={{ marginTop: 30, marginBottom: 30 }} />
                <Card.Title style={{ color: "#fff" }}>HOSPITAL</Card.Title>
                <Card.Text style={{ color: "#fff", fontWeight: 100 }}>Book your self to avail the various services online in easy way.</Card.Text>
                <Link to="/hospitaldashboard">
                  <Button variant="outline-light" style={{ marginTop: 10, marginBottom: 10 }}>Hospital Dashboard</Button>
                </Link>
              </Card.Body>
            </Card>

            <Card className="custom-class" style={{ margin: 10, borderRadius: 10 }}>
              <Card.Body>
                <Clipboard2Pulse color="white" size={60} style={{ marginTop: 30, marginBottom: 30 }} />
                <Card.Title style={{ color: "#fff" }}>LABORATORY</Card.Title>
                <Card.Text style={{ color: "#fff", fontWeight: 100 }}>Book your self to avail the various services online in easy way.</Card.Text>
                <Link to="/laboratorydashboard">
                  <Button variant="outline-light" style={{ marginTop: 10, marginBottom: 10 }}>Laboratory Dashboard</Button>
                </Link>
              </Card.Body>
            </Card>

            <Card className="custom-class" style={{ margin: 10, borderRadius: 10 }}>
              <Card.Body>
                <CapsulePill color="white" size={60} style={{ marginTop: 30, marginBottom: 30 }} />
                <Card.Title style={{ color: "#fff" }}>PHARMACY</Card.Title>
                <Card.Text style={{ color: "#fff", fontWeight: 100 }}>Book your self to avail the various services online in easy way.</Card.Text>
                <Link to="/pharmacydashboard">
                  <Button variant="outline-light" style={{ marginTop: 10, marginBottom: 10 }}>Pharmacy Dashboard</Button>
                </Link>
              </Card.Body>
            </Card>

          </CardGroup>

        </div>

      </Container>

      <Footer />
    </div>

  );
}

export default Temp;