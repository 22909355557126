import React, { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./navcss.css";
import { Image } from "react-bootstrap";
import { CaretDownFill, CaretUpFill } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import MyImage from "../images/cropped-transparant-logo.png";
import { useNavigate } from "react-router-dom";
import { CDBSidebar, CDBSidebarHeader, CDBSidebarMenuItem, CDBSidebarContent, CDBSidebarMenu, CDBListGroup, } from 'cdbreact';

var sidebarStat = false;

const HospitalSidebar = () => {

    const loginid = localStorage.getItem("loggedInId");
    const navigate = useNavigate();

    const roleName = localStorage.getItem("loggedRoleName");

    const [hospitalAdmin, setHospitalAdmin] = useState(false);
    const [frontend, setFrontend] = useState(false);
    const [medical, setMedical] = useState(false);
    const [account, setAccount] = useState(false);

    const [manageHospMenu, setManageHospMenu] = useState(false);
    const manageHospitalMenuClicked = () => { setManageHospMenu(!manageHospMenu); dashclick("Dashboard")  }

    useEffect(() => {
        checkLoginId();
        manageSidebar();
    })

    const checkLoginId = () => {
        if (loginid === null || loginid === "") {
            navigate('/login');
        }
    }

    const manageSidebar = () => {
        if (roleName === "Hospital Admin") {
            setHospitalAdmin(true);
            setFrontend(false);
            setMedical(false);
            setAccount(false);
        } else if (roleName === "Frontend") {
            setHospitalAdmin(false);
            setFrontend(true);
            setMedical(false);
            setAccount(false);
        } else if (roleName === "Medical") {
            setHospitalAdmin(false);
            setFrontend(false);
            setMedical(true);
            setAccount(false);
        } else if (roleName === "Account") {
            setHospitalAdmin(false);
            setFrontend(false);
            setMedical(false);
            setAccount(true);
        }
    };
    
    const dashclick = (menuItemName) => {
        if(menuItemName === "Dashboard"){
            sidebarStat = false;
        } else if(menuItemName === "Other"){
            sidebarStat = true;
        }
    };

    return (
        <div>

            {hospitalAdmin ?
                <div style={{ display: 'flex', height: '100vh', overflow: 'scroll initial' }}>
                    <CDBSidebar textColor="#808080" backgroundColor="#fff" toggled={sidebarStat} >
                        <CDBSidebarHeader prefix={<i className="fa fa-bars" />}>
                            <div style={{ padding: 10 }}>
                                <Link to="/hospitaldashboard"><Image src={MyImage} className="d-inline-block align-top" alt="check"  height={60}  /></Link>
                            </div>
                        </CDBSidebarHeader>
                        <CDBSidebarContent>
                            <CDBSidebarMenu>
                                <span style={{ fontWeight: "bold" }} onClick={() => {dashclick("Dashboard")}}><Link to="/hospitaldashboard"><CDBSidebarMenuItem icon="th-large" iconSize="sm" textFontSize="12px">Dashboard</CDBSidebarMenuItem></Link></span>
                                <span style={{ fontWeight: "bold" }} onClick={() => {dashclick("Other")}}><Link to="/hospitalpatientrecords"><CDBSidebarMenuItem icon="file-alt" iconSize="sm" textFontSize="12px" >Patient Records</CDBSidebarMenuItem></Link></span>
                                <span style={{ fontWeight: "bold" }} onClick={() => {dashclick("Other")}}><Link to="/hospitalpatientappointments"><CDBSidebarMenuItem icon="calendar-alt" iconSize="sm" textFontSize="12px" >Appointments</CDBSidebarMenuItem></Link></span>
                                <span style={{ fontWeight: "bold" }} onClick={() => {dashclick("Other")}}><Link to="/hospitalpreviousappointments"><CDBSidebarMenuItem icon="history" iconSize="sm" textFontSize="12px" >Previous Appointments</CDBSidebarMenuItem></Link></span>

                                <span onClick={manageHospitalMenuClicked} style={{ fontWeight: "bold" }}>
                                    <CDBSidebarMenuItem icon="file" iconSize="sm" textFontSize="12px">
                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            Manage
                                            {manageHospMenu ? <CaretUpFill size={13} /> : <CaretDownFill size={13} />}
                                        </div>
                                    </CDBSidebarMenuItem>
                                </span>
                                {
                                    manageHospMenu ?
                                        <CDBListGroup style={{ paddingLeft: 20 }}>
                                            <div style={{ height: 40 }} onClick={() => {dashclick("Other")}}><Link to="/hospitalspecialities"><CDBSidebarMenuItem icon="circle-small" iconSize="sm" textFontSize="12px" >Departments</CDBSidebarMenuItem></Link></div>
                                            <div style={{ height: 40 }} onClick={() => {dashclick("Other")}}><Link to="/hospitalservices"><CDBSidebarMenuItem icon="circle-small" iconSize="sm" textFontSize="12px" >Services</CDBSidebarMenuItem></Link></div>
                                            <div style={{ height: 40 }} onClick={() => {dashclick("Other")}}><Link to="/hospitaldoctors"><CDBSidebarMenuItem icon="circle-small" iconSize="sm" textFontSize="12px" >Doctors</CDBSidebarMenuItem></Link></div>
                                            <div style={{ height: 40 }} onClick={() => {dashclick("Other")}}><Link to="/hospitaltimeslots"><CDBSidebarMenuItem icon="circle-small" iconSize="sm" textFontSize="12px" >Appointment Timeslots</CDBSidebarMenuItem></Link></div>
                                            <div style={{ height: 40 }} onClick={() => {dashclick("Other")}}><Link to="/hospitalmanageusers"><CDBSidebarMenuItem icon="circle-small" iconSize="sm" textFontSize="12px" >Manage Users</CDBSidebarMenuItem></Link></div>
                                            <div style={{ height: 40 }} onClick={() => {dashclick("Other")}}><Link to="/hospitaltimings"><CDBSidebarMenuItem icon="circle-small" iconSize="sm" textFontSize="12px" >Hospital Timings</CDBSidebarMenuItem></Link></div>
                                            <div style={{ height: 40 }} onClick={() => {dashclick("Other")}}><Link to="/hospitalholidays"><CDBSidebarMenuItem icon="circle-small" iconSize="sm" textFontSize="12px" >Hospital Holidays</CDBSidebarMenuItem></Link></div>
                                        </CDBListGroup>
                                        :
                                        <div></div>
                                }

                            </CDBSidebarMenu>
                        </CDBSidebarContent>
                    </CDBSidebar>
                </div>
                :
                <div></div>
            }

            {frontend ?
                <div style={{ display: 'flex', height: '100vh', overflow: 'scroll initial' }}>
                    <CDBSidebar textColor="#808080" backgroundColor="#fff" toggled={sidebarStat} >
                        <CDBSidebarHeader prefix={<i className="fa fa-bars" />}>
                            <div style={{ padding: 10 }}>
                                <Link to="/hospitaldashboard"><Image src={MyImage} className="d-inline-block align-top" alt="check" height={60} /></Link>
                            </div>
                        </CDBSidebarHeader>
                        <CDBSidebarContent>
                            <CDBSidebarMenu>
                                <span style={{ fontWeight: "bold" }} onClick={() => {dashclick("Dashboard")}}><Link to="/hospitaldashboard"><CDBSidebarMenuItem icon="th-large" iconSize="sm" textFontSize="12px" >Dashboard</CDBSidebarMenuItem></Link></span>
                                <span style={{ fontWeight: "bold" }} onClick={() => {dashclick("Other")}}><Link to="/hospitalpatientrecords"><CDBSidebarMenuItem icon="file-alt" iconSize="sm" textFontSize="12px" >Patient Records</CDBSidebarMenuItem></Link></span>
                                <span style={{ fontWeight: "bold" }} onClick={() => {dashclick("Other")}}><Link to="/hospitalpatientappointments"><CDBSidebarMenuItem icon="calendar-alt" iconSize="sm" textFontSize="12px" >Appointments</CDBSidebarMenuItem></Link></span>
                                <span style={{ fontWeight: "bold" }} onClick={() => {dashclick("Other")}}><Link to="/hospitalpreviousappointments"><CDBSidebarMenuItem icon="history" iconSize="sm" textFontSize="12px" >Previous Appointments</CDBSidebarMenuItem></Link></span>
                            </CDBSidebarMenu>
                        </CDBSidebarContent>
                    </CDBSidebar>
                </div>
                :
                <div></div>
            }

            {medical ?
                <div style={{ display: 'flex', height: '100vh', overflow: 'scroll initial' }}>
                    <CDBSidebar textColor="#808080" backgroundColor="#fff" toggled={sidebarStat} >
                        <CDBSidebarHeader prefix={<i className="fa fa-bars" />}>
                            <div style={{ padding: 10 }}>
                                <Link to="/hospitaldashboard"><Image src={MyImage} className="d-inline-block align-top" alt="check" height={60} /></Link>
                            </div>
                        </CDBSidebarHeader>
                        <CDBSidebarContent>
                            <CDBSidebarMenu>
                                <span style={{ fontWeight: "bold" }} onClick={() => {dashclick("Dashboard")}}><Link to="/hospitaldashboard"><CDBSidebarMenuItem icon="th-large" iconSize="sm" textFontSize="12px" >Dashboard</CDBSidebarMenuItem></Link></span>
                                <span style={{ fontWeight: "bold" }} onClick={() => {dashclick("Other")}}><Link to="/hospitalpatientrecords"><CDBSidebarMenuItem icon="file-alt" iconSize="sm" textFontSize="12px" >Patient Records</CDBSidebarMenuItem></Link></span>
                                <span style={{ fontWeight: "bold" }} onClick={() => {dashclick("Other")}}><Link to="/hospitalpatientappointments"><CDBSidebarMenuItem icon="calendar-alt" iconSize="sm" textFontSize="12px" >Appointments</CDBSidebarMenuItem></Link></span>
                                <span style={{ fontWeight: "bold" }} onClick={() => {dashclick("Other")}}><Link to="/hospitalpreviousappointments"><CDBSidebarMenuItem icon="history" iconSize="sm" textFontSize="12px" >Previous Appointments</CDBSidebarMenuItem></Link></span>
                                <span style={{ fontWeight: "bold" }} onClick={() => {dashclick("Other")}}><Link to="/hospitalpatientcases"><CDBSidebarMenuItem icon="file-alt" iconSize="sm" textFontSize="12px" >Cases</CDBSidebarMenuItem></Link></span>
                            </CDBSidebarMenu>
                        </CDBSidebarContent>
                    </CDBSidebar>
                </div>
                :
                <div></div>
            }

            {account ?
                <div style={{ display: 'flex', height: '100vh', overflow: 'scroll initial' }}>
                    <CDBSidebar textColor="#808080" backgroundColor="#fff" toggled={sidebarStat} >
                        <CDBSidebarHeader prefix={<i className="fa fa-bars" />}>
                            <div style={{ padding: 10 }}>
                                <Link to="/hospitaldashboard"><Image src={MyImage} className="d-inline-block align-top" alt="check" height={60} /></Link>
                            </div>
                        </CDBSidebarHeader>
                        <CDBSidebarContent>
                            <CDBSidebarMenu>
                                <span style={{ fontWeight: "bold" }} onClick={() => {dashclick("Dashboard")}}><Link to="/hospitaldashboard"><CDBSidebarMenuItem icon="th-large" iconSize="sm" textFontSize="12px" >Dashboard</CDBSidebarMenuItem></Link></span>
                                <span style={{ fontWeight: "bold" }} onClick={() => {dashclick("Other")}}><Link to="/hospitalpatientrecords"><CDBSidebarMenuItem icon="file-alt" iconSize="sm" textFontSize="12px" >Patient Records</CDBSidebarMenuItem></Link></span>
                            </CDBSidebarMenu>
                        </CDBSidebarContent>
                    </CDBSidebar>
                </div>
                :
                <div></div>
            }

        </div>
    );
};

export default HospitalSidebar;