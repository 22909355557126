import React, { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./admincss.css";
import HospitalSidebar from "../Navbar/HospitalSidebar";
import Adminheader from "../Navbar/Adminheader";
import { CDBCard, CDBCardBody, CDBDataTable } from 'cdbreact';
import { Link } from "react-router-dom";
import { Button, Spinner } from "react-bootstrap";
// import { API_BASE } from '../../setupProxy';

const HospitalPatientBillings = () => {

    const intOrganizationID = localStorage.getItem("orgid");

    const [patients, setPatients] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchPatients(intOrganizationID);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //FETCH ALL PATIENTS
    const fetchPatients = (orgid) => {
        fetch("https://e-arogya.com:8443/api/general/patients/list/" + orgid)
            .then(response => response.json())
            .then(data => {
                let dataFromApi = data.map(pat => {
                    return {
                        id: pat.intPatientID,
                        firstname: pat.strFirstName,
                        lastname: pat.strLastName,
                        mobile: pat.strmobileno,
                        dob: pat.dtmBirthDate,
                        gender: pat.strGender,
                        address: pat.straddress,
                        city: pat.intcityID,
                        cityname: pat.strcityname,
                        status: pat.strstatus
                    }
                })
                setPatients(dataFromApi);
                setLoading(true);
            })
            .catch(error => {
                //console.log(error);
            })
    }

    const data = () => {
        return {
            columns: [
                { label: 'Sr.No.', field: 'srno', },
                {
                    label: 'Name',
                    field: 'name',
                    attributes: {
                        'aria-controls': 'DataTable',
                        'aria-label': 'Name',
                    },
                },
                { label: 'Mobile', field: 'mobile', },
                { label: 'Date of Birth', field: 'dob', },
                { label: 'Gender', field: 'gender', },
                { label: 'Address', field: 'address', },
                { label: 'City', field: 'city', },
                { label: '', field: 'save', },

            ],
            rows: [...patients.map((data1, index) => (
                {
                    srno: index + 1,
                    name: data1.firstname + " " + data1.lastname,
                    mobile: data1.mobile,
                    dob: data1.dob,
                    gender: data1.gender,
                    address: data1.address,
                    city: data1.cityname,
                    save: <Link to="/hospitalpatientviewbill" state={{ patid: data1.id, patmobile: data1.mobile, patfname: data1.firstname, patlname: data1.lastname, patgender: data1.gender }}>
                        <Button variant="primary" type="submit" style={{ fontSize: 12, backgroundColor: "steelblue", borderColor: "steelblue" }} size="sm">
                            View Bill
                        </Button>
                    </Link>
                }
            ))

            ],
        };
    };

    return (

        <div className="main" >

            <HospitalSidebar />

            <div className="body">

                <Adminheader />

                <div className="page-content">

                    <h5>Patient Billings</h5>

                    <div className="table-content">

                        <CDBCard style={{ padding: 20, fontSize: 12, color: "grey" }}>
                            <CDBCardBody >
                                {!loading ? (
                                    <div style={{ textAlign: "center" }}><Spinner animation="border" size="sm" variant="primary" /></div>
                                ) :
                                    <CDBDataTable
                                        responsive
                                        noRecordsFoundLabel="No Records Found"
                                        noBottomColumn={true}
                                        hover
                                        entriesOptions={[10, 25, 50, 100, 200, 500]}
                                        entries={10}
                                        pagesAmount={4}
                                        data={data()}
                                    />
                                }
                            </CDBCardBody>
                        </CDBCard>

                    </div>

                </div>
            </div>
        </div>

    );
};

export default HospitalPatientBillings;
