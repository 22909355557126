import React from "react";
import BannerImage from "../images/banner-img.png";
import MyImage from "../images/img-1.png";
import PersonImage1 from "../images/img-7.png";
import PersonImage2 from "../images/img-8.png";
import PersonImage3 from "../images/img-9.png";
import { Button, Image, Row, Col, Card, Carousel, Container, CardGroup } from "react-bootstrap";
import { Search, Check2All, PencilSquare, Share } from 'react-bootstrap-icons';
import "./mycss.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../Navbar/Navbar";
import Footer from "../Navbar/Footer";
import { useNavigate } from "react-router-dom";

function Home() {

  const navigate = useNavigate();

  return (
    <div>
      <Navbar />

      <Container fluid>

        <Row>
          <Col sm={5}>
            <div style={{ textAlign: "center", marginTop: 100 }}>
              <div>
                <p style={{ fontSize: 60, fontWeight: 900, }}>AROGYA</p>
                <p style={{ fontSize: 60, fontWeight: 900, }}>SERVICES</p>
              </div>
              <div>
                <p style={{ color: "grey" }}>Get your health related services on click!</p>
              </div>
              <div>
                <Button variant="outline-danger" href="https://play.google.com/store/apps/details?id=com.csitpark.ehealth&hl=en&gl=US" target="_blank" size="lg" style={{ marginRight: 20 }}>Download</Button>
                <Button variant="outline-primary" href="#" size="lg" onClick={() => {navigate("/contactus")}}>Contact Us</Button>
              </div>
            </div>
          </Col>
          <Col sm={7}>
            <div style={{ textAlign: "center", marginTop: 20 }}>
              <Image src={BannerImage} alt="check" width={800} height={800} className="img-fluid" />
            </div>
          </Col>
        </Row>

        <div style={{ marginTop: 100 }} className='container'>

          <Row>
            <Col sm={7}>
              <div>
                <div style={{ background: "#fd5252", width: 70, height: 8, borderColor: "#fd5252", borderRadius: 10 }} />
                <p style={{ fontSize: 35, fontWeight: 700 }}>WELCOME TO E-AROGYA SERVICES</p>

              </div>
              <div>
                <p style={{ color: "grey", marginBottom: 40, }}>Get information related to your health services. Book appointments, search hospitals, doctors, laboratories, pharmacies and many more services are available.</p>
              </div>
              <div>
                <Button variant="outline-info" href="#" size="lg" onClick={() => {navigate("/about")}} >Read More</Button>
              </div>
            </Col>
            <Col sm={5}>
              <div>
                <Image src={MyImage} alt="check" className="img-fluid" />
              </div>

            </Col>
          </Row>

          <Row>
            <Col className="text-center justify-content-center">
              <div style={{ marginTop: 100, textAlign: "center" }}>
                <div className="about-border"></div>
                <p style={{ fontSize: 35, fontWeight: 700 }}>WHAT WE DO</p>
              </div>
              <div>
                <p style={{ color: "grey" }}>We are bridge between patient and service providers. We find the best services for you.</p>
              </div>
            </Col>
          </Row>


          <CardGroup>


            <Card className="custom-class" style={{ margin: 10, borderRadius: 10 }}>
              <Card.Body>
                <Search color="white" size={60} style={{ marginTop: 30, marginBottom: 30 }} />
                <Card.Title style={{ color: "#fff" }}>SEARCH</Card.Title>
                <Card.Text style={{ color: "#fff", fontWeight: 100 }}>Search health services in your city, area or at desired locations.</Card.Text>
                <Button variant="outline-light" href="#" style={{ marginTop: 10, marginBottom: 10 }}>Read More</Button>
              </Card.Body>
            </Card>

            <Card className="custom-class" style={{ margin: 10, borderRadius: 10 }}>
              <Card.Body>
                <Check2All color="white" size={60} style={{ marginTop: 30, marginBottom: 30 }} />
                <Card.Title style={{ color: "#fff" }}>BOOKING</Card.Title>
                <Card.Text style={{ color: "#fff", fontWeight: 100 }}>Book your self to avail the various services online in easy way.</Card.Text>
                <Button variant="outline-light" href="#" style={{ marginTop: 10, marginBottom: 10 }}>Read More</Button>
              </Card.Body>
            </Card>

            <Card className="custom-class" style={{ margin: 10, borderRadius: 10 }}>
              <Card.Body>
                <Share color="white" size={60} style={{ marginTop: 30, marginBottom: 30 }} />
                <Card.Title style={{ color: "#fff" }}>SHARE</Card.Title>
                <Card.Text style={{ color: "#fff", fontWeight: 100 }}>Share your health records to authenticated persons in Securly.</Card.Text>
                <Button variant="outline-light" href="#" style={{ marginTop: 10, marginBottom: 10 }}>Read More</Button>
              </Card.Body>
            </Card>

            <Card className="custom-class" style={{ margin: 10, borderRadius: 10 }}>
              <Card.Body>
                <PencilSquare color="white" size={60} style={{ marginTop: 30, marginBottom: 30 }} />
                <Card.Title style={{ color: "#fff" }}>MANAGE</Card.Title>
                <Card.Text style={{ color: "#fff", fontWeight: 100 }}>Manage patient records in secure way and generate daily reports.</Card.Text>
                <Button variant="outline-light" href="#" style={{ marginTop: 10, marginBottom: 10 }}>Read More</Button>
              </Card.Body>
            </Card>


          </CardGroup>

          <Row>
            <Col className="text-center">
              <div style={{ marginTop: 100 }}>
                <div className="about-border"></div>
                <p style={{ fontSize: 35, fontWeight: 700 }}>WHAT OUR USER SAYS</p>
              </div>
            </Col>
          </Row>

          <Row>

            <Col className="text-center">
              <Carousel variant="dark" >
                <Carousel.Item>
                  <Image src={PersonImage1} alt="check" width={200} height={200} className="img-fluid" style={{ marginBottom: 250 }} />
                  <Carousel.Caption>
                    <h5>Ketan Patel</h5>
                    <p style={{ color: "grey" }}>Nice App. You can get all hospital, laboratories and pharmacies info near you.</p>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <Image src={PersonImage2} alt="check" width={200} height={200} style={{ marginBottom: 250 }} />

                  <Carousel.Caption>
                    <h5>Sagar Mehta</h5>
                    <p style={{ color: "grey" }}>Wonderful app, very good service.</p>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <Image src={PersonImage3} alt="check" width={200} height={200} style={{ marginBottom: 250 }} />
                  <Carousel.Caption>
                    <h5>Viraj Shah</h5>
                    <p style={{ color: "grey" }}>Highly recommended. Nice services.</p>
                  </Carousel.Caption>
                </Carousel.Item>
              </Carousel>
            </Col>
            
          </Row>

        </div>

      </Container>
      <Footer />
    </div>
  );
}

export default Home;