import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Row, Col, Container, Form } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../Navbar/Navbar";
import Footer from "../Navbar/Footer";
import { Asterisk } from "react-bootstrap-icons";
import moment from 'moment';

function EnterOtp() {

  const [strOtp, setStrOtp] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const [timer, setTimer] = useState();

  let { state } = useLocation();
  const mobileno = state.mobileno;

  useEffect(() => {

    generateRandom6DigitOtp();

  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const generateRandom6DigitOtp = () => {

    let gOtp = '';
    const characters = '0123456789';

    for (let i = 0; i < 6; i++) {
      gOtp += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    saveOtp(gOtp);

    console.log('Your Otp is: ' + gOtp);
  }

  const saveOtp = (genOTP) => {

    const gendatetime = moment().format('YYYY-MM-DD HH:mm:ss');
    const validdatetime = moment().add(1, 'minutes').format('YYYY-MM-DD HH:mm:ss');

    if (gendatetime && validdatetime) {
      const formData = new FormData();

      formData.append('strotp', genOTP);
      formData.append('strotpfor', "Forgot Password - Web");
      formData.append('dtmgenerateddatetime', gendatetime);
      formData.append('dtmvaliduptodatetime', validdatetime);
      formData.append('strstatus', "Active");
      saveOtpToDatabase(formData);
    }

  }

  const saveOtpToDatabase = async (formData) => {
    const requestOptions = {
      method: 'POST',
      body: formData
    };

    await fetch('https://e-arogya.com:8443/api/general/patientapplication/saveotp', requestOptions)
      .then(async response => {
        const isJson = response.headers.get('content-type')?.includes('application/json');
        const data = isJson && await response.json();

        if (!response.ok) {
          const error = (data?.message) || response.status;
          return Promise.reject("Promise-reject Error Occured: " + error);
        }
        if (data) {

          let countDownDate = moment(data[0].dtmvaliduptodatetime);
          let x = setInterval(function () {
            let diff = countDownDate.diff(moment());

            if (diff <= 0) {
              setStrOtp("");
              clearInterval(x);
              setErrorMessage(<span style={{ color: "red" }}>Otp Expired. Please Click Resend for New Otp</span>);
              setTimeout(() => setErrorMessage(""), 3000);
            }
            else {
              setTimer(moment.utc(diff).format("mm:ss"));
            }

          }, 1000);
        }
      })
      .catch(error => {
        setErrorMessage(<span style={{ color: "red" }}>Error Occured. Please try again.</span>);
        setTimeout(() => setErrorMessage(""), 3000);
      });

  }

  const verifyOtp = async () => {

    if (strOtp === "" || strOtp.length !== 6 || isNaN(strOtp)) {
      setErrorMessage(<span style={{ color: "red" }}>Please enter a valid 6 digit otp</span>);
      setTimeout(() => setErrorMessage(""), 3000);
    } else {

      const currdatetime = moment().format('YYYY-MM-DD HH:mm:ss');

      const formData = new FormData();

      formData.append('strotp', strOtp);
      formData.append('strotpfor', "Forgot Password - Web");
      formData.append('dtmgenerateddatetime', currdatetime);

      const requestOptions = {
        method: 'POST',
        body: formData
      };

      await fetch('https://e-arogya.com:8443/api/general/patientapplication/checkotp', requestOptions)
        .then(async response => {
          const isJson = response.headers.get('content-type')?.includes('application/json');
          const data = isJson && await response.json();
          if (!response.ok) {
            const error = (data?.message) || response.status;
            return Promise.reject("Promise-reject Error Occured: " + error);
          }

          if (data) {
            navigate("/resetpassword", { state: { mobileno: mobileno } });
          } else {
            setErrorMessage(<span style={{ color: "red" }}>Incorrect code. Please try again.</span>);
            setTimeout(() => setErrorMessage(""), 3000);
            setStrOtp("");
          }

        })
        .catch(error => {
          setErrorMessage(<span style={{ color: "red" }}>Error Occured. Please try again.</span>);
          setTimeout(() => setErrorMessage(""), 3000);
        });

    }

  }


  return (

    <div>
      <Navbar />

      <Container fluid>

        <div style={{ marginTop: 100, marginBottom: 50 }} className='container'>

          <Row>
            <Col className="text-center justify-content-center">
              <div style={{ textAlign: "center" }}>
                <div className="about-border"></div>
                <p style={{ fontSize: 35, fontWeight: 700 }}>ENTER OTP</p>
              </div>
              <div>
                <p style={{ color: "grey" }}>Please enter the otp recieved in your mobile number</p>
              </div>
            </Col>
          </Row>

          <Row className="py-5 justify-content-center">

            <Col md={4}>

              <Form>

                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label><Asterisk size={6} style={{ marginBottom: 8, marginRight: 5, color: "red" }} />OTP</Form.Label>
                  <Form.Control type="text" value={strOtp || ""} onChange={e => { setStrOtp(e.target.value) }} required />
                </Form.Group>

              </Form>

              <div style={{ fontSize: 12, fontWeight: "bold", marginBottom: 10 }}>{errorMessage}</div>

              <Row className="align-items-center">
                <Col md={6} className="justify-content-md-center ">
                  <p style={{ marginTop: 20, marginBottom: 20, textAlign: 'center', flex: 1, color: "#000" }}>{timer === "00:00" ? null : timer}</p>

                </Col>
                <Col md={6}>
                  {timer === "00:00" ?
                    <Button style={{ marginTop: 20, marginBottom: 20, backgroundColor: "#fff", borderColor: "#fd5252", width: "100%", color: "#fd5252" }} onClick={() => generateRandom6DigitOtp()}>Resend</Button>
                    :
                    <Button style={{ marginTop: 20, marginBottom: 20, backgroundColor: "#fd5252", borderColor: "#fd5252", width: "100%", color: "#fff" }} onClick={verifyOtp}>Verify</Button>
                  }
                </Col>
              </Row>

            </Col>

          </Row>

        </div>

      </Container>

      <Footer />

    </div>

  );
}

export default EnterOtp;