import React from "react";
import { Row, Col, Card, Container, CardGroup } from "react-bootstrap";
import { Hospital, CapsulePill, Clipboard2Pulse } from 'react-bootstrap-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../Navbar/Navbar";
import Footer from "../Navbar/Footer";
import { Link } from "react-router-dom";

function SelectOrganizationType() {
  return (

    <div>
      <Navbar />

      <Container fluid>

        <div style={{ marginTop: 100, marginBottom: 200 }} className='container'>

          <Row>
            <Col className="text-center justify-content-center">
              <div style={{ textAlign: "center" }}>
                <div className="about-border"></div>
                <p style={{ fontSize: 35, fontWeight: 700, marginBottom: 50 }}>I want to Register</p>
              </div>
            </Col>
          </Row>

          <CardGroup>

            <Card className="custom-class" style={{ margin: 30, borderRadius: 10 }}>
              <Link to="/regiorg" state={{ type: "Hospital" }} style={{ textDecoration: 'none' }}  >
                <Card.Body>
                  <Hospital color="white" size={60} style={{ marginTop: 10, marginBottom: 20 }} />
                  <Card.Title style={{ color: "#fff" }}>HOSPITAL</Card.Title>
                </Card.Body>
              </Link>
            </Card>

            <Card className="custom-class" style={{ margin: 30, borderRadius: 10 }}>
              <Link to="/regiorg" state={{ type: "Laboratory" }} style={{ textDecoration: 'none' }}>
                <Card.Body>
                  <Clipboard2Pulse color="white" size={60} style={{ marginTop: 10, marginBottom: 20 }} />
                  <Card.Title style={{ color: "#fff" }}>LABORATORY</Card.Title>
                </Card.Body>
              </Link>
            </Card>

            <Card className="custom-class" style={{ margin: 30, borderRadius: 10 }}>
              <Link to="/regiorg" state={{ type: "Pharmacy" }} style={{ textDecoration: 'none' }}>
                <Card.Body>
                  <CapsulePill color="white" size={60} style={{ marginTop: 10, marginBottom: 20 }} />
                  <Card.Title style={{ color: "#fff" }}>PHARMACY</Card.Title>
                </Card.Body>
              </Link>
            </Card>


          </CardGroup>

        </div>

      </Container>

      <Footer />
    </div>

  );
}

export default SelectOrganizationType;