import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./admincss.css";
import Sidebar from "../Navbar/Sidebar";
import Adminheader from "../Navbar/Adminheader";
import { Row, Col, Container, Card, CardGroup, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
// import { API_BASE } from '../../setupProxy';
import { Chart as ChartJS, CategoryScale, BarElement, LinearScale, Tooltip, Legend, } from 'chart.js';
import { Bar } from 'react-chartjs-2';
ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

function SuperAdminDashboard() {

    const navigate = useNavigate();
    const goToUsers = () => { navigate('/manageusers') }
    const goToHospital = () => { navigate('/managehospitals'); }
    const goToLaboratory = () => { navigate('/managelaboratories'); }
    const goToPharmacy = () => { navigate('/managepharmacies'); }

    const [totalUsers, setTotalUsers] = useState(0);
    const [totalHospitals, setTotalHospitals] = useState(0);
    const [totalLaboratory, setTotalLaboratory] = useState(0);
    const [totalPharmacy, setTotalPharmacy] = useState(0);

    const [usertotalMonthlyCount, setUserTotalMonthlyCount] = useState([]);
    const [hosptotalMonthlyCount, setHospTotalMonthlyCount] = useState([]);
    const [labtotalMonthlyCount, setLabTotalMonthlyCount] = useState([]);
    const [pharmtotalMonthlyCount, setPharmTotalMonthlyCount] = useState([]);
    const [loginYears, setLoginYears] = useState([]);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

    const userdata = {
        labels: usertotalMonthlyCount.map(c => c.userCountMonth),
        datasets: [
            {
                label: 'Users',
                data: usertotalMonthlyCount.map(c => c.userMonthlyCount),
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
        ],
    };
    const useroptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Chart.js Bar Chart',
            },
        },
    };

    const hospitaldata = {
        labels: hosptotalMonthlyCount.map(c => c.hospCountMonth),
        datasets: [
            {
                label: 'Hospitals',
                data: hosptotalMonthlyCount.map(c => c.hospMonthlyCount),
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
        ],
    };
    const hospitaloptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Chart.js Bar Chart',
            },
        },
    };

    const laboratorydata = {
        labels: labtotalMonthlyCount.map(c => c.labCountMonth),
        datasets: [
            {
                label: 'Laboratories',
                data: labtotalMonthlyCount.map(c => c.labMonthlyCount),
                backgroundColor: 'rgba(102, 255, 51, 0.5)',
            },
        ],
    };
    const laboratoryoptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Chart.js Bar Chart',
            },
        },
    };

    const pharmacydata = {
        labels: pharmtotalMonthlyCount.map(c => c.pharmCountMonth),
        datasets: [
            {
                label: 'Pharmacies',
                data: pharmtotalMonthlyCount.map(c => c.pharmMonthlyCount),
                backgroundColor: 'rgba(255, 153, 51, 0.5)',
            },
        ],
    };
    const pharmacyoptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Chart.js Bar Chart',
            },
        },
    };

    useEffect(() => {
        getSuperadminDashboardData();
        fetchUserCountMonthly(selectedYear);
        fetchHospitalCountMonthly(selectedYear);
        fetchLaboratoryCountMonthly(selectedYear);
        fetchPharmacyCountMonthly(selectedYear);
        fetchYear();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //FETCH ALL TOTAL DATA
    const getSuperadminDashboardData = () => {
        fetch("https://e-arogya.com:8443/api/general/superadmindashboard")
            .then(response => response.json())
            .then(data => {
                setTotalUsers(data[0].totalUsers);
                setTotalHospitals(data[0].totalHospitals);
                setTotalLaboratory(data[0].totalLaboratory);
                setTotalPharmacy(data[0].totalPharmacy);
            })
            .catch(error => {
                //console.log(error);
            })
    }
    const fetchUserCountMonthly = (yr) => {
        fetch("https://e-arogya.com:8443/api/general/user/usercountmonthly/" + yr)
            .then(response => response.json())
            .then(data => {
                setUserTotalMonthlyCount(data);
            })
            .catch(error => {
                //console.log(error);
            })
    }
    const fetchHospitalCountMonthly = (yr) => {
        fetch("https://e-arogya.com:8443/api/general/hospital/hospcountmonthly/Hospital/" + yr)
            .then(response => response.json())
            .then(data => {
                setHospTotalMonthlyCount(data);
            })
            .catch(error => {
                //console.log(error);
            })
    }
    const fetchLaboratoryCountMonthly = (yr) => {
        fetch("https://e-arogya.com:8443/api/general/laboratory/labcountmonthly/Laboratory/" + yr)
            .then(response => response.json())
            .then(data => {
                setLabTotalMonthlyCount(data);
            })
            .catch(error => {
                //console.log(error);
            })
    }
    const fetchPharmacyCountMonthly = (yr) => {
        fetch("https://e-arogya.com:8443/api/general/pharmacy/pharmcountmonthly/Pharmacy/" + yr)
            .then(response => response.json())
            .then(data => {
                setPharmTotalMonthlyCount(data);
            })
            .catch(error => {
                //console.log(error);
            })
    }

    const fetchYear = () => {
        fetch("https://e-arogya.com:8443/api/general/user/logincountyear")
            .then(response => response.json())
            .then(data => {
                setLoginYears(data);
                //console.log("data: " + JSON.stringify(data));
                //console.log("" + loginYears);
            })
            .catch(error => {
                //console.log(error);
            })
    }

    return (

        <div className="main" >

            <Sidebar />

            <div className="page-content">

                <Adminheader />

                <h5>Welcome</h5>

                <CardGroup>

                    <Container fluid>
                        <Row>
                            <Col md>
                                <Card style={{ margin: 10, borderRadius: 10, }} className="cardclass" onClick={goToUsers}>
                                    <Card.Body>
                                        <Card.Text style={{ fontSize: 12, color: "grey" }}> Users</Card.Text>
                                        <Card.Title style={{ paddingLeft: 10 }}>{totalUsers}</Card.Title>
                                        {/* <Card.Text style={{ fontSize: 12, color: "grey" }}>+18.9% Last week</Card.Text> */}
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md>
                                <Card style={{ margin: 10, borderRadius: 10 }} className="cardclass" onClick={goToHospital}>
                                    <Card.Body>
                                        <Card.Text style={{ fontSize: 12, color: "grey" }}>Hospitals</Card.Text>
                                        <Card.Title style={{ paddingLeft: 10 }}>{totalHospitals}</Card.Title>
                                        {/* <Card.Text style={{ fontSize: 12, color: "grey" }}>+1.9% Last week</Card.Text> */}
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md>
                                <Card style={{ margin: 10, borderRadius: 10 }} className="cardclass" onClick={goToLaboratory}>
                                    <Card.Body>
                                        <Card.Text style={{ fontSize: 12, color: "grey" }}>Laboratories</Card.Text>
                                        <Card.Title style={{ paddingLeft: 10 }}>{totalLaboratory}</Card.Title>
                                        {/* <Card.Text style={{ fontSize: 12, color: "grey" }}>+37.7% Last week</Card.Text> */}
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md>
                                <Card style={{ margin: 10, borderRadius: 10 }} className="cardclass" onClick={goToPharmacy}>
                                    <Card.Body>
                                        <Card.Text style={{ fontSize: 12, color: "grey" }}>Pharmacy</Card.Text>
                                        <Card.Title style={{ paddingLeft: 10 }}>{totalPharmacy}</Card.Title>
                                        {/* <Card.Text style={{ fontSize: 12, color: "grey" }}>+37.7% Last week</Card.Text> */}
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col md>
                                <div style={{ margin: 10 }}>
                                    <Bar
                                        data={userdata}
                                        options={useroptions}
                                    />
                                </div>
                            </Col>
                            <Col md>
                                <div style={{ margin: 10 }}>
                                    <Bar
                                        data={hospitaldata}
                                        options={hospitaloptions}
                                    />
                                </div>
                            </Col>
                            <Col md>
                                <div style={{ margin: 10 }}>
                                    <Bar
                                        data={laboratorydata}
                                        options={laboratoryoptions}
                                    />
                                </div>
                            </Col>
                            <Col md>
                                <div style={{ margin: 10 }}>
                                    <Bar
                                        data={pharmacydata}
                                        options={pharmacyoptions}
                                    />
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col md>
                                <Form.Group className="mb-3" controlId="formSelectStateStatus">
                                    <Form.Select aria-label="Default select example"
                                        style={{ fontSize: 12 }}
                                        value={selectedYear || ""}
                                        onChange={e => {
                                            setSelectedYear(e.target.value);
                                            fetchUserCountMonthly(e.target.value);
                                            fetchHospitalCountMonthly(e.target.value);
                                            fetchLaboratoryCountMonthly(e.target.value);
                                            fetchPharmacyCountMonthly(e.target.value);
                                        }}
                                        required
                                    >
                                        {loginYears.map((data, key) => <option key={data.loginyear} value={data.loginyear} >{data.loginyear}</option>)}
                                        {/* <option value="2023">2023</option>
                                        <option value="2022">2022</option> */}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col md></Col>
                            <Col md></Col>
                            <Col md></Col>
                        </Row>

                    </Container>

                </CardGroup>

            </div>

        </div>
    );
}

export default SuperAdminDashboard;