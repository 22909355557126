import React, { useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./admincss.css";
import HospitalSidebar from "../Navbar/HospitalSidebar";
import Adminheader from "../Navbar/Adminheader";
import { CDBCard, CDBCardBody, CDBDataTable } from 'cdbreact';
import { Form, Col, Row, Button } from "react-bootstrap";
// import { API_BASE } from '../../setupProxy';
import { Asterisk } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import * as moment from 'moment';

const HospitalPreviousAppointments = () => {

    const intOrganizationID = localStorage.getItem("orgid");
    //const intLoginID = localStorage.getItem("loggedInId");
    const roleName = localStorage.getItem("loggedRoleName");

    const navigate = useNavigate();

    const [appointments, setAppointments] = useState([]);
    const [dtmAppointmentFromDate, setDtmAppointmentFromDate] = useState("");
    const [dtmAppointmentToDate, setDtmAppointmentToDate] = useState("");

    const [message, setMessage] = useState("");

    const searchPreviousAppointmentsFormSubmit = () => {

        if (dtmAppointmentFromDate === "") {
            setMessage(<span style={{ color: "red" }}>Please Select Appointment From Date</span>);
            setTimeout(() => setMessage(""), 3000);
        } else if (dtmAppointmentToDate === "") {
            setMessage(<span style={{ color: "red" }}>Please Select Appointment To Date</span>);
            setTimeout(() => setMessage(""), 3000);
        } else {
            searchAppointment(intOrganizationID, dtmAppointmentFromDate, dtmAppointmentToDate);
        }

    }

    const searchAppointment = (orgid, fromdate, todate) => {
        fetch("https://e-arogya.com:8443/api/general/appointments/prevapplist/" + orgid + "/" + fromdate + "/" + todate)
            .then(response => response.json())
            .then(data => {
                let dataFromApi = data.map(app => {
                    return {
                        id: app.intAppointmentId,
                        firstname: app.strFirstName,
                        lastname: app.strLastName,
                        gender: app.strGender,
                        mobile: app.strmobileno,
                        apptime: app.strFromTime,
                        appdate: app.dtmAppointmentDate,
                        department: app.strSpecialityName,
                        visittype: app.strVisitType,
                        status: app.strstatus,
                        patid: app.intPatientID,
                        appcreateDate: app.dtmAppointmentDate,
                    }
                })
                setAppointments(dataFromApi);
            })
            .catch(error => {
                //console.log(error);
            })
    }

    // const clearFields = () => {
    //     setDtmAppointmentFromDate("");
    //     setDtmAppointmentToDate("");
    // }


    const data = () => {
        return {
            columns: [
                { label: 'Id', field: 'srno', },
                {
                    label: 'Name',
                    field: 'name',
                    attributes: {
                        'aria-controls': 'DataTable',
                        'aria-label': 'Name',
                    },
                },
                { label: 'Mobile', field: 'mobile', },
                { label: 'Gender', field: 'gender', },
                { label: 'Date', field: 'appdate', },
                { label: 'Time', field: 'apptime', },
                // { label: 'Department', field: 'department', },
                { label: 'Visit Type', field: 'visittype', },
                // { label: '', field: 'edit', },
                // { label: '', field: 'delete', },
                // { label: '', field: 'generateCase', },
                { label: '', field: 'getprescription', },
            ],
            rows: [...appointments.map((data1, index) => (
                {
                    srno: index + 1,
                    name: data1.firstname + " " + data1.lastname,
                    mobile: data1.mobile,
                    gender: data1.gender,
                    apptime: data1.apptime,
                    appdate: moment(data1.appdate).format('DD-MMM-YYYY'),
                    // department: data1.department,
                    visittype: data1.visittype,
                    // edit: <span onClick={(e) => { handleEditClick(e, data1.id); }}> <Button variant="primary" type="submit" style={{ fontSize: 12 }} size="sm"> Edit </Button> </span>,
                    // delete: <span onClick={(e) => handleDeleteClick(e, data1.id)}> <Button variant="danger" type="submit" style={{ fontSize: 12 }} size="sm"> Delete </Button> </span>,
                    // generateCase: <div>
                    //     {roleName === "Medical" || roleName === "Hospital Admin" ?
                    //         <span onClick={(e) => { handleGenerateCaseClick(e, data1.id, data1.patid); }}>
                    //             <Button variant="primary" type="submit" style={{ fontSize: 12, backgroundColor: "steelblue", borderColor: "steelblue" }} size="sm"> Generate Case </Button>
                    //         </span>
                    //         :
                    //         <div></div>
                    //     }
                    // </div>
                    getprescription: <div>
                    {roleName === "Medical" || roleName === "Hospital Admin" || roleName === 'Frontend' ?
                        <span onClick={(e) => { navigate('/hospitalgenerateprescription', { state: { appid: data1.id, patid: data1.patid } }); }}>
                            <Button variant="primary" type="submit" style={{ fontSize: 12, backgroundColor: "#00626D", borderColor: "#00626D" }} size="sm">Get Prescription</Button>
                        </span>
                        :
                        <div></div>
                    }
                    </div>
                }
            ))

            ],
        };
    };


    return (

        <div className="main" >

            <HospitalSidebar />

            <div className="page-content">

                <Adminheader />

                <h5>Previous Appointments</h5>

                <div className="table-content">

                    <div style={{ fontSize: 12, fontWeight: "bold", marginBottom: 10 }}>{message}</div>

                    <Row style={{ fontSize: 12, color: "grey" }}>

                        <Col md={2}>
                            <Form.Group className="mb-3" controlId="formEnterState">
                                <Form.Label><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />From Date</Form.Label>
                                <Form.Control type="date" style={{ fontSize: 12 }} value={dtmAppointmentFromDate || ""} onChange={e => { setDtmAppointmentFromDate(e.target.value) }} required />
                            </Form.Group>
                        </Col>

                        <Col md={2}>
                            <Form.Group className="mb-3" controlId="formEnterState">
                                <Form.Label><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />To date</Form.Label>
                                <Form.Control type="date" style={{ fontSize: 12 }} value={dtmAppointmentToDate || ""} onChange={e => { setDtmAppointmentToDate(e.target.value) }} required />
                            </Form.Group>
                        </Col>

                        <Col md={2} >
                            <Form.Group className="mb-3" controlId="formEnterState">
                                <Button variant="primary" style={{ marginTop: 25, fontSize: 12 }} onClick={searchPreviousAppointmentsFormSubmit}> Search </Button>
                            </Form.Group>
                        </Col>

                    </Row>

                    <CDBCard style={{ padding: 20, fontSize: 12, color: "grey" }}>
                        <CDBCardBody>
                            <CDBDataTable
                                responsive
                                noRecordsFoundLabel="No Records Found"
                                noBottomColumn={true}
                                hover
                                entriesOptions={[10, 25, 50, 100, 200, 500]}
                                entries={10}
                                pagesAmount={4}
                                data={data()}
                            />
                        </CDBCardBody>
                    </CDBCard>

                </div>

            </div>
        </div>

    );
};

export default HospitalPreviousAppointments;