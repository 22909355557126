import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../src/components/pages/Home";
import About from "./components/pages/About";
import Services from "./components/pages/Services";
import Contactus from "./components/pages/Contactus";
import PrivacyPolicy from "./components/pages/PrivacyPolicy";
import Login from "./components/pages/Login";
import EnterOtp from "./components/pages/EnterOtp";
import Register from "./components/pages/Register";
import RegisterOrganization from "./components/pages/RegisterOrganization";
import RegiOrg from "./components/pages/RegiOrg";
import RegiOrgAddress from "./components/pages/RegiOrgAddress";
import RegiOrgLogin from "./components/pages/RegiOrgLogin";
import SelectOrganizationType from "./components/pages/SelectOrganizationType";
import Temp from "./components/pages/Temp";

import ManageFileUpload from "./components/pages/ManageFileUpload";

import OrganizationEditProfile from "./components/pages/OrganizationEditProfile";

import SuperAdminDashboard from "./components/pages/SuperAdminDashboard";
import ManageStates from "./components/pages/ManageStates";
import ManageCities from "./components/pages/ManageCities";
import ManageHospitals from "./components/pages/ManageHospitals";
import ManageLaboratories from "./components/pages/ManageLaboratories";
import ManagePharmacies from "./components/pages/ManagePharmacies";
import ManageUsers from "./components/pages/ManageUsers";
import ManageRoleMaster from "./components/pages/ManageRoleMaster";
import ManagePermissionMaster from "./components/pages/ManagePermissionMaster";
import ManageSpecialities from "./components/pages/ManageSpecialities";
import ManageHospitalServices from "./components/pages/ManageHospitalServices";
import ManageReports from "./components/pages/ManageReports";
import ManageSubReports from "./components/pages/ManageSubReports";
import ManageRelationMaster from "./components/pages/ManageRelationMaster";

import HospitalDashboard from "./components/pages/HospitalDashboard";
import HospitalServices from "./components/pages/HospitalServices";
import HospitalSpecialities from "./components/pages/HospitalSpecialities";
import HospitalDoctors from "./components/pages/HospitalDoctors";
import HospitalTimeslots from "./components/pages/HospitalTimeslots";
import HospitalPatientRecords from "./components/pages/HospitalPatientRecords";
import HospitalPatientAppointments from "./components/pages/HospitalPatientAppointments";
import HospitalPreviousAppointments from "./components/pages/HospitalPreviousAppointments";
import HospitalPatientBillings from "./components/pages/HospitalPatientBillings";
import HospitalManageUsers from "./components/pages/HospitalManageUsers";
import HospitalRegisterNewHospital from "./components/pages/HospitalRegisterNewHospital";
import HospitalTimings from "./components/pages/HospitalTimings";
import HospitalHolidays from "./components/pages/HospitalHolidays";
import HospitalPatientViewBill from "./components/pages/HospitalPatientViewBill";
import HospitalPatientGenerateCase from "./components/pages/HospitalPatientGenerateCase";
import HospitalPatientCases from "./components/pages/HospitalPatientCases";
import HospitalGeneratePrescription from "./components/pages/HospitalGeneratePrescription";
import HospitalGetPatientHistory from "./components/pages/HospitalGetPatientHistory";

import LaboratoryDashboard from "./components/pages/LaboratoryDashboard";
import LaboratoryManageReports from "./components/pages/LaboratoryManageReports";
import LaboratoryViewAppointments from "./components/pages/LaboratoryViewAppointments";
import LaboratoryTimings from "./components/pages/LaboratoryTimings";

import PharmacyDashboard from "./components/pages/PharmacyDashboard";
import PharmacyTimings from "./components/pages/PharmacyTimings";
import PharmacyManagePrescriptions from "./components/pages/PharmacyManagePrescriptions";

import OperatorDashboard from "./components/pages/OperatorDashboard";
import OperatorEnterOrganizationData from "./components/pages/OperatorEnterOrganizationData";

import PrivateRoute from "./components/Navbar/PrivateRoute";
import ProtectedRoute from "./components/Navbar/ProtectedRoute";
import OperatorUploadOrganizationData from "./components/pages/OperatorUploadOrganizationData";
import PatientSharableQrCode from "./components/pages/PatientSharableQrCode";
import ForgotPassword from "./components/pages/ForgotPassword";
import ResetPassword from "./components/pages/ResetPassword";
import OrganizationChangePassword from "./components/pages/OrganizationChangePassword";

function App() {

  return (
    <div>
      <BrowserRouter>

        <Routes>


          <Route path="/" element={<Home />} onChange />
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contactus" element={<Contactus />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/enterotp" element={<EnterOtp />} />
          <Route path="/resetpassword" element={<ResetPassword />} />

          <Route path="/register" element={<Register />} />
          <Route path="/registerorganization" element={<ProtectedRoute Component={RegisterOrganization} />} />
          <Route path="/regiorg" element={<RegiOrg />} />
          <Route path="/regiorgaddress" element={<RegiOrgAddress />} />
          <Route path="/regiorglogin" element={<RegiOrgLogin />} />
          <Route path="/selectorganizationtype" element={<SelectOrganizationType/>} />
          <Route path="/temp" element={<Temp />} />

          <Route path="/managefileUpload" element={<ProtectedRoute Component={ManageFileUpload} />} />

          <Route path="/organizationeditprofile" element={<PrivateRoute Component={OrganizationEditProfile} userRole={"Hospital Admin, Laboratory Admin, Pharmacy Admin"} />} />
          <Route path="/organizationchangepassword" element={<PrivateRoute Component={OrganizationChangePassword} userRole={"Super, Hospital Admin, Laboratory Admin, Pharmacy Admin"} />} />

          <Route path="/superadmindashboard" element={<PrivateRoute Component={SuperAdminDashboard} userRole={"Super"} />} />
          <Route path="/managestates" element={<PrivateRoute Component={ManageStates} userRole={"Super"} />} />
          <Route path="/managecities" element={<PrivateRoute Component={ManageCities} userRole={"Super"} />} />
          <Route path="/managehospitals" element={<PrivateRoute Component={ManageHospitals} userRole={"Super"} />} />
          <Route path="/managelaboratories" element={<PrivateRoute Component={ManageLaboratories} userRole={"Super"} />} />
          <Route path="/managepharmacies" element={<PrivateRoute Component={ManagePharmacies} userRole={"Super"} />} />
          <Route path="/manageusers" element={<PrivateRoute Component={ManageUsers} userRole={"Super"} />} />
          <Route path="/managerolemaster" element={<PrivateRoute Component={ManageRoleMaster} userRole={"Super"} />} />
          <Route path="/managepermissionmaster" element={<PrivateRoute Component={ManagePermissionMaster} userRole={"Super"} />} />
          <Route path="/managespecialities" element={<PrivateRoute Component={ManageSpecialities} userRole={"Super"} />} />
          <Route path="/managehospitalservices" element={<PrivateRoute Component={ManageHospitalServices} userRole={"Super"} />} />
          <Route path="/managereports" element={<PrivateRoute Component={ManageReports} userRole={"Super"} />} />
          <Route path="/managesubreports" element={<PrivateRoute Component={ManageSubReports} userRole={"Super"} />} />
          <Route path="/managerelationmaster" element={<PrivateRoute Component={ManageRelationMaster} userRole={"Super"} />} />

          <Route path="/hospitaldashboard" element={<PrivateRoute Component={HospitalDashboard} userRole={"Hospital Admin, Frontend, Medical, Account"} />} />
          <Route path="/hospitalservices" element={<PrivateRoute Component={HospitalServices} userRole={"Hospital Admin"} />} />
          <Route path="/hospitalspecialities" element={<PrivateRoute Component={HospitalSpecialities} userRole={"Hospital Admin"} />} />
          <Route path="/hospitaldoctors" element={<PrivateRoute Component={HospitalDoctors} userRole={"Hospital Admin"} />} />
          <Route path="/hospitaltimeslots" element={<PrivateRoute Component={HospitalTimeslots} userRole={"Hospital Admin"} />} />
          <Route path="/hospitalpatientrecords" element={<PrivateRoute Component={HospitalPatientRecords} userRole={"Hospital Admin, Frontend, Medical, Account"} />} />
          <Route path="/hospitalpatientappointments" element={<PrivateRoute Component={HospitalPatientAppointments} userRole={"Hospital Admin, Frontend, Medical, Account"} />} />
          <Route path="/hospitalpreviousappointments" element={<PrivateRoute Component={HospitalPreviousAppointments} userRole={"Hospital Admin, Frontend, Medical, Account"} />} />
          <Route path="/hospitalpatientbillings" element={<PrivateRoute Component={HospitalPatientBillings} userRole={"Hospital Admin, Account"} />} />
          <Route path="/hospitalmanageusers" element={<PrivateRoute Component={HospitalManageUsers} userRole={"Hospital Admin"} />} />
          <Route path="/hospitalregisternewhospital" element={<PrivateRoute Component={HospitalRegisterNewHospital} userRole={"Hospital Admin"} />} />
          <Route path="/hospitaltimings" element={<PrivateRoute Component={HospitalTimings} userRole={"Hospital Admin"} />} />
          <Route path="/hospitalholidays" element={<PrivateRoute Component={HospitalHolidays} userRole={"Hospital Admin"} />} />
          <Route path="/hospitalpatientviewbill" element={<PrivateRoute Component={HospitalPatientViewBill} userRole={"Hospital Admin, Account"} />} />
          <Route path="/hospitalpatientgeneratecase" element={<PrivateRoute Component={HospitalPatientGenerateCase} userRole={"Hospital Admin, Frontend, Medical"} />} />
          <Route path="/hospitalpatientcases" element={<PrivateRoute Component={HospitalPatientCases} userRole={"Hospital Admin, Medical"} />} />
          <Route path="/hospitalgenerateprescription" element={<PrivateRoute Component={HospitalGeneratePrescription} userRole={"Hospital Admin, Frontend, Medical"} />} />
          <Route path="/hospitalgetpatienthistory" element={<PrivateRoute Component={HospitalGetPatientHistory} userRole={"Hospital Admin, Frontend, Medical, Account"} />} />

          <Route path="/laboratorydashboard" element={<PrivateRoute Component={LaboratoryDashboard} userRole={"Laboratory Admin"} />} />
          <Route path="/laboratorymanagereports" element={<PrivateRoute Component={LaboratoryManageReports} userRole={"Laboratory Admin"} />} />
          <Route path="/laboratoryviewappointments" element={<PrivateRoute Component={LaboratoryViewAppointments} userRole={"Laboratory Admin"} />} />
          <Route path="/laboratorytimings" element={<PrivateRoute Component={LaboratoryTimings} userRole={"Laboratory Admin"} />} />

          <Route path="/pharmacydashboard" element={<PrivateRoute Component={PharmacyDashboard} userRole={"Pharmacy Admin"} />} />
          <Route path="/pharmacytimings" element={<PrivateRoute Component={PharmacyTimings} userRole={"Pharmacy Admin"} />} />
          <Route path="/pharmacymanageprescriptions" element={<PrivateRoute Component={PharmacyManagePrescriptions} userRole={"Pharmacy Admin"} />} />

          <Route path="/operatordashboard" element={<PrivateRoute Component={OperatorDashboard} userRole={"Operator"} />} />
          <Route path="/operatororganization" element={<PrivateRoute Component={OperatorEnterOrganizationData} userRole={"Operator"} />} />
          <Route path="/operatororganizationupload" element={<PrivateRoute Component={OperatorUploadOrganizationData} userRole={"Operator"} />} />

          <Route path="/patientsharableqrcode" element={<PatientSharableQrCode />} />

        </Routes>

      </BrowserRouter>

    </div>
  );
}

export default App;