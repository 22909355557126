import React, { useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../Navbar/Navbar";
import Footer from "../Navbar/Footer";
import { Asterisk, EyeFill, EyeSlashFill } from "react-bootstrap-icons";
import { Button, Row, Col, Container, Form, InputGroup } from "react-bootstrap";
import { Base64 } from 'js-base64';
// import { API_BASE } from '../../setupProxy';
import "./mycss.css";
import { useNavigate, useLocation } from "react-router-dom";

function ResetPassword() {

    const [strpassword, setStrPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [strConfirmPassword, setStrConfirmPassword] = useState("");
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const navigate = useNavigate();
    let { state } = useLocation();
    const strmobileno = state.mobileno;

    const handleSubmit = () => {

        if (strpassword === "" || strConfirmPassword === "") {
            setErrorMessage(<span style={{ color: "red" }}>Please enter data</span>);
            setTimeout(() => setErrorMessage(""), 3000);
        } else if (strpassword === "") {
            setErrorMessage(<span style={{ color: "red" }}>Please enter your new password</span>);
            setTimeout(() => setErrorMessage(""), 3000);
        } else if (strConfirmPassword === "") {
            setErrorMessage(<span style={{ color: "red" }}>Please enter confirm password</span>);
            setTimeout(() => setErrorMessage(""), 3000);
        } else if (strpassword !== strConfirmPassword) {
            setErrorMessage(<span style={{ color: "red" }}>Password did not matched</span>);
            setTimeout(() => setErrorMessage(""), 3000);
        }
        else {
            const strencryptedpassword = Base64.encode(strpassword);
            //console.log("strepass: " + strencryptedpassword + " " + str mobile number);
            const toInput = { strmobileno, strencryptedpassword };
            fetch("https://e-arogya.com:8443/api/general/forgotpassword", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                redirect: "follow",
                body: JSON.stringify(toInput)
            })
                .then(async response => {
                    const isJson = response.headers.get('content-type')?.includes('application/json');
                    const data = isJson && await response.json();

                    if (!response.ok) {
                        const error = (data && data.message) || response.status;
                        return Promise.reject(error);
                    }
                    setErrorMessage(<span style={{ color: "green" }}>Password Changed</span>);
                    setTimeout(() => setErrorMessage(""), 3000);
                    
                    setTimeout(() => navigate("/login"), 3000);
                    
                })
                .catch(error => {
                    setErrorMessage(<span style={{ color: "red" }}>Password not changed. Please try again.</span>);
                    setTimeout(() => setErrorMessage(""), 3000);
                });
        }

    }

    const togglePassword = () => {
        setShowPassword(!showPassword);
    };

    const toggleConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    return (

        <div>
            <Navbar />

            <Container fluid>

                <div style={{ marginTop: 100, marginBottom: 50 }} className='container'>

                    <Row>
                        <Col className="text-center justify-content-center">
                            <div style={{ textAlign: "center" }}>
                                <div className="about-border"></div>
                                <p style={{ fontSize: 35, fontWeight: 700 }}>RESET PASSWORD</p>
                            </div>
                            <div>
                                <p style={{ color: "grey" }}>Enter your new password</p>
                            </div>
                        </Col>
                    </Row>

                    <Row className="py-5 justify-content-center">
                        <Col md={4}>
                            <Form>

                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label><Asterisk size={6} style={{ marginBottom: 8, marginRight: 5, color: "red" }} />Password</Form.Label>
                                    <InputGroup className="mb-2">
                                        <Form.Control
                                            type={showPassword ? "text" : "password"}
                                            value={strpassword || ""}
                                            onChange={e => { setStrPassword(e.target.value) }}
                                            required />
                                        <InputGroup.Text onClick={togglePassword}>
                                            {showPassword ? <EyeFill size={15} /> : <EyeSlashFill size={15} />}
                                        </InputGroup.Text>
                                    </InputGroup>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label><Asterisk size={6} style={{ marginBottom: 8, marginRight: 5, color: "red" }} />Confirm Password</Form.Label>
                                    <InputGroup className="mb-2">
                                        <Form.Control
                                            type={showConfirmPassword ? "text" : "password"}
                                            value={strConfirmPassword || ""}
                                            onChange={e => { setStrConfirmPassword(e.target.value) }}
                                            required />
                                        <InputGroup.Text onClick={toggleConfirmPassword}>
                                            {showConfirmPassword ? <EyeFill size={15} /> : <EyeSlashFill size={15} />}
                                        </InputGroup.Text>
                                    </InputGroup>
                                </Form.Group>

                                <div style={{ fontSize: 12, fontWeight: "bold", marginBottom: 10 }}>{errorMessage}</div>
                                <Button style={{ marginTop: 20, marginBottom: 20, backgroundColor: "#fd5252", borderColor: "#fd5252", width: "100%" }} onClick={handleSubmit}>Reset</Button>

                            </Form>

                        </Col>

                    </Row>

                </div>

            </Container>

            <Footer />
        </div>

    );
}

export default ResetPassword;