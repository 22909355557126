import React, { useState, useEffect, useRef } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./admincss.css";
import HospitalSidebar from "../Navbar/HospitalSidebar";
import Adminheader from "../Navbar/Adminheader";
import { XSquare } from "react-bootstrap-icons";
import { Col, Form, Row, Button, Container, Modal, Image, Table } from "react-bootstrap";
import { Asterisk } from "react-bootstrap-icons";
//import { Link } from "react-router-dom";
import Male from "../images/Male.png";
import Female from "../images/Female.png";
import HeaderBackground from "../images/banner2.png";
import { useLocation, useNavigate } from 'react-router-dom'
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import PDFFileImage from "../images/pdfimage.png";
import '@progress/kendo-theme-default/dist/all.css';
//import samplePDF from "../images/test.pdf";
// import { API_BASE } from '../../setupProxy';
import * as moment from 'moment';

//For PDF File
import { Document, Page } from "@react-pdf/renderer";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

const HospitalPatientGenerateCase = () => {

    const intOrganizationID = localStorage.getItem("orgid");
    const intLoginID = localStorage.getItem("loggedInId");

    const navigate = useNavigate();

    // const location = useLocation()
    // const { appid, patid } = location.state

    // const loc = useLocation();
    // const {appidU, patidU, caseidU} = loc.state;

    let { state } = useLocation();
    const appid = state.appid;
    const patid = state.patid;
    const apptype = state.apptype;
    const caseno = state.caseno;
    const casestatus = state.casestatus;
    const casetype = state.casetype;
    const generateEdit = state.genedit;

    //console.log("appid: " + appid + " patid: " + patid + " caseno: " + caseno + " apptype: " + apptype + " casestatus: " + casestatus + " casetype: " + casetype);

    const intAppointmentID = appid;
    const intPatientID = patid;

    const aRef = useRef(null);

    const [genFemale, setGenFemale] = useState(false);
    const [addMessage, setAddMessage] = useState("");

    //Patient Info
    const [strFirstName, setStrFirstName] = useState("");
    const [strLastName, setStrLastName] = useState("");
    const [dtmBirthDate, setDtmBirthDate] = useState("");
    const [strBloodGroup, setStrBloodGroup] = useState("");
    const [strGender, setStrGender] = useState("");
    const [straddress, setStraddress] = useState("");
    const [strmobileno, setStrmobileno] = useState("");
    const [strcityname, setStrcityname] = useState("");
    const [strstatename, setStrstatename] = useState("");
    const [strstatus, setStatus] = useState('Active');
    const [totalVisits, setTotalVisits] = useState(null);

    const [strCaseNo, setStrCaseNo] = useState(caseno);
    const [strAppointmentType, setStrAppointmentType] = useState(apptype);

    //Case Status
    const [strCaseStatus, setStrCaseStatus] = useState(casestatus);

    //Case Type
    const [strCaseType, setStrCaseType] = useState(casetype);

    //Chief Complains
    const symptoms = ["Fever", "Cold", "Cough", "Headache", "Toothache", "Abdominal pain", "Back pain", "Chest pain", "Fatigue", "Knee pain", "Urinary Tract Infection (UTI)"];
    const [value, setValue] = useState([]);
    const handleChange = (event) => {
        const values = event.target.value;
        const lastItem = values[values.length - 1];
        if (lastItem) {
            values.pop();
            const sameItem = values.find((value) => value === lastItem);
            if (sameItem === undefined) {
                values.push(lastItem);
            }
        }
        setValue(values);
    };
    //console.log(JSON.stringify(value));

    //Vitals
    const [strWeightNum, setStrWeightNum] = useState("");
    const [strWeightUnit, setStrWeightUnit] = useState("kg");
    const [strHeightNum, setStrHeightNum] = useState("");
    const [strHeightUnit, setStrHeightUnit] = useState("ft");
    const [strBmi, setStrBmi] = useState("");
    const [strBsa, setStrBsa] = useState("");
    const [strPulse, setStrPulse] = useState("");
    const [strSpo, setStrSpo] = useState("");
    const [strRespiratoryRate, setStrRespiratoryRate] = useState("");
    const [strTemperature, setStrTemperature] = useState("");
    //const [strBloodPressure, setStrBloodPressure] = useState("");
    const [strBloodPressureMM, setStrBloodPressureMM] = useState("");
    const [strBloodPressureHG, setStrBloodPressureHG] = useState("");

    //History
    const [historyId, setHistoryId] = useState(null);
    const [strPersonalHistory, setStrPersonalHistory] = useState("");
    const [strMajorIllness, setStrMajorIllness] = useState("");
    const [strHospitalization, setStrHospitalization] = useState("");
    const [strMedications, setStrMedications] = useState("");
    const [strCovidHO, setStrCovidHO] = useState("");
    const [strCovidHospitalization, setStrCovidHospitalization] = useState("");
    const [strCovidVaccine, setStrCovidVaccine] = useState("");
    const [strFamilyHistory, setStrFamilyHistory] = useState("");

    //Medication and Treatment
    // const [strMedication, setStrMedication] = useState("");
    // const [strAdvice, setStrAdvice] = useState("");
    //const [medTreatId, setMedTreatId] = useState(null);
    const [strMedicineName, setStrMedicineName] = useState("");
    const [strMedicineType, setStrMedicineType] = useState("");
    const [strMedicineDuration, setStrMedicineDuration] = useState("");
    const [strMedicineFrequency, setStrMedicineFrequency] = useState("");
    const [strMedFreqMorning, setStrMedFreqMorning] = useState("");
    const [strMedFreqAfternoon, setStrMedFreqAfternoon] = useState("");
    const [strMedFreqEvening, setStrMedFreqEvening] = useState("");
    const [strMedFreqNight, setStrMedFreqNight] = useState("");
    const [strMedicineQuantity, setStrMedicineQuantity] = useState("");
    const medAdvise = ["After Meal", "Before Meal", "Empty Stomach", "Apply on Affected Area",];
    const [valueMedicineAdvise, setValueMedicineAdvise] = useState([]);
    const handleChangeMedicineAdvise = (event) => {
        const values = event.target.value;
        const lastItem = values[values.length - 1];
        if (lastItem) {
            values.pop();
            const sameItem = values.find((value) => value === lastItem);
            if (sameItem === undefined) {
                values.push(lastItem);
            }
        }
        setValueMedicineAdvise(values);
    };

    //Clinical Examination 
    const [strClinicalExamination, setStrClinicalExamination] = useState("");
    // const [strInspection, setStrInspection] = useState("");
    // const [strPalpation, setStrPalpation] = useState("");
    // const [strPercussion, setStrPercussion] = useState("");
    // const [strAusculation, setStrAusculation] = useState("");
    // const [strInvestigations, setStrInvestigations] = useState("");

    //Advice
    const [strDoctorAdvice, setStrDoctorAdvice] = useState("");

    //Notes
    const [strDoctorNotes, setStrDoctorNotes] = useState("");

    //Investigation
    const investigation = ["Blood Report", "Urine Report", "CT Scan", "X-Ray", "MRI Scan"];
    const [valueInvestigation, setValueInvestigation] = useState([]);
    const handleChangeInvestigation = (event) => {
        const values = event.target.value;
        const lastItem = values[values.length - 1];
        if (lastItem) {
            values.pop();
            const sameItem = values.find((value) => value === lastItem);
            if (sameItem === undefined) {
                values.push(lastItem);
            }
        }
        setValueInvestigation(values);
    };

    //Diagnosis
    const [strDiagnosis, setStrDiagnosis] = useState("");

    const diag = [
        "Cholera due to Vibrio cholerae 01, biovar cholerae",
        "Cholera due to Vibrio cholerae 01, biovar eltor",
        "Cholera, unspecified",
        "Typhoid fever, unspecified",
        "Typhoid meningitis",
        "Typhoid fever with heart involvement",
        "Typhoid pneumonia",
        "Typhoid arthritis",
        "Typhoid osteomyelitis",
        "Typhoid fever with other complications",
        "Paratyphoid fever A",
        "Paratyphoid fever B",
        "Paratyphoid fever C",
        "Paratyphoid fever, unspecified",
        "Salmonella enteritis",
        "Salmonella sepsis",
        "Localized salmonella infection, unspecified",
        "Salmonella meningitis",
        "Salmonella pneumonia",
        "Salmonella arthritis",
        "Salmonella osteomyelitis",
        "Salmonella pyelonephritis",
        "Salmonella with other localized infection",
        "Other specified salmonella infections",
        "Salmonella infection, unspecified",
        "Shigellosis due to Shigella dysenteriae",
        "Shigellosis due to Shigella flexneri",
        "Shigellosis due to Shigella boydii",
        "Shigellosis due to Shigella sonnei",
        "Other shigellosis",
        "Shigellosis, unspecified",
        "Enteropathogenic Escherichia coli infection",
        "Enterotoxigenic Escherichia coli infection",
        "Enteroinvasive Escherichia coli infection",
        "Enterohemorrhagic Escherichia coli infection",
        "Other intestinal Escherichia coli infections",
        "Campylobacter enteritis",
        "Enteritis due to Yersinia enterocolitica",
        "Enterocolitis due to Clostridium difficile",
        "Enterocolitis due to Clostridium difficile, recurrent",
        "Enterocolitis d/t Clostridium difficile, not spcf as recur",
        "Other specified bacterial intestinal infections",
        "Bacterial intestinal infection, unspecified",
        "Foodborne staphylococcal intoxication",
        "Botulism food poisoning",
        "Foodborne Clostridium perfringens intoxication",
        "Foodborne Vibrio parahaemolyticus intoxication",
        "Foodborne Bacillus cereus intoxication",
        "Foodborne Vibrio vulnificus intoxication",
        "Other specified bacterial foodborne intoxications",
        "Bacterial foodborne intoxication, unspecified",
        "Acute amebic dysentery",
        "Chronic intestinal amebiasis",
        "Amebic nondysenteric colitis",
        "Ameboma of intestine",
        "Amebic liver abscess",
        "Amebic lung abscess",
        "Amebic brain abscess",
        "Cutaneous amebiasis",
        "Healthy individual, routine check-up", "Viral Infection", "Seasonal Allergies", "Migraine", "Appendicitis", "Gastritis", "Pneumonia", "Arthritis", "Osteoporosis", "Asthma", "Thyroid", "Interstitial cystitis"];
    const [valueDiagnosisSearch, setValueDiagnosisSearch] = useState([]);
    const handleChangeDiagnosisSearch = (event) => {
        const values = event.target.value;
        const lastItem = values[values.length - 1];
        if (lastItem) {
            values.pop();
            const sameItem = values.find((value) => value === lastItem);
            if (sameItem === undefined) {
                values.push(lastItem);
            }
        }
        setValueDiagnosisSearch(values);
    };

    //Attachments
    const [files, setFiles] = useState('');
    const [strAttRemarks, setStrAttRemarks] = useState('');
    const [fileSize, setFileSize] = useState("");

    //For Females Only
    const [strMenstrualCycle, setStrMenstrualCycle] = useState("");
    const [strPain, setStrPain] = useState("");
    const [dtmLmpDate, setDtmLmpDate] = useState("");
    const [strChildren, setStrChildren] = useState("");
    const [strAbortion, setStrAbortion] = useState("");
    const [strTypeOfDelivery, setStrTypeOfDelivery] = useState("");

    //Previous Complaints
    const [previousComplains, setPreviousComplains] = useState([]);
    const [showPreviousComplains, setShowPreviousComplains] = useState(false);
    const handleClosePreviousComplains = () => setShowPreviousComplains(false);
    const handleShowPreviousComplains = () => {
        setShowPreviousComplains(true);
        fetch("https://e-arogya.com:8443/api/general/patientcase/prevcomplains/" + patid)
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }
                if (data) {
                    setPreviousComplains(data);
                }
            })
            .catch(error => {
                //console.log("Error Occurred: " + error);
            });
    };

    const getPatientAppComplaints = () => {
        fetch("https://e-arogya.com:8443/api/general/patientcase/prevcomplainspatapp/" + patid + "/" + appid)
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }
                if (data) {
                    let str1 = data[0].strComplain;
                    var myarray1 = str1.split(',');
                    setValue(myarray1);
                }
            })
            .catch(error => {
                //console.log("Error Occurred: " + error);
            });
    };

    //Previous Investigation
    const [previousInvestigation, setPreviousInvestigation] = useState([]);
    const [showPreviousInvestigation, setShowPreviousInvestigation] = useState(false);
    const handleClosePreviousInvestigation = () => setShowPreviousInvestigation(false);
    const handleShowPreviousInvestigation = () => {
        setShowPreviousInvestigation(true);
        fetch("https://e-arogya.com:8443/api/general/patientcase/previnvestigation/" + patid)
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }
                if (data) {
                    setPreviousInvestigation(data);
                }
            })
            .catch(error => {
                //console.log("Error Occurred: " + error);
            });
    };

    const getPatientAppInvestigation = () => {
        fetch("https://e-arogya.com:8443/api/general/patientcase/previnvestigationpatapp/" + patid + "/" + appid)
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }
                if (data) {
                    let str1 = data[0].strInvestigation;
                    var myarray1 = str1.split(',');
                    setValueInvestigation(myarray1);
                }
            })
            .catch(error => {
                //console.log("Error Occurred: " + error);
            });
    };

    //Previous Vitals
    const [previousVitals, setPreviousVitals] = useState([]);
    const [showPreviousVitals, setShowPreviousVitals] = useState(false);
    const handleClosePreviousVitals = () => setShowPreviousVitals(false);
    const handleShowPreviousVitals = () => {
        setShowPreviousVitals(true);
        fetch("https://e-arogya.com:8443/api/general/patientcase/prevvitals/" + patid)
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }
                if (data) {
                    setPreviousVitals(data);
                }
            })
            .catch(error => {
                //console.log("Error Occurred: " + error);
            });
    };

    const getPatientAppVitals = () => {
        fetch("https://e-arogya.com:8443/api/general/patientcase/prevvitalspatapp/" + patid + "/" + appid)
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }
                if (data) {
                    let wt = data[0].strWeight.split(' ');
                    setStrWeightNum(wt[0]);
                    let ht = data[0].strHeight.split(' ');
                    setStrHeightNum(ht[0]);
                    setStrBmi(data[0].strBmi);
                    setStrBsa(data[0].strBsa);
                    setStrPulse(data[0].strPulse);
                    setStrSpo(data[0].strSpo);
                    //console.log(data[0].strBloodPressure);
                    let bp = data[0].strBloodPressure.split('/');
                    setStrBloodPressureMM(bp[0]);
                    setStrBloodPressureHG(bp[1]);
                    setStrRespiratoryRate(data[0].strRespiratoryRate);
                    setStrTemperature(data[0].strTemperature);
                }
            })
            .catch(error => {
                //console.log("Error Occurred: " + error);
            });
    };

    // //Previous History
    // const [previousHistory, setPreviousHistory] = useState([]);
    // const [showPreviousHistory, setShowPreviousHistory] = useState(false);
    // const handleClosePreviousHistory = () => setShowPreviousHistory(false);
    // const handleShowPreviousHistory = () => {
    //     setShowPreviousHistory(true);
    //     fetch("https://e-arogya.com:8443/api/general/patientcase/prevhistory/" + patid)
    //         .then(async response => {
    //             const isJson = response.headers.get('content-type')?.includes('application/json');
    //             const data = isJson && await response.json();
    //             if (!response.ok) {
    //                 const error = (data && data.message) || response.status;
    //                 return Promise.reject(error);
    //             }
    //             if (data) {
    //                 setPreviousHistory(data);
    //             }
    //         })
    //         .catch(error => {
    //             //console.log("Error Occurred: " + error);
    //         });
    // };

    const getPatientAppHistory = () => {
        fetch("https://e-arogya.com:8443/api/general/patientcase/prevhistorypatapp/" + patid + "/" + appid)
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }
                if (data) {
                    setStrPersonalHistory(data[0].strPersonalHistory);
                    setStrMajorIllness(data[0].strMajorIllness);
                    setStrHospitalization(data[0].strHospitalization);
                    setStrMedications(data[0].strMedications);
                    setStrCovidHO(data[0].strCovidHO);
                    setStrCovidHospitalization(data[0].strCovidHospitalization);
                    setStrCovidVaccine(data[0].strCovidVaccine);
                    setStrFamilyHistory(data[0].strFamilyHistory);
                    setHistoryId(data[0].intHistoryId);
                }
            })
            .catch(error => {
                //console.log("Error Occurred: " + error);
            });
    };

    //Previous Medication Treatment
    const [previousMedicationTreatment, setPreviousMedicationTreatment] = useState([]);
    const [showPreviousMedicationTreatment, setShowPreviousMedicationTreatment] = useState(false);
    const handleClosePreviousMedicationTreatment = () => setShowPreviousMedicationTreatment(false);
    const handleShowPreviousMedicationTreatment = () => {
        setShowPreviousMedicationTreatment(true);
        fetch("https://e-arogya.com:8443/api/general/patientcase/prevmedicationtreatment/" + patid)
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }
                if (data) {
                    setPreviousMedicationTreatment(data);
                }
            })
            .catch(error => {
                //console.log("Error Occurred: " + error);
            });
    };

    const getPatientAppMedicationTreatment = () => {
        fetch("https://e-arogya.com:8443/api/general/patientcase/prevmedicationtreatmentpatapp/" + patid + "/" + appid)
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }
                if (data) {
                    setMedTableData(data);
                }
            })
            .catch(error => {
                //console.log("Error Occurred: " + error);
            });
    };

    //Previous Doctor Advice
    const [previousDoctorAdvice, setPreviousDoctorAdvice] = useState([]);
    const [showPreviousDoctorAdvice, setShowPreviousDoctorAdvice] = useState(false);
    const handleClosePreviousDoctorAdvice = () => setShowPreviousDoctorAdvice(false);
    const handleShowPreviousDoctorAdvice = () => {
        setShowPreviousDoctorAdvice(true);
        fetch("https://e-arogya.com:8443/api/general/patientcase/prevdoctoradvice/" + patid)
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }
                if (data) {
                    setPreviousDoctorAdvice(data);
                }
            })
            .catch(error => {
                //console.log("Error Occurred: " + error);
            });
    };

    const getPatientAppDoctorAdvice = () => {
        fetch("https://e-arogya.com:8443/api/general/patientcase/prevdoctoradvicepatapp/" + patid + "/" + appid)
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }
                if (data) {
                    setStrDoctorAdvice(data[0].strDoctorAdvice);
                }
            })
            .catch(error => {
                //console.log("Error Occurred: " + error);
            });
    };

    //Previous Doctor Notes
    const [previousDoctorNotes, setPreviousDoctorNotes] = useState([]);
    const [showPreviousDoctorNotes, setShowPreviousDoctorNotes] = useState(false);
    const handleClosePreviousDoctorNotes = () => setShowPreviousDoctorNotes(false);
    const handleShowPreviousDoctorNotes = () => {
        setShowPreviousDoctorNotes(true);
        fetch("https://e-arogya.com:8443/api/general/patientcase/prevdoctornotes/" + patid)
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }
                if (data) {
                    setPreviousDoctorNotes(data);
                }
            })
            .catch(error => {
                //console.log("Error Occurred: " + error);
            });
    };

    const getPatientAppDoctorNotes = () => {
        fetch("https://e-arogya.com:8443/api/general/patientcase/prevdoctornotespatapp/" + patid + "/" + appid)
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }
                if (data) {
                    setStrDoctorNotes(data[0].strDoctorNotes);
                }
            })
            .catch(error => {
                //console.log("Error Occurred: " + error);
            });
    };

    //Previous Clinical Examination
    const [previousClinicalExamination, setPreviousClinicalExamination] = useState([]);
    const [showPreviousClinicalExamination, setShowPreviousClinicalExamination] = useState(false);
    const handleClosePreviousClinicalExamination = () => setShowPreviousClinicalExamination(false);
    const handleShowPreviousClinicalExamination = () => {
        setShowPreviousClinicalExamination(true);
        fetch("https://e-arogya.com:8443/api/general/patientcase/prevclinicalexamination/" + patid)
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }
                if (data) {
                    setPreviousClinicalExamination(data);
                }
            })
            .catch(error => {
                //console.log("Error Occurred: " + error);
            });
    };

    const getPatientAppClinicalExamination = () => {
        fetch("https://e-arogya.com:8443/api/general/patientcase/prevclinicalexaminationpatapp/" + patid + "/" + appid)
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }
                if (data) {
                    setStrClinicalExamination(data[0].strClinicalExamination);
                }
            })
            .catch(error => {
                //console.log("Error Occurred: " + error);
            });
    };

    //Previous Diagnosis
    const [previousDiagnosis, setPreviousDiagnosis] = useState([]);
    const [showPreviousDiagnosis, setShowPreviousDiagnosis] = useState(false);
    const handleClosePreviousDiagnosis = () => setShowPreviousDiagnosis(false);
    const handleShowPreviousDiagnosis = () => {
        setShowPreviousDiagnosis(true);
        fetch("https://e-arogya.com:8443/api/general/patientcase/prevdiagnosis/" + patid)
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }
                if (data) {
                    setPreviousDiagnosis(data);
                }
            })
            .catch(error => {
                //console.log("Error Occurred: " + error);
            });
    };

    const getPatientAppDiagnosis = () => {
        fetch("https://e-arogya.com:8443/api/general/patientcase/prevdiagnosispatapp/" + patid + "/" + appid)
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }
                if (data) {
                    setStrDiagnosis(data[0].strDiagnosis);
                    let str1 = data[0].strDiagnosisSearch;
                    var myarray1 = str1.split(',');
                    setValueDiagnosisSearch(myarray1);
                }
            })
            .catch(error => {
                //console.log("Error Occurred: " + error);
            });
    };

    //Previous For Females
    const [previousForFemalesOnly, setPreviousForFemalesOnly] = useState([]);
    const [showPreviousForFemalesOnly, setShowPreviousForFemalesOnly] = useState(false);
    const handleClosePreviousForFemalesOnly = () => setShowPreviousForFemalesOnly(false);
    const handleShowPreviousForFemalesOnly = () => {
        setShowPreviousForFemalesOnly(true);
        fetch("https://e-arogya.com:8443/api/general/patientcase/prevforfemalesonly/" + patid)
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }
                if (data) {
                    setPreviousForFemalesOnly(data);
                }
            })
            .catch(error => {
                //console.log("Error Occurred: " + error);
            });
    };

    const getPatientAppForFemales = () => {
        fetch("https://e-arogya.com:8443/api/general/patientcase/prevforfemalesonlypatapp/" + patid + "/" + appid)
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }
                if (data) {
                    setStrMenstrualCycle(data[0].strMenstrualCycle);
                    setStrPain(data[0].strPain);
                    setDtmLmpDate(data[0].dtmLmpDate);
                    setStrChildren(data[0].strChildren);
                    setStrAbortion(data[0].strAbortion);
                    setStrTypeOfDelivery(data[0].strTypeOfDelivery);
                }
            })
            .catch(error => {
                //console.log("Error Occurred: " + error);
            });
    };

    //Previous Attachments
    const [previousAttachments, setPreviousAttachments] = useState([]);
    const [showPreviousAttachments, setShowPreviousAttachments] = useState(false);
    const handleClosePreviousAttachments = () => setShowPreviousAttachments(false);
    const handleShowPreviousAttachments = () => {
        setShowPreviousAttachments(true);
        fetch("https://e-arogya.com:8443/api/general/patientcase/prevattachments/" + patid)
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }
                if (data) {
                    setPreviousAttachments(data);
                }
            })
            .catch(error => {
                //console.log("Error Occurred: " + error);
            });
    };

    const [attachTableData, setAttachTableData] = useState([]);
    const getPatientAppAttachments = () => {
        fetch("https://e-arogya.com:8443/api/general/patientcase/prevattachmentspatapp/" + patid + "/" + appid)
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }
                if (data) {
                    setAttachTableData(data);
                }
            })
            .catch(error => {
                //console.log("Error Occurred: " + error);
            });
    };

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [deleteThis, setDeleteThis] = useState(null);
    const [deleteMessage, setDeleteMessage] = useState("");
    const attachDeleteClicked = (id) => {
        setShow(true);
        setDeleteThis(id);
    }

    const deleteThisFunc = (id) => {

        fetch("https://e-arogya.com:8443/api/general/patientcase/deletepatientcaseattachment/" + id, { method: 'DELETE' })
            .then(async response => {
                const data = await response.text;

                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }

                setDeleteMessage(<span style={{ color: "green" }}>Attachment Deleted</span>);
                setTimeout(() => setDeleteMessage(""), 3000);
                setDeleteThis(null);
                getPatientAppAttachments();
            })
            .catch(error => {
                setDeleteMessage(<span style={{ color: "red" }}>Attachment Not Deleted. Please try again.</span>);
                setTimeout(() => setDeleteMessage(""), 3000);
            });
    }

    //Open My Attachments
    const [myAttachments, setMyAttachments] = useState([]);
    const [showMyAttachments, setShowMyAttachments] = useState(false);
    const handleCloseMyAttachments = () => setShowMyAttachments(false);
    const [filetype, setFileType] = useState("");

    const [numPages, setNumPages] = useState(null);
    function onDocumentLoadSuccess({ numPages }) { setNumPages(numPages); }

    const ViewAttachmentFiles = (filename, file) => {
        setShowMyAttachments(true);
        setMyAttachments(file);
        if (filename.split('.').pop() === 'pdf') {
            setFileType("pdf");
        } else if (filename.split('.').pop() === 'jpeg') {
            setFileType("jpeg");
        } else if (filename.split('.').pop() === 'png') {
            setFileType("png");
        }
    }

    const [myImageAttachments, setMyImageAttachments] = useState([]);
    const [showMyImageAttachments, setShowMyImageAttachments] = useState(false);
    const handleCloseMyImageAttachments = () => setShowMyImageAttachments(false);


    const [medTableData, setMedTableData] = useState([]);
    const addNewMedicine = () => {
        let todos = [...medTableData];
        todos.push({
            strMedicineName: strMedicineName,
            strMedicineType: strMedicineType,
            strMedicineDuration: strMedicineDuration,
            strMedicineFrequency: strMedicineFrequency,
            strMedFreqMorning: strMedFreqMorning,
            strMedFreqAfternoon: strMedFreqAfternoon,
            strMedFreqEvening: strMedFreqEvening,
            strMedFreqNight: strMedFreqNight,
            strMedicineQuantity: strMedicineQuantity,
            valueMedicineAdvise: valueMedicineAdvise,
        });

        setMedTableData(todos);
        setStrMedicineName("");
        setStrMedicineType("");
        setStrMedicineDuration("");
        setStrMedicineFrequency("");
        setStrMedFreqMorning("");
        setStrMedFreqAfternoon("");
        setStrMedFreqEvening("");
        setStrMedFreqNight("");
        setStrMedicineQuantity("");
        setValueMedicineAdvise([]);

    }
    const medDeleteClicked = (index) => {
        const rows = [...medTableData];
        rows.splice(index, 1);
        setMedTableData(rows);
    }

    const [patientHistory, setPatientHistory] = useState(false);
    const handleClosePatientHistory = () => setPatientHistory(false);
    const saveHistoryData = () => {
        //console.log("Inside SaveHistory Data: " + strPersonalHistory + " " + strMajorIllness + " " + strHospitalization + " " + strMedications + " " + strCovidHO + " " + strCovidHospitalization + " " + strCovidVaccine + " " + strFamilyHistory );
        setPatientHistory(false);
    }

    const [patientOtherDetails, setPatientOtherDetails] = useState(false);
    const handleClosePatientOtherDetails = () => setPatientOtherDetails(false);

    useEffect(() => {
        getTotalVisits(patid);
        fetchPatientData(appid);
        fetchOtherDetailsOfPatient(patid);
        //fetchPatientInsuranceDetails(patid);
        getPatientVaccineDetails(patid);
        getPatientMedicationDetails(patid);
        getPatientClinicalDetails(patid);
        getPatientSurgeryDetails(patid);
        getPatientImagesDocs(patid);
        getPatientAppHistory();
        if (generateEdit === "Update") {
            getPatientAppComplaints();
            getPatientAppVitals();
            getPatientAppHistory();
            getPatientAppInvestigation();
            getPatientAppDoctorAdvice();
            getPatientAppDoctorNotes();
            getPatientAppClinicalExamination();
            getPatientAppDiagnosis();
            getPatientAppMedicationTreatment();
            getPatientAppForFemales();
            getPatientAppAttachments();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const fetchPatientData = (id) => {
        fetch("https://e-arogya.com:8443/api/general/appointments/" + appid)
            .then(res => res.json())
            .then(data => {
                setStrFirstName(data[0].strFirstName);
                setStrLastName(data[0].strLastName);
                setDtmBirthDate(data[0].dtmBirthDate);
                setStrBloodGroup(data[0].strBloodGroup);
                setStrGender(data[0].strGender);
                setStraddress(data[0].straddress);
                setStrmobileno(data[0].strmobileno);
                setStrcityname(data[0].strcityname);
                setStrstatename(data[0].strstatename);
                setStatus(data[0].strstatus);

                if (data[0].strGender === "Female") {
                    setGenFemale(true);
                } else {
                    setGenFemale(false);
                }
            })
            .catch(error => {
                //console.log(error);
            });
    }

    const [strMaritalStatus, setStrMaritalStatus] = useState("");
    const [strOccupation, setStrOccupation] = useState("");
    const [strReligion, setStrReligion] = useState("");
    const [strAllergies, setStrAllergies] = useState("");
    const [strHabits, setStrHabits] = useState("")
    const [strDiet, setStrDiet] = useState("");
    const [strLifestyle, setStrLifestyle] = useState("");
    const [strPatInsuranceCompanyName, setPatInsuranceCompanyName] = useState("");
    const [strPatInsuranceNumber, setPatInsuranceNumber] = useState("");
    const [strPatInsuranceExpiryDate, setPatInsuranceExpiryDate] = useState("");
    const fetchOtherDetailsOfPatient = (patid) => {
        fetch("https://e-arogya.com:8443/api/general/patientapplication/" + patid)
            .then(res => res.json())
            .then(data => {
                setStrMaritalStatus(data[0].strMaritalStatus);
                setStrOccupation(data[0].strOccupation);
                setStrReligion(data[0].strReligion);
                setStrAllergies(data[0].strAllergies);
                setStrHabits(data[0].strHabits);
                setStrDiet(data[0].strDiet);
                setStrLifestyle(data[0].strLifestyle);
                setPatInsuranceCompanyName(data[0].strInsuranceCompany);
                setPatInsuranceNumber(data[0].strInsuranceNumber);
                setPatInsuranceExpiryDate(data[0].dtmInsuranceExpiry)
            })
            .catch(error => {
                //console.log(error);
            });
    }

    const [patientVaccineDetails, setPatientVaccineDetails] = useState([]);
    const [patientMedicationDetails, setPatientMedicationDetails] = useState([]);
    const [patientClinicalDetails, setPatientClinicalDetails] = useState([]);
    const [patientSurgeryDetails, setPatientSurgeryDetails] = useState([]);
    const getPatientVaccineDetails = (patid) => {
        fetch("https://e-arogya.com:8443/api/general/patientapplication/patvacc/" + patid)
            .then(res => res.json())
            .then(data => {
                setPatientVaccineDetails(data);
            })
            .catch(error => {
                //console.log(error);
            });
    };
    const getPatientMedicationDetails = (patid) => {
        fetch("https://e-arogya.com:8443/api/general/patientapplication/patmedi/" + patid)
            .then(res => res.json())
            .then(data => {
                setPatientMedicationDetails(data);
            })
            .catch(error => {
                //console.log(error);
            });
    };
    const getPatientClinicalDetails = (patid) => {
        fetch("https://e-arogya.com:8443/api/general/patientapplication/patclinic/" + patid)
            .then(res => res.json())
            .then(data => {
                setPatientClinicalDetails(data);
            })
            .catch(error => {
                //console.log(error);
            });
    };
    const getPatientSurgeryDetails = (patid) => {
        fetch("https://e-arogya.com:8443/api/general/patientapplication/patsurgi/" + patid)
            .then(res => res.json())
            .then(data => {
                setPatientSurgeryDetails(data);
            })
            .catch(error => {
                //console.log(error);
            });
    };
    const getTotalVisits = (patid) => {
        fetch("https://e-arogya.com:8443/api/general/patientcase/" + patid + "/" + intOrganizationID)
            .then(res => res.json())
            .then(tv => {
                setTotalVisits(tv);
            })
            .catch(error => {
                //console.log(error);
            });
    }

    const [patientDocVaccineImagesDetails, setPatientDocVaccineImagesDetails] = useState([]);
    const [patientDocMedicationImagesDetails, setPatientDocMedicationImagesDetails] = useState([]);
    const [patientDocClinicalImagesDetails, setPatientDocClinicalImagesDetails] = useState([]);
    const [patientDocSurgeryImagesDetails, setPatientDocSurgeryImagesDetails] = useState([]);

    const getPatientImagesDocs = async (pid) => {
        try {
            const response = await fetch(`https://e-arogya.com:8443/api/general/patientapplication/patimgdocs/` + pid);
            const json = await response.json();

            const vaccimagesonly = json.filter((val, i) => {
                if (val.strDocumentType === "Vaccine") {
                    return val;
                }
                return null;
            })
            setPatientDocVaccineImagesDetails(vaccimagesonly);

            const mediimagesonly = json.filter((val, i) => {
                if (val.strDocumentType === "Medication") {
                    return val;
                }
                return null;
            })
            setPatientDocMedicationImagesDetails(mediimagesonly);

            const cliniimagesonly = json.filter((val, i) => {
                if (val.strDocumentType === "Clinical") {
                    return val;
                }
                return null;
            })
            setPatientDocClinicalImagesDetails(cliniimagesonly);

            const surgiimagesonly = json.filter((val, i) => {
                if (val.strDocumentType === "Surgery") {
                    return val;
                }
                return null;
            })
            setPatientDocSurgeryImagesDetails(surgiimagesonly);

        } catch (error) {
            console.log("Error Images Docs: " + error);
        }
    };

    //FORM SUBMIT
    const onGenerateCaseFormSubmit = (event) => {

        event.preventDefault();

        const formData = new FormData();

        if (strCaseStatus && strCaseStatus !== "- Select -" && strCaseType && strCaseType !== "- Select -") {

            const strWeight = strWeightNum + " " + strWeightUnit;
            const strHeight = strHeightNum + " " + strHeightUnit;

            const bloodPressure = strBloodPressureMM + "/" + strBloodPressureHG;

            var dtmCaseRegisterDate = new Date().toISOString().slice(0, 10);

            // console.log("Medicine Data: " + JSON.stringify(medTableData));
            // console.log(" " + intOrganizationID + " " + intLoginID + " " + intAppointmentID + " " + intPatientID + " " + dtmCaseRegisterDate + " " + strAppointmentType);
            // console.log(" " + strFirstName + " " + strLastName + " " + dtmBirthDate + " " + strBloodGroup + " " + strGender + " " + straddress + " " + strmobileno + " " + strcityname + " " + strstatename + " " + strstatus + " " + strCaseType + " " + strCaseStatus)
            // console.log(" " + JSON.stringify(value));
            // console.log(" " + strWeight + " " + strHeight + " " + strBmi + " " + strBsa + " " + strPulse + " " + strSpo + " " + strBloodPressureMM + " " + strBloodPressureHG + " " + strRespiratoryRate + " " + strTemperature );
            // console.log(" " + strPersonalHistory + " " + strMajorIllness + " " + strHospitalization + " " + strMedications + " " + strCovidHO + " " + strCovidHospitalization + " " + strCovidVaccine + " " + strFamilyHistory);
            // console.log(" " + strMedicineName, + " " + strMedicineType + " " + strMedicineDuration + " " + strMedicineFrequency);
            // console.log(" " + JSON.stringify(valueMedicineAdvise));
            // console.log(" " + strMedication + " " + strAdvice);
            // console.log(" " + strInspection + " " + strPalpation + " " + strPercussion + " " + strAusculation + " " + strInvestigations);
            // console.log(" " + strClinicalExamination);
            // console.log(" " + strDoctorAdvice);
            // console.log(" " + strDoctorNotes);
            // console.log(" " + JSON.stringify(valueInvestigation));
            // console.log(" " + strDiagnosis);
            // console.log(" " + JSON.stringify(valueDiagnosisSearch);)
            // console.log(" " + strMenstrualCycle + " " + strPain + " " + dtmLmpDate + " " + strChildren + " " + strAbortion + " " + strTypeOfDelivery);
            // console.log(" " + strAttRemarks);
            // console.log("files: " + files);

            if (files !== 0) {
                for (let i = 0; i < files.length; i++) {
                    if (files[i].size > 1000000) {
                        setFileSize(<span style={{ color: "red" }}>File size exceeded!! Total file size should be less than 1 MB</span>);
                        setTimeout(() => setFileSize(""), 3000);
                        return;
                    }
                    formData.append('files', files[i]);
                }
                if (strAttRemarks) { formData.append('strAttRemarks', strAttRemarks); }
            }

            formData.append('strCaseNo', strCaseNo); // case table
            formData.append('strCaseType', strCaseType); // case table
            formData.append('strCaseStatus', strCaseStatus); //case table
            formData.append('strAppointmentType', strAppointmentType); // appointment table + add case id  
            formData.append('intPatientID', intPatientID); //case table
            formData.append('dtmCaseRegisterDate', dtmCaseRegisterDate);
            formData.append('intOrganizationID', intOrganizationID);
            formData.append('intLoginID', intLoginID);
            formData.append('intAppointmentID', intAppointmentID);
            formData.append('strstatus', strstatus);
            formData.append('strGender', strGender);

            if (strWeight) { formData.append('strWeight', strWeight); }
            if (strHeight) { formData.append('strHeight', strHeight); }
            if (strBmi) { formData.append('strBmi', strBmi); }
            if (strBsa) { formData.append('strBsa', strBsa); }
            if (strPulse) { formData.append('strPulse', strPulse); }
            if (strSpo) { formData.append('strSpo', strSpo); }
            if (strRespiratoryRate) { formData.append('strRespiratoryRate', strRespiratoryRate); }
            if (strTemperature) { formData.append('strTemperature', strTemperature); }
            if (strBloodPressureMM || strBloodPressureHG) { formData.append('strBloodPressure', bloodPressure); }

            if (value) { formData.append('value', value); }

            if (strPersonalHistory) { formData.append('strPersonalHistory', strPersonalHistory); }
            if (strMajorIllness) { formData.append('strMajorIllness', strMajorIllness); }
            if (strHospitalization) { formData.append('strHospitalization', strHospitalization); }
            if (strMedications) { formData.append('strMedications', strMedications); }
            if (strCovidHO) { formData.append('strCovidHO', strCovidHO); }
            if (strCovidHospitalization) { formData.append('strCovidHospitalization', strCovidHospitalization); }
            if (strCovidVaccine) { formData.append('strCovidVaccine', strCovidVaccine); }
            if (strFamilyHistory) { formData.append('strFamilyHistory', strFamilyHistory); }

            // if (strMedication) { formData.append('strMedication', strMedication); }
            // if (strAdvice) { formData.append('strAdvice', strAdvice); }

            // if (strMedicineName) { formData.append('strMedicineName', strMedicineName); }
            // if (strMedicineType) { formData.append('strMedicineType', strMedicineType); }
            // if (strMedicineDuration) { formData.append('strMedicineDuration', strMedicineDuration); }    
            // if (strMedicineFrequency) { formData.append('strMedicineFrequency', strMedicineFrequency); }
            // if (valueMedicineAdvise) { formData.append('valueMedicineAdvise', valueMedicineAdvise); }
            if (medTableData) { formData.append('medTableData', JSON.stringify(medTableData)); }

            if (strDoctorAdvice) { formData.append('strDoctorAdvice', strDoctorAdvice); }

            if (strDoctorNotes) { formData.append('strDoctorNotes', strDoctorNotes); }

            if (strClinicalExamination) { formData.append('strClinicalExamination', strClinicalExamination); }
            // if (strInspection) { formData.append('strInspection', strInspection); }
            // if (strPalpation) { formData.append('strPalpation', strPalpation); }
            // if (strPercussion) { formData.append('strPercussion', strPercussion); }
            // if (strAusculation) { formData.append('strAusculation', strAusculation); }
            // if (strInvestigations) { formData.append('strInvestigations', strInvestigations); }
            if (valueInvestigation) { formData.append('valueInvestigation', valueInvestigation); }

            if (strDiagnosis) { formData.append('strDiagnosis', strDiagnosis); }
            if (valueDiagnosisSearch) { formData.append('valueDiagnosisSearch', valueDiagnosisSearch); }

            if (strMenstrualCycle) { formData.append('strMenstrualCycle', strMenstrualCycle); }
            if (strPain) { formData.append('strPain', strPain); }
            if (dtmLmpDate) { formData.append('dtmLmpDate', dtmLmpDate); }
            if (strChildren) { formData.append('strChildren', strChildren); }
            if (strAbortion) { formData.append('strAbortion', strAbortion); }
            if (strTypeOfDelivery) { formData.append('strTypeOfDelivery', strTypeOfDelivery); }

            //console.log("genedit: " + generateEdit);

            if (generateEdit === "Update") {
                updateCaseData(formData);
            } else if (generateEdit === "Generate") {
                generateCase(formData);
            }

        } else {
            setAddMessage(<span style={{ color: "red" }}>Please Select Case Status & Case Type</span>);
            setTimeout(() => setAddMessage(""), 3000);
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }

    }

    function generateCase(formData) {

        const requestOptions = {
            method: 'POST',
            body: formData
        };
        fetch("https://e-arogya.com:8443/api/general/patientcase/addnewcase", requestOptions)
            .then(res => {

                if (res.ok) {
                    setAddMessage(<span style={{ color: "green" }}>Patient case generated successfully</span>);
                    setTimeout(() => setAddMessage(""), 3000);
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    clearFields();
                    setTimeout(() => navigate('/hospitalpatientappointments'), 4000);

                } else {
                    setAddMessage(<span style={{ color: "red" }}>Patient case not generated. Please Try again.</span>);
                    setTimeout(() => setAddMessage(""), 3000);
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                }
            })
            .catch(error => {
                setAddMessage(<span style={{ color: "red" }}>Patient case not generated. Please try again.</span>);
                setTimeout(() => setAddMessage(""), 3000);
                window.scrollTo({ top: 0, behavior: 'smooth' });
            });

    }

    function updateCaseData(formData) {

        const requestOptions = {
            method: 'PUT',
            body: formData
        };
        fetch("https://e-arogya.com:8443/api/general/patientcase/updatepatientcase", requestOptions)
            .then(res => {

                if (res.ok) {
                    setAddMessage(<span style={{ color: "green" }}>Patient Case Updated Successfully</span>);
                    setTimeout(() => setAddMessage(""), 3000);
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    clearFields();
                    setTimeout(() => navigate('/hospitalpatientrecords'), 4000);

                } else {
                    setAddMessage(<span style={{ color: "red" }}>Patient case not updated. Please Try again.</span>);
                    setTimeout(() => setAddMessage(""), 3000);
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                }
            })
            .catch(error => {
                setAddMessage(<span style={{ color: "red" }}>Patient case not updated. Please try again.</span>);
                setTimeout(() => setAddMessage(""), 3000);
                window.scrollTo({ top: 0, behavior: 'smooth' });
            });
    }

    const clearFields = () => {

        setStrCaseType("- Select -");
        setStrCaseStatus("- Select -");
        //setItem(prevState => ({ ...prevState, strAppointmentType: "" }));
        setValue([]);
        setStrWeightNum("");
        setStrWeightUnit("kg");
        setStrHeightNum("");
        setStrHeightUnit("ft");
        setStrBmi("");
        setStrBsa("");
        setStrPulse("");
        setStrSpo("");
        setStrRespiratoryRate("");
        setStrTemperature("");
        //setStrBloodPressure("");
        setStrBloodPressureMM("");
        setStrBloodPressureHG("");
        setHistoryId(null);
        setStrPersonalHistory("");
        setStrMajorIllness("");
        setStrHospitalization("- Select -");
        setStrMedications("");
        setStrCovidHO("- Select -");
        setStrCovidHospitalization("- Select -");
        setStrCovidVaccine("- Select -");
        setStrFamilyHistory("");
        setStrMedicineName("");
        setStrMedicineType("");
        setStrMedicineDuration("");
        setStrMedicineFrequency("");
        setStrMedFreqMorning("");
        setStrMedFreqAfternoon("");
        setStrMedFreqEvening("");
        setStrMedFreqNight("");
        setStrMedicineQuantity("");
        setValueMedicineAdvise([]);
        // setStrMedication("");
        // setStrAdvice("");
        setStrDoctorAdvice("");
        setStrDoctorNotes("");
        setStrClinicalExamination("");
        // setStrInspection("");
        // setStrPalpation("");
        // setStrPercussion("");
        // setStrAusculation("");
        // setStrInvestigations("");
        setValueInvestigation([]);
        setStrDiagnosis("");
        setValueDiagnosisSearch([]);
        setStrMenstrualCycle("- Select -");
        setStrPain("- Select -");
        setDtmLmpDate("");
        setStrChildren("");
        setStrAbortion("");
        setStrTypeOfDelivery("- Select -");
        setStrCaseNo("");
        setStrAppointmentType("");

        setStrAttRemarks("");
        setFiles([]);
        aRef.current.value = null;

        setMedTableData([]);
        setAttachTableData([]);
    }

    const itemRender = (li, itemProps) => {
        const index = itemProps.index;
        const itemChildren = (
            <span style={{ fontSize: 12 }} >
                {li.props.children}
            </span>
        );
        return React.cloneElement(li, li.props, itemChildren);
    };
    const tagRender = (tagData, li) =>
        React.cloneElement(li, li.props, [
            <span key={tagData.data[0]} className="custom-tag" style={{ fontSize: 12 }}>
                {tagData.data[0]}
            </span>,
            li.props.children,
        ]);

    localStorage.setItem("mytable", JSON.stringify(medTableData));

    return (

        <div className="main" >

            <HospitalSidebar />

            <div className="page-content">

                <Adminheader />

                <h5>Generate Case</h5>

                <div className="table-content responsive" >

                    <Container fluid >

                        <Form onSubmit={onGenerateCaseFormSubmit}>

                            <Row style={{
                                padding: 15,
                                backgroundImage: `url(${HeaderBackground})`,
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                                color: "white",
                                justifyContent: "center",
                                fontSize: 12,
                            }}>

                                <Row>

                                    {/* <Row> <p style={{ fontSize: 20 }}>Patient Info</p> </Row> */}

                                    <Col md={1} className="justify-content-md-center">
                                        {strGender === "Female" ?
                                            <Image src={Female} className="banner_img" alt="check" width={70} height={70} />
                                            :
                                            <Image src={Male} className="banner_img" alt="check" width={70} height={70} />
                                        }
                                    </Col>

                                    <Col md={3}>
                                        <Row> <Form.Label ><b>Patient Id : </b>  {intPatientID} </Form.Label> </Row>
                                        <Row> <Form.Label htmlFor="inputPassword5"><b>Patient Name : </b>  {strFirstName} {strLastName} </Form.Label> </Row>
                                        <Row> <Form.Label htmlFor="inputPassword5"><b>Date Of Birth : </b> {dtmBirthDate !== null ? moment(dtmBirthDate).format('DD-MMM-YYYY') : null}</Form.Label> </Row>
                                    </Col>

                                    <Col md={3}>
                                        <Row> <Form.Label htmlFor="inputPassword5"><b>Gender : </b>  {strGender}</Form.Label> </Row>
                                        <Row> <Form.Label htmlFor="inputPassword5"><b>Blood Group: </b> {strBloodGroup} </Form.Label> </Row>
                                        <Row> <Form.Label htmlFor="inputPassword5"><b>Mobile No. : </b>  {strmobileno}</Form.Label> </Row>
                                    </Col>

                                    <Col md={3}>
                                        <Row> <Form.Label htmlFor="inputPassword5"><b>Address : </b>  {straddress}</Form.Label> </Row>
                                        <Row> <Form.Label htmlFor="inputPassword5"><b>City : </b>  {strcityname}</Form.Label> </Row>
                                        <Row> <Form.Label htmlFor="inputPassword5"><b>State : </b>  {strstatename}</Form.Label> </Row>
                                    </Col>

                                    <Col md={2}>
                                        <Row> <Form.Label htmlFor="inputPassword5"><b>Total no. of Visits : </b>  {totalVisits}</Form.Label> </Row>
                                        <Row>
                                            <div>
                                                <Button variant="light" size="sm" style={{ fontSize: 12, paddingLeft: 20, paddingRight: 20, marginBottom: 5 }} onClick={() => { setPatientHistory(true) }}>Get Patient History</Button>
                                            </div>
                                        </Row>
                                        <Row>
                                            <div>
                                                <Button variant="light" size="sm" style={{ fontSize: 12, paddingLeft: 20, paddingRight: 20 }} onClick={() => { setPatientOtherDetails(true) }}>Get Patient Other Details</Button>
                                            </div>
                                        </Row>
                                    </Col>

                                </Row>

                            </Row>

                            <Modal size="lg" show={patientHistory} onHide={handleClosePatientHistory}>
                                <Modal.Header closeButton>
                                    <Modal.Title style={{ fontSize: 18 }}>Patient History</Modal.Title>
                                </Modal.Header>
                                <Modal.Body style={{ fontSize: 14 }}>

                                    <Col>

                                        <Row style={{ alignContent: "center", marginBottom: 5 }}>
                                            <Col xs={4} style={{ fontSize: 12, color: "grey" }}>
                                                Personal History
                                            </Col>
                                            <Col xs={8}>
                                                <Form.Group controlId="formSelectStateStatus">
                                                    <Form.Control as="textarea" rows={4} style={{ fontSize: 12 }} value={strPersonalHistory || ""} onChange={e => { setStrPersonalHistory(e.target.value) }} />
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <Row style={{ alignItems: "center", alignContent: "center", marginBottom: 5 }}>
                                            <Col xs={4} style={{ fontSize: 12, color: "grey" }}>
                                                Major Illness
                                            </Col>
                                            <Col xs={8}>
                                                <Form.Group controlId="formSelectStateStatus">
                                                    <Form.Control type="text" style={{ fontSize: 12 }} value={strMajorIllness || ""} onChange={e => { setStrMajorIllness(e.target.value) }} />
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <Row style={{ alignItems: "center", alignContent: "center", marginBottom: 5 }}>
                                            <Col xs={4} style={{ fontSize: 12, color: "grey" }}>
                                                Hospitalization
                                            </Col>
                                            <Col xs={8}>
                                                <Form.Group controlId="formSelectStateStatus">
                                                    <Form.Select aria-label="Default select example" style={{ fontSize: 12 }} value={strHospitalization || ""} onChange={e => { setStrHospitalization(e.target.value) }}>
                                                        <option value="- Select -">- Select -</option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">No</option>
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <Row style={{ alignItems: "center", alignContent: "center", marginBottom: 5 }}>
                                            <Col xs={4} style={{ fontSize: 12, color: "grey" }}>
                                                Medications
                                            </Col>
                                            <Col xs={8}>
                                                <Form.Group controlId="formSelectStateStatus">
                                                    <Form.Control type="text" style={{ fontSize: 12 }} value={strMedications || ""} onChange={e => { setStrMedications(e.target.value) }} />
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <Row style={{ alignItems: "center", alignContent: "center", marginBottom: 5 }}>
                                            <Col xs={4} style={{ fontSize: 12, color: "grey" }}>
                                                Covid-19 H/o
                                            </Col>
                                            <Col xs={8}>
                                                <Form.Group controlId="formSelectStateStatus">
                                                    <Form.Select aria-label="Default select example" style={{ fontSize: 12 }} value={strCovidHO || ""} onChange={e => { setStrCovidHO(e.target.value) }}>
                                                        <option value="- Select -">- Select -</option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">No</option>
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <Row style={{ alignItems: "center", alignContent: "center", marginBottom: 5 }}>
                                            <Col xs={4} style={{ fontSize: 12, color: "grey" }}>
                                                Covid Hospitalization
                                            </Col>
                                            <Col xs={8}>
                                                <Form.Group controlId="formSelectStateStatus">
                                                    <Form.Select aria-label="Default select example" style={{ fontSize: 12 }} value={strCovidHospitalization || ""} onChange={e => { setStrCovidHospitalization(e.target.value) }}>
                                                        <option value="- Select -">- Select -</option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">No</option>
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <Row style={{ alignItems: "center", alignContent: "center", marginBottom: 5 }}>
                                            <Col xs={4} style={{ fontSize: 12, color: "grey" }}>
                                                Covid-19 Vaccine
                                            </Col>
                                            <Col xs={8}>
                                                <Form.Group controlId="formSelectStateStatus">
                                                    <Form.Select aria-label="Default select example" style={{ fontSize: 12 }} value={strCovidVaccine || ""} onChange={e => { setStrCovidVaccine(e.target.value) }}>
                                                        <option value="- Select -">- Select -</option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">No</option>
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <Row style={{ alignContent: "center", marginBottom: 5 }}>
                                            <Col xs={4} style={{ fontSize: 12, color: "grey" }}>
                                                Family History
                                            </Col>
                                            <Col xs={8}>
                                                <Form.Group controlId="formSelectStateStatus">
                                                    <Form.Control as="textarea" rows={5} style={{ fontSize: 12 }} value={strFamilyHistory || ""} onChange={e => { setStrFamilyHistory(e.target.value) }} />
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                    </Col>

                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleClosePatientHistory} style={{ fontSize: 12 }}> Close </Button>
                                    <Button variant="primary" onClick={saveHistoryData} style={{ fontSize: 12 }}> Save </Button>
                                </Modal.Footer>
                            </Modal>

                            <Modal size="lg" show={patientOtherDetails} onHide={handleClosePatientOtherDetails}>
                                <Modal.Header closeButton>
                                    <Modal.Title style={{ fontSize: 18 }}>Patient Other Details</Modal.Title>
                                </Modal.Header>
                                <Modal.Body style={{ fontSize: 14 }}>
                                    <Col>

                                        <Row style={{ alignContent: "center", marginBottom: 5 }}>
                                            <Col md={4} style={{ fontSize: 12, color: "grey", fontWeight: "bold" }}>
                                                Marital Status:
                                            </Col>
                                            <Col md={8} style={{ fontSize: 12, color: "grey" }}>
                                                {strMaritalStatus}
                                            </Col>
                                        </Row>

                                        <Row style={{ alignContent: "center", marginBottom: 5 }}>
                                            <Col md={4} style={{ fontSize: 12, color: "grey", fontWeight: "bold" }}>
                                                Religion:
                                            </Col>
                                            <Col md={8} style={{ fontSize: 12, color: "grey" }}>
                                                {strReligion}
                                            </Col>
                                        </Row>

                                        <Row style={{ alignContent: "center", marginBottom: 5 }}>
                                            <Col md={4} style={{ fontSize: 12, color: "grey", fontWeight: "bold" }}>
                                                Occupation:
                                            </Col>
                                            <Col md={8} style={{ fontSize: 12, color: "grey" }}>
                                                {strOccupation}
                                            </Col>
                                        </Row>

                                        <Row style={{ alignContent: "center", marginBottom: 5 }}>
                                            <Col md={4} style={{ fontSize: 12, color: "grey", fontWeight: "bold" }}>
                                                Allergies:
                                            </Col>
                                            <Col md={8} style={{ fontSize: 12, color: "grey" }}>
                                                {strAllergies}
                                            </Col>
                                        </Row>

                                        <Row style={{ alignContent: "center", marginBottom: 5 }}>
                                            <Col md={4} style={{ fontSize: 12, color: "grey", fontWeight: "bold" }}>
                                                Habits:
                                            </Col>
                                            <Col md={8} style={{ fontSize: 12, color: "grey" }}>
                                                {strHabits}
                                            </Col>
                                        </Row>

                                        <Row style={{ alignContent: "center", marginBottom: 5 }}>
                                            <Col md={4} style={{ fontSize: 12, color: "grey", fontWeight: "bold" }}>
                                                Diet:
                                            </Col>
                                            <Col md={8} style={{ fontSize: 12, color: "grey" }}>
                                                {strDiet}
                                            </Col>
                                        </Row>

                                        <Row style={{ alignContent: "center", marginBottom: 5 }}>
                                            <Col md={4} style={{ fontSize: 12, color: "grey", fontWeight: "bold" }}>
                                                Lifestyle:
                                            </Col>
                                            <Col md={8} style={{ fontSize: 12, color: "grey" }}>
                                                {strLifestyle}
                                            </Col>
                                        </Row>

                                        <Row style={{ alignContent: "center", marginBottom: 5 }}>
                                            <Col md={4} style={{ fontSize: 12, color: "grey", fontWeight: "bold" }}>
                                                Insurance Company Name:
                                            </Col>
                                            <Col md={8} style={{ fontSize: 12, color: "grey" }}>
                                                {strPatInsuranceCompanyName}
                                            </Col>
                                        </Row>

                                        <Row style={{ alignContent: "center", marginBottom: 5 }}>
                                            <Col md={4} style={{ fontSize: 12, color: "grey", fontWeight: "bold" }}>
                                                Insurance Number:
                                            </Col>
                                            <Col md={8} style={{ fontSize: 12, color: "grey" }}>
                                                {strPatInsuranceNumber}
                                            </Col>
                                        </Row>

                                        <Row style={{ alignContent: "center", marginBottom: 5 }}>
                                            <Col md={4} style={{ fontSize: 12, color: "grey", fontWeight: "bold" }}>
                                                Insurance Expiry Date:
                                            </Col>
                                            <Col md={8} style={{ fontSize: 12, color: "grey" }}>
                                                {strPatInsuranceExpiryDate !== null ? <td>{moment(strPatInsuranceExpiryDate).format('DD-MMM-YYYY')}</td> : null}
                                            </Col>
                                        </Row>

                                        <Row style={{ alignContent: "center", marginBottom: 5 }}>
                                            <Col style={{ fontSize: 12, color: "grey", fontWeight: "bold" }}>
                                                Vaccination Details:
                                            </Col>
                                        </Row>

                                        <Row style={{ alignItems: "center", alignContent: "center" }}>
                                            <Table bordered hover size="sm" responsive style={{ fontSize: 12, color: "grey" }}>
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Vaccine Name</th>
                                                        <th>No. of Dose</th>
                                                        <th>Dosage Date</th>
                                                    </tr>
                                                </thead>
                                                {patientVaccineDetails.length !== 0 ?
                                                    <tbody>
                                                        {
                                                            patientVaccineDetails.map((data, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>{index + 1}</td>
                                                                        <td>{data.strVaccineName}</td>
                                                                        <td>{data.intNoOfDose}</td>
                                                                        {data.dtmDosageDate !== null ? <td>{moment(data.dtmDosageDate).format('DD-MMM-YYYY')}</td> : null}
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                    :
                                                    <tbody>
                                                        <tr>
                                                            <td colSpan={4} style={{ textAlign: "center" }}>No Vaccine Details Found</td>
                                                        </tr>
                                                    </tbody>
                                                }
                                            </Table>
                                        </Row>
                                        <Row style={{ alignItems: "center", alignContent: "center" }}>
                                            {patientDocVaccineImagesDetails.length !== 0 ?
                                                <div>
                                                    {patientDocVaccineImagesDetails.map((pat, index) => {
                                                        return (
                                                            <div key={"imgDoc" + index} >
                                                                <Row style={{ alignContent: "center", marginBottom: 5 }}>
                                                                    <Col md={2} style={{ fontSize: 12, color: "grey", fontWeight: "bold" }}>
                                                                        <Image
                                                                            onClick={() => { setShowMyImageAttachments(true); setMyImageAttachments(pat.strPatientDocument); }}
                                                                            src={`data:image/jpeg;base64,${(pat.strPatientDocument)}`}
                                                                            height={75}
                                                                            width={75}
                                                                            style={{ padding: 2, cursor: "pointer" }}
                                                                        />
                                                                    </Col>
                                                                    <Col md={10} style={{ fontSize: 12, color: "grey" }}>
                                                                        {pat.strDocumentRemarks}
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        );
                                                    })
                                                    }
                                                </div>
                                                :
                                                null
                                            }
                                        </Row>
                                        <Row style={{ alignContent: "center", marginBottom: 5 }}>
                                            <Col style={{ fontSize: 12, color: "grey", fontWeight: "bold" }}>
                                                Medication Details:
                                            </Col>
                                        </Row>

                                        <Row style={{ alignItems: "center", alignContent: "center" }}>
                                            <Table bordered hover size="sm" responsive style={{ fontSize: 12, color: "grey" }}>
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Disease Name</th>
                                                        <th>Medicine Name</th>
                                                        <th>Strength</th>
                                                        <th>Start Date</th>
                                                        <th>End Date</th>
                                                    </tr>
                                                </thead>
                                                {patientMedicationDetails.length !== 0 ?
                                                    <tbody>
                                                        {
                                                            patientMedicationDetails.map((data, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>{index + 1}</td>
                                                                        <td>{data.strDiseaseName}</td>
                                                                        <td>{data.strMedicineName}</td>
                                                                        <td>{data.strStrength}</td>
                                                                        {data.dtmMedicineStartDate !== null ? <td>{moment(data.dtmMedicineStartDate).format('DD-MMM-YYYY')}</td> : null}
                                                                        {data.dtmMedicineEndDate !== null ? <td>{moment(data.dtmMedicineEndDate).format('DD-MMM-YYYY')}</td> : null}
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                    :
                                                    <tbody>
                                                        <tr>
                                                            <td colSpan={6} style={{ textAlign: "center" }}>No Medication Details Found</td>
                                                        </tr>
                                                    </tbody>
                                                }

                                            </Table>
                                        </Row>
                                        <Row style={{ alignItems: "center", alignContent: "center" }}>
                                            {patientDocMedicationImagesDetails.length !== 0 ?
                                                <div>
                                                    {patientDocMedicationImagesDetails.map((pat, index) => {
                                                        return (
                                                            <div key={"imgDoc" + index} >
                                                                <Row style={{ alignContent: "center", marginBottom: 5 }}>
                                                                    <Col md={2} style={{ fontSize: 12, color: "grey", fontWeight: "bold" }}>
                                                                        <Image
                                                                            onClick={() => { setShowMyImageAttachments(true); setMyImageAttachments(pat.strPatientDocument); }}
                                                                            src={`data:image/jpeg;base64,${(pat.strPatientDocument)}`}
                                                                            height={75}
                                                                            width={75}
                                                                            style={{ padding: 2, cursor: "pointer" }}
                                                                        />
                                                                    </Col>
                                                                    <Col md={10} style={{ fontSize: 12, color: "grey" }}>
                                                                        {pat.strDocumentRemarks}
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        );
                                                    })
                                                    }
                                                </div>
                                                :
                                                null
                                            }
                                        </Row>
                                        <Row style={{ alignContent: "center", marginBottom: 5 }}>
                                            <Col style={{ fontSize: 12, color: "grey", fontWeight: "bold" }}>
                                                Clinical Medication Details (LifeLong Disease):
                                            </Col>
                                        </Row>

                                        <Row style={{ alignItems: "center", alignContent: "center" }}>
                                            <Table bordered hover size="sm" responsive style={{ fontSize: 12, color: "grey" }}>
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Disease Name</th>
                                                        <th>Medicine Name</th>
                                                        <th>Strength</th>
                                                        <th>Start Date</th>
                                                        <th>Reminder Date</th>
                                                        <th>Notification Time</th>
                                                    </tr>
                                                </thead>
                                                {patientClinicalDetails.length !== 0 ?
                                                    <tbody>
                                                        {
                                                            patientClinicalDetails.map((data, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>{index + 1}</td>
                                                                        <td>{data.strLifelongDiseaseName}</td>
                                                                        <td>{data.strLifelongMedicineName}</td>
                                                                        <td>{data.strLifelongStrength}</td>
                                                                        {data.dtmLifelongStartDate !== null ? <td>{moment(data.dtmLifelongStartDate).format('DD-MMM-YYYY')}</td> : null}
                                                                        {data.dtmLifelongReminderDate !== null ? <td>{moment(data.dtmLifelongReminderDate).format('DD-MMM-YYYY')}</td> : null}
                                                                        <td>{data.strLifelongNotificationTime}</td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                    :
                                                    <tbody>
                                                        <tr>
                                                            <td colSpan={7} style={{ textAlign: "center" }}>No Clinical Details Found</td>
                                                        </tr>
                                                    </tbody>
                                                }

                                            </Table>
                                        </Row>
                                        <Row style={{ alignItems: "center", alignContent: "center" }}>
                                            {patientDocClinicalImagesDetails.length !== 0 ?
                                                <div>
                                                    {patientDocClinicalImagesDetails.map((pat, index) => {
                                                        return (
                                                            <div key={"imgDoc" + index} >
                                                                <Row style={{ alignContent: "center", marginBottom: 5 }}>
                                                                    <Col md={2} style={{ fontSize: 12, color: "grey", fontWeight: "bold" }}>
                                                                        <Image
                                                                            onClick={() => { setShowMyImageAttachments(true); setMyImageAttachments(pat.strPatientDocument); }}
                                                                            src={`data:image/jpeg;base64,${(pat.strPatientDocument)}`}
                                                                            height={75}
                                                                            width={75}
                                                                            style={{ padding: 2, cursor: "pointer" }}
                                                                        />
                                                                    </Col>
                                                                    <Col md={10} style={{ fontSize: 12, color: "grey" }}>
                                                                        {pat.strDocumentRemarks}
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        );
                                                    })
                                                    }
                                                </div>
                                                :
                                                null
                                            }
                                        </Row>
                                        <Row style={{ alignContent: "center", marginBottom: 5 }}>
                                            <Col style={{ fontSize: 12, color: "grey", fontWeight: "bold" }}>
                                                Surgery Details:
                                            </Col>
                                        </Row>

                                        <Row style={{ alignItems: "center", alignContent: "center" }}>
                                            <Table bordered hover size="sm" responsive style={{ fontSize: 12, color: "grey" }}>
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Surgery Name</th>
                                                        <th>Hospital Name</th>
                                                        <th>Doctor's Name</th>
                                                        <th>Type of Surgery</th>
                                                        <th>Surgery Date</th>
                                                    </tr>
                                                </thead>
                                                {patientSurgeryDetails.length !== 0 ?
                                                    <tbody>
                                                        {
                                                            patientSurgeryDetails.map((data, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>{index + 1}</td>
                                                                        <td>{data.strSurgeryName}</td>
                                                                        <td>{data.strSurgeryHospitalName}</td>
                                                                        <td>{data.strSurgeryDoctorName}</td>
                                                                        <td>{data.strSurgeryType}</td>
                                                                        {data.dtmSurgeryDate !== null ? <td>{moment(data.dtmSurgeryDate).format('DD-MMM-YYYY')}</td> : null}
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                    :
                                                    <tbody>
                                                        <tr>
                                                            <td colSpan={6} style={{ textAlign: "center" }}>No Surgery Details Found</td>
                                                        </tr>
                                                    </tbody>
                                                }

                                            </Table>
                                        </Row>
                                        <Row style={{ alignItems: "center", alignContent: "center" }}>
                                            {patientDocSurgeryImagesDetails.length !== 0 ?
                                                <div>
                                                    {patientDocSurgeryImagesDetails.map((pat, index) => {
                                                        return (
                                                            <div key={"imgDoc" + index} >
                                                                <Row style={{ alignContent: "center", marginBottom: 5 }}>
                                                                    <Col md={2} style={{ fontSize: 12, color: "grey", fontWeight: "bold" }}>
                                                                        <Image
                                                                            onClick={() => { setShowMyImageAttachments(true); setMyImageAttachments(pat.strPatientDocument); }}
                                                                            src={`data:image/jpeg;base64,${(pat.strPatientDocument)}`}
                                                                            height={75}
                                                                            width={75}
                                                                            style={{ padding: 2, cursor: "pointer" }}
                                                                        />
                                                                    </Col>
                                                                    <Col md={10} style={{ fontSize: 12, color: "grey" }}>
                                                                        {pat.strDocumentRemarks}
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        );
                                                    })
                                                    }
                                                </div>
                                                :
                                                null
                                            }
                                        </Row>

                                    </Col>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleClosePatientOtherDetails} style={{ fontSize: 12 }}> Close </Button>
                                </Modal.Footer>
                            </Modal>

                            <Modal show={show} onHide={handleClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title style={{ fontSize: 18 }}>Delete Attachments</Modal.Title>
                                </Modal.Header>
                                <Modal.Body style={{ fontSize: 14 }}>Are you sure you want to delete this attached file?</Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleClose} style={{ fontSize: 12 }}>No</Button>
                                    <Button variant="primary" onClick={() => {
                                        handleClose();
                                        deleteThisFunc(deleteThis);
                                    }} style={{ fontSize: 12 }}>Yes</Button>
                                </Modal.Footer>
                            </Modal>

                            <Modal show={showPreviousComplains} onHide={handleClosePreviousComplains} size="xl">
                                <Modal.Header closeButton>
                                    <Modal.Title style={{ fontSize: 18 }}>Previous Complains</Modal.Title>
                                </Modal.Header>
                                <Modal.Body style={{ fontSize: 14 }}>
                                    <table className="table" style={{ padding: 10 }} >
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Date</th>
                                                <th>Complains</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {previousComplains.map((pc, index) => (
                                                <tr key={index}>
                                                    <td style={{ fontSize: 12, color: "grey" }}>{index + 1}</td>
                                                    <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={pc.dtmupdatedate}>{moment(pc.dtmupdatedate).format('DD-MMM-YYYY')}</Form.Label></td>
                                                    <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={pc.strComplain}>{pc.strComplain}</Form.Label></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="primary" onClick={handleClosePreviousComplains} style={{ fontSize: 12 }}> Ok </Button>
                                </Modal.Footer>
                            </Modal>

                            <Modal show={showPreviousInvestigation} onHide={handleClosePreviousInvestigation} size="xl">
                                <Modal.Header closeButton>
                                    <Modal.Title style={{ fontSize: 18 }}>Previous Investigation</Modal.Title>
                                </Modal.Header>
                                <Modal.Body style={{ fontSize: 14 }}>
                                    <table className="table" style={{ padding: 10 }} >
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Date</th>
                                                <th>Investigation</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {previousInvestigation.map((pc, index) => (
                                                <tr key={index}>
                                                    <td style={{ fontSize: 12, color: "grey" }}>{index + 1}</td>
                                                    <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={pc.dtmupdatedate}>{moment(pc.dtmupdatedate).format('DD-MMM-YYYY')}</Form.Label></td>
                                                    <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={pc.strInvestigation}>{pc.strInvestigation}</Form.Label></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="primary" onClick={handleClosePreviousInvestigation} style={{ fontSize: 12 }}> Ok </Button>
                                </Modal.Footer>
                            </Modal>

                            <Modal show={showPreviousVitals} onHide={handleClosePreviousVitals} size="xl">
                                <Modal.Header closeButton>
                                    <Modal.Title style={{ fontSize: 18 }}>Previous Vitals</Modal.Title>
                                </Modal.Header>
                                <Modal.Body style={{ fontSize: 14 }}>
                                    <table className="table" style={{ padding: 10 }} >
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Date</th>
                                                <th>Weight</th>
                                                <th>Height</th>
                                                <th>BMI</th>
                                                <th>BSA</th>
                                                <th>Pulse</th>
                                                <th>SpO2</th>
                                                <th>Blood Pressure</th>
                                                <th>Respiratory Rate</th>
                                                <th>Temperature</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {previousVitals.map((pv, index) => (
                                                <tr key={index}>
                                                    <td style={{ fontSize: 12, color: "grey" }}>{index + 1}</td>
                                                    <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={pv.dtmupdatedate}>{moment(pv.dtmupdatedate).format('DD-MMM-YYYY')}</Form.Label></td>
                                                    <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={pv.strWeight}>{pv.strWeight}</Form.Label></td>
                                                    <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={pv.strHeight}>{pv.strHeight}</Form.Label></td>
                                                    <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={pv.strBmi}>{pv.strBmi} kg/m2</Form.Label></td>
                                                    <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={pv.strBsa}>{pv.strBsa} m2</Form.Label></td>
                                                    <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={pv.strPulse}>{pv.strPulse} /min</Form.Label></td>
                                                    <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={pv.strSpo}>{pv.strSpo} %</Form.Label></td>
                                                    <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={pv.strBloodPressure}>{pv.strBloodPressure} mm Hg</Form.Label></td>
                                                    <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={pv.strRespiratoryRate}>{pv.strRespiratoryRate} breaths/min</Form.Label></td>
                                                    <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={pv.strTemperature}>{pv.strTemperature} °F</Form.Label></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="primary" onClick={handleClosePreviousVitals} style={{ fontSize: 12 }}> Ok </Button>
                                </Modal.Footer>
                            </Modal>

                            {/* <Modal show={showPreviousHistory} onHide={handleClosePreviousHistory} size="xl">
                                <Modal.Header closeButton>
                                    <Modal.Title style={{ fontSize: 18 }}>Previous History</Modal.Title>
                                </Modal.Header>
                                <Modal.Body style={{ fontSize: 14 }}>
                                    <table className="table" style={{ padding: 10 }} >
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Date</th>
                                                <th>Personal History</th>
                                                <th>Major Illness</th>
                                                <th>Hospitalization</th>
                                                <th>Medications</th>
                                                <th>Covid HO</th>
                                                <th>Covid Hospitalization</th>
                                                <th>Covid Vaccine</th>
                                                <th>Family History</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {previousHistory.map((ph, index) => (
                                                <tr key={index}>
                                                    <td style={{ fontSize: 12, color: "grey" }}>{index + 1}</td>
                                                    <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={ph.dtmupdatedate}>{moment(ph.dtmupdatedate).format('DD-MMM-YYYY')}</Form.Label></td>
                                                    <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={ph.strPersonalHistory}>{ph.strPersonalHistory}</Form.Label></td>
                                                    <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={ph.strMajorIllness}>{ph.strMajorIllness}</Form.Label></td>
                                                    <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={ph.strHospitalization}>{ph.strHospitalization}</Form.Label></td>
                                                    <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={ph.strMedications}>{ph.strMedications}</Form.Label></td>
                                                    <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={ph.strCovidHO}>{ph.strCovidHO}</Form.Label></td>
                                                    <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={ph.strCovidHospitalization}>{ph.strCovidHospitalization}</Form.Label></td>
                                                    <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={ph.strCovidVaccine}>{ph.strCovidVaccine}</Form.Label></td>
                                                    <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={ph.strFamilyHistory}>{ph.strFamilyHistory}</Form.Label></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="primary" onClick={handleClosePreviousHistory} style={{ fontSize: 12 }}> Ok </Button>
                                </Modal.Footer>
                            </Modal> */}

                            <Modal show={showPreviousMedicationTreatment} onHide={handleClosePreviousMedicationTreatment} size="xl">
                                <Modal.Header closeButton>
                                    <Modal.Title style={{ fontSize: 18 }}>Previous Medication and Treatment</Modal.Title>
                                </Modal.Header>
                                <Modal.Body style={{ fontSize: 14 }}>
                                    <table className="table" style={{ padding: 10 }} >
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Date</th>
                                                <th>MedicineName</th>
                                                <th>Type of Medicine</th>
                                                <th>Duration</th>
                                                <th>Frequency</th>
                                                <th>Mor</th>
                                                <th>Aft</th>
                                                <th>Eve</th>
                                                <th>Nig</th>
                                                <th>Quantity</th>
                                                <th>Advise</th>
                                                {/* <th>Medication</th>
                                            <th>Treatment</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {previousMedicationTreatment.map((pmt, index) => (
                                                <tr key={index}>
                                                    <td style={{ fontSize: 12, color: "grey" }}>{index + 1}</td>
                                                    <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={pmt.dtmupdatedate}>{moment(pmt.dtmupdatedate).format('DD-MMM-YYYY')}</Form.Label></td>
                                                    <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={pmt.strMedicineName}>{pmt.strMedicineName}</Form.Label></td>
                                                    <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={pmt.strMedicineType}>{pmt.strMedicineType}</Form.Label></td>
                                                    <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={pmt.strMedicineDuration}>{pmt.strMedicineDuration}</Form.Label></td>
                                                    <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={pmt.strMedicineFrequency}>{pmt.strMedicineFrequency}</Form.Label></td>
                                                    <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={pmt.strMedFreqMorning}>{pmt.strMedFreqMorning}</Form.Label></td>
                                                    <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={pmt.strMedFreqAfternoon}>{pmt.strMedFreqAfternoon}</Form.Label></td>
                                                    <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={pmt.strMedFreqEvening}>{pmt.strMedFreqEvening}</Form.Label></td>
                                                    <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={pmt.strMedFreqNight}>{pmt.strMedFreqNight}</Form.Label></td>
                                                    <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={pmt.strMedicineQuantity}>{pmt.strMedicineQuantity}</Form.Label></td>
                                                    <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={pmt.strMedicineAdvise}>{pmt.strMedicineAdvise}</Form.Label></td>
                                                    {/* <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={pmt.strMedication}>{pmt.strMedication}</Form.Label></td>
                                                <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={pmt.strAdvice}>{pmt.strAdvice}</Form.Label></td> */}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="primary" onClick={handleClosePreviousMedicationTreatment} style={{ fontSize: 12 }}> Ok </Button>
                                </Modal.Footer>
                            </Modal>

                            <Modal show={showPreviousDoctorAdvice} onHide={handleClosePreviousDoctorAdvice} size="xl">
                                <Modal.Header closeButton>
                                    <Modal.Title style={{ fontSize: 18 }}>Previous Advice</Modal.Title>
                                </Modal.Header>
                                <Modal.Body style={{ fontSize: 14 }}>
                                    <table className="table" style={{ padding: 10 }} >
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Date</th>
                                                <th>Advice</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {previousDoctorAdvice.map((pce, index) => (
                                                <tr key={index}>
                                                    <td style={{ fontSize: 12, color: "grey" }}>{index + 1}</td>
                                                    <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={pce.dtmupdatedate}>{moment(pce.dtmupdatedate).format('DD-MMM-YYYY')}</Form.Label></td>
                                                    <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={pce.strDoctorAdvice}>{pce.strDoctorAdvice}</Form.Label></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="primary" onClick={handleClosePreviousDoctorAdvice} style={{ fontSize: 12 }}> Ok </Button>
                                </Modal.Footer>
                            </Modal>

                            <Modal show={showPreviousDoctorNotes} onHide={handleClosePreviousDoctorNotes} size="xl">
                                <Modal.Header closeButton>
                                    <Modal.Title style={{ fontSize: 18 }}>Previous Notes</Modal.Title>
                                </Modal.Header>
                                <Modal.Body style={{ fontSize: 14 }}>
                                    <table className="table" style={{ padding: 10 }} >
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Date</th>
                                                <th>Notes</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {previousDoctorNotes.map((pce, index) => (
                                                <tr key={index}>
                                                    <td style={{ fontSize: 12, color: "grey" }}>{index + 1}</td>
                                                    <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={pce.dtmupdatedate}>{moment(pce.dtmupdatedate).format('DD-MMM-YYYY')}</Form.Label></td>
                                                    <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={pce.strDoctorNotes}>{pce.strDoctorNotes}</Form.Label></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="primary" onClick={handleClosePreviousDoctorNotes} style={{ fontSize: 12 }}> Ok </Button>
                                </Modal.Footer>
                            </Modal>

                            <Modal show={showPreviousClinicalExamination} onHide={handleClosePreviousClinicalExamination} size="xl">
                                <Modal.Header closeButton>
                                    <Modal.Title style={{ fontSize: 18 }}>Previous Clinical Examination</Modal.Title>
                                </Modal.Header>
                                <Modal.Body style={{ fontSize: 14 }}>
                                    <table className="table" style={{ padding: 10 }} >
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Date</th>
                                                <th>Clinical Examination</th>
                                                {/* <th>Inspection</th>
                                            <th>Palpation</th>
                                            <th>Percussion</th>
                                            <th>Ausculation</th>
                                            <th>Investigations</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {previousClinicalExamination.map((pce, index) => (
                                                <tr key={index}>
                                                    <td style={{ fontSize: 12, color: "grey" }}>{index + 1}</td>
                                                    <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={pce.dtmupdatedate}>{moment(pce.dtmupdatedate).format('DD-MMM-YYYY')}</Form.Label></td>
                                                    <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={pce.strClinicalExamination}>{pce.strClinicalExamination}</Form.Label></td>
                                                    {/* <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={pce.strInspection}>{pce.strInspection}</Form.Label></td>
                                                <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={pce.strPalpation}>{pce.strPalpation}</Form.Label></td>
                                                <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={pce.strPercussion}>{pce.strPercussion}</Form.Label></td>
                                                <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={pce.strAusculation}>{pce.strAusculation}</Form.Label></td>
                                                <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={pce.strInvestigations}>{pce.strInvestigations}</Form.Label></td> */}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="primary" onClick={handleClosePreviousClinicalExamination} style={{ fontSize: 12 }}> Ok </Button>
                                </Modal.Footer>
                            </Modal>

                            <Modal show={showPreviousDiagnosis} onHide={handleClosePreviousDiagnosis} size="xl">
                                <Modal.Header closeButton>
                                    <Modal.Title style={{ fontSize: 18 }}>Previous Diagnosis</Modal.Title>
                                </Modal.Header>
                                <Modal.Body style={{ fontSize: 14 }}>
                                    <table className="table" style={{ padding: 10 }} >
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Date</th>
                                                <th>Disgnosis</th>
                                                <th>Notes</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {previousDiagnosis.map((pd, index) => (
                                                <tr key={index}>
                                                    <td style={{ fontSize: 12, color: "grey" }}>{index + 1}</td>
                                                    <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={pd.dtmupdatedate}>{moment(pd.dtmupdatedate).format('DD-MMM-YYYY')}</Form.Label></td>
                                                    <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={pd.strDiagnosisSearch}>{pd.strDiagnosisSearch}</Form.Label></td>
                                                    <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={pd.strDiagnosis}>{pd.strDiagnosis}</Form.Label></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="primary" onClick={handleClosePreviousDiagnosis} style={{ fontSize: 12 }}> Ok </Button>
                                </Modal.Footer>
                            </Modal>

                            <Modal show={showPreviousForFemalesOnly} onHide={handleClosePreviousForFemalesOnly} size="xl">
                                <Modal.Header closeButton>
                                    <Modal.Title style={{ fontSize: 18 }}>Previous Data</Modal.Title>
                                </Modal.Header>
                                <Modal.Body style={{ fontSize: 14 }}>
                                    <table className="table" style={{ padding: 10 }} >
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Date</th>
                                                <th>Menstrual Cycle</th>
                                                <th>Pain</th>
                                                <th>Lmp Date</th>
                                                <th>Children</th>
                                                <th>Abortion</th>
                                                <th>Type Of Delivery</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {previousForFemalesOnly.map((pffo, index) => (
                                                <tr key={index}>
                                                    <td style={{ fontSize: 12, color: "grey" }}>{index + 1}</td>
                                                    <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={pffo.dtmupdatedate}>{moment(pffo.dtmupdatedate).format('DD-MMM-YYYY')}</Form.Label></td>
                                                    <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={pffo.strMenstrualCycle}>{pffo.strMenstrualCycle}</Form.Label></td>
                                                    <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={pffo.strPain}>{pffo.strPain}</Form.Label></td>
                                                    <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={pffo.dtmLmpDate}>{pffo.dtmLmpDate}</Form.Label></td>
                                                    <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={pffo.strChildren}>{pffo.strChildren}</Form.Label></td>
                                                    <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={pffo.strAbortion}>{pffo.strAbortion}</Form.Label></td>
                                                    <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={pffo.strTypeOfDelivery}>{pffo.strTypeOfDelivery}</Form.Label></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="primary" onClick={handleClosePreviousForFemalesOnly} style={{ fontSize: 12 }}> Ok </Button>
                                </Modal.Footer>
                            </Modal>

                            <Modal show={showPreviousAttachments} onHide={handleClosePreviousAttachments} size="xl">
                                <Modal.Header closeButton>
                                    <Modal.Title style={{ fontSize: 18 }}>Previous Attachments</Modal.Title>
                                </Modal.Header>
                                <Modal.Body style={{ fontSize: 14 }}>
                                    <table className="table" style={{ padding: 10 }} >
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Date</th>
                                                <th>File</th>
                                                <th>File Name</th>
                                                <th>File Remarks</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {previousAttachments.map((pa, index) => (
                                                <tr key={index}>
                                                    <td style={{ fontSize: 12, color: "grey" }}>{index + 1}</td>
                                                    <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={pa.dtmupdatedate}>{moment(pa.dtmupdatedate).format('DD-MMM-YYYY')}</Form.Label></td>
                                                    <td>
                                                        {pa.strFileName.split('.').pop().match("pdf") ?
                                                            <Image width={50} height={50} src={PDFFileImage} />
                                                            :
                                                            <Image width={50} height={50} src={`data:image/jpeg;base64,${pa.strFile}`} />
                                                        }
                                                    </td>
                                                    <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={pa.strFileName}>{pa.strFileName}</Form.Label></td>
                                                    <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={pa.strAttRemarks}>{pa.strAttRemarks}</Form.Label></td>
                                                    <td onClick={() => { ViewAttachmentFiles(pa.strFileName, pa.strFile) }} ><Form.Label style={{ fontSize: 12, color: "RoyalBlue", cursor: "pointer" }} >View File</Form.Label></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="primary" onClick={handleClosePreviousAttachments} style={{ fontSize: 12 }}> Ok </Button>
                                </Modal.Footer>
                            </Modal>

                            <Modal show={showMyAttachments} onHide={handleCloseMyAttachments} fullscreen={true} >
                                <Modal.Header closeButton>
                                    <Modal.Title style={{ fontSize: 18 }}>Files</Modal.Title>
                                </Modal.Header>
                                <Modal.Body style={{ fontSize: 14, display: "flex", justifyContent: "center" }}>
                                    {filetype.match("pdf") ?
                                        <div>
                                            <Document file={`data:application/pdf;base64,${myAttachments}`} renderTextLayer={false} options={{ workerSrc: "/pdf.worker.js" }} onLoadSuccess={onDocumentLoadSuccess}  >
                                                {Array.from(new Array(numPages), (el, index) => (<Page key={`page_${index + 1}`} pageNumber={index + 1} />))}
                                            </Document>
                                            {/* file={samplePDF}  */}
                                        </div>
                                        : filetype.match("png") ?
                                            <div>
                                                <Image src={`data:image/png;base64,${myAttachments}`} />
                                            </div>
                                            :
                                            <div>
                                                <Image src={`data:image/jpeg;base64,${myAttachments}`} />
                                            </div>
                                    }
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="primary" onClick={handleCloseMyAttachments} style={{ fontSize: 12 }}> Ok </Button>
                                </Modal.Footer>
                            </Modal>

                            <Modal show={showMyImageAttachments} onHide={handleCloseMyImageAttachments} fullscreen={true} >
                                <Modal.Header closeButton>
                                    <Modal.Title style={{ fontSize: 18 }}>Files</Modal.Title>
                                </Modal.Header>
                                <Modal.Body style={{ fontSize: 14, display: "flex", justifyContent: "center" }}>
                                    <div>
                                        <Image src={`data:image/jpeg;base64,${myImageAttachments}`} />
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="primary" onClick={handleCloseMyImageAttachments} style={{ fontSize: 12 }}> Ok </Button>
                                </Modal.Footer>
                            </Modal>

                            <div style={{ fontSize: 12, fontWeight: "bold", marginTop: 10 }}>{addMessage}</div>

                            <Row style={{ alignItems: "center", alignContent: "center", marginBottom: 15 }}>

                                <Col md={8}>
                                    <Row style={{ alignItems: "center", alignContent: "center" }}>
                                        <Col>
                                            {totalVisits !== 0 ?
                                                <div style={{ fontSize: 12, fontWeight: "bold" }}> Previous Visit:
                                                    <Button variant="outline-primary" size="sm" style={{ fontSize: 12, marginRight: 10, marginLeft: 10 }} onClick={handleShowPreviousComplains}> Complaints </Button>
                                                    <Button variant="outline-primary" size="sm" style={{ fontSize: 12, marginRight: 10 }} onClick={handleShowPreviousVitals}> Vitals </Button>
                                                    {/* <Button variant="outline-primary" size="sm" style={{ fontSize: 12, marginRight: 10 }} onClick={handleShowPreviousHistory}> History </Button> */}
                                                    <Button variant="outline-primary" size="sm" style={{ fontSize: 12, marginRight: 10 }} onClick={handleShowPreviousMedicationTreatment}> Medication & Treatment </Button>
                                                    <Button variant="outline-primary" size="sm" style={{ fontSize: 12, marginRight: 10 }} onClick={handleShowPreviousClinicalExamination}> Clinical Examination </Button>
                                                    <Button variant="outline-primary" size="sm" style={{ fontSize: 12, marginRight: 10 }} onClick={handleShowPreviousDoctorAdvice}> Advice </Button>
                                                    <Button variant="outline-primary" size="sm" style={{ fontSize: 12, marginRight: 10 }} onClick={handleShowPreviousDoctorNotes}> Notes </Button>
                                                    <Button variant="outline-primary" size="sm" style={{ fontSize: 12, marginRight: 10 }} onClick={handleShowPreviousInvestigation}> Investigation </Button>
                                                    <Button variant="outline-primary" size="sm" style={{ fontSize: 12, marginRight: 10 }} onClick={handleShowPreviousDiagnosis}> Diagnosis </Button>
                                                    <Button variant="outline-primary" size="sm" style={{ fontSize: 12, marginRight: 10 }} onClick={handleShowPreviousAttachments}> Attachments </Button>
                                                    {genFemale ?
                                                        <Button variant="outline-primary" size="sm" style={{ fontSize: 12, marginRight: 10 }} onClick={handleShowPreviousForFemalesOnly}> For Females </Button>
                                                        :
                                                        <div></div>
                                                    }
                                                </div>
                                                :
                                                <div></div>
                                            }
                                        </Col>

                                    </Row>

                                    {/* <Row>
                                        <Col md={3}>
                                            <Form.Group className="mb-3" controlId="formSelectStateStatus">
                                                <Form.Label style={{ fontSize: 12, fontWeight: "bold" }}>Case Number:</Form.Label>
                                                <Form.Text style={{ fontSize: 12 }}>{caseno}</Form.Text>
                                            </Form.Group>
                                        </Col>

                                        <Col md={3}>
                                            <Form.Group className="mb-3" controlId="formSelectStateStatus">
                                                {apptype.match("New Case") ?
                                                    <Form.Label style={{ fontSize: 12, fontWeight: "bold" }}><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Case Status</Form.Label>
                                                    :
                                                    <Form.Label style={{ fontSize: 12, fontWeight: "bold" }}>Case Status:</Form.Label>
                                                }

                                                {apptype.match("New Case") ?
                                                    <Form.Select aria-label="Default select example" style={{ fontSize: 12 }} value={strCaseStatus || ""} onChange={e => { setStrCaseStatus(e.target.value) }}>
                                                        <option value="- Select -">- Select -</option>
                                                        <option value="Open">Open</option>
                                                        <option value="Closed">Closed</option>
                                                    </Form.Select>
                                                    :
                                                    <Form.Text style={{ fontSize: 12 }}>{casestatus}</Form.Text>
                                                }

                                            </Form.Group>
                                        </Col>

                                        <Col md={3}>
                                            <Form.Group className="mb-3" controlId="formSelectStateStatus">
                                                {apptype.match("New Case") ?
                                                    <Form.Label style={{ fontSize: 12, fontWeight: "bold" }}><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Case Type</Form.Label>
                                                    :
                                                    <Form.Label style={{ fontSize: 12, fontWeight: "bold" }}>Case Type:</Form.Label>
                                                }

                                                {apptype.match("New Case") ?
                                                    <Form.Select aria-label="Default select example" style={{ fontSize: 12 }} value={strCaseType || ""} onChange={e => { setStrCaseType(e.target.value) }}>
                                                        <option value="- Select -">- Select -</option>
                                                        <option value="OPD">OPD</option>
                                                        <option value="Indoor">Indoor</option>
                                                    </Form.Select>
                                                    :
                                                    <Form.Text style={{ fontSize: 12 }}>{casetype}</Form.Text>
                                                }

                                            </Form.Group>
                                        </Col>
                                    </Row> */}

                                </Col>

                                <Col md={4}>
                                    <Row style={{ alignItems: "center", alignContent: "center" }}>
                                        <Col md={4}>
                                            <Form.Group controlId="formSelectStateStatus">
                                                <Form.Label style={{ fontSize: 12, fontWeight: "bold" }}>Case Number:</Form.Label>
                                                <Form.Text style={{ fontSize: 12 }}>{caseno}</Form.Text>
                                            </Form.Group>
                                        </Col>

                                        <Col md={4}>
                                            <Form.Group controlId="formSelectStateStatus">
                                                {apptype.match("New Case") ?
                                                    <Form.Label style={{ fontSize: 12, fontWeight: "bold" }}><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Case Status</Form.Label>
                                                    :
                                                    <Form.Label style={{ fontSize: 12, fontWeight: "bold" }}>Case Status:</Form.Label>
                                                }

                                                {apptype.match("New Case") ?
                                                    <Form.Select aria-label="Default select example" style={{ fontSize: 12 }} value={strCaseStatus || ""} onChange={e => { setStrCaseStatus(e.target.value) }}>
                                                        <option value="- Select -">- Select -</option>
                                                        <option value="Open">Open</option>
                                                        <option value="Closed">Closed</option>
                                                    </Form.Select>
                                                    :
                                                    <Form.Text style={{ fontSize: 12 }}>{casestatus}</Form.Text>
                                                }

                                            </Form.Group>
                                        </Col>

                                        <Col md={4}>
                                            <Form.Group controlId="formSelectStateStatus">
                                                {apptype.match("New Case") ?
                                                    <Form.Label style={{ fontSize: 12, fontWeight: "bold" }}><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Case Type</Form.Label>
                                                    :
                                                    <Form.Label style={{ fontSize: 12, fontWeight: "bold" }}>Case Type:</Form.Label>
                                                }

                                                {apptype.match("New Case") ?
                                                    <Form.Select aria-label="Default select example" style={{ fontSize: 12 }} value={strCaseType || ""} onChange={e => { setStrCaseType(e.target.value) }}>
                                                        <option value="- Select -">- Select -</option>
                                                        <option value="OPD">OPD</option>
                                                        <option value="Indoor">Indoor</option>
                                                    </Form.Select>
                                                    :
                                                    <Form.Text style={{ fontSize: 12 }}>{casetype}</Form.Text>
                                                }

                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Col>

                                {/* History Tab */}
                                {/* {totalVisits === 0 || historyId !== null ?
                                    <Col md={4}>
                                        <Accordion defaultActiveKey={['0']} alwaysOpen>
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>
                                                    <ClockHistory size={16} style={{ marginRight: 10 }} />History
                                                </Accordion.Header>
                                                <Accordion.Body>

                                                    <Row style={{ alignContent: "center" }}>
                                                        <Col xs={4} style={{ fontSize: 12, color: "grey" }}>
                                                            Personal History
                                                        </Col>
                                                        <Col xs={8}>
                                                            <Form.Group controlId="formSelectStateStatus">
                                                                <Form.Control as="textarea" rows={5} style={{ fontSize: 12 }} value={strPersonalHistory || ""} onChange={e => { setStrPersonalHistory(e.target.value) }} />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>

                                                    <Row style={{ alignItems: "center", alignContent: "center" }}>
                                                        <Col xs={4} style={{ fontSize: 12, color: "grey" }}>
                                                            Major Illness
                                                        </Col>
                                                        <Col xs={8}>
                                                            <Form.Group controlId="formSelectStateStatus">
                                                                <Form.Control type="text" style={{ fontSize: 12 }} value={strMajorIllness || ""} onChange={e => { setStrMajorIllness(e.target.value) }} />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>

                                                    <Row style={{ alignItems: "center", alignContent: "center" }}>
                                                        <Col xs={4} style={{ fontSize: 12, color: "grey" }}>
                                                            Hospitalization
                                                        </Col>
                                                        <Col xs={8}>
                                                            <Form.Group controlId="formSelectStateStatus">
                                                                <Form.Select aria-label="Default select example" style={{ fontSize: 12 }} value={strHospitalization || ""} onChange={e => { setStrHospitalization(e.target.value) }}>
                                                                    <option value="- Select -">- Select -</option>
                                                                    <option value="Yes">Yes</option>
                                                                    <option value="No">No</option>
                                                                </Form.Select>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>

                                                    <Row style={{ alignItems: "center", alignContent: "center" }}>
                                                        <Col xs={4} style={{ fontSize: 12, color: "grey" }}>
                                                            Medications
                                                        </Col>
                                                        <Col xs={8}>
                                                            <Form.Group controlId="formSelectStateStatus">
                                                                <Form.Control type="text" style={{ fontSize: 12 }} value={strMedications || ""} onChange={e => { setStrMedications(e.target.value) }} />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>

                                                    <Row style={{ alignItems: "center", alignContent: "center" }}>
                                                        <Col xs={4} style={{ fontSize: 12, color: "grey" }}>
                                                            Covid-19 H/o
                                                        </Col>
                                                        <Col xs={8}>
                                                            <Form.Group controlId="formSelectStateStatus">
                                                                <Form.Select aria-label="Default select example" style={{ fontSize: 12 }} value={strCovidHO || ""} onChange={e => { setStrCovidHO(e.target.value) }}>
                                                                    <option value="- Select -">- Select -</option>
                                                                    <option value="Yes">Yes</option>
                                                                    <option value="No">No</option>
                                                                </Form.Select>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>

                                                    <Row style={{ alignItems: "center", alignContent: "center" }}>
                                                        <Col xs={4} style={{ fontSize: 12, color: "grey" }}>
                                                            Covid Hospitalization
                                                        </Col>
                                                        <Col xs={8}>
                                                            <Form.Group controlId="formSelectStateStatus">
                                                                <Form.Select aria-label="Default select example" style={{ fontSize: 12 }} value={strCovidHospitalization || ""} onChange={e => { setStrCovidHospitalization(e.target.value) }}>
                                                                    <option value="- Select -">- Select -</option>
                                                                    <option value="Yes">Yes</option>
                                                                    <option value="No">No</option>
                                                                </Form.Select>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>

                                                    <Row style={{ alignItems: "center", alignContent: "center" }}>
                                                        <Col xs={4} style={{ fontSize: 12, color: "grey" }}>
                                                            Covid-19 Vaccine
                                                        </Col>
                                                        <Col xs={8}>
                                                            <Form.Group controlId="formSelectStateStatus">
                                                                <Form.Select aria-label="Default select example" style={{ fontSize: 12 }} value={strCovidVaccine || ""} onChange={e => { setStrCovidVaccine(e.target.value) }}>
                                                                    <option value="- Select -">- Select -</option>
                                                                    <option value="Yes">Yes</option>
                                                                    <option value="No">No</option>
                                                                </Form.Select>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>

                                                    <Row style={{ alignContent: "center" }}>
                                                        <Col xs={4} style={{ fontSize: 12, color: "grey" }}>
                                                            Family History
                                                        </Col>
                                                        <Col xs={8}>
                                                            <Form.Group controlId="formSelectStateStatus">
                                                                <Form.Control as="textarea" rows={5} style={{ fontSize: 12 }} value={strFamilyHistory || ""} onChange={e => { setStrFamilyHistory(e.target.value) }} />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </Col>
                                    :
                                    <div></div>
                                } */}

                            </Row>

                            {/* <Row className="align-items-center">
                                <Col md>
                                    <Form.Label style={{ fontSize: 12, color: "grey" }}> <Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Appointment Type</Form.Label>
                                    <Form.Group className="mb-3" controlId="strAppointmentType">
                                        <Form.Check inline label="New Case" name="group1" type='radio' value="New Case" aria-label="radio 1" style={{ fontSize: 12 }} onChange={handleRadioChange} checked={strAppointmentType === "New Case"} />
                                        <Form.Check inline label="Follow up Case" name="group1" type='radio' value="Follow up Case" aria-label="radio 2" style={{ fontSize: 12 }} onChange={handleRadioChange} checked={strAppointmentType === "Follow up Case"} />
                                    </Form.Group>
                                </Col>
                            </Row> */}

                            {/* <div style={{ marginTop: 20, marginBottom: 20 }}></div> */}

                            <Row>

                                <Col md>

                                    <Row className="border p-1 mb-2 mx-1">
                                        <Col md>
                                            <Row style={{ alignItems: "center", alignContent: "center", marginBottom: 5, }}>
                                                <Col xs={3} style={{ fontSize: 12, color: "grey" }}>
                                                    Weight
                                                </Col>

                                                <Col xs={5}>
                                                    <Form.Group controlId="formSelectStateStatus">
                                                        <Form.Control type="text" style={{ fontSize: 12 }} value={strWeightNum || ""}
                                                            onChange={e => {
                                                                setStrWeightNum(e.target.value);
                                                                if (strHeightNum !== "") {

                                                                    let ht = strHeightNum.split('.');

                                                                    let htInCm = Math.round((ht[0] * 30.48) + (ht[1] * 2.54));
                                                                    let htInM = htInCm / 100;

                                                                    //console.log(" htInCm: " + htInCm + " htInM: " + htInM);
                                                                    var bmi = (parseFloat(e.target.value)) / (parseFloat(htInM) * parseFloat(htInM));
                                                                    bmi = bmi.toFixed(2);
                                                                    setStrBmi(bmi);

                                                                    var bsa = Math.sqrt((parseFloat(e.target.value) * parseFloat(htInCm)) / 3600);
                                                                    bsa = bsa.toFixed(2);
                                                                    setStrBsa(bsa);
                                                                } else {
                                                                    setStrBmi("");
                                                                    setStrBsa("");
                                                                }
                                                            }}
                                                        />
                                                    </Form.Group>
                                                </Col>

                                                <Col xs={4}>
                                                    <Form.Group controlId="formSelectStateStatus">
                                                        <Form.Select aria-label="Default select example" style={{ fontSize: 12 }} value={strWeightUnit || ""} onChange={e => { setStrWeightUnit(e.target.value) }} disabled>
                                                            <option value="kg">kg</option>
                                                            <option value="lbs">lbs</option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>

                                            </Row>

                                            <Row style={{ alignItems: "center", alignContent: "center", marginBottom: 5, }}>

                                                <Col xs={3} style={{ fontSize: 12, color: "grey" }}>
                                                    Height
                                                </Col>

                                                <Col xs={5}>
                                                    <Form.Group controlId="formSelectStateStatus">
                                                        <Form.Control type="text" style={{ fontSize: 12 }} value={strHeightNum || ""}
                                                            onChange={e => {
                                                                setStrHeightNum(e.target.value);
                                                                if (strWeightNum !== "") {

                                                                    let ht = e.target.value.split('.');

                                                                    let htInCm = Math.round((ht[0] * 30.48) + (ht[1] * 2.54));
                                                                    let htInM = htInCm / 100;

                                                                    //console.log(" htInCm: " + htInCm + " htInM: " + htInM);
                                                                    var bmi = (parseFloat(strWeightNum)) / (parseFloat(htInM) * parseFloat(htInM));
                                                                    bmi = bmi.toFixed(2);
                                                                    setStrBmi(bmi);

                                                                    var bsa = Math.sqrt((parseFloat(strWeightNum) * parseFloat(htInCm)) / 3600);
                                                                    bsa = bsa.toFixed(2);
                                                                    setStrBsa(bsa);
                                                                } else {
                                                                    setStrBmi("");
                                                                    setStrBsa("");
                                                                }
                                                            }}
                                                        />
                                                    </Form.Group>
                                                </Col>

                                                <Col xs={4}>
                                                    <Form.Group controlId="formSelectStateStatus">
                                                        <Form.Select aria-label="Default select example" style={{ fontSize: 12 }} value={strHeightUnit || ""} onChange={e => { setStrHeightUnit(e.target.value) }} disabled>
                                                            <option value="ft">ft</option>
                                                            <option value="cm">cm</option>
                                                            <option value="m">m</option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>

                                            </Row>


                                            <Row style={{ alignItems: "center", alignContent: "center", marginBottom: 5, }}>

                                                <Col xs={3} style={{ fontSize: 12, color: "grey" }}>
                                                    BMI
                                                </Col>

                                                <Col xs={5}>
                                                    <Form.Group controlId="formSelectStateStatus">
                                                        <Form.Control type="text" style={{ fontSize: 12 }} value={strBmi || ""} onChange={e => { setStrBmi(e.target.value) }} />
                                                    </Form.Group>
                                                </Col>

                                                <Col xs={4}>
                                                    <Form.Group controlId="formSelectStateStatus">
                                                        <Form.Control type="text" style={{ fontSize: 12 }} value="kg/m2" disabled />
                                                    </Form.Group>
                                                </Col>

                                            </Row>

                                            <Row style={{ alignItems: "center", alignContent: "center", marginBottom: 5, }}>

                                                <Col xs={3} style={{ fontSize: 12, color: "grey" }}>
                                                    BSA
                                                </Col>

                                                <Col xs={5}>
                                                    <Form.Group controlId="formSelectStateStatus">
                                                        <Form.Control type="text" style={{ fontSize: 12 }} value={strBsa || ""} onChange={e => { setStrBsa(e.target.value) }} />
                                                    </Form.Group>
                                                </Col>

                                                <Col xs={4}>
                                                    <Form.Group controlId="formSelectStateStatus">
                                                        <Form.Control type="text" style={{ fontSize: 12 }} value="m2" disabled />
                                                    </Form.Group>
                                                </Col>

                                            </Row>

                                            <Row style={{ alignItems: "center", alignContent: "center", marginBottom: 5, }}>

                                                <Col xs={3} style={{ fontSize: 12, color: "grey" }}>
                                                    Pulse
                                                </Col>

                                                <Col xs={5}>
                                                    <Form.Group controlId="formSelectStateStatus">
                                                        <Form.Control type="text" style={{ fontSize: 12 }} value={strPulse || ""} onChange={e => { setStrPulse(e.target.value) }} />
                                                    </Form.Group>
                                                </Col>

                                                <Col xs={4}>
                                                    <Form.Group controlId="formSelectStateStatus">
                                                        <Form.Control type="text" style={{ fontSize: 12 }} value="/min" disabled />
                                                    </Form.Group>
                                                </Col>

                                            </Row>

                                            <Row style={{ alignItems: "center", alignContent: "center", marginBottom: 5, }}>

                                                <Col xs={3} style={{ fontSize: 12, color: "grey" }}>
                                                    SpO2
                                                </Col>

                                                <Col xs={5}>
                                                    <Form.Group controlId="formSelectStateStatus">
                                                        <Form.Control type="text" style={{ fontSize: 12 }} value={strSpo || ""} onChange={e => { setStrSpo(e.target.value) }} />
                                                    </Form.Group>
                                                </Col>

                                                <Col xs={4}>
                                                    <Form.Group controlId="formSelectStateStatus">
                                                        <Form.Control type="text" style={{ fontSize: 12 }} value="%" disabled />
                                                    </Form.Group>
                                                </Col>

                                            </Row>

                                            <Row style={{ alignItems: "center", alignContent: "center", marginBottom: 5, }}>

                                                <Col xs={3} style={{ fontSize: 12, color: "grey" }}>
                                                    Blood Pressure
                                                </Col>

                                                <Col xs={3}>
                                                    <Form.Group controlId="formSelectStateStatus">
                                                        <Form.Control type="text" style={{ fontSize: 12 }} value={strBloodPressureMM || ""} onChange={e => { setStrBloodPressureMM(e.target.value) }} />
                                                    </Form.Group>
                                                </Col>

                                                <Col xs={3}>
                                                    <Form.Group controlId="formSelectStateStatus">
                                                        <Form.Control type="text" style={{ fontSize: 12 }} value={strBloodPressureHG || ""} onChange={e => { setStrBloodPressureHG(e.target.value) }} />
                                                    </Form.Group>
                                                </Col>

                                                <Col xs={3}>
                                                    <Form.Group controlId="formSelectStateStatus">
                                                        <Form.Control type="text" style={{ fontSize: 12 }} value="mm Hg" disabled />
                                                    </Form.Group>
                                                </Col>

                                            </Row>

                                            <Row style={{ alignItems: "center", alignContent: "center", marginBottom: 5, }}>

                                                <Col xs={3} style={{ fontSize: 12, color: "grey" }}>
                                                    Respiratory Rate
                                                </Col>

                                                <Col xs={5}>
                                                    <Form.Group controlId="formSelectStateStatus">
                                                        <Form.Control type="text" style={{ fontSize: 12 }} value={strRespiratoryRate || ""} onChange={e => { setStrRespiratoryRate(e.target.value) }} />
                                                    </Form.Group>
                                                </Col>

                                                <Col xs={4}>
                                                    <Form.Group controlId="formSelectStateStatus">
                                                        <Form.Control type="text" style={{ fontSize: 12 }} value="breaths/min" disabled />
                                                    </Form.Group>
                                                </Col>

                                            </Row>

                                            <Row style={{ alignItems: "center", alignContent: "center" }}>

                                                <Col xs={3} style={{ fontSize: 12, color: "grey" }}>
                                                    Temperature
                                                </Col>

                                                <Col xs={5}>
                                                    <Form.Group controlId="formSelectStateStatus">
                                                        <Form.Control type="text" style={{ fontSize: 12 }} value={strTemperature || ""} onChange={e => { setStrTemperature(e.target.value) }} />
                                                    </Form.Group>
                                                </Col>

                                                <Col xs={4}>
                                                    <Form.Group controlId="formSelectStateStatus">
                                                        <Form.Control type="text" style={{ fontSize: 12 }} value="°F" disabled />
                                                    </Form.Group>
                                                </Col>

                                            </Row>
                                        </Col>
                                    </Row>

                                    <Row className="border p-1 mb-2 mx-1">
                                        <Col md>
                                            <Row>
                                                <Col>
                                                    <div style={{ fontSize: 12, fontWeight: "bold" }}>{fileSize}</div>
                                                </Col>
                                            </Row>
                                            <Row style={{ alignItems: "center", alignContent: "center", marginBottom: 5, }}>
                                                <Col>
                                                    <Form.Control ref={aRef} type="file" style={{ fontSize: 12 }} onChange={e => { setFiles(e.target.files) }} multiple />
                                                </Col>
                                            </Row>
                                            <Row style={{ alignItems: "center", alignContent: "center", }}>
                                                <Col xs={4} style={{ fontSize: 12, color: "grey" }}>
                                                    Remarks
                                                </Col>
                                                <Col xs={8}>
                                                    <Form.Group controlId="formSelectStateStatus">
                                                        <Form.Control type="text" style={{ fontSize: 12 }} value={strAttRemarks || ""} onChange={e => { setStrAttRemarks(e.target.value) }} />
                                                    </Form.Group>
                                                </Col>
                                            </Row>

                                            {deleteMessage ?
                                                <div style={{ fontSize: 12, fontWeight: "bold", marginBottom: 10 }}>
                                                    <Row style={{ marginTop: 15 }}> {deleteMessage} </Row>
                                                </div>
                                                :
                                                <div></div>
                                            }

                                            {attachTableData.length !== 0 ?
                                                <div>
                                                    <Row>
                                                        <Table bordered hover size="sm" responsive style={{ fontSize: 12, marginTop: 20, color: "grey" }}>
                                                            <thead>
                                                                <tr>
                                                                    <th>#</th>
                                                                    <th>File Name</th>
                                                                    <th>Remarks</th>
                                                                    <th></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {attachTableData.map((data, index) => (
                                                                    <tr key={index}>
                                                                        <td>{index + 1}</td>
                                                                        <td>{data.strFileName}</td>
                                                                        <td>{data.strAttRemarks}</td>
                                                                        <td onClick={() => (attachDeleteClicked(data.intPatientAttachmentsId))}><XSquare color="red" /></td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </Table>
                                                    </Row>
                                                </div>
                                                :
                                                <div></div>
                                            }
                                        </Col>
                                    </Row>

                                </Col>

                                <Col md>

                                    <Row className="border p-1 mb-2 mx-1" >
                                        <Col md>
                                            <Row style={{ alignItems: "center", alignContent: "center" }}>
                                                <Col xs={4} style={{ fontSize: 12, color: "grey" }}>
                                                    Chief Complaints
                                                </Col>

                                                <Col xs={8}>
                                                    <Form.Group controlId="formSelectStateStatus">

                                                        {/* <Form.Control as="textarea" rows={5} style={{ fontSize: 12 }} /> */}
                                                        <MultiSelect
                                                            data={symptoms}
                                                            onChange={handleChange}
                                                            value={value}
                                                            allowCustom={true}
                                                            placeholder="Please select ..."
                                                            style={{ fontSize: 12, fontStyle: "normal" }}
                                                            size="medium"
                                                            itemRender={itemRender}
                                                            tagRender={tagRender}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>

                                    <Row className="border p-1 mb-2 mx-1">
                                        <Col md>
                                            <Row style={{ alignItems: "center", alignContent: "center", marginBottom: 5 }}>
                                                <Col xs={4} style={{ fontSize: 12, color: "grey" }}>
                                                    Diagnosis
                                                </Col>

                                                <Col xs={8}>
                                                    <Form.Group controlId="formSelectStateStatus">

                                                        {/* <Form.Control as="textarea" rows={5} style={{ fontSize: 12 }} /> */}
                                                        <MultiSelect
                                                            data={diag}
                                                            onChange={handleChangeDiagnosisSearch}
                                                            value={valueDiagnosisSearch}
                                                            allowCustom={true}
                                                            placeholder="Please select ..."
                                                            style={{ fontSize: 12 }}
                                                            size="medium"
                                                            itemRender={itemRender}
                                                            tagRender={tagRender}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row style={{ alignContent: "center" }}>

                                                <Col xs={4} style={{ fontSize: 12, color: "grey" }}>
                                                    Notes
                                                </Col>
                                                <Col xs={8}>
                                                    <Form.Group controlId="formSelectStateStatus">
                                                        <Form.Control as="textarea" rows={2} style={{ fontSize: 12 }} value={strDiagnosis || ""} onChange={e => { setStrDiagnosis(e.target.value) }} />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>

                                    <Row className="border p-1 mb-2 mx-1">
                                        <Col>
                                            <Row>
                                                <Col xs={4} style={{ marginTop: 5, fontSize: 12, color: "grey" }}>
                                                    Clinical Examination
                                                </Col>
                                                <Col xs={8}>
                                                    <Form.Group controlId="formSelectStateStatus">
                                                        <Form.Control as="textarea" rows={2} style={{ fontSize: 12 }} value={strClinicalExamination || ""} onChange={e => { setStrClinicalExamination(e.target.value) }} />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>

                                    <Row className="border p-1 mb-2 mx-1">
                                        <Col>
                                            <Row>
                                                <Col xs={4} style={{ marginTop: 5, fontSize: 12, color: "grey" }}>
                                                    Investigation
                                                </Col>

                                                <Col xs={8}>
                                                    <Form.Group controlId="formSelectStateStatus">

                                                        {/* <Form.Control as="textarea" rows={5} style={{ fontSize: 12 }} /> */}
                                                        <MultiSelect
                                                            data={investigation}
                                                            onChange={handleChangeInvestigation}
                                                            value={valueInvestigation}
                                                            allowCustom={true}
                                                            placeholder="Please select ..."
                                                            style={{ fontSize: 12, fontStyle: "normal" }}
                                                            size="medium"
                                                            itemRender={itemRender}
                                                            tagRender={tagRender}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>

                                    {genFemale ?
                                        <Row className="border p-1 mb-2 mx-1">
                                            <Col md>
                                                <Row style={{ alignItems: "center", alignContent: "center", marginBottom: 5, }}>
                                                    <Col xs={4} style={{ fontSize: 12, color: "grey" }}>
                                                        Menstrual Cycle
                                                    </Col>
                                                    <Col xs={8}>
                                                        <Form.Group controlId="formSelectStateStatus">
                                                            <Form.Select aria-label="Default select example" style={{ fontSize: 12 }} value={strMenstrualCycle || ""} onChange={e => { setStrMenstrualCycle(e.target.value) }}  >
                                                                <option value="- Select -">- Select -</option>
                                                                <option value="Regular">Regular</option>
                                                                <option value="Irregular">Irregular</option>
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>

                                                <Row style={{ alignItems: "center", alignContent: "center", marginBottom: 5, }}>
                                                    <Col xs={4} style={{ fontSize: 12, color: "grey" }}>
                                                        Pain
                                                    </Col>
                                                    <Col xs={8}>
                                                        <Form.Group controlId="formSelectStateStatus">
                                                            <Form.Select aria-label="Default select example" style={{ fontSize: 12 }} value={strPain || ""} onChange={e => { setStrPain(e.target.value) }} >
                                                                <option value="- Select -">- Select -</option>
                                                                <option value="Mild">Mild</option>
                                                                <option value="Moderate">Moderate</option>
                                                                <option value="Severe">Severe</option>
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>

                                                <Row style={{ alignItems: "center", alignContent: "center", marginBottom: 5, }}>
                                                    <Col xs={4} style={{ fontSize: 12, color: "grey" }}>
                                                        LMP Date
                                                    </Col>
                                                    <Col xs={8}>
                                                        <Form.Group controlId="formSelectStateStatus">
                                                            <Form.Control type="date" style={{ fontSize: 12 }} value={dtmLmpDate || ""} onChange={e => { setDtmLmpDate(e.target.value) }} />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>

                                                <Row style={{ alignItems: "center", alignContent: "center", marginBottom: 5, }}>
                                                    <Col xs={4} style={{ fontSize: 12, color: "grey" }}>
                                                        Children/s
                                                    </Col>
                                                    <Col xs={8}>
                                                        <Form.Group controlId="formSelectStateStatus">
                                                            <Form.Control type="text" style={{ fontSize: 12 }} value={strChildren || ""} onChange={e => { setStrChildren(e.target.value) }} />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>

                                                <Row style={{ alignItems: "center", alignContent: "center", marginBottom: 5, }}>
                                                    <Col xs={4} style={{ fontSize: 12, color: "grey" }}>
                                                        Abortion/s
                                                    </Col>
                                                    <Col xs={8}>
                                                        <Form.Group controlId="formSelectStateStatus">
                                                            <Form.Control type="text" style={{ fontSize: 12 }} value={strAbortion || ""} onChange={e => { setStrAbortion(e.target.value) }} />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>

                                                <Row style={{ alignItems: "center", alignContent: "center" }}>
                                                    <Col xs={4} style={{ fontSize: 12, color: "grey" }}>
                                                        Type of Delivery
                                                    </Col>
                                                    <Col xs={8}>
                                                        <Form.Group controlId="formSelectStateStatus">
                                                            <Form.Select aria-label="Default select example" style={{ fontSize: 12 }} value={strTypeOfDelivery || ""} onChange={e => { setStrTypeOfDelivery(e.target.value) }} >
                                                                <option value=" Select -">- Select -</option>
                                                                <option value="Normal">Normal</option>
                                                                <option value="C-Section">C-Section</option>
                                                                <option value="Assisted">Assisted</option>
                                                                <option value="NA">NA</option>
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        :
                                        null
                                    }

                                </Col>

                                <Col md>

                                    <Row className="border p-1 mb-2 mx-1">
                                        <Col md>
                                            <Row style={{ alignItems: "center", alignContent: "center", marginBottom: 5, }}>
                                                <Col xs={4} style={{ fontSize: 12, color: "grey" }}>
                                                    Name of Medicine
                                                </Col>
                                                <Col xs={8}>
                                                    <Form.Group controlId="formSelectStateStatus">
                                                        <Form.Control type="text" style={{ fontSize: 12 }} value={strMedicineName || ""} onChange={e => { setStrMedicineName(e.target.value) }} />
                                                    </Form.Group>
                                                </Col>
                                            </Row>

                                            <Row style={{ alignItems: "center", alignContent: "center", marginBottom: 5, }}>
                                                <Col xs={4} style={{ fontSize: 12, color: "grey" }}>
                                                    Type of Medicine
                                                </Col>
                                                <Col xs={8}>
                                                    <Form.Group controlId="formSelectStateStatus">
                                                        <Form.Select aria-label="Default select example" style={{ fontSize: 12 }} value={strMedicineType || ""} onChange={e => { setStrMedicineType(e.target.value) }}  >
                                                            <option value="- Select -">- Select -</option>
                                                            <option value="Tablet">Tablet</option>
                                                            <option value="Capsule">Capsule</option>
                                                            <option value="Injection">Injection</option>
                                                            <option value="Syrup">Syrup</option>
                                                            <option value="Spray">Spray</option>
                                                            <option value="Paste">Paste</option>
                                                            <option value="Tube">Tube</option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>
                                            </Row>

                                            <Row style={{ alignItems: "center", alignContent: "center", marginBottom: 5, }}>
                                                <Col xs={4} style={{ fontSize: 12, color: "grey" }}>
                                                    Duration
                                                </Col>
                                                <Col xs={8}>
                                                    <Form.Group controlId="formSelectStateStatus">
                                                        <Form.Control type="text" style={{ fontSize: 12 }} value={strMedicineDuration || ""}
                                                            onChange={e => {
                                                                setStrMedicineDuration(e.target.value);
                                                                if (strMedicineFrequency !== "") {
                                                                    var m, a, ev, n = 0;
                                                                    if (strMedFreqMorning === "1/2") { m = 0.5; } else { m = strMedFreqMorning; }
                                                                    if (strMedFreqAfternoon === "1/2") { a = 0.5 } else { a = strMedFreqAfternoon; }
                                                                    if (strMedFreqEvening === "1/2") { ev = 0.5 } else { ev = strMedFreqEvening; }
                                                                    if (strMedFreqNight === "1/2") { n = 0.5 } else { n = strMedFreqNight; }
                                                                    var t = parseFloat(m) + parseFloat(a) + parseFloat(ev) + parseFloat(n)
                                                                    let medQuan = (e.target.value) * t;
                                                                    setStrMedicineQuantity(medQuan);
                                                                } else {
                                                                    setStrMedicineQuantity("");
                                                                }
                                                            }}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>

                                            <Row style={{ alignItems: "center", alignContent: "center", marginBottom: 5, }}>
                                                <Col xs={4} style={{ fontSize: 12, color: "grey" }}>
                                                    Medicine Time
                                                </Col>

                                                <Col xs={2}>
                                                    <Form.Group controlId="formSelectStateStatus">
                                                        <Form.Control type="text" style={{ fontSize: 12 }} value={strMedFreqMorning || ""}
                                                            onChange={e => {
                                                                setStrMedFreqMorning(e.target.value);

                                                                if (strMedFreqAfternoon || strMedFreqEvening || strMedFreqNight !== "") {
                                                                    setStrMedicineFrequency(e.target.value + " - " + strMedFreqAfternoon + " - " + strMedFreqEvening + " - " + strMedFreqNight);
                                                                    if (strMedicineDuration !== "") {
                                                                        var m, a, ev, n = 0;
                                                                        if (e.target.value === "1/2") { m = 0.5; } else { m = e.target.value; }
                                                                        if (strMedFreqAfternoon === "1/2") { a = 0.5 } else { a = strMedFreqAfternoon; }
                                                                        if (strMedFreqEvening === "1/2") { ev = 0.5 } else { ev = strMedFreqEvening; }
                                                                        if (strMedFreqNight === "1/2") { n = 0.5 } else { n = strMedFreqNight; }
                                                                        var t = parseFloat(m) + parseFloat(a) + parseFloat(ev) + parseFloat(n)
                                                                        let medQuan = (strMedicineDuration) * t;
                                                                        setStrMedicineQuantity(medQuan);
                                                                    } else {
                                                                        setStrMedicineQuantity("");
                                                                    }
                                                                } else {
                                                                    setStrMedicineFrequency("");
                                                                }
                                                            }}

                                                            placeholder="M" />
                                                    </Form.Group>
                                                </Col>

                                                <Col xs={2}>
                                                    <Form.Group controlId="formSelectStateStatus">
                                                        <Form.Control type="text" style={{ fontSize: 12 }} value={strMedFreqAfternoon || ""}
                                                            onChange={e => {
                                                                setStrMedFreqAfternoon(e.target.value);
                                                                if (strMedFreqMorning || strMedFreqEvening || strMedFreqNight !== "") {
                                                                    setStrMedicineFrequency(strMedFreqMorning + " - " + e.target.value + " - " + strMedFreqEvening + " - " + strMedFreqNight);
                                                                    if (strMedicineDuration !== "") {
                                                                        var m, a, ev, n = 0;
                                                                        if (strMedFreqMorning === "1/2") { m = 0.5; } else { m = strMedFreqMorning; }
                                                                        if (e.target.value === "1/2") { a = 0.5 } else { a = e.target.value; }
                                                                        if (strMedFreqEvening === "1/2") { ev = 0.5 } else { ev = strMedFreqEvening; }
                                                                        if (strMedFreqNight === "1/2") { n = 0.5 } else { n = strMedFreqNight; }
                                                                        var t = parseFloat(m) + parseFloat(a) + parseFloat(ev) + parseFloat(n)
                                                                        let medQuan = (strMedicineDuration) * t;
                                                                        setStrMedicineQuantity(medQuan);
                                                                    } else {
                                                                        setStrMedicineQuantity("");
                                                                    }
                                                                } else {
                                                                    setStrMedicineFrequency("");
                                                                }
                                                            }}
                                                            placeholder="A" />
                                                    </Form.Group>
                                                </Col>

                                                <Col xs={2}>
                                                    <Form.Group controlId="formSelectStateStatus">
                                                        <Form.Control type="text" style={{ fontSize: 12 }} value={strMedFreqEvening || ""}
                                                            onChange={e => {
                                                                setStrMedFreqEvening(e.target.value);
                                                                if (strMedFreqMorning || strMedFreqAfternoon || strMedFreqNight !== "") {
                                                                    setStrMedicineFrequency(strMedFreqMorning + " - " + strMedFreqAfternoon + " - " + e.target.value + " - " + strMedFreqNight);
                                                                    if (strMedicineDuration !== "") {
                                                                        var m, a, ev, n = 0;
                                                                        if (strMedFreqMorning === "1/2") { m = 0.5; } else { m = strMedFreqMorning; }
                                                                        if (strMedFreqAfternoon === "1/2") { a = 0.5 } else { a = strMedFreqAfternoon; }
                                                                        if (e.target.value === "1/2") { ev = 0.5 } else { ev = e.target.value; }
                                                                        if (strMedFreqNight === "1/2") { n = 0.5 } else { n = strMedFreqNight; }
                                                                        var t = parseFloat(m) + parseFloat(a) + parseFloat(ev) + parseFloat(n)
                                                                        let medQuan = (strMedicineDuration) * t;
                                                                        setStrMedicineQuantity(medQuan);
                                                                    } else {
                                                                        setStrMedicineQuantity("");
                                                                    }
                                                                } else {
                                                                    setStrMedicineFrequency("");
                                                                }
                                                            }}
                                                            placeholder="E" />
                                                    </Form.Group>
                                                </Col>

                                                <Col xs={2}>
                                                    <Form.Group controlId="formSelectStateStatus">
                                                        <Form.Control type="text" style={{ fontSize: 12 }} value={strMedFreqNight || ""}
                                                            onChange={e => {
                                                                setStrMedFreqNight(e.target.value);
                                                                if (strMedFreqMorning || strMedFreqAfternoon || strMedFreqEvening !== "") {
                                                                    setStrMedicineFrequency(strMedFreqMorning + " - " + strMedFreqAfternoon + " - " + strMedFreqEvening + " - " + e.target.value);
                                                                    if (strMedicineDuration !== "") {
                                                                        var m, a, ev, n = 0;
                                                                        if (strMedFreqMorning === "1/2") { m = 0.5; } else { m = strMedFreqMorning; }
                                                                        if (strMedFreqAfternoon === "1/2") { a = 0.5 } else { a = strMedFreqAfternoon; }
                                                                        if (strMedFreqEvening === "1/2") { ev = 0.5 } else { ev = strMedFreqEvening; }
                                                                        if (e.target.value === "1/2") { n = 0.5 } else { n = e.target.value; }
                                                                        var t = parseFloat(m) + parseFloat(a) + parseFloat(ev) + parseFloat(n)
                                                                        let medQuan = (strMedicineDuration) * t;
                                                                        setStrMedicineQuantity(medQuan);
                                                                    } else {
                                                                        setStrMedicineQuantity("");
                                                                    }
                                                                } else {
                                                                    setStrMedicineFrequency("");
                                                                }
                                                            }}
                                                            placeholder="N" />
                                                    </Form.Group>
                                                </Col>
                                            </Row>

                                            <Row style={{ alignItems: "center", alignContent: "center", marginBottom: 5, }}>
                                                <Col xs={4} style={{ fontSize: 12, color: "grey" }}>
                                                    Frequency
                                                </Col>
                                                <Col xs={8}>
                                                    <Form.Group controlId="formSelectStateStatus">
                                                        <Form.Control type="text" style={{ fontSize: 12 }} value={strMedicineFrequency || ""} onChange={e => { setStrMedicineFrequency(e.target.value); }} disabled />
                                                    </Form.Group>
                                                </Col>
                                            </Row>

                                            <Row style={{ alignItems: "center", alignContent: "center", marginBottom: 5, }}>
                                                <Col xs={4} style={{ fontSize: 12, color: "grey" }}>
                                                    Quantity
                                                </Col>
                                                <Col xs={8}>
                                                    <Form.Group controlId="formSelectStateStatus">
                                                        <Form.Control type="text" style={{ fontSize: 12 }} value={strMedicineQuantity || ""} onChange={e => { setStrMedicineQuantity(e.target.value) }} disabled />
                                                    </Form.Group>
                                                </Col>
                                            </Row>

                                            <Row style={{ alignItems: "center", alignContent: "center", marginBottom: 5, }}>
                                                <Col xs={4} style={{ fontSize: 12, color: "grey" }}>
                                                    Advise
                                                </Col>

                                                <Col xs={8}>
                                                    <Form.Group controlId="formSelectStateStatus">

                                                        {/* <Form.Control as="textarea" rows={5} style={{ fontSize: 12 }} /> */}
                                                        <MultiSelect
                                                            data={medAdvise}
                                                            onChange={handleChangeMedicineAdvise}
                                                            value={valueMedicineAdvise}
                                                            allowCustom={true}
                                                            placeholder="Please select ..."
                                                            style={{ fontSize: 12, fontStyle: "normal" }}
                                                            size="medium"
                                                            itemRender={itemRender}
                                                            tagRender={tagRender}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>

                                            <Row style={{ alignItems: "center", alignContent: "center", marginBottom: 5, }}>
                                                <div className="text-center">
                                                    <Button variant="outline-primary" size="sm" style={{ fontSize: 12, paddingLeft: 20, paddingRight: 20 }} onClick={addNewMedicine}>Add</Button>
                                                </div>
                                            </Row>

                                            <Row style={{ alignItems: "center", alignContent: "center" }}>
                                                <Table bordered hover size="sm" responsive style={{ fontSize: 12, color: "grey" }}>
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Name</th>
                                                            <th>Type</th>
                                                            <th>Duration</th>
                                                            <th>Frequency</th>
                                                            <th>M</th>
                                                            <th>A</th>
                                                            <th>E</th>
                                                            <th>N</th>
                                                            <th>Quantity</th>
                                                            <th>Advise</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            medTableData.map((data, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>{index + 1}</td>
                                                                        <td>{data.strMedicineName}</td>
                                                                        <td>{data.strMedicineType}</td>
                                                                        <td>{data.strMedicineDuration}</td>
                                                                        <td>{data.strMedicineFrequency}</td>
                                                                        <td>{data.strMedFreqMorning}</td>
                                                                        <td>{data.strMedFreqAfternoon}</td>
                                                                        <td>{data.strMedFreqEvening}</td>
                                                                        <td>{data.strMedFreqNight}</td>
                                                                        <td>{data.strMedicineQuantity}</td>
                                                                        <td>{data.valueMedicineAdvise}</td>
                                                                        <td onClick={() => (medDeleteClicked(index))}><XSquare color="red" /></td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </Table>
                                            </Row>
                                        </Col>
                                    </Row>

                                    <Row className="border p-1 mb-2 mx-1">
                                        <Col md>
                                            <Row style={{ alignItems: "center", alignContent: "center" }}>
                                                <Col xs={4} style={{ fontSize: 12, color: "grey" }}>
                                                    Notes
                                                </Col>
                                                <Col xs={8}>
                                                    <Form.Group controlId="formSelectStateStatus">
                                                        <Form.Control as="textarea" rows={2} style={{ fontSize: 12 }} value={strDoctorNotes || ""} onChange={e => { setStrDoctorNotes(e.target.value) }} />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>

                                    <Row className="border p-1 mb-2 mx-1">
                                        <Col md>
                                            <Row style={{ alignItems: "center", alignContent: "center" }}>
                                                <Col xs={4} style={{ fontSize: 12, color: "grey" }}>
                                                    Advice
                                                </Col>
                                                <Col xs={8}>
                                                    <Form.Group controlId="formSelectStateStatus">
                                                        <Form.Control as="textarea" rows={2} style={{ fontSize: 12 }} value={strDoctorAdvice || ""} onChange={e => { setStrDoctorAdvice(e.target.value) }} />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>

                                </Col>

                            </Row>

                            <Row style={{ marginTop: 10, marginBottom: 10 }}>
                                <Col md></Col>
                                <Col md>
                                    <div style={{ textAlign: "center" }}>
                                        <Button variant="primary" type="submit" style={{ paddingLeft: 40, paddingRight: 40, fontSize: 16, width: 200 }} size="lg">Save</Button>
                                    </div>
                                </Col>

                                <Col md></Col>
                            </Row>

                        </Form>

                    </Container>

                </div>
            </div >
        </div >

    );
};

export default HospitalPatientGenerateCase;