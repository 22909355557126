import React, { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./admincss.css";
import HospitalSidebar from "../Navbar/HospitalSidebar";
import Adminheader from "../Navbar/Adminheader";
import { useLocation } from "react-router-dom";
import { Document, Page, View, PDFDownloadLink, Text, StyleSheet, Image } from "@react-pdf/renderer";
// import { API_BASE } from '../../setupProxy';
import * as moment from 'moment'
import Form from 'react-bootstrap/Form';

const HospitalGetPatientHistory = () => {

    const intOrganizationID = localStorage.getItem("orgid");

    const location = useLocation();

    const [strFirstName, setStrFirstName] = useState("");
    const [strLastName, setStrLastName] = useState("");
    const [intAge, setIntAge] = useState(null);
    const [strBloodGroup, setStrBloodGroup] = useState("");
    const [strGender, setStrGender] = useState("");
    const [strmobileno, setStrmobileno] = useState("");
    const [strcityname, setStrcityname] = useState("");

    const [strorganizationname, setStrorganizationname] = useState("");
    const [strwebsite, setStrwebsite] = useState("");
    const [strphoneno1, setStrphoneno1] = useState("");
    const [straddressline1, setStraddressline1] = useState("");
    const [straddressline2, setStraddressline2] = useState("");
    const [strarea, setStrarea] = useState("");
    const [strpincode, setStrpincode] = useState("");
    const [orgstrcityname, setOrgStrCityName] = useState("");
    const [orgstrstatename, setOrgStrStateName] = useState("");
    const [imagefile, setImageFile] = useState(null);
    const [imagefiletype, setImageFileType] = useState("");
    const [letterpadfile, setLetterpadFile] = useState(null);
    const [letterpadfiletype, setLetterpadFileType] = useState("");

    const [allPatientCases, setAllPatientCases] = useState([]);
    const [patientCasesDetails, setPatientCasesDetails] = useState([]);

    const { patid } = location.state ?? {}

    const currentdate = new Date();
    const todaydate = `${currentdate.getDate()}/${currentdate.getMonth() + 1}/${currentdate.getFullYear()}`;

    useEffect(() => {
        fetchPatientInfo(patid);
        fetchOrganizationData(intOrganizationID);
        fetchOrganizationAllPatientCases(intOrganizationID, patid);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchPatientInfo = (id) => {
        fetch("https://e-arogya.com:8443/api/general/patients/" + id)
            .then(res => res.json())
            .then(data => {
                setStrFirstName(data[0].strFirstName);
                setStrLastName(data[0].strLastName);
                setStrmobileno(data[0].strmobileno);
                setIntAge(data[0].intAge + " years");
                setStrGender(data[0].strGender);
                setStrBloodGroup(data[0].strBloodGroup);
                setStrcityname(data[0].strcityname);
            })
            .catch(error => {
                //console.log(error);
            });
    }

    const fetchOrganizationData = (id) => {
        fetch("https://e-arogya.com:8443/api/general/organization/" + id)
            .then(res => res.json())
            .then(data => {
                setStrorganizationname(data[0].strorganizationname);
                setStrwebsite("Website: " + data[0].strwebsite);
                setStrphoneno1("Phone: " + data[0].strphoneno1);
                setStraddressline1(data[0].straddressline1);
                setStraddressline2(data[0].straddressline2);
                setStrarea(data[0].strarea);
                setStrpincode(data[0].strpincode);
                if (data[0].strlogo) { setImageFile(data[0].strlogo) }
                if (data[0].strorgletterpad) { setLetterpadFile(data[0].strorgletterpad) }
                setOrgStrCityName(data[0].strcityname);
                getStateName(data[0].intstateid);
                setLetterpadFileType(data[0].strorgletterpadtype);
                setImageFileType(data[0].strlogotype);
            })
            .catch(error => {
                //console.log(error);
            });

    }

    const getStateName = (id) => {
        fetch("https://e-arogya.com:8443/api/general/states/" + id)
            .then(res => res.json())
            .then(data => {
                setOrgStrStateName(data[0].strstatename);
            })
            .catch(error => {
                //console.log(error);
            });
    }

    const fetchOrganizationAllPatientCases = async (orgid, patid) => {
        let response = await fetch("https://e-arogya.com:8443/api/general/orgallpatientcases/list/" + orgid + "/" + patid)
        let data = await response.json();

        setAllPatientCases(data);

        if (data) {

            let urls = [];
            data.forEach(
                function (d) {
                    urls.push("https://e-arogya.com:8443/api/general/orgallpatientcasesdetails/list/" + orgid + "/" + patid + "/" + d.intCaseID);
                }
            );

            Promise.all(
                urls.map(url => fetch(url)
                    .then(response => response.json())
                    .catch(err => console.error(err))
                )
            ).then(data1 => {
                //console.log("data1: " + JSON.stringify(data1));
                setPatientCasesDetails(data1);
            });
        }

    }

    //LetterPad Radio Button 
    const [withLp, setWithLp] = useState("Default");
    const [item, setItem] = useState({ letterType: "", another: "another" });
    const { letterType } = item;
    const handleRadioChange = e => {
        e.persist();
        setItem(prevState => ({ ...prevState, letterType: e.target.value }));
        //console.log("val: " + e.target.value);
        if (e.target.value === "On LetterPad") {
            setWithLp("On LetterPad");
        } else if (e.target.value === "Default") {
            setWithLp("Default");
        } else if (e.target.value === "With Uploaded Image") {
            setWithLp("With Uploaded Image");
        }
    };

    return (

        <div className="main" >

            <HospitalSidebar />

            <div className="page-content">

                <Adminheader />

                <h5>Patient History</h5>

                <div className="table-content">

                    <Form.Check label="On LetterPad" name="group1" type='radio' value="On LetterPad" aria-label="radio 1" style={{ fontSize: 12, margin: 5 }} onChange={handleRadioChange} checked={letterType === "On LetterPad"} />
                    <Form.Check label="Default" name="group1" type='radio' value="Default" aria-label="radio 2" style={{ fontSize: 12, margin: 5 }} onChange={handleRadioChange} checked={letterType === "Default"} />
                    <Form.Check label="With Uploaded Image" name="group1" type='radio' value="With Uploaded Image" aria-label="radio 3" style={{ fontSize: 12, margin: 5 }} onChange={handleRadioChange} checked={letterType === "With Uploaded Image"} />
                    {letterType ?

                        <p>
                            <PDFDownloadLink
                                document={
                                    <Document>
                                        <Page size="A4" style={styles.page}>

                                            {withLp === "On LetterPad" ?
                                                <View style={{ height: 200, backgroundColor: "#fff", paddingRight: 20, paddingLeft: 20 }} />
                                                :
                                                null
                                            }

                                            {withLp === "Default" ?
                                                <View style={{ height: 80, backgroundColor: "#fff", paddingRight: 20, paddingLeft: 20 }}>
                                                    <View style={{ flexDirection: "row", alignItems: "center" }}>
                                                        <View style={{ flexDirection: "column", flex: 1 }}>
                                                            <Text style={styles.mainHeading}>{strorganizationname}</Text>
                                                            <Text style={styles.headingLabel}>{straddressline1}, {straddressline2}, {strarea}, {orgstrcityname}, {orgstrstatename} - {strpincode}</Text>
                                                            <Text style={styles.headingLabel}>{strphoneno1}</Text>
                                                            <Text style={styles.headingLabel}>{strwebsite}</Text>
                                                        </View>
                                                        <View>
                                                            {imagefiletype === "image/png" ?
                                                                <Image src={`data:image/png;base64,${imagefile}`} style={styles.headinglogo} />
                                                                :
                                                                <Image src={`data:image/jpeg;base64,${imagefile}`} style={styles.headinglogo} />
                                                            }
                                                        </View>
                                                    </View>
                                                    <View style={{ height: 1, backgroundColor: "grey", marginTop: 10 }}></View>
                                                </View>
                                                :
                                                null
                                            }

                                            {withLp === "With Uploaded Image" ?
                                                <View style={{ alignItems: "center" }}>
                                                    {letterpadfiletype === "image/png" ?
                                                        <Image src={`data:image/png;base64,${letterpadfile}`} style={styles.headingbanner} />
                                                        :
                                                        <Image src={`data:image/jpeg;base64,${letterpadfile}`} style={styles.headingbanner} />
                                                    }

                                                </View>
                                                :
                                                null
                                            }

                                            <View style={{ margin: 20 }}>

                                                <View style={{ height: 20, backgroundColor: "#02647E", marginTop: 10, padding: 5, marginBottom: 10 }}>
                                                    <Text style={styles.mainSectionHeading}>PATIENT DETAILS</Text>
                                                </View>

                                                <View style={{ flexDirection: "row" }}>

                                                    <View style={{ flexDirection: "column" }}>

                                                        <View style={{ flexDirection: "row", padding: 2 }}>
                                                            <View style={{ flexDirection: "row" }}>
                                                                <Text style={styles.label}>Name:</Text>
                                                                <Text style={styles.labelans}>{strFirstName} {strLastName}</Text>
                                                            </View>
                                                            <View style={{ flexDirection: "row" }}>
                                                                <Text style={styles.label}>Age:</Text>
                                                                <Text style={styles.labelans}>{intAge}</Text>
                                                            </View>
                                                            <View style={{ flexDirection: "row" }}>
                                                                <Text style={styles.label}>Gender:</Text>
                                                                <Text style={styles.labelans}>{strGender}</Text>
                                                            </View>
                                                        </View>

                                                        <View style={{ flexDirection: "row", padding: 2 }}>
                                                            <View style={{ flexDirection: "row" }}>
                                                                <Text style={styles.label}>Blood Group:</Text>
                                                                <Text style={styles.labelans}>{strBloodGroup}</Text>
                                                            </View>
                                                            <View style={{ flexDirection: "row" }}>
                                                                <Text style={styles.label}>Mobile:</Text>
                                                                <Text style={styles.labelans}>{strmobileno}</Text>
                                                            </View>
                                                            <View style={{ flexDirection: "row" }}>
                                                                <Text style={styles.label}>City:</Text>
                                                                <Text style={styles.labelans}>{strcityname}</Text>
                                                            </View>
                                                        </View>

                                                    </View>

                                                    <View style={{ flexDirection: "column" }}>
                                                        <View style={{ flexDirection: "row", padding: 2 }}>
                                                            <Text style={styles.label}>Date:</Text>
                                                            <Text style={styles.labelans}>{todaydate}</Text>
                                                        </View>
                                                    </View>
                                                </View>

                                                <View style={{ height: 20, backgroundColor: "#02647E", marginTop: 10, padding: 5, marginBottom: 10 }}>
                                                    <Text style={styles.mainSectionHeading}>PATIENT CASES</Text>
                                                </View>


                                                {allPatientCases?.map((pc, index) =>

                                                    <View key={index + "pc"} break={index > 0} wrap={true}  >

                                                        <View style={{ height: 20, backgroundColor: "#037C6E", marginTop: 10, padding: 5, marginBottom: 10 }} >
                                                            <Text style={styles.mainSectionHeading}>Case No: {pc.strCaseNo} - Case Register Date: {moment(pc.dtmCaseRegisterDat).format('DD/MM/YYYY')}</Text>
                                                        </View>

                                                        {patientCasesDetails[index]?.map((pcd, index1) =>

                                                            <View key={index1 + "pcd"} break={index1 > 0} wrap={true} >

                                                                <View style={{ height: 20, backgroundColor: "#81CDC6", margin: 10, padding: 5 }} >
                                                                    <Text style={styles.mainSectionHeading}>{pcd.strAppointmentType} - Appointment Date: {moment(pcd.dtmAppointmentDate).format('DD/MM/YYYY')}</Text>
                                                                </View>

                                                                {pcd.strWeight || pcd.strHeight || pcd.strBmi || pcd.strBsa || pcd.strPulse || pcd.strSpo || pcd.strBloodPressure || pcd.strRespiratoryRate || pcd.strTemperature !== null ?

                                                                    <View>

                                                                        <View>
                                                                            {pcd.strWeight !== ' kg' || pcd.strHeight !== ' ft' || pcd.strBmi !== null || pcd.strBsa !== null || pcd.strPulse !== null || pcd.strSpo !== null || pcd.strBloodPressure !== null || pcd.strRespiratoryRate !== null || pcd.strTemperature !== null ?
                                                                                <View style={{ margin: 10 }} key={index + "ce"}>
                                                                                    <Text style={styles.dataHeading}>VITALS</Text>
                                                                                </View>
                                                                                :
                                                                                <View></View>
                                                                            }

                                                                            <View style={{ flexDirection: "row" }}>

                                                                                {pcd.strWeight !== ' kg' ?
                                                                                    <View style={{ flexDirection: "column" }}>
                                                                                        <Text style={styles.dataLabel}>Weight:</Text>
                                                                                        <Text style={styles.dataLabel}>{pcd.strWeight}</Text>
                                                                                    </View>
                                                                                    :
                                                                                    <View></View>
                                                                                }
                                                                                {pcd.strHeight !== ' ft' ?
                                                                                    <View style={{ flexDirection: "column" }}>
                                                                                        <Text style={styles.dataLabel}>Height:</Text>
                                                                                        <Text style={styles.dataLabel}>{pcd.strHeight}</Text>
                                                                                    </View>
                                                                                    :
                                                                                    <View></View>
                                                                                }
                                                                                {pcd.strBmi !== null ?
                                                                                    <View style={{ flexDirection: "column" }}>
                                                                                        <Text style={styles.dataLabel}>BMI:</Text>
                                                                                        <Text style={styles.dataLabel}>{pcd.strBmi} kg/m²</Text>
                                                                                    </View>
                                                                                    :
                                                                                    <View></View>
                                                                                }
                                                                                {pcd.strBsa !== null ?
                                                                                    <View style={{ flexDirection: "column" }}>
                                                                                        <Text style={styles.dataLabel}>BSA:</Text>
                                                                                        <Text style={styles.dataLabel}>{pcd.strBsa} m²</Text>
                                                                                    </View>
                                                                                    :
                                                                                    <View></View>
                                                                                }
                                                                                {pcd.strPulse !== null ?
                                                                                    <View style={{ flexDirection: "column" }}>
                                                                                        <Text style={styles.dataLabel}>Pulse:</Text>
                                                                                        <Text style={styles.dataLabel}>{pcd.strPulse} /min</Text>
                                                                                    </View>
                                                                                    :
                                                                                    <View></View>
                                                                                }
                                                                                {pcd.strSpo !== null ?
                                                                                    <View style={{ flexDirection: "column" }}>
                                                                                        <Text style={styles.dataLabel}>Spo:</Text>
                                                                                        <Text style={styles.dataLabel}>{pcd.strSpo}%</Text>
                                                                                    </View>
                                                                                    :
                                                                                    <View></View>
                                                                                }
                                                                                {pcd.strBloodPressure !== null ?
                                                                                    <View style={{ flexDirection: "column" }}>
                                                                                        <Text style={styles.dataLabel}>Blood Pressure:</Text>
                                                                                        <Text style={styles.dataLabel}>{pcd.strBloodPressure} mm/Hg</Text>
                                                                                    </View>
                                                                                    :
                                                                                    <View></View>
                                                                                }
                                                                                {pcd.strRespiratoryRate !== null ?
                                                                                    <View style={{ flexDirection: "column" }}>
                                                                                        <Text style={styles.dataLabel}>Respiratory Rate:</Text>
                                                                                        <Text style={styles.dataLabel}>{pcd.strRespiratoryRate} breaths/min</Text>
                                                                                    </View>
                                                                                    :
                                                                                    <View></View>
                                                                                }
                                                                                {pcd.strTemperature !== null ?
                                                                                    <View style={{ flexDirection: "column" }}>
                                                                                        <Text style={styles.dataLabel}>Temperature:</Text>
                                                                                        <Text style={styles.dataLabel}>{pcd.strTemperature} °F</Text>
                                                                                    </View>
                                                                                    :
                                                                                    <View></View>
                                                                                }

                                                                            </View>
                                                                        </View>

                                                                    </View>

                                                                    :
                                                                    <View></View>
                                                                }

                                                                {pcd.strPersonalHistory || pcd.strMajorIllness || pcd.strHospitalization || pcd.strMedications || pcd.strCovidHO || pcd.strCovidHospitalization || pcd.strCovidVaccine || pcd.strFamilyHistory !== null ?
                                                                    <View>
                                                                        <View style={{ margin: 10 }} key={index1 + "ce"}>
                                                                            <Text style={styles.dataHeading}>HISTORY</Text>
                                                                        </View>

                                                                        {pcd.strPersonalHistory !== null ?
                                                                            <View style={{ flexDirection: "row" }}>
                                                                                <Text style={styles.dataLabel}>Personal History: </Text>
                                                                                <Text style={styles.dataLabelOther}>{pcd.strPersonalHistory}</Text>
                                                                            </View>
                                                                            :
                                                                            <View></View>
                                                                        }
                                                                        {pcd.strMajorIllness !== null ?
                                                                            <View style={{ flexDirection: "row" }}>
                                                                                <Text style={styles.dataLabel}>Major Illness: </Text>
                                                                                <Text style={styles.dataLabelOther}>{pcd.strMajorIllness}</Text>
                                                                            </View>
                                                                            :
                                                                            <View></View>
                                                                        }
                                                                        {pcd.strHospitalization !== null ?
                                                                            <View style={{ flexDirection: "row" }}>
                                                                                <Text style={styles.dataLabel}>Hospitalization: </Text>
                                                                                <Text style={styles.dataLabelOther}>{pcd.strHospitalization}</Text>
                                                                            </View>
                                                                            :
                                                                            <View></View>
                                                                        }
                                                                        {pcd.strMedications !== null ?
                                                                            <View style={{ flexDirection: "row" }}>
                                                                                <Text style={styles.dataLabel}>Medications: </Text>
                                                                                <Text style={styles.dataLabelOther}>{pcd.strMedications}</Text>
                                                                            </View>
                                                                            :
                                                                            <View></View>
                                                                        }
                                                                        {pcd.strCovidHO !== null ?
                                                                            <View style={{ flexDirection: "row" }}>
                                                                                <Text style={styles.dataLabel}>Covid-19 HO: </Text>
                                                                                <Text style={styles.dataLabelOther}>{pcd.strCovidHO}</Text>
                                                                            </View>
                                                                            :
                                                                            <View></View>
                                                                        }
                                                                        {pcd.strCovidHospitalization !== null ?
                                                                            <View style={{ flexDirection: "row" }}>
                                                                                <Text style={styles.dataLabel}>Covid Hospitalization: </Text>
                                                                                <Text style={styles.dataLabelOther}>{pcd.strCovidHospitalization}</Text>
                                                                            </View>
                                                                            :
                                                                            <View></View>
                                                                        }
                                                                        {pcd.strCovidVaccine !== null ?
                                                                            <View style={{ flexDirection: "row" }}>
                                                                                <Text style={styles.dataLabel}>Covid Vaccine: </Text>
                                                                                <Text style={styles.dataLabelOther}>{pcd.strCovidVaccine}</Text>
                                                                            </View>
                                                                            :
                                                                            <View></View>
                                                                        }
                                                                        {pcd.strFamilyHistory !== null ?
                                                                            <View style={{ flexDirection: "row" }}>
                                                                                <Text style={styles.dataLabel}>Family History: </Text>
                                                                                <Text style={styles.dataLabelOther}>{pcd.strFamilyHistory}</Text>
                                                                            </View>
                                                                            :
                                                                            <View></View>
                                                                        }

                                                                    </View>
                                                                    :
                                                                    <View></View>
                                                                }

                                                                {pcd.strComplain !== '' ?
                                                                    <View>
                                                                        <View style={{ margin: 10 }} key={index1 + "ce"}>
                                                                            <Text style={styles.dataHeading}>CHIEF COMPLAINTS</Text>
                                                                        </View>
                                                                        <Text style={styles.dataLabel}>{pcd.strComplain}</Text>
                                                                    </View>
                                                                    :
                                                                    <View></View>
                                                                }

                                                                {pcd.strInvestigation !== '' ?
                                                                    <View>
                                                                        <View style={{ margin: 10 }} key={index1 + "ce"}>
                                                                            <Text style={styles.dataHeading}>INVESTIGATIONS</Text>
                                                                        </View>
                                                                        <Text style={styles.dataLabel}>{pcd.strInvestigation}</Text>
                                                                    </View>
                                                                    :
                                                                    <View></View>
                                                                }

                                                                {pcd.strClinicalExamination !== null ?
                                                                    <View>
                                                                        <View style={{ margin: 10 }} key={index1 + "ce"}>
                                                                            <Text style={styles.dataHeading}>CLINICAL EXAMINATION</Text>
                                                                        </View>
                                                                        <Text style={styles.dataLabel}>{pcd.strClinicalExamination}</Text>
                                                                    </View>
                                                                    :
                                                                    <View></View>
                                                                }

                                                                {pcd.strDiagnosisSearch || pcd.strDiagnosis !== null ?
                                                                    <View>
                                                                        <View style={{ margin: 10 }} key={index1 + "ce"}>
                                                                            <Text style={styles.dataHeading}>DIAGNOSIS</Text>
                                                                        </View>

                                                                        {pcd.strDiagnosisSearch !== null ?
                                                                            <View style={{ flexDirection: "row" }}>
                                                                                <Text style={styles.dataLabel}>Diagnose: </Text>
                                                                                <Text style={styles.dataLabelOther}>{pcd.strDiagnosisSearch}</Text>
                                                                            </View>
                                                                            :
                                                                            <View></View>
                                                                        }

                                                                        {pcd.strDiagnosis !== null ?
                                                                            <View style={{ flexDirection: "row" }}>
                                                                                <Text style={styles.dataLabel}>Diagnose Notes: </Text>
                                                                                <Text style={styles.dataLabelOther}>{pcd.strDiagnosis}</Text>
                                                                            </View>
                                                                            :
                                                                            <View></View>
                                                                        }

                                                                    </View>
                                                                    :
                                                                    <View></View>
                                                                }

                                                                {strGender === "Female" ?

                                                                    <View>

                                                                        {pcd.strMenstrualCycle || pcd.strPain || pcd.dtmLmpDate || pcd.strChildren || pcd.strAbortion || pcd.strTypeOfDelivery !== null ?
                                                                            <View>
                                                                                <View style={{ margin: 10 }} key={index1 + "ce"}>
                                                                                    <Text style={styles.dataHeading}>FOR FEMALE</Text>
                                                                                </View>

                                                                                <View style={{ flexDirection: "row" }}>

                                                                                    {pcd.strMenstrualCycle !== null ?
                                                                                        <View style={{ flexDirection: "column" }}>
                                                                                            <Text style={styles.dataLabel}>Menstrual Cycle:</Text>
                                                                                            <Text style={styles.dataLabel}>{pcd.strMenstrualCycle}</Text>
                                                                                        </View>
                                                                                        :
                                                                                        <View></View>
                                                                                    }
                                                                                    {pcd.strPain !== null ?
                                                                                        <View style={{ flexDirection: "column" }}>
                                                                                            <Text style={styles.dataLabel}>Pain:</Text>
                                                                                            <Text style={styles.dataLabel}>{pcd.strPain}</Text>
                                                                                        </View>
                                                                                        :
                                                                                        <View></View>
                                                                                    }
                                                                                    {pcd.dtmLmpDate !== null ?
                                                                                        <View style={{ flexDirection: "column" }}>
                                                                                            <Text style={styles.dataLabel}>LMP Date:</Text>
                                                                                            <Text style={styles.dataLabel}>{pcd.dtmLmpDate}</Text>
                                                                                        </View>
                                                                                        :
                                                                                        <View></View>
                                                                                    }
                                                                                    {pcd.strChildren !== null ?
                                                                                        <View style={{ flexDirection: "column" }}>
                                                                                            <Text style={styles.dataLabel}>Children:</Text>
                                                                                            <Text style={styles.dataLabel}>{pcd.strChildren}</Text>
                                                                                        </View>
                                                                                        :
                                                                                        <View></View>
                                                                                    }
                                                                                    {pcd.strAbortion !== null ?
                                                                                        <View style={{ flexDirection: "column" }}>
                                                                                            <Text style={styles.dataLabel}>Abortion:</Text>
                                                                                            <Text style={styles.dataLabel}>{pcd.strAbortion}</Text>
                                                                                        </View>
                                                                                        :
                                                                                        <View></View>
                                                                                    }
                                                                                    {pcd.strTypeOfDelivery !== null ?
                                                                                        <View style={{ flexDirection: "column" }}>
                                                                                            <Text style={styles.dataLabel}>Type of Delivery:</Text>
                                                                                            <Text style={styles.dataLabel}>{pcd.strTypeOfDelivery}</Text>
                                                                                        </View>
                                                                                        :
                                                                                        <View></View>
                                                                                    }

                                                                                </View>
                                                                            </View>
                                                                            :
                                                                            <View></View>
                                                                        }
                                                                    </View>
                                                                    :
                                                                    <View></View>
                                                                }

                                                                {pcd.strDoctorAdvice !== null ?
                                                                    <View>
                                                                        <View style={{ margin: 10 }} key={index1 + "ce"}>
                                                                            <Text style={styles.dataHeading}>ADVICE</Text>
                                                                        </View>
                                                                        <Text style={styles.dataLabel}>{pcd.strDoctorAdvice}</Text>
                                                                    </View>
                                                                    :
                                                                    <View></View>
                                                                }

                                                                {pcd.strMedicationTreatment[0].strMedicineName || pcd.strMedicationTreatment[0].strMedicineType || pcd.strMedicationTreatment[0].strMedicineDuration ||
                                                                    pcd.strMedicationTreatment[0].strMedicineFrequency || pcd.strMedicationTreatment[0].strMedicineAdvise || pcd.strMedicationTreatment[0].strMedFreqMorning ||
                                                                    pcd.strMedicationTreatment[0].strMedFreqAfternoon || pcd.strMedicationTreatment[0].strMedFreqEvening || pcd.strMedicationTreatment[0].strMedFreqNight ||
                                                                    pcd.strMedicationTreatment[0].strMedicineQuantity !== null ?

                                                                    <View>

                                                                        <View style={{ margin: 10 }} key={index1 + "ce"}>
                                                                            <Text style={styles.dataHeading}>MEDICATION & TREATMENT</Text>
                                                                        </View>

                                                                        <View style={styles.table}>
                                                                            <View style={styles.tableRowHeader}>
                                                                                <View style={{ width: "3%" }}>
                                                                                    <Text style={styles.tableCell}>#</Text>
                                                                                </View>
                                                                                <View style={{ width: "21%" }}>
                                                                                    <Text style={styles.tableCell}>Name</Text>
                                                                                </View>
                                                                                <View style={{ width: "10%" }}>
                                                                                    <Text style={styles.tableCell}>Type</Text>
                                                                                </View>
                                                                                <View style={{ width: "10%" }}>
                                                                                    <Text style={styles.tableCell}>Duration</Text>
                                                                                </View>
                                                                                <View style={{ width: "10%" }}>
                                                                                    <Text style={styles.tableCell}>Frequency</Text>
                                                                                </View>
                                                                                <View style={{ width: "10%" }}>
                                                                                    <Text style={styles.tableCell}>Quantity</Text>
                                                                                </View>
                                                                                <View style={{ width: "20%" }}>
                                                                                    <Text style={styles.tableCell}>Advise</Text>
                                                                                </View>
                                                                            </View>

                                                                            {pcd.strMedicationTreatment.map((as, index) => (

                                                                                <View key={index + "as"}>

                                                                                    <View style={styles.tableRowItem} key={index + 1} id={index}>
                                                                                        <View style={{ width: "3%", borderColor: "#e0e0e0", borderLeftWidth: 0.5, borderRightWidth: 0.5 }}>
                                                                                            <Text style={styles.tableCell}>{index + 1}</Text>
                                                                                        </View>
                                                                                        <View style={{ width: "21%", borderColor: "#e0e0e0", borderRightWidth: 0.5 }}>
                                                                                            <Text style={styles.tableCell}>{as.strMedicineName}</Text>
                                                                                        </View>
                                                                                        <View style={{ width: "10%", borderColor: "#e0e0e0", borderRightWidth: 0.5 }}>
                                                                                            <Text style={styles.tableCell}>{as.strMedicineType}</Text>
                                                                                        </View>
                                                                                        <View style={{ width: "10%", borderColor: "#e0e0e0", borderRightWidth: 0.5 }}>
                                                                                            <Text style={styles.tableCell}>{as.strMedicineDuration}</Text>
                                                                                        </View>
                                                                                        <View style={{ width: "10%", borderColor: "#e0e0e0", borderRightWidth: 0.5 }}>
                                                                                            <Text style={styles.tableCell}>{as.strMedicineFrequency}</Text>
                                                                                        </View>
                                                                                        <View style={{ width: "10%", borderColor: "#e0e0e0", borderRightWidth: 0.5 }}>
                                                                                            <Text style={styles.tableCell}>{as.strMedicineQuantity}</Text>
                                                                                        </View>
                                                                                        <View style={{ width: "20%", borderColor: "#e0e0e0", borderRightWidth: 0.5 }}>
                                                                                            <Text style={styles.tableCell}>{as.strMedicineAdvise}</Text>
                                                                                        </View>
                                                                                    </View>

                                                                                </View>
                                                                            )
                                                                            )}

                                                                        </View>
                                                                    </View>
                                                                    :
                                                                    <View>
                                                                        <View style={{ margin: 10 }} key={index1 + "ce"}>
                                                                            <Text style={styles.dataHeading}>MEDICATION & TREATMENT</Text>
                                                                        </View>
                                                                        <Text style={styles.dataLabel}>No Medicines Prescribed</Text>
                                                                    </View>
                                                                }

                                                            </View>
                                                        )}

                                                    </View>
                                                )}

                                                <View style={{ justifyContent: "flex-end", flexDirection: 'row' }}>
                                                    <View style={{ height: 1, backgroundColor: "grey", marginBottom: 5, marginTop: 50, width: "20%", }}></View>
                                                </View>

                                                <View style={styles.signature}>
                                                    <Text style={styles.label}>Signature</Text>
                                                </View>

                                            </View>
                                        </Page>
                                    </Document>
                                }
                                fileName={"Invoice" + new Date().getTime() + ".pdf"} >

                                {({ blob, url, loading, error }) => loading ? "Loading . . ." : "Download"}
                            </PDFDownloadLink>
                        </p>
                        :
                        <p></p>
                    }

                </div>

            </div>

        </div>

    );
};

export default HospitalGetPatientHistory;

const styles = StyleSheet.create({
    page: {
        padding: 10,
        fontSize: 8,
    },
    mainHeading: {
        fontSize: 20,
        marginTop: 10,
        marginBottom: 10,
        color: "#004AAD",
    },
    headingLabel: {
        color: "grey",
    },
    headinglogo: {
        width: 50,
        height: 50,
        color: "blue",
    },
    headingbanner: {
        // width: 559,
        height: 200,
    },
    label: {
        marginRight: 5,
        color: "grey",
    },
    labelans: {
        marginRight: 20,
    },
    dataHeading: {
        fontSize: 8,
    },
    dataLabel: {
        marginLeft: 15,
        color: "grey",
    },
    dataLabelOther: {
        color: "grey",
    },
    mainSectionHeading: {
        fontSize: 8,
        color: "#fff",
    },
    table: {
        display: "table",
        width: "100%",
        padding: 10,
    },
    tableRowHeader: {
        margin: "auto",
        flexDirection: "row",
        backgroundColor: "#e0e0e0",
        height: 20,
    },
    tableRowItem: {
        margin: "auto",
        flexDirection: "row",
        backgroundColor: "#fff",
        height: 20,
        borderColor: "#e0e0e0",
        borderBottomWidth: 0.5,
    },
    tableCell: {
        margin: "auto",
        fontSize: 8,
        color: "grey",
    },
    signature: {
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },

});