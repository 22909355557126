import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./admincss.css";
import Adminheader from "../Navbar/Adminheader";
import { Col, Row, Form, Button } from "react-bootstrap";
import { CDBCard, CDBCardBody, CDBDataTable } from 'cdbreact';
import LaboratorySidebar from "../Navbar/LaboratorySidebar";
import { Asterisk } from "react-bootstrap-icons";

function LaboratoryManageReports() {

    function testClickEvent(param) {
        //console.log('Row Click Event');
    }

    const data = () => {
        return {
            columns: [
                { label: '#', field: 'srno', },
                {
                    label: 'Name',
                    field: 'name',
                    attributes: {
                        'aria-controls': 'DataTable',
                        'aria-label': 'Name',
                    },
                },
                { label: 'Report', field: 'report', },
                { label: 'Status', field: 'status', },
                { label: '', field: 'edit', },
                { label: '', field: 'delete', },

            ],
            rows: [
                {
                    srno: '1',
                    name: 'CBC',
                    report: 'Blood Report',
                    status: 'Active',
                    edit: 'Edit',
                    delete: 'Delete',
                    clickEvent: () => testClickEvent(1),
                },
                {
                    srno: '2',
                    name: 'Lipid Panel',
                    report: 'Blood Report',
                    status: 'Active',
                    edit: 'Edit',
                    delete: 'Delete',
                    clickEvent: () => testClickEvent(1),
                },

            ],
        };
    };

    return (

        <div className="main" >

            <LaboratorySidebar />

            <div className="page-content">

                <Adminheader />

                <h5>Manage Reports (Tests)</h5>

                <div className="table-content">

                    <Row style={{ fontSize: 12, color: "grey" }}>

                        <Col sm={2}>
                            <Form.Group className="mb-3" controlId="formSelectStateStatus">
                                <Form.Label><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Report Type</Form.Label>
                                <Form.Select aria-label="Default select example" style={{ fontSize: 12 }}>
                                    <option value="1">Blood Report</option>
                                    <option value="2">Urine Report</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>

                        <Col sm={2}>
                            <Form.Group className="mb-3" controlId="formSelectStateStatus">
                                <Form.Label><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Test Type</Form.Label>
                                <Form.Select aria-label="Default select example" style={{ fontSize: 12 }}>
                                    <option value="1">CBC</option>
                                    <option value="2">Lipid Panel</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>

                        <Col sm={2}>
                            <Form.Group className="mb-3" controlId="formEnterState">
                                <Form.Label>Remarks</Form.Label>
                                <Form.Control type="text" style={{ fontSize: 12 }} />
                            </Form.Group>
                        </Col>

                        <Col sm={2}>
                            <Form.Group className="mb-3" controlId="formSelectStateStatus">
                                <Form.Label>Status</Form.Label>
                                <Form.Select aria-label="Default select example" style={{ fontSize: 12 }}>
                                    <option value="1">Active</option>
                                    <option value="2">Inactive</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>

                        <Col sm={2} >
                            <Form.Group className="mb-3" controlId="formEnterState">
                                <Button variant="primary" type="submit" style={{ marginTop: 25, fontSize: 12 }}> Add </Button>
                            </Form.Group>
                        </Col>

                    </Row>

                    <CDBCard style={{ padding: 20, fontSize: 12, color: "grey" }}>
                        <CDBCardBody>
                            <CDBDataTable
                                responsive
                                noRecordsFoundLabel="No Records Found"
                                noBottomColumn={true}
                                hover
                                entriesOptions={[10, 25, 50, 100, 200, 500]}
                                entries={5}
                                pagesAmount={4}
                                data={data()}

                            />
                        </CDBCardBody>
                    </CDBCard>


                </div>
            </div>
        </div>
    );
}

export default LaboratoryManageReports;