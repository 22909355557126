import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./admincss.css";
import HospitalSidebar from "../Navbar/HospitalSidebar";
import Adminheader from "../Navbar/Adminheader";
import { CDBCard, CDBCardBody, CDBDataTable } from 'cdbreact';
import { Spinner } from "react-bootstrap";
// import { API_BASE } from '../../setupProxy';

function HospitalPatientCases() {

    const intOrganizationID = localStorage.getItem("orgid");
    //const roleName = localStorage.getItem("loggedRoleName");

    const [cases, setCases] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchCases(intOrganizationID);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchCases = (orgid) => {
        fetch("https://e-arogya.com:8443/api/general/cases/list/" + orgid)
            .then(response => response.json())
            .then(data => {
                let dataFromApi = data.map(pat => {
                    return {
                        id: pat.intCaseID,
                        firstname: pat.strFirstName,
                        lastname: pat.strLastName,
                        caseno: pat.strCaseNo,
                        casetype: pat.strCaseType,
                        casestatus: pat.strCaseStatus,
                        caseregdate: pat.dtmCaseRegisterDate,
                        apptype: pat.strAppointmentType,
                    }
                })
                setCases(dataFromApi);
                setLoading(true);
            })
            .catch(error => {
                //console.log(error);
            })
    }

    const data = () => {
        return {
            columns: [
                { label: 'Sr.No.', field: 'srno', },
                {
                    label: 'Name',
                    field: 'name',
                    attributes: {
                        'aria-controls': 'DataTable',
                        'aria-label': 'Name',
                    },
                },
                { label: 'Case No', field: 'caseno', },
                { label: 'Case Type', field: 'casetype', },
                { label: 'Case Status', field: 'casestatus', },
                { label: 'Case Reg. Date', field: 'caseregdate', },
                { label: 'Appointment Type', field: 'apptype', },
            ],
            rows: [...cases.map((data1, index) => (
                {
                    srno: index + 1,
                    name: data1.firstname + " " + data1.lastname,
                    caseno: data1.caseno,
                    casetype: data1.casetype,
                    casestatus: data1.casestatus,
                    caseregdate: data1.caseregdate,
                    apptype: data1.apptype,
                }
            ))

            ],
        };
    };

    return (

        <div className="main" >

            <HospitalSidebar />

            <div className="page-content">

                <Adminheader />

                <h5>Patient Cases</h5>

                <div className="table-content">

                    <CDBCard style={{ padding: 20, fontSize: 12, color: "grey" }}>
                        <CDBCardBody >
                            {!loading ? (
                                <div style={{ textAlign: "center" }}><Spinner animation="border" size="sm" variant="primary" /></div>
                            ) :
                                <CDBDataTable
                                    responsive
                                    noRecordsFoundLabel="No Records Found"
                                    noBottomColumn={true}
                                    hover
                                    entriesOptions={[10, 25, 50, 100, 200, 500]}
                                    entries={10}
                                    pagesAmount={4}
                                    data={data()}
                                />
                            }
                        </CDBCardBody>
                    </CDBCard>

                </div>

            </div>
        </div>

    );
}

export default HospitalPatientCases;