import React, { useState, useEffect, useRef } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./admincss.css";
import HospitalSidebar from "../Navbar/HospitalSidebar";
import LaboratorySidebar from "../Navbar/LaboratorySidebar";
import PharmacySidebar from "../Navbar/PharmacySidebar";
import Adminheader from "../Navbar/Adminheader";
import { Col, Form, Row, Button, Image } from "react-bootstrap";
import { GoogleMap, Marker, LoadScript } from "@react-google-maps/api";
import { Asterisk } from "react-bootstrap-icons";
// import { API_BASE } from '../../setupProxy';
/* global google */

function OrganizationEditProfile() {

    const intorganizationid = localStorage.getItem("orgid");
    const intloginid = localStorage.getItem("loggedInId");
    const roleName = localStorage.getItem("loggedRoleName");

    const [updateMessage, setUpdateMessage] = useState("");
    const [locStatus, setLocStatus] = useState(null);

    const [strorganizationtype, setStrorganizationtype] = useState("");
    const [strorganizationname, setStrorganizationname] = useState("");
    const [strwebsite, setStrwebsite] = useState("");
    const [strphoneno1, setStrphoneno1] = useState("");
    const [strphoneno2, setStrphoneno2] = useState("");
    const [straddressline1, setStraddressline1] = useState("");
    const [straddressline2, setStraddressline2] = useState("");
    const [strarea, setStrarea] = useState("");
    const [strpincode, setStrpincode] = useState("");
    const [intcityid, setIntcityid] = useState(null);
    const [intstateid, setIntstateid] = useState(null);
    const [strstatus, setStatus] = useState('Active');
    const [decorglatitude, setDecorglatitude] = useState(22.3039);
    const [decorglongitude, setDecorglongitude] = useState(70.8022);
    const [strorganizationregno, setStrorganizationregno] = useState("");

    const [cities, setCities] = useState([]);
    const [states, setStates] = useState([]);
    const [filteredCities, setFilteredCities] = useState([]);

    const [files, setFiles] = useState('');
    const [imagefile, setImageFile] = useState(null);
    const aRef = useRef(null);
    const [uploadlogofile, setUploadLogoFile] = useState(null);

    const [certifiles, setCertiFiles] = useState('');
    const [certiimagefile, setCertiImageFile] = useState(null);
    const aCertiRef = useRef(null);
    const [uploadcertifile, setUploadCertiFile] = useState(null);

    const [letterpadfiles, setLetterpadFiles] = useState('');
    const [letterpadimagefile, setLetterpadImageFile] = useState(null);
    const aLetterpadRef = useRef(null);
    const [uploadletterpadfile, setUploadLetterpadFile] = useState(null);

    const [hospitalAdmin, setHospitalAdmin] = useState(false);
    const [laboratoryAdmin, setLaboratoryAdmin] = useState(false);
    const [pharmacyAdmin, setPharmacyAdmin] = useState(false);

    useEffect(() => {
        fetchOrganizationData(intorganizationid);
        checkRoles();
        fetchCities();
        fetchStates();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const checkRoles = () => {
        if (roleName === "Hospital Admin") {
            setHospitalAdmin(true);
            setLaboratoryAdmin(false);
            setPharmacyAdmin(false);
        } else if (roleName === "Laboratory Admin") {
            setHospitalAdmin(false);
            setLaboratoryAdmin(true);
            setPharmacyAdmin(false);
        } else if (roleName === "Pharmacy Admin") {
            setHospitalAdmin(false);
            setLaboratoryAdmin(false);
            setPharmacyAdmin(true);
        }
    }

    const fetchStates = () => {
        fetch("https://e-arogya.com:8443/api/general/states")
            .then(res => res.json())
            .then(states => {
                setStates(states);
            })
            .catch(error => {
                //console.log(error);
            })
    }

    const fetchCities = () => {
        fetch("https://e-arogya.com:8443/api/general/cities")
            .then(res => res.json())
            .then(cities => {
                setCities(cities);
            })
            .catch(error => {
                //console.log(error);
            })
    }

    //FETCH ORGANIZATION BY ID (FOR EDIT ORGANIZATION)
    const fetchOrganizationData = (id) => {
        fetch("https://e-arogya.com:8443/api/general/organization/" + id)
            .then(res => res.json())
            .then(data => {
                //console.log("data: " + JSON.stringify(data));
                setStrorganizationtype(data[0].strorganizationtype);
                setStrorganizationname(data[0].strorganizationname);
                if (data[0].strorganizationregno) { setStrorganizationregno(data[0].strorganizationregno) }
                setStrwebsite(data[0].strwebsite);
                setStrphoneno1(data[0].strphoneno1);
                setStrphoneno2(data[0].strphoneno2);
                setStraddressline1(data[0].straddressline1);
                setStraddressline2(data[0].straddressline2);
                setStrarea(data[0].strarea);
                setStrpincode(data[0].strpincode);
                if (data[0].decorglatitude === 0 || data[0].decorglongitude === 0) {
                    getCurrentLocation();
                } else {
                    setDecorglatitude(data[0].decorglatitude);
                    setDecorglongitude(data[0].decorglongitude);
                }

                if (data[0].strlogo) { setImageFile(data[0].strlogo) }
                if (data[0].strorganizationregcertificate) { setCertiImageFile(data[0].strorganizationregcertificate) }
                if (data[0].strorgletterpad) { setLetterpadImageFile(data[0].strorgletterpad) }
                setStatus(data[0].strstatus);
                setIntstateid(data[0].intstateid);

                if (data[0].intstateid) {
                    fetch("https://e-arogya.com:8443/api/general/cities")
                        .then(res => res.json())
                        .then(cities => {
                            setCities(cities);

                            let cityList = [];
                            for (let i = 0; i < cities.length; i++) {
                                if (cities[i].intstateid === parseInt(data[0].intstateid)) {
                                    cityList.push(cities[i]);
                                }
                            }
                            setFilteredCities(cityList);
                            setIntcityid(data[0].intcityid);
                        })
                        .catch(error => {
                            //console.log(error);
                        })
                }
            })
            .catch(error => {
                //console.log(error);
            });

    }

    const getCurrentLocation = () => {
        if (!navigator.geolocation) {
            setLocStatus('Geolocation is not supported by your browser');
        } else {
            setLocStatus('Locating...');
            navigator.geolocation.getCurrentPosition((position) => {
                setLocStatus(null);
                setDecorglatitude(position.coords.latitude);
                setDecorglongitude(position.coords.longitude);
                //console.log("curr lat: " + position.coords.latitude + " longi: " + position.coords.longitude);
            }, () => {
                setLocStatus('Unable to retrieve your location');
            });
        }
    }

    const getAddress = (id) => {
        fetch("https://e-arogya.com:8443/api/general/citiesstates/" + id)
            .then(res => res.json())
            .then(data => {
                setMarkerByAddress(data[0].strcityname, data[0].strstatename);
            })
            .catch(error => {
                //console.log(error);
            });
    }

    const setMarkerByAddress = (cityname, statename) => {
        var address = `${strorganizationname}, ${cityname}, ${statename}`;
        //console.log("Address: " + address);
        var geocoder = new google.maps.Geocoder();
        geocoder.geocode({
            'address': address
        }, function (results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
                var Lat = results[0].geometry.location.lat();
                var Lng = results[0].geometry.location.lng();
                setDecorglatitude(Lat);
                setDecorglongitude(Lng);
            } else {
                setUpdateMessage(<span style={{ color: "red" }}>Something got wrong</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
            }
        });
    }

    //FORM SUBMIT
    const onAddNewOrgTimingsFormSubmit = (event) => {
        event.preventDefault();

        const formData = new FormData();

        if (strphoneno1) {
            if (strphoneno1 === "" || strphoneno1.length !== 10 || isNaN(strphoneno1)) {
                setUpdateMessage(<span style={{ color: "red" }}>Please Enter Valid 10 digit Phone 1</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        }
        if (strphoneno2) {
            if (strphoneno2 === "" || strphoneno2.length !== 10 || isNaN(strphoneno2)) {
                setUpdateMessage(<span style={{ color: "red" }}>Please Enter Valid 10 digit Phone 2</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        }
        if (strwebsite) {
            if (strwebsite === "") {
                setUpdateMessage(<span style={{ color: "red" }}>Please enter organization website</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        }

        if (strorganizationtype === "" || strorganizationtype === "- Select -") {
            setUpdateMessage(<span style={{ color: "red" }}>Please select organization type</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
            window.scrollTo({ top: 0, behavior: 'smooth' });
        } else if (strorganizationname === "") {
            setUpdateMessage(<span style={{ color: "red" }}>Please enter organization name</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
            window.scrollTo({ top: 0, behavior: 'smooth' });
        } else if (straddressline1 === "") {
            setUpdateMessage(<span style={{ color: "red" }}>Please enter Address Line 1</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
            window.scrollTo({ top: 0, behavior: 'smooth' });
        } else if (straddressline2 === "") {
            setUpdateMessage(<span style={{ color: "red" }}>Please enter Address Line 2</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
            window.scrollTo({ top: 0, behavior: 'smooth' });
        } else if (strarea === "") {
            setUpdateMessage(<span style={{ color: "red" }}>Please enter area</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
            window.scrollTo({ top: 0, behavior: 'smooth' });
        } else if (strpincode === "") {
            setUpdateMessage(<span style={{ color: "red" }}>Please enter pincode</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
            window.scrollTo({ top: 0, behavior: 'smooth' });
        } else if (intcityid === "" || intcityid === null || intcityid === "- Select -") {
            setUpdateMessage(<span style={{ color: "red" }}>Please select city</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
            window.scrollTo({ top: 0, behavior: 'smooth' });
        } else if (intstateid === "" || intstateid === null || intstateid === "- Select -") {
            setUpdateMessage(<span style={{ color: "red" }}>Please select state</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
            window.scrollTo({ top: 0, behavior: 'smooth' });
        } else {

            if (files !== 0) {
                for (let i = 0; i < files.length; i++) {
                    if (files[i].size > 20000) {
                        setUpdateMessage(<span style={{ color: "red" }}>File size exceeded!! Please select filesize less than 20KB.</span>);
                        setTimeout(() => setUpdateMessage(""), 3000);
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                        return;
                    }
                    formData.append('files', files[i])
                }
            }

            if (certifiles !== 0) {
                for (let i = 0; i < certifiles.length; i++) {
                    // if (certifiles[i].size > 100000) {
                    //     setUpdateMessage(<span style={{ color: "red" }}>Certificate File size exceeded!! Please select filesize less than 20KB.</span>);
                    //     setTimeout(() => setUpdateMessage(""), 3000);
                    //     window.scrollTo({ top: 0, behavior: 'smooth' });
                    //     return;
                    // }
                    formData.append('certifiles', certifiles[i])
                }
            }

            if (letterpadfiles !== 0) {
                for (let i = 0; i < letterpadfiles.length; i++) {
                    // if (letterpadfiles[i].size > 100000) {
                    //     setUpdateMessage(<span style={{ color: "red" }}>LetterPad File size exceeded!! Please select filesize less than 20KB.</span>);
                    //     setTimeout(() => setUpdateMessage(""), 3000);
                    //     window.scrollTo({ top: 0, behavior: 'smooth' });
                    //     return;
                    // }
                    formData.append('letterpadfiles', letterpadfiles[i])
                }
            }

            // console.log(" " + intloginid + " " + strorganizationtype + " " + strorganizationname + " " + straddressline1 + " " + straddressline2 + " "
            //     + strarea + " " + strpincode + " " + intcityid + " " + intstateid + " " + strstatus + " " + files);

            formData.append('intloginid', intloginid);
            formData.append('strorganizationtype', strorganizationtype);
            formData.append('strorganizationname', strorganizationname);
            if (strorganizationregno) { formData.append('strorganizationregno', strorganizationregno) }
            if (strwebsite) { formData.append('strwebsite', strwebsite); }
            if (strphoneno1) { formData.append('strphoneno1', strphoneno1); }
            if (strphoneno2) { formData.append('strphoneno2', strphoneno2); }
            formData.append('straddressline1', straddressline1);
            formData.append('straddressline2', straddressline2);
            formData.append('strarea', strarea);
            formData.append('strpincode', strpincode);
            formData.append('intcityid', intcityid);
            formData.append('strstatus', strstatus);
            formData.append('decorglatitude', decorglatitude);
            formData.append('decorglongitude', decorglongitude);

            updateOrganizationDetails(formData, intorganizationid);
            //console.log("Pharmacy Admin" + pharmacyAdmin);
        }
    }

    //UPDATE ORGANIZATION DETAILS
    async function updateOrganizationDetails(formData, intorganizationid) {
        const requestOptions = {
            method: 'PUT',
            body: formData
        };
        fetch("https://e-arogya.com:8443/api/general/organization/update/" + intorganizationid, requestOptions)
            .then(res => {
                if (res.ok) {
                    setUpdateMessage(<span style={{ color: "green" }}>Organization Updated Successfully</span>);
                    setTimeout(() => setUpdateMessage(""), 3000);
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    setUploadLogoFile(null);
                    setUploadCertiFile(null);
                    setUploadLetterpadFile(null);
                    fetchOrganizationData(intorganizationid);
                } else {
                    setUpdateMessage(<span style={{ color: "red" }}>Organization Not Updated</span>);
                    setTimeout(() => setUpdateMessage(""), 3000);
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                }
            })
            .catch(error => {
                setUpdateMessage(<span style={{ color: "red" }}>Organization Not Updated. Please try again.</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
                window.scrollTo({ top: 0, behavior: 'smooth' });
            });
    }

    return (

        <div className="main" >

            {hospitalAdmin ?
                <div><HospitalSidebar /></div>
                :
                <div>
                    {laboratoryAdmin ?
                        <div><LaboratorySidebar /></div>
                        :
                        <div><PharmacySidebar /></div>
                    }
                </div>
            }

            <div className="page-content">

                <Adminheader />

                <h5>Edit Profile</h5>


                <div className="table-content">

                    <Form onSubmit={onAddNewOrgTimingsFormSubmit}>

                        <div style={{ fontSize: 12, fontWeight: "bold", marginBottom: 10 }}>{updateMessage}</div>

                        <Row style={{ fontSize: 12, color: "grey" }}>

                            <Col sm={2}>
                                <Form.Group className="mb-3" controlId="formSelectStateStatus">
                                    <Form.Label><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Organization Type</Form.Label>
                                    <Form.Select aria-label="Default select example" style={{ fontSize: 12 }} value={strorganizationtype || ""} onChange={e => { setStrorganizationtype(e.target.value) }} required >
                                        <option value="- Select -">- Select -</option>
                                        <option value="Hospital">Hospital</option>
                                        <option value="Laboratory">Laboratory</option>
                                        <option value="Pharmacy">Pharmacy</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>

                            <Col sm={4}>
                                <Form.Group className="mb-3" controlId="formEnterState">
                                    <Form.Label><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Name</Form.Label>
                                    <Form.Control type="text" style={{ fontSize: 12 }} value={strorganizationname || ""} onChange={e => { setStrorganizationname(e.target.value) }} required />
                                </Form.Group>
                            </Col>

                            <Col sm={2}>
                                <Form.Group className="mb-3" controlId="formEnterState">
                                    <Form.Label>Registration Number</Form.Label>
                                    <Form.Control type="text" style={{ fontSize: 12 }} value={strorganizationregno || ""} onChange={e => { setStrorganizationregno(e.target.value) }} />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row style={{ fontSize: 12, color: "grey" }}>

                            <Col sm={4}>
                                <Form.Group className="mb-3" controlId="formEnterState">
                                    <Form.Label>Website</Form.Label>
                                    <Form.Control type="" style={{ fontSize: 12 }} value={strwebsite || ""} onChange={e => { setStrwebsite(e.target.value) }} />
                                </Form.Group>
                            </Col>

                            <Col sm={2}>
                                <Form.Group className="mb-3" controlId="formEnterState">
                                    <Form.Label>Phone 1</Form.Label>
                                    <Form.Control type="text" style={{ fontSize: 12 }} value={strphoneno1 || ""} onChange={e => { setStrphoneno1(e.target.value) }} />
                                </Form.Group>
                            </Col>

                            <Col sm={2}>
                                <Form.Group className="mb-3" controlId="formEnterState">
                                    <Form.Label>Phone 2</Form.Label>
                                    <Form.Control type="text" style={{ fontSize: 12 }} value={strphoneno2 || ""} onChange={e => { setStrphoneno2(e.target.value) }} />
                                </Form.Group>
                            </Col>

                        </Row>

                        <Row style={{ fontSize: 12, color: "grey" }}>

                            <Col sm={4}>
                                <Form.Group className="mb-3" controlId="formEnterState">
                                    <Form.Label><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Address Line 1</Form.Label>
                                    <Form.Control type="text" style={{ fontSize: 12 }} value={straddressline1 || ""} onChange={e => { setStraddressline1(e.target.value) }} required />
                                </Form.Group>
                            </Col>

                            <Col sm={4}>
                                <Form.Group className="mb-3" controlId="formEnterState">
                                    <Form.Label><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Address Line 2</Form.Label>
                                    <Form.Control type="text" style={{ fontSize: 12 }} value={straddressline2 || ""} onChange={e => { setStraddressline2(e.target.value) }} required />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row style={{ fontSize: 12, color: "grey" }}>

                            <Col sm={2}>
                                <Form.Group className="mb-3" controlId="formEnterState">
                                    <Form.Label><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Area</Form.Label>
                                    <Form.Control type="text" style={{ fontSize: 12 }} value={strarea || ""} onChange={e => { setStrarea(e.target.value) }} required />
                                </Form.Group>
                            </Col>

                            <Col sm={2}>
                                <Form.Group className="mb-3" controlId="formEnterState">
                                    <Form.Label><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Pincode</Form.Label>
                                    <Form.Control type="text" style={{ fontSize: 12 }} value={strpincode || ""} onChange={e => { setStrpincode(e.target.value) }} required />
                                </Form.Group>
                            </Col>

                            <Col sm={2}>
                                <Form.Group className="mb-3" controlId="formSelectStateStatus">
                                    <Form.Label><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />State</Form.Label>
                                    <Form.Select
                                        aria-label="Default select example"
                                        style={{ fontSize: 12 }}
                                        value={intstateid || ""}
                                        onChange={e => {
                                            setIntstateid(e.target.value);
                                            //console.log(e.target.value);
                                            //fetchCitiesById(e.target.value) 
                                            let cityList = [];
                                            for (let i = 0; i < cities.length; i++) {
                                                if (cities[i].intstateid === parseInt(e.target.value)) { //parseInt because the value received (e.target.value) is in string
                                                    //intstateid is equal
                                                    cityList.push(cities[i]);
                                                }
                                            }

                                            //console.log("City List: " + JSON.stringify(cityList));
                                            setFilteredCities(cityList);

                                        }}
                                        required
                                    >
                                        <option value="- Select -">- Select -</option>
                                        {states.map((data, key) => <option key={data.intstateid} value={data.intstateid} >{data.strstatename}</option>)}
                                    </Form.Select>
                                </Form.Group>
                            </Col>

                            <Col sm={2}>
                                <Form.Group className="mb-3" controlId="formSelectStateStatus">
                                    <Form.Label><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />City</Form.Label>
                                    <Form.Select
                                        aria-label="Default select example"
                                        style={{ fontSize: 12 }}
                                        value={intcityid || ""}
                                        onChange={e => {
                                            setIntcityid(e.target.value);
                                            //console.log(e.target.value);
                                            getAddress(e.target.value);
                                        }}
                                        required
                                    >
                                        <option value="- Select -">- Select -</option>
                                        {filteredCities.map((data1, key) => <option key={data1.intcityid} value={data1.intcityid}>{data1.strcityname}</option>)}
                                    </Form.Select>
                                </Form.Group>
                            </Col>

                        </Row>

                        <Row style={{ fontSize: 12, color: "grey", alignItems: "self-end" }}>

                            <Col sm={2}>
                                <Form.Group className="mb-3" controlId="formSelectStateStatus">
                                    <Form.Label>Status</Form.Label>
                                    <Form.Select aria-label="Default select example" style={{ fontSize: 12 }} value={strstatus || ""} onChange={e => { setStatus(e.target.value) }} >
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>

                            <Col sm={4}>
                                <Form.Group className="mb-3" controlId="formEnterState">
                                    <Form.Label>Logo</Form.Label>
                                    <Form.Control
                                        ref={aRef}
                                        type="file"
                                        style={{ fontSize: 12 }}
                                        onChange={e => {
                                            setFiles(e.target.files);
                                            const reader = new FileReader();
                                            reader.addEventListener("load", () => {
                                                setImageFile(reader.result);
                                                setUploadLogoFile(reader.result);
                                            });
                                            reader.readAsDataURL(e.target.files[0]);
                                        }}
                                        accept="image/png, image/jpeg"
                                    />
                                </Form.Group>
                            </Col>

                            <Col sm={2}>
                                <Form.Group className="mb-3" controlId="formSelectStateStatus">
                                    {uploadlogofile ?
                                        <Image src={uploadlogofile} width={60} height={60} fluid />
                                        :
                                        <Image src={`data:image/jpeg;base64,${(imagefile)}`} width={60} height={60} fluid />
                                    }
                                </Form.Group>
                            </Col>

                        </Row>

                        <Row style={{ fontSize: 12, color: "grey", alignItems: "self-end" }}>
                            <Col sm={4}>
                                <Form.Group className="mb-3" controlId="formEnterState">
                                    <Form.Label>Registration Certificate</Form.Label>
                                    <Form.Control
                                        ref={aCertiRef}
                                        type="file"
                                        style={{ fontSize: 12 }}
                                        onChange={e => {
                                            setCertiFiles(e.target.files);
                                            const reader = new FileReader();
                                            reader.addEventListener("load", () => {
                                                setCertiImageFile(reader.result);
                                                setUploadCertiFile(reader.result);
                                            });
                                            reader.readAsDataURL(e.target.files[0]);
                                        }}
                                        accept="image/png, image/jpeg"
                                    />
                                </Form.Group>
                            </Col>

                            <Col sm={2}>
                                <Form.Group className="mb-3" controlId="formSelectStateStatus">
                                    {uploadcertifile ?
                                        <Image src={uploadcertifile} width={60} height={60} fluid />
                                        :
                                        <Image src={`data:image/jpeg;base64,${(certiimagefile)}`} width={60} height={60} fluid />
                                    }
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row style={{ fontSize: 12, color: "grey", alignItems: "self-end" }}>
                            <Col sm={4}>
                                <Form.Group className="mb-3" controlId="formEnterState">
                                    <Form.Label>Upload Letter Pad</Form.Label>
                                    <Form.Control
                                        ref={aLetterpadRef}
                                        type="file"
                                        style={{ fontSize: 12 }}
                                        onChange={e => {
                                            setLetterpadFiles(e.target.files);
                                            const reader = new FileReader();
                                            reader.addEventListener("load", () => {
                                                setLetterpadImageFile(reader.result);
                                                setUploadLetterpadFile(reader.result);
                                            });
                                            reader.readAsDataURL(e.target.files[0]);
                                        }}
                                        accept="image/png, image/jpeg"
                                    />
                                </Form.Group>
                            </Col>

                            <Col sm={2}>
                                <Form.Group className="mb-3" controlId="formSelectStateStatus">
                                    {uploadletterpadfile ?
                                        <Image src={uploadletterpadfile} width={60} height={60} fluid />
                                        :
                                        <Image src={`data:image/jpeg;base64,${(letterpadimagefile)}`} width={60} height={60} fluid />
                                    }
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row style={{ fontSize: 12, color: "grey", marginTop: 20 }}>
                            <Form.Label>Location</Form.Label>
                            <div style={{ fontSize: 12, color: "grey", marginTop: 20 }}>{locStatus}</div>
                            <LoadScript googleMapsApiKey='AIzaSyBzW45N5jxqiWtMZ6Ez_bmE0J70N6Z6XKA'>
                                <GoogleMap
                                    defaultCenter={{ lat: 22.3039, lng: 70.8022 }}
                                    mapContainerStyle={{ width: "80%", height: "450px", }}
                                    center={{ lat: decorglatitude, lng: decorglongitude }}
                                    zoom={13}
                                    onClick={(e) => {
                                        //console.log(e.latLng.lat(), e.latLng.lng())
                                    }}

                                >
                                    <Marker
                                        position={{ lat: decorglatitude, lng: decorglongitude }}
                                        label={{ color: "white", text: "H" }}
                                        draggable={true}

                                        onDragEnd={(e) => {
                                            //console.log("Marker Latitude: " + e.latLng.lat() + " Longitude: " + e.latLng.lng());
                                            setDecorglatitude(e.latLng.lat());
                                            setDecorglongitude(e.latLng.lng());
                                        }}

                                        onClick={e => { console.log(e) }}
                                    >
                                    </Marker>

                                </GoogleMap>

                            </LoadScript>

                        </Row>

                        <Row style={{ fontSize: 12, color: "grey" }}>

                            <Col sm={2} >
                                <Form.Group className="mb-3" controlId="formSelectStateStatus">
                                    <Button variant="primary" type="submit" style={{ marginTop: 25, fontSize: 12 }}> Update </Button>
                                </Form.Group>
                            </Col>

                        </Row>

                    </Form>

                </div>

            </div>
        </div>
    );
}

export default OrganizationEditProfile;