import React, { useEffect, useState, useRef, useCallback } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./admincss.css";
import HospitalSidebar from "../Navbar/HospitalSidebar";
import Adminheader from "../Navbar/Adminheader";
import { CDBCard, CDBCardBody, CDBDataTable } from 'cdbreact';
import { Col, Row, Button, Form, Modal, Container, Image } from "react-bootstrap";
import { Asterisk } from "react-bootstrap-icons";
import { useLocation, useNavigate } from "react-router-dom";
import { Base64 } from 'js-base64';
// import { API_BASE } from '../../setupProxy';
import Select from 'react-select';
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import Webcam1 from "react-webcam";
import * as moment from 'moment';

// const chiefComplaintsList = [
//     { label: 'Cold', value: 'Cold' },
//     { label: 'Cough', value: 'Cough' },
//     { label: 'Fever', value: 'Fever' },
//     { label: 'Headache', value: 'Headache' },
//     { label: 'Stomachache', value: 'Stomachache' },
//     { label: 'Fatigue', value: 'Fatigue' },
// ];

const HospitalPatientAppointments = () => {

    const [selectedDepartments, setSelectedDepartments] = useState([]);

    const symptoms = ["Fever", "Cold", "Cough", "Headache", "Toothache", "Abdominal pain", "Back pain", "Chest pain", "Fatigue", "Knee pain", "Urinary Tract Infection (UTI)"];
    const [value, setValue] = useState([]);
    const handleChange = (event) => {
        const values = event.target.value;
        const lastItem = values[values.length - 1];
        if (lastItem) {
            values.pop();
            const sameItem = values.find((value) => value === lastItem);
            if (sameItem === undefined) {
                values.push(lastItem);
            }
        }
        setValue(values);
    };

    const intOrganizationID = localStorage.getItem("orgid");
    const intLoginID = localStorage.getItem("loggedInId");
    const roleName = localStorage.getItem("loggedRoleName");

    const location = useLocation()
    const { patid, patmobile, patfname, patlname, patgender, patdob } = location.state ?? {}    // added ( ?? {} - to avoid errors when we directly click on patient appointments from sidebar)

    const navigate = useNavigate();

    const [checkButton, setCheckButton] = useState(false);
    const [patientsFound, setPatientsFound] = useState([]);
    const [addNewUser, setAddNewUser] = useState(false);

    const [appointments, setAppointments] = useState([]);
    const [specialities, setSpecialities] = useState([]);
    const [timeslot, setTimeslot] = useState([]);

    const [intAppointmentId, setIntAppointmentId] = useState(null);
    const [strFirstName, setStrFirstName] = useState("");
    const [strLastName, setStrLastName] = useState("");
    const [strGender, setStrGender] = useState("- Select -");
    const [strmobileno, setStrMobileNo] = useState("");
    const [strAppointmentTime, setStrAppointmentTime] = useState("");
    const [dtmAppointmentDate, setDtmAppointmentDate] = useState("");
    const [dtmBirthDate, setDtmBirthdate] = useState("");
    const [intAge, setIntAge] = useState(null);
    // const [intSpecialityID, setIntSpecialityID] = useState(null);
    const [strVisitType, setStrVisitType] = useState("");
    const [strstatus, setStatus] = useState('Active');

    const [isFirstNameDisabled, setIsFirstNameDisabled] = useState(false);
    const [isLastNameDisabled, setIsLastNameDisabled] = useState(false);
    const [isGenderDisabled, setIsGenderDisabled] = useState(false);
    const [isBirthDateDisabled, setIsBirthDateDisabled] = useState(false);

    const [updateMessage, setUpdateMessage] = useState("");
    const [checkMobile, setCheckMobile] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const [showPatientList, setShowPatientList] = useState(false);
    const handleClosePatientList = () => setShowPatientList(false);
    const handleShowPatientList = () => setShowPatientList(true);

    const [showNoPatientList, setShowNoPatientList] = useState(false);
    const handleCloseNoPatientList = () => setShowNoPatientList(false);
    const handleShowNoPatientList = () => setShowNoPatientList(true);

    const [showDelete, setShowDelete] = useState(false);
    const handleCloseDelete = () => setShowDelete(false);
    const [deleteThis, setDeleteThis] = useState(null);

    const [itemPatient, setItemPatient] = useState({ strPatient: "", another: "another" });
    const { strPatient } = itemPatient;
    const handleChangePatient = e => {
        e.persist();
        setItemPatient(prevState => ({ ...prevState, strPatient: e.target.value }));
    };

    const [itemCase, setItemCase] = useState({ strCase: "", another: "another" });
    const { strCase } = itemCase;
    const handleChangeCase = e => {
        e.persist();
        setItemCase(prevState => ({ ...prevState, strCase: e.target.value }));
    };

    useEffect(() => {

        if (patid) {
            setItemPatient(prevState => ({ ...prevState, strPatient: patid }));
            setCheckButton(true);
        } else
            setItemPatient(prevState => ({ ...prevState, strPatient: "" }));

        if (patmobile)
            setStrMobileNo(patmobile);
        else
            setStrMobileNo("");

        if (patfname)
            setStrFirstName(patfname);
        else
            setStrFirstName("");

        if (patlname)
            setStrLastName(patlname);
        else
            setStrLastName("");

        if (patgender)
            setStrGender(patgender);
        else
            setStrGender("");

        if (patdob) {
            setDtmBirthdate(patdob);
            var today = new Date();
            var birthDate = new Date(patdob);  // create a date object directly from `dob1` argument
            var age_now = today.getFullYear() - birthDate.getFullYear();
            var m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age_now--;
            }
            setIntAge(age_now + " years");
        }
        else
            setDtmBirthdate("");

        fetchAppointments(intOrganizationID);
        fetchSpecialityByOrganization(intOrganizationID);
        fetchTimeslotsByOrganization(intOrganizationID);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //FETCH ALL APPOINTMENTS
    const fetchAppointments = (orgid) => {
        fetch("https://e-arogya.com:8443/api/general/appointments/list/" + orgid)
            .then(response => response.json())
            .then(data => {
                let dataFromApi = data.map(app => {
                    return {
                        id: app.intAppointmentId,
                        firstname: app.strFirstName,
                        lastname: app.strLastName,
                        gender: app.strGender,
                        mobile: app.strmobileno,
                        apptime: app.strFromTime,
                        appdate: app.dtmAppointmentDate,
                        department: app.intSpecialityID,
                        visittype: app.strVisitType,
                        status: app.strstatus,
                        patid: app.intPatientID,
                        appcreateDate: app.dtmAppointmentDate,
                        appcaseid: app.intCaseID,
                    }
                })
                setAppointments(dataFromApi);
            })
            .catch(error => {
                //console.log(error);
            })
    }

    //FETCH ALL SPECIALITIES (FOR DROPDOWN)
    const fetchSpecialityByOrganization = (orgid) => {
        fetch("https://e-arogya.com:8443/api/general/organizationspecialities/list/" + orgid)
            .then(res => res.json())
            .then(spec => {
                setSpecialities(spec);
            })
            .catch(error => {
                //console.log(error);
            })
    }

    //FETCH ALL TIMESLOTS (FOR DROPDOWN)
    const fetchTimeslotsByOrganization = (orgid) => {
        fetch("https://e-arogya.com:8443/api/general/hospitaltimeslotforappointment/list/" + orgid)
            .then(res => res.json())
            .then(spec => {
                setTimeslot(spec);
            })
            .catch(error => {
                //console.log(error);
            })
    }

    function handleDeleteClick(e, id) {
        setShowDelete(true);
        setDeleteThis(id);
    }

    const deleteThisFunc = (id) => {
        fetch("https://e-arogya.com:8443/api/general/appointments/" + id, { method: 'DELETE' })
            .then(async response => {
                const data = await response.text;

                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }

                setUpdateMessage(<span style={{ color: "green" }}>Appointment Deleted Successfully</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
                setDeleteThis(null);
                fetchAppointments(intOrganizationID);
                setCheckButton(false);
            })
            .catch(error => {
                setUpdateMessage(<span style={{ color: "red" }}>Appointment Not Deleted. Please try again.</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
                setCheckButton(false);
            });
    }


    //FETCH APPOINTMENT BY ID (FOR EDIT APPOINTMENT)
    const handleEditClick = (e, id) => {

        e.preventDefault();

        setCheckButton(true);

        fetch("https://e-arogya.com:8443/api/general/appointments/" + id)
            .then(res => res.json())
            .then(data => {
                setIntAppointmentId(data[0].intAppointmentId);
                setStrFirstName(data[0].strFirstName);
                setStrLastName(data[0].strLastName);
                setStrGender(data[0].strGender);
                setStrMobileNo(data[0].strmobileno);
                setDtmAppointmentDate(data[0].dtmAppointmentDate);
                if (data[0].dtmBirthDate) { setDtmBirthdate(data[0].dtmBirthDate); }
                if (data[0].intAge) { setIntAge(data[0].intAge + " years"); }
                setStrAppointmentTime(data[0].strAppointmentTime);
                //setIntSpecialityID(data[0].intSpecialityID);
                //setSelectedDepartments(data[0].intSpecialityID);
                setStrVisitType(data[0].strVisitType);
                setStatus(data[0].strstatus);
                //setValue(data[0].strChiefComplains);
                //if(data[0].strChiefComplains) { setValue(data[0].strChiefComplains);}
                setItemPatient(prevState => ({ ...prevState, strPatient: data[0].intPatientID }));
                setIsFirstNameDisabled(true);
                setIsLastNameDisabled(true);
                setIsGenderDisabled(true);
                setIsBirthDateDisabled(true);
                setEditImg(data[0].strPatientPhoto);

                //setStrChiefComplains
                let str1 = data[0].strChiefComplains;
                var myarray1 = str1.split(',');
                //console.log(myarray1);
                setValue(myarray1);

                //setSpecialities
                // let str = data[0].intSpecialityID;
                // var myarray = str.split(',');
                // console.log(myarray);
                // var arr = [...specialities];
                // var newarr = [];
                // for (var i = 0; i < arr.length; i++) {
                //     if (myarray[i] == arr[i].intSpecialityID) {
                //         newarr.push({ value: arr[i].intSpecialityID, label: arr[i].strSpecialityName })
                //     }
                // }

                // console.log("newarr: " + JSON.stringify(newarr));

            })
            .catch(error => {
                //console.log(error);
            });
    }


    //FORM SUBMIT
    const onAddNewAppointmentFormSubmit = (event) => {
        event.preventDefault();

        const intPatientID = strPatient;

        // console.log("" + selectedDepartments + " " + value + " " + intOrganizationID + " " + intLoginID + " " + strmobileno + " " + intPatientID + " " + strAppointmentTime +
        //     " " + dtmAppointmentDate + " " + dtmBirthDate + " " + strVisitType + " " + strstatus);

        // if (img) {
        //     console.log("Image: " + img);
        // }

        // console.log("FormValue: " + JSON.stringify(selectedDepartments));
        // console.log("Form only Value: " + (Array.isArray(selectedDepartments) ? selectedDepartments.map(x => x.value) : []))


        if (checkButton) {

            if (strFirstName === "") {
                setUpdateMessage(<span style={{ color: "red" }}>Please Enter First Name</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
            } else if (strLastName === "") {
                setUpdateMessage(<span style={{ color: "red" }}>Please Enter Last Name</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
            } else if (strGender === "- Select -" || strGender === "") {
                setUpdateMessage(<span style={{ color: "red" }}>Please select gender</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
            } else if (strmobileno === "" || strmobileno.length !== 10 || isNaN(strmobileno)) {
                setUpdateMessage(<span style={{ color: "red" }}>Please enter a valid 10 digit mobile number</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
            } else if (strVisitType === "" || strVisitType === "- Select -" || strVisitType === null) {
                setUpdateMessage(<span style={{ color: "red" }}>Please select Appointment Type</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
            } else if (dtmAppointmentDate === "") {
                setUpdateMessage(<span style={{ color: "red" }}>Please Select Appointment Date</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
            } else if (strAppointmentTime === "") {
                setUpdateMessage(<span style={{ color: "red" }}>Please Select Appointment Time</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
            }

            else {

                if (intAppointmentId) {

                    const formData = new FormData();
                    formData.append('intAppointmentId', intAppointmentId);
                    formData.append('intOrganizationID', intOrganizationID);
                    formData.append('intLoginID', intLoginID);
                    formData.append('intPatientID', intPatientID);
                    formData.append('strVisitType', strVisitType);
                    formData.append('dtmAppointmentDate', dtmAppointmentDate);
                    formData.append('strAppointmentTime', strAppointmentTime);
                    formData.append('strstatus', strstatus);
                    if (value && value.length) { formData.append('strChiefComplains', value); }
                    if (selectedDepartments && selectedDepartments.length) { formData.append('intSpecialityID', selectedDepartments) }
                    updateAppointment(formData);
                    setIntAppointmentId(null);
                    clearFields();
                } else {
                    if (addNewUser) {
                        const strencryptedpassword = Base64.encode(strmobileno);
                        const formData = new FormData();
                        formData.append('intOrganizationID', intOrganizationID);
                        formData.append('intLoginID', intLoginID);
                        formData.append('strFirstName', strFirstName);
                        formData.append('strLastName', strLastName);
                        formData.append('strGender', strGender);
                        formData.append('strmobileno', strmobileno);
                        formData.append('strencryptedpassword', strencryptedpassword);
                        formData.append('strVisitType', strVisitType);
                        formData.append('dtmAppointmentDate', dtmAppointmentDate);
                        formData.append('strAppointmentTime', strAppointmentTime);
                        formData.append('strstatus', strstatus);
                        if (value && value.length) { formData.append('strChiefComplains', value); }
                        if (selectedDepartments && selectedDepartments.length) { formData.append('intSpecialityID', selectedDepartments) }
                        if (dtmBirthDate !== "") { formData.append('dtmBirthDate', dtmBirthDate); }
                        if (img != null) { formData.append('strPatientPhoto', img) }
                        addNewUserPatientCreateAppointment(formData);
                        clearFields();
                        setAddNewUser(false);

                    } else {
                        setAddNewUser(false);
                        if (intPatientID === "createNewPatient") {
                            const formData = new FormData();
                            formData.append('intOrganizationID', intOrganizationID);
                            formData.append('intLoginID', intLoginID);
                            formData.append('strFirstName', strFirstName);
                            formData.append('strLastName', strLastName);
                            formData.append('strGender', strGender);
                            formData.append('strmobileno', strmobileno);
                            formData.append('strVisitType', strVisitType);
                            formData.append('dtmAppointmentDate', dtmAppointmentDate);
                            formData.append('strAppointmentTime', strAppointmentTime);
                            formData.append('strstatus', strstatus);
                            if (value && value.length) { formData.append('strChiefComplains', value); }
                            if (selectedDepartments && selectedDepartments.length) { formData.append('intSpecialityID', selectedDepartments) }
                            if (dtmBirthDate !== "") { formData.append('dtmBirthDate', dtmBirthDate); }
                            if (img != null) { formData.append('strPatientPhoto', img) }
                            addNewPatientCreateAppointment(formData);
                            clearFields();
                        } else {

                            const formData = new FormData();
                            formData.append('intOrganizationID', intOrganizationID);
                            formData.append('intLoginID', intLoginID);
                            formData.append('intPatientID', intPatientID);
                            formData.append('strmobileno', strmobileno);
                            formData.append('strVisitType', strVisitType);
                            formData.append('dtmAppointmentDate', dtmAppointmentDate);
                            formData.append('strAppointmentTime', strAppointmentTime);
                            formData.append('strstatus', strstatus);
                            if (value && value.length) { formData.append('strChiefComplains', value); }
                            if (selectedDepartments !== null) { formData.append('intSpecialityID', selectedDepartments) }
                            if (dtmBirthDate !== "") { formData.append('dtmBirthDate', dtmBirthDate); }
                            if (img != null) { formData.append('strPatientPhoto', img) }
                            addNewAppointment(formData);
                            clearFields();
                        }
                    }
                }
            }
        }
    }
    //UPDATE EXISTING ORGANIZATION APPOINTMENT
    function updateAppointment(formData) {
        const requestOptions = {
            method: 'PUT',
            body: formData
        };

        fetch("https://e-arogya.com:8443/api/general/appointments/updatenew", requestOptions)
            .then(res => {

                if (res.ok) {
                    setUpdateMessage(<span style={{ color: "green" }}>Appointment Updated  Successfully</span>);
                    setTimeout(() => setUpdateMessage(""), 3000);
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    fetchAppointments(intOrganizationID);
                    fetchTimeslotsByOrganization(intOrganizationID);
                    setCheckButton(false);
                    setIsFirstNameDisabled(false);
                    setIsLastNameDisabled(false);
                    setIsGenderDisabled(false);
                    setIsBirthDateDisabled(false);
                } else {
                    setUpdateMessage(<span style={{ color: "red" }}>Appointment not updated</span>);
                    setTimeout(() => setUpdateMessage(""), 3000);
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                }
            })
            .catch(error => {
                setUpdateMessage(<span style={{ color: "red" }}>Appointment not updated. Please try again.</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
                window.scrollTo({ top: 0, behavior: 'smooth' });
                setCheckButton(false);
                setIsFirstNameDisabled(false);
                setIsLastNameDisabled(false);
                setIsGenderDisabled(false);
                setIsBirthDateDisabled(false);
            });
    }
    //ADD NEW ORGANIZATION APPOINTMENT
    function addNewAppointment(formData) {

        const requestOptions = {
            method: 'POST',
            body: formData
        };

        fetch("https://e-arogya.com:8443/api/general/appointments/addnewappointmentnew", requestOptions)
            .then(res => {

                if (res.ok) {
                    setUpdateMessage(<span style={{ color: "green" }}>Appointment Added Successfully</span>);
                    setTimeout(() => setUpdateMessage(""), 3000);
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    fetchAppointments(intOrganizationID);
                    fetchTimeslotsByOrganization(intOrganizationID);
                    setCheckButton(false);

                } else {
                    setUpdateMessage(<span style={{ color: "red" }}>Appointment Not Added</span>);
                    setTimeout(() => setUpdateMessage(""), 3000);
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                }
            })
            .catch(error => {
                setUpdateMessage(<span style={{ color: "red" }}>Appointment Not Added. Please try again.</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
                window.scrollTo({ top: 0, behavior: 'smooth' });
                setCheckButton(false);
            });

    }
    function addNewPatientCreateAppointment(formData) {

        const requestOptions = {
            method: 'POST',
            body: formData
        };

        fetch("https://e-arogya.com:8443/api/general/appointments/addnewpatientcreateappointmentnew", requestOptions)
            .then(res => {

                if (res.ok) {
                    setUpdateMessage(<span style={{ color: "green" }}>Appointment Added Successfully</span>);
                    setTimeout(() => setUpdateMessage(""), 3000);
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    fetchAppointments(intOrganizationID);
                    fetchTimeslotsByOrganization(intOrganizationID);
                    setCheckButton(false);
                } else {
                    setUpdateMessage(<span style={{ color: "red" }}>Appointment Not Added</span>);
                    setTimeout(() => setUpdateMessage(""), 3000);
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                }
            })
            .catch(error => {
                setUpdateMessage(<span style={{ color: "red" }}>Appointment Not Added. Please try again.</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
                window.scrollTo({ top: 0, behavior: 'smooth' });
                setCheckButton(false);
            });

    }
    function addNewUserPatientCreateAppointment(formData) {

        const requestOptions = {
            method: 'POST',
            body: formData
        };

        fetch("https://e-arogya.com:8443/api/general/appointments/addnewuserpatientcreateappointmentnew", requestOptions)
            .then(res => {

                if (res.ok) {
                    setUpdateMessage(<span style={{ color: "green" }}>Appointment Added Successfully</span>);
                    setTimeout(() => setUpdateMessage(""), 3000);
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    fetchAppointments(intOrganizationID);
                    fetchTimeslotsByOrganization(intOrganizationID);
                    setCheckButton(false);

                } else {
                    setUpdateMessage(<span style={{ color: "red" }}>Appointment Not Added</span>);
                    setTimeout(() => setUpdateMessage(""), 3000);
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                }
            })
            .catch(error => {
                setUpdateMessage(<span style={{ color: "red" }}>Appointment Not Added. Please try again.</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
                window.scrollTo({ top: 0, behavior: 'smooth' });
                setCheckButton(false);
            });

    }
    const clearFields = () => {
        setStrFirstName("");
        setStrLastName("");
        setStrGender("- Select -");
        setDtmBirthdate("");
        setIntAge(null);
        setStrMobileNo("");
        setStrAppointmentTime("");
        setDtmAppointmentDate("");
        // setIntSpecialityID(null);
        setStrVisitType('- Select -')
        setStatus('Active');
        setImg(null);
        setEditImg(null);
    }
    const checkMobileNo = () => {

        if (strmobileno === "" || strmobileno.length !== 10 || isNaN(strmobileno)) {
            setCheckMobile(<span style={{ color: "red" }}>Please enter a valid 10 digit mobile number</span>);
            setTimeout(() => setCheckMobile(""), 3000);
        } else {
            fetch("https://e-arogya.com:8443/api/general/patients/checkmobile/" + strmobileno)
                .then(async response => {
                    const isJson = response.headers.get('content-type')?.includes('application/json');
                    const data = isJson && await response.json();
                    if (!response.ok) {
                        const error = (data && data.message) || response.status;
                        return Promise.reject(error);
                    }
                    if (data) {
                        setCheckMobile(<span style={{ color: "green" }}>Mobile number exist.</span>);
                        setTimeout(() => setCheckMobile(""), 3000);
                        handleShowPatientList();
                        setPatientsFound(data);
                    } else {
                        setCheckMobile(<span style={{ color: "red" }}>Mobile number does not exist.</span>);
                        setTimeout(() => setCheckMobile(""), 3000);
                        handleShowNoPatientList();
                        setCheckButton(false);
                    }
                })
                .catch(error => {
                    setCheckMobile(<span style={{ color: "red" }}>Error Occurred. Please try again.</span>);
                    setTimeout(() => setCheckMobile(""), 3000);
                    setCheckButton(false);
                });
        }
    }
    const handleDone = () => {
        setCheckButton(true);
        if (strPatient.match("createNewPatient")) {
            setStrFirstName("");
            setStrLastName("");
            setStrGender("- Select -");
            setDtmBirthdate("");
            setIntAge(null);
            setStrAppointmentTime("");
            setDtmAppointmentDate("");
            // setIntSpecialityID('null');
            setStrVisitType('- Select -')
            setStatus('Active');
        } else {
            getSelectedPatientInfo(strPatient);
        }
    }
    const addNewUserPatientAppointment = () => {
        setCheckButton(true);
        setAddNewUser(true);
        setStrFirstName("");
        setStrLastName("");
        setStrGender("- Select -");
        setDtmBirthdate("");
        setIntAge(null);
        setStrAppointmentTime("");
        setDtmAppointmentDate("");
        // setIntSpecialityID('null');
        setStrVisitType('- Select -')
        setStatus('Active');

    }
    //FETCH PATIENT BY ID ()
    const getSelectedPatientInfo = (id) => {
        fetch("https://e-arogya.com:8443/api/general/patients/" + id)
            .then(res => res.json())
            .then(data => {
                setStrFirstName(data[0].strFirstName);
                setStrLastName(data[0].strLastName);
                setStrGender(data[0].strGender);
                if (data[0].dtmBirthDate) { setDtmBirthdate(data[0].dtmBirthDate); };
                if (data[0].intAge) { setIntAge(data[0].intAge + " years"); };
                setStrMobileNo(data[0].strmobileno);
                setStrAppointmentTime("");
                setDtmAppointmentDate("");
                // setIntSpecialityID('null');
                setStrVisitType('- Select -')
                setStatus('Active');
                setEditImg(data[0].strPatientPhoto);
            })
            .catch(error => {
                //console.log(error);
            });
    }
    const [showGenerateCase, setShowGenerateCase] = useState(false);
    const handleCloseGenerateCase = () => setShowGenerateCase(false);
    const [genCaseAppId, setGenCaseAppId] = useState(null);
    const [genCasePatId, setGenCasePatId] = useState(null);
    const handleGenerateCaseClick = (e, appid, patid) => {
        e.preventDefault();
        setShowGenerateCase(true);
        setGenCaseAppId(appid);
        setGenCasePatId(patid);
    }

    const goToGenerateCase = (appid, patid) => {
        if (strAppointmentType) {
            //console.log("Selected: " + strAppointmentType);
            insertIntoCaseTable(appid, patid, strAppointmentType);
        } else {
            //console.log("Nothing: " + strAppointmentType);
            setUpdateMessage(<span style={{ color: "red" }}>Please Select Case Type</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
        }
        setItem(prevState => ({ ...prevState, strAppointmentType: "" }));    //clears all radio button selected
    }

    //Case Type - New Case - Follow up Case 
    const [item, setItem] = useState({ strAppointmentType: "", another: "another" });
    const { strAppointmentType } = item;
    const handleRadioChange = e => {
        e.persist();
        setItem(prevState => ({ ...prevState, strAppointmentType: e.target.value }));
    };

    const insertIntoCaseTable = (appid, patid, strAppointmentType) => {
        const char = 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890';
        const random = Array.from({ length: 6 }, () => char[Math.floor(Math.random() * char.length)]);
        const randomString = random.join("");
        const strCaseNo = "ORG" + intOrganizationID + "_PAT-" + randomString;

        const intAppointmentID = appid;
        const intPatientID = patid;
        const strstatus = 'Active';

        var dtmCaseRegisterDate = new Date().toISOString().slice(0, 10);

        //console.log(" " + intOrganizationID + " " + intLoginID + " " + intAppointmentID + " " + intPatientID + " " + dtmCaseRegisterDate + " " + strCaseNo + " " + strstatus);

        if (strAppointmentType === "New Case") {
            const toInput = { intOrganizationID, intLoginID, intAppointmentID, intPatientID, dtmCaseRegisterDate, strCaseNo, strstatus };
            addInsertNewCaseGetCaseNo(toInput, appid, patid, strAppointmentType);
        } else if (strAppointmentType === "Follow up Case") {
            getAllCases(intPatientID, appid, strAppointmentType);
        }
    };

    async function addInsertNewCaseGetCaseNo(toInput, appid, patid, strAppointmentType) {
        fetch("https://e-arogya.com:8443/api/general/patientcase/addinsertnewcasegetcaseno", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            redirect: "follow",
            body: JSON.stringify(toInput)
        })
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();

                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }
                if (data) {
                    navigate('/hospitalpatientgeneratecase', { state: { appid: appid, patid: patid, apptype: strAppointmentType, caseno: data.caseno, casestatus: data.strCaseStatus, casetype: data.strCaseType, genedit: "Generate" } });
                }

            })
            .catch(error => {
                console.error('There was an error in adding city record!', error);
            });

    }

    const [showCases, setShowCases] = useState(false);
    const handleCloseCases = () => setShowCases(false);
    const handleShowCases = () => setShowCases(true);
    const [casesFound, setCasesFound] = useState([]);
    const [appoId, setAppoId] = useState(null);
    const [appoType, setAppoType] = useState("");
    const getAllCases = (patid, appid, strAppointmentType) => {
        fetch("https://e-arogya.com:8443/api/general/patientcase/opencaselist/" + intOrganizationID + "/" + patid)
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }
                if (data) {
                    setItemCase(prevState => ({ ...prevState, strCase: "" }));    //clears all radio button selected
                    handleShowCases();
                    setCasesFound(data);
                    setAppoId(appid);
                    setAppoType(strAppointmentType);
                }
            })
            .catch(error => {
                //console.log("Error Occurred: " + error);
            });
    }

    const handleFollowUpCase = () => {
        // console.log("Checking Follow up case: "+ strCase);
        fetch("https://e-arogya.com:8443/api/general/patientcase/patientcasedatabyid/" + intOrganizationID + "/" + strCase)
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }
                if (data) {
                    navigate('/hospitalpatientgeneratecase', { state: { appid: appoId, patid: data[0].intPatientID, apptype: appoType, caseno: data[0].strCaseNo, casestatus: data[0].strCaseStatus, casetype: data[0].strCaseType, genedit: "Generate" } });
                    setAppoId(null);
                    setAppoType("");
                }
            })
            .catch(error => {
                //console.log("Error Occurred: " + error);
            });

    }

    const webcamRef = useRef(null);
    const [img, setImg] = useState(null);
    const [editImg, setEditImg] = useState(null);
    const [showCaptureImage, setShowCaptureImage] = useState(false);
    const handleShowCaptureImage = () => { setShowCaptureImage(true); setImg(null); setEditImg(null); }
    const handleCloseCaptureImage = () => { setShowCaptureImage(false); setImg(null); setEditImg(null); };
    const handleRetake = () => setImg(null);

    const capturePhoto = useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        setImg(imageSrc);
    }, [webcamRef]);

    const capturePhotoDone = () => {
        if (img !== null) {
            setShowCaptureImage(false);
        } else {
            setErrorMessage(<span style={{ color: "red" }}>Please Capture Image</span>);
            setTimeout(() => setErrorMessage(""), 3000);
        }
    }

    const data = () => {
        return {
            columns: [
                { label: 'Id', field: 'srno', },
                {
                    label: 'Name',
                    field: 'name',
                    attributes: {
                        'aria-controls': 'DataTable',
                        'aria-label': 'Name',
                    },
                },
                { label: 'Mobile', field: 'mobile', },
                { label: 'Gender', field: 'gender', },
                { label: 'Date', field: 'appdate', },
                { label: 'Time', field: 'apptime', },
                // { label: 'Department', field: 'department', },
                { label: 'Appointment Type', field: 'visittype', },
                { label: '', field: 'edit', },
                { label: '', field: 'delete', },
                { label: '', field: 'generateCase', },
                { label: '', field: 'getprescription', },
            ],
            rows: [...appointments.map((data1, index) => (
                {
                    srno: index + 1,
                    name: data1.firstname + " " + data1.lastname,
                    mobile: data1.mobile,
                    gender: data1.gender,
                    apptime: data1.apptime,
                    appdate: moment(data1.appdate).format('DD-MMM-YYYY'),
                    // department: data1.department,
                    visittype: data1.visittype,
                    edit: <span onClick={(e) => { handleEditClick(e, data1.id); }}> <Button variant="primary" type="submit" style={{ fontSize: 12 }} size="sm"> Edit </Button> </span>,
                    delete: <span onClick={(e) => handleDeleteClick(e, data1.id)}> <Button variant="danger" type="submit" style={{ fontSize: 12 }} size="sm"> Delete </Button> </span>,
                    generateCase: <div>
                        {roleName === "Medical" || roleName === "Hospital Admin" || roleName === 'Frontend' ?
                            <span onClick={(e) => { handleGenerateCaseClick(e, data1.id, data1.patid); }}>
                                <Button variant="primary" type="submit" style={{ fontSize: 12, backgroundColor: "steelblue", borderColor: "steelblue" }} size="sm"> Generate Case </Button>
                            </span>
                            :
                            <div></div>
                        }
                    </div>,
                    getprescription: <div>
                        {roleName === "Medical" || roleName === "Hospital Admin" || roleName === 'Frontend' ?
                            <div>
                                {data1.appcaseid !== 0 ?
                                    <span onClick={(e) => { navigate('/hospitalgenerateprescription', { state: { appid: data1.id, patid: data1.patid } }); }}>
                                        <Button variant="primary" type="submit" style={{ fontSize: 12, backgroundColor: "#00626D", borderColor: "#00626D" }} size="sm">Get Prescription</Button>
                                    </span>
                                    :
                                    <div></div>
                                }
                            </div>
                            :
                            <div></div>
                        }
                    </div>
                }
            ))

            ],
        };
    };

    return (

        <div className="main" >

            <HospitalSidebar />

            <div className="page-content">

                <Adminheader />

                <h5>Patient Appointments</h5>

                <div className="table-content">

                    <Form onSubmit={onAddNewAppointmentFormSubmit}>

                        <Modal show={showCases} onHide={handleCloseCases} size="xl">
                            <Modal.Header closeButton>
                                <Modal.Title style={{ fontSize: 18 }}>Select Patient Case</Modal.Title>
                            </Modal.Header>
                            <Modal.Body style={{ fontSize: 14 }}>
                                <table className="table" style={{ padding: 10 }} >
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Name</th>
                                            <th>Case No</th>
                                            <th>Case Type</th>
                                            <th>Case Status</th>
                                            <th>Case Create Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {casesFound.map((data, index) => (
                                            <tr key={data.intCaseID}>
                                                <td><Form.Check
                                                    type="radio"
                                                    id={data.intCaseID}
                                                    name="group3"
                                                    style={{ paddingLeft: 20 }}
                                                    value={data.intCaseID}
                                                    onChange={handleChangeCase}
                                                    checked={strCase === `${data.intCaseID}`}
                                                />
                                                </td>
                                                <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={data.strFirstName}>{data.strFirstName} {data.strLastName}</Form.Label></td>
                                                <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={data.strCaseNo}>{data.strCaseNo}</Form.Label></td>
                                                <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={data.strCaseType}>{data.strCaseType}</Form.Label></td>
                                                <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={data.strCaseStatus}>{data.strCaseStatus}</Form.Label></td>
                                                <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={data.dtmCaseRegisterDate}>{data.dtmCaseRegisterDate}</Form.Label></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleCloseCases} style={{ fontSize: 12 }}> Cancel </Button>
                                <Button variant="primary" onClick={() => { handleCloseCases(); handleFollowUpCase(); }} style={{ fontSize: 12 }}> Ok </Button>
                            </Modal.Footer>
                        </Modal>

                        {/* GENERATE CASE */}
                        <Modal show={showGenerateCase} onHide={handleCloseGenerateCase}>
                            <Modal.Header closeButton>
                                <Modal.Title style={{ fontSize: 18 }}>Select Case Type</Modal.Title>
                            </Modal.Header>
                            <Modal.Body style={{ fontSize: 14 }}>
                                <Form.Group className="mb-3" controlId="strAppointmentType">
                                    <Form.Check inline label="New Case" name="group1" type='radio' value="New Case" aria-label="radio 1" style={{ fontSize: 12 }} onChange={handleRadioChange} checked={strAppointmentType === "New Case"} />
                                    <Form.Check inline label="Follow up Case" name="group1" type='radio' value="Follow up Case" aria-label="radio 2" style={{ fontSize: 12 }} onChange={handleRadioChange} checked={strAppointmentType === "Follow up Case"} />
                                </Form.Group>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleCloseGenerateCase} style={{ fontSize: 12 }} size="sm" >Cancel</Button>
                                <Button variant="primary" style={{ fontSize: 12, backgroundColor: "steelblue", borderColor: "steelblue" }} size="sm" onClick={() => {
                                    handleCloseGenerateCase();
                                    goToGenerateCase(genCaseAppId, genCasePatId);
                                }}>Generate Case</Button>
                            </Modal.Footer>
                        </Modal>

                        {/* DELETE APPOINTMENT */}
                        <Modal show={showDelete} onHide={handleCloseDelete}>
                            <Modal.Header closeButton>
                                <Modal.Title style={{ fontSize: 18 }}>Delete Appointment</Modal.Title>
                            </Modal.Header>
                            <Modal.Body style={{ fontSize: 14 }}>Are you sure you want to delete this Appointment?</Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleCloseDelete} style={{ fontSize: 12 }}>No</Button>
                                <Button variant="primary" onClick={() => {
                                    handleCloseDelete();
                                    deleteThisFunc(deleteThis);
                                }} style={{ fontSize: 12 }}>Yes</Button>
                            </Modal.Footer>
                        </Modal>

                        <Modal show={showPatientList} onHide={handleClosePatientList}>
                            <Modal.Header closeButton>
                                <Modal.Title style={{ fontSize: 18 }}>Patient List</Modal.Title>
                            </Modal.Header>
                            <Modal.Body style={{ fontSize: 14 }}>
                                <table className="table" style={{ padding: 10 }} >
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Name</th>
                                            <th>Mobile</th>
                                            <th>Gender</th>
                                            <th>City</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {patientsFound.map((data) => (
                                            <tr key={data.intPatientID}>
                                                <td><Form.Check
                                                    type="radio"
                                                    id={data.intPatientID}
                                                    name="group2"
                                                    style={{ paddingLeft: 20 }}
                                                    value={data.intPatientID}
                                                    onChange={handleChangePatient}
                                                    checked={strPatient === `${data.intPatientID}`}
                                                />
                                                </td>
                                                <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={data.strFirstName}>{data.strFirstName} {data.strLastName}</Form.Label></td>
                                                <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={data.strmobileno}>{data.strmobileno}</Form.Label></td>
                                                <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={data.strGender}>{data.strGender}</Form.Label></td>
                                                <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={data.strcityname}>{data.strcityname}</Form.Label></td>
                                            </tr>
                                        ))}
                                        <tr>
                                            <td><Form.Check
                                                type="radio"
                                                name="group2"
                                                value="createNewPatient"
                                                style={{ paddingLeft: 20 }}
                                                onChange={handleChangePatient}
                                            />
                                            </td>
                                            <td><Form.Label style={{ fontSize: 12, color: "grey" }} >+ Create New Patient</Form.Label></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>

                                {/* <div style={{ textAlign: "center" }}><Button variant="outline-primary" onClick={() => {createNewPatient()}} style={{ fontSize: 12 }} size="sm">+ Create New Patient</Button></div> */}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClosePatientList} style={{ fontSize: 12 }}>Cancel</Button>
                                <Button variant="primary" onClick={() => { handleClosePatientList(); handleDone(); }} style={{ fontSize: 12 }}>Done</Button>
                            </Modal.Footer>
                        </Modal>

                        <Modal show={showNoPatientList} onHide={handleCloseNoPatientList}>
                            <Modal.Header closeButton>
                                <Modal.Title style={{ fontSize: 18 }}>No Patient List Found</Modal.Title>
                            </Modal.Header>
                            <Modal.Body style={{ fontSize: 14 }}>
                                <div style={{ textAlign: "center" }}>
                                    <Form.Group className="mb-3" controlId="formEnterState">
                                        <Form.Label variant="outline-primary" onClick={() => { }} style={{ fontSize: 12, marginRight: 10 }} size="sm">Are you sure you want to add a new user?</Form.Label>
                                    </Form.Group>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleCloseNoPatientList} style={{ fontSize: 12 }}>No</Button>
                                <Button variant="primary" onClick={() => { handleCloseNoPatientList(); addNewUserPatientAppointment(); }} style={{ fontSize: 12 }}>Yes</Button>
                            </Modal.Footer>
                        </Modal>

                        <Row style={{ fontSize: 12, color: "grey" }}>
                            <Col md={2}>
                                <Form.Group className="mb-3" controlId="formEnterState">
                                    <Form.Label><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Mobile</Form.Label>
                                    <Form.Control type="text" style={{ fontSize: 12 }} value={strmobileno || ""} onChange={e => { setStrMobileNo(e.target.value) }} required />
                                </Form.Group>
                            </Col>

                            <Col md={2}>
                                <Button variant="primary" style={{ marginTop: 25, fontSize: 12 }} onClick={checkMobileNo}> Check </Button>
                            </Col>

                        </Row>

                        <Modal show={showCaptureImage} onHide={handleCloseCaptureImage} >
                            <Modal.Header>
                                <Modal.Title style={{ fontSize: 18 }}>Capture Photo</Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="grid-example">
                                <Container>
                                    <Row className="justify-content-md-center">
                                        <Col xs={12} md={8}>
                                            <div style={{ justifyContent: "center", display: "flex" }} >
                                                {img ?
                                                    <Image src={img} alt="webcam" />
                                                    :
                                                    <Webcam1
                                                        audio={false}
                                                        mirrored={true}
                                                        height={300}
                                                        width={300}
                                                        ref={webcamRef}
                                                        screenshotFormat="image/jpeg"
                                                        videoConstraints={{ width: 300, height: 300, facingMode: "user" }}
                                                    />
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="justify-content-md-center">
                                        <div style={{ fontSize: 12, fontWeight: "bold", justifyContent: "center", display: "flex", marginTop: 20 }}>{errorMessage}</div>
                                    </Row>
                                    <Row className="justify-content-md-center">
                                        <Col xs={6} md={4}>
                                            <div style={{ justifyContent: "center", display: "flex", marginTop: 20 }} >
                                                <Button variant="primary" onClick={capturePhoto} style={{ fontSize: 12, width: 75 }}> Capture </Button>
                                            </div>
                                        </Col>
                                        <Col xs={6} md={4}>
                                            <div style={{ justifyContent: "center", display: "flex", marginTop: 20 }} >
                                                <Button variant="primary" onClick={handleRetake} style={{ fontSize: 12, width: 75 }}> Retake </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="primary" onClick={capturePhotoDone} style={{ fontSize: 12 }}> Done </Button>
                                <Button variant="secondary" onClick={handleCloseCaptureImage} style={{ fontSize: 12 }}> Close </Button>
                            </Modal.Footer>
                        </Modal>

                        <div style={{ fontSize: 12, fontWeight: "bold", marginBottom: 10 }}>{checkMobile}</div>

                        {checkButton ?
                            <div>
                                <Row style={{ fontSize: 12, color: "grey" }}>
                                    <Col md={9}>
                                        <div>
                                            <Row style={{ fontSize: 12, color: "grey" }}>

                                                <Col md={3}>
                                                    <Form.Group className="mb-3" controlId="formEnterState">
                                                        <Form.Label><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Patient First Name</Form.Label>
                                                        <Form.Control type="text" style={{ fontSize: 12 }} value={strFirstName || ""} onChange={e => { setStrFirstName(e.target.value) }} disabled={isFirstNameDisabled} required />
                                                    </Form.Group>
                                                </Col>

                                                <Col md={3}>
                                                    <Form.Group className="mb-3" controlId="formEnterState">
                                                        <Form.Label><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Patient Last Name</Form.Label>
                                                        <Form.Control type="text" style={{ fontSize: 12 }} value={strLastName || ""} onChange={e => { setStrLastName(e.target.value) }} disabled={isLastNameDisabled} required />
                                                    </Form.Group>
                                                </Col>

                                                <Col md={2}>
                                                    <Form.Group className="mb-3" controlId="formSelectStateStatus">
                                                        <Form.Label><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Gender</Form.Label>
                                                        <Form.Select aria-label="Default select example" style={{ fontSize: 12 }} value={strGender || ""} onChange={e => { setStrGender(e.target.value) }} disabled={isGenderDisabled} required>
                                                            <option value="- Select -">- Select -</option>
                                                            <option value="Male">Male</option>
                                                            <option value="Female">Female</option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>

                                                <Col md={2}>
                                                    <Form.Group className="mb-3" controlId="formEnterState">
                                                        <Form.Label>Date of Birth</Form.Label>
                                                        <Form.Control type="date" max={new Date().toISOString().split('T')[0]} style={{ fontSize: 12 }} value={dtmBirthDate || ""} onChange={e => { setDtmBirthdate(e.target.value) }} disabled={isBirthDateDisabled} />
                                                    </Form.Group>
                                                </Col>

                                                <Col md={2}>
                                                    <Form.Group className="mb-3" controlId="formEnterState">
                                                        <Form.Label>Age</Form.Label>
                                                        <Form.Control type="text" style={{ fontSize: 12 }} value={intAge || ""} onChange={e => { setIntAge(e.target.value) }} disabled />
                                                    </Form.Group>
                                                </Col>



                                                {/* <Col md={2}>
                                        <Form.Group className="mb-3" controlId="formSelectStateStatus">
                                            <Form.Label><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Department</Form.Label>
                                            <Form.Select aria-label="Default select example" style={{ fontSize: 12 }} value={intSpecialityID || ""} onChange={e => { setIntSpecialityID(e.target.value) }} >
                                                <option value="- Select -">- Select -</option>
                                                {specialities.map((data, key) => <option key={data.intSpecialityID} value={data.intSpecialityID} >{data.strSpecialityName}</option>)}
                                            </Form.Select>
                                        </Form.Group>
                                    </Col> */}

                                            </Row>

                                            <Row style={{ fontSize: 12, color: "grey" }}>
                                                <Col md={3}>
                                                    <Form.Group className="mb-3" controlId="formSelectStateStatus">
                                                        <Form.Label>Department</Form.Label>
                                                        <Select
                                                            options={specialities.map(data => ({ label: data.strSpecialityName, value: data.intSpecialityID }))}
                                                            placeholder="- Select -"
                                                            onChange={(e) => { setSelectedDepartments(Array.isArray(e) ? e.map(x => x.value) : []) }}
                                                            isSearchable={true}
                                                            isMulti
                                                            isClearable
                                                        />

                                                    </Form.Group>
                                                </Col>

                                                <Col md={3}>
                                                    <Form.Group className="mb-3" controlId="formSelectStateStatus">
                                                        <Form.Label>Chief Complains</Form.Label>
                                                        <MultiSelect
                                                            data={symptoms}
                                                            onChange={handleChange}
                                                            value={value}
                                                            allowCustom={true}
                                                            placeholder="- Select -"
                                                            style={{ fontSize: 12, fontStyle: "normal", minHeight: 38, borderWidth: 0.5, borderColor: "#d7d7d7" }}
                                                            size="small"

                                                        />
                                                    </Form.Group>
                                                </Col>

                                            </Row>

                                            <Row style={{ fontSize: 12, color: "grey" }}>

                                                <Col md={3}>
                                                    <Form.Group controlId="formSelectStateStatus">
                                                        <Form.Label><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Appointment Type</Form.Label>
                                                        <Form.Select aria-label="Default select example" style={{ fontSize: 12 }} value={strVisitType || ""} onChange={e => { setStrVisitType(e.target.value) }}>
                                                            <option value="- Select -">- Select -</option>
                                                            <option value="WalkIn">Walk-In</option>
                                                            <option value="Telephonic">Telephonic</option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>

                                                <Col md={3}>
                                                    <Form.Group controlId="formEnterState">
                                                        <Form.Label><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Appointment Date</Form.Label>
                                                        <Form.Control type="date" min={new Date().toISOString().split('T')[0]} style={{ fontSize: 12 }} value={dtmAppointmentDate || ""} onChange={e => { setDtmAppointmentDate(e.target.value) }} required />
                                                    </Form.Group>
                                                </Col>

                                                <Col md={3}>
                                                    <Form.Group controlId="formSelectStateStatus">
                                                        <Form.Label><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Select Available Timeslot</Form.Label>
                                                        <Form.Select aria-label="Default select example"
                                                            style={{ fontSize: 12 }}
                                                            value={strAppointmentTime || ""}
                                                            onChange={e => { setStrAppointmentTime(e.target.value); }}
                                                        >
                                                            <option value="- Select -">- Select -</option>
                                                            {timeslot.map((data, key) => <option key={data.intTimeSlotID} value={data.intTimeSlotID} >
                                                                {data.strDayName} - {data.strFromTime} - {data.strToTime} - ({data.intRemainingAppointments})
                                                            </option>)}
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>

                                            </Row>
                                        </div>
                                    </Col>

                                    <Col md={3}>

                                        {intAppointmentId ?
                                            <div></div>
                                            :
                                            <div>
                                                <Col md={1}>
                                                    <Form.Group className="mb-3" controlId="formEnterState">
                                                        <Row>
                                                            <Form.Label>Photo</Form.Label>
                                                        </Row>
                                                        <Button variant="primary" style={{ fontSize: 12 }} onClick={handleShowCaptureImage}> Capture </Button>
                                                    </Form.Group>
                                                </Col>
                                            </div>
                                        }

                                        <Col md={2}>
                                            <Form.Group controlId="formEnterState">
                                                {img ?
                                                    <div> <Image src={img} width={100} height={100} /></div>
                                                    :
                                                    <div></div>
                                                }
                                                {editImg ?
                                                    <div><Image src={atob(editImg)} width={100} height={100} /></div>
                                                    :
                                                    <div></div>
                                                }
                                            </Form.Group>
                                        </Col>
                                    </Col>

                                </Row>
                                <Row style={{ fontSize: 12, color: "grey" }}>
                                    <Col md={2} >
                                        <Form.Group controlId="formEnterState">
                                            {intAppointmentId ?
                                                <Button variant="primary" type="submit" style={{ marginTop: 25, fontSize: 12 }}> Update Appointment </Button>
                                                :
                                                <Button variant="primary" type="submit" style={{ marginTop: 25, fontSize: 12 }}> Create Appointment </Button>
                                            }
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                            :
                            <div></div>
                        }

                    </Form>

                    <div style={{ fontSize: 12, fontWeight: "bold", marginBottom: 10 }}>{updateMessage}</div>

                    <CDBCard style={{ padding: 20, fontSize: 12, color: "grey" }}>
                        <CDBCardBody>
                            <CDBDataTable
                                responsive
                                noRecordsFoundLabel="No Records Found"
                                noBottomColumn={true}
                                hover
                                entriesOptions={[10, 25, 50, 100, 200, 500]}
                                entries={10}
                                pagesAmount={4}
                                data={data()}
                            />
                        </CDBCardBody>
                    </CDBCard>

                </div>

            </div>
        </div>

    );
};

export default HospitalPatientAppointments;

// if (checkButton) {

//     if (strFirstName === "") {
//         setAddMessage(<span style={{ color: "red" }}>Please Enter First Name</span>);
//         setTimeout(() => setAddMessage(""), 3000);
//     } else if (strLastName === "") {
//         setAddMessage(<span style={{ color: "red" }}>Please Enter Last Name</span>);
//         setTimeout(() => setAddMessage(""), 3000);
//     } else if (strGender === "- Select -" || strGender === "") {
//         setAddMessage(<span style={{ color: "red" }}>Please select gender</span>);
//         setTimeout(() => setAddMessage(""), 3000);
//     } else if (strmobileno === "" || strmobileno.length !== 10 || isNaN(strmobileno)) {
//         setAddMessage(<span style={{ color: "red" }}>Please enter a valid 10 digit mobile number</span>);
//         setTimeout(() => setAddMessage(""), 3000);
//     }
//     // else if (intSpecialityID === "" || intSpecialityID === "- Select -" || intSpecialityID === null) {
//     //     alert("Please select Department");
//     // }
//     else if (strVisitType === "" || strVisitType === "- Select -" || strVisitType === null) {
//         setAddMessage(<span style={{ color: "red" }}>Please select Visit Type</span>);
//         setTimeout(() => setAddMessage(""), 3000);
//     } else if (dtmAppointmentDate === "") {
//         setAddMessage(<span style={{ color: "red" }}>Please Select Appointment Date</span>);
//         setTimeout(() => setAddMessage(""), 3000);
//     } else if (strAppointmentTime === "") {
//         setAddMessage(<span style={{ color: "red" }}>Please Select Appointment Time</span>);
//         setTimeout(() => setAddMessage(""), 3000);
//     }

//     else {
//         if (intAppointmentId) {
//             console.log("" + intAppointmentId + " " + intOrganizationID + " " + intLoginID + " " + intPatientID + " " + strmobileno + " " + strAppointmentTime + " " + dtmAppointmentDate + " " + intSpecialityID + " " + strVisitType + " " + strstatus);
//             const existingInputs = { intOrganizationID, intLoginID, intPatientID, strmobileno, strAppointmentTime, dtmAppointmentDate, intSpecialityID, strVisitType, strstatus }
//             updateAppointment(existingInputs, intAppointmentId);
//             setIntAppointmentId(null);
//             clearFields();
//         } else {
//             if (addNewUser) {
//                 const strencryptedpassword = Base64.encode(strmobileno);
//                 console.log("CreateAddUserPatientApp: " + intOrganizationID + " " + intLoginID + " " + strmobileno + " " + strAppointmentTime + " " + dtmAppointmentDate + " " + intSpecialityID + " " + strVisitType + " " + strstatus
//                     + " " + strFirstName + " " + strLastName + " " + strGender + " " + strencryptedpassword);
//                 const toInput = { intOrganizationID, intLoginID, strmobileno, strAppointmentTime, dtmAppointmentDate, intSpecialityID, strVisitType, strstatus, strFirstName, strLastName, strGender, strencryptedpassword };
//                 addNewUserPatientCreateAppointment(toInput);
//                 clearFields();
//                 setAddNewUser(false);

//             } else {
//                 setAddNewUser(false);
//                 console.log("inPatientId: " + intPatientID);
//                 if (intPatientID === "createNewPatient") {
//                     console.log("CreateAddPatientApp: " + intOrganizationID + " " + intLoginID + " " + strmobileno + " " + strAppointmentTime + " " + dtmAppointmentDate + " " + intSpecialityID + " " + strVisitType + " " + strstatus
//                         + " " + strFirstName + " " + strLastName + " " + strGender);
//                     const toInput = { intOrganizationID, intLoginID, strmobileno, strAppointmentTime, dtmAppointmentDate, intSpecialityID, strVisitType, strstatus, strFirstName, strLastName, strGender };
//                     addNewPatientCreateAppointment(toInput);
//                     clearFields();
//                 } else {
//                     console.log("AddNewApp " + intOrganizationID + " " + intLoginID + " " + strmobileno + " " + intPatientID + " " + strAppointmentTime + " " + dtmAppointmentDate + " " + intSpecialityID + " " + strVisitType + " " + strstatus);
//                     const toInput = { intOrganizationID, intLoginID, intPatientID, strmobileno, strAppointmentTime, dtmAppointmentDate, intSpecialityID, strVisitType, strstatus };
//                     addNewAppointment(toInput);
//                     clearFields();
//                 }
//             }

//         }
//     }
// }