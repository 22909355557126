import React, { useEffect, useState } from 'react'
import { Row, Col, Container, Table, Image, Modal, Button, Form } from "react-bootstrap";
import * as moment from 'moment'
import MyImage from "../images/cropped-transparant-logo.png";
import MyImage1 from "../images/img-1.png";
import { Asterisk } from "react-bootstrap-icons";
import { useSearchParams } from "react-router-dom"
import Navbar from "../Navbar/Navbar";
import Footer from "../Navbar/Footer";
// import { API_BASE } from '../../setupProxy';

const PatientSharableQrCode = () => {

  const [queryParameters] = useSearchParams();

  const [strPatientCode, setStrPatientCode] = useState("");
  const [updateMessage, setUpdateMessage] = useState("");
  const [queryParamPatientId, setQueryParamPatientId] = useState(null);
  const [queryParamPatientCode, setQueryParamPatientCode] = useState("");

  const [showPatientDetails, setShowPatientDetails] = useState(false);

  const [patientData, setPatientData] = useState([]);
  const [patientOtherData, setPatientOtherData] = useState([]);
  const [allPatientCases, setAllPatientCases] = useState([]);
  const [patientVaccineDetails, setPatientVaccineDetails] = useState([]);
  const [patientMedicationDetails, setPatientMedicationDetails] = useState([]);
  const [patientClinicalDetails, setPatientClinicalDetails] = useState([]);
  const [patientSurgeryDetails, setPatientSurgeryDetails] = useState([]);
  const [patientDocVaccineImagesDetails, setPatientDocVaccineImagesDetails] = useState([]);
  const [patientDocMedicationImagesDetails, setPatientDocMedicationImagesDetails] = useState([]);
  const [patientDocClinicalImagesDetails, setPatientDocClinicalImagesDetails] = useState([]);
  const [patientDocSurgeryImagesDetails, setPatientDocSurgeryImagesDetails] = useState([]);
  const [myImageAttachments, setMyImageAttachments] = useState([]);
  const [showMyImageAttachments, setShowMyImageAttachments] = useState(false);
  const handleCloseMyImageAttachments = () => setShowMyImageAttachments(false);

  useEffect(() => {

    if (queryParameters) {

      if (queryParameters.get("pid")) {
        setQueryParamPatientId(queryParameters.get("pid"));
        getPatientCode(queryParameters.get("pid"));
      }

    }


  }, [queryParameters]);

  const getPatientCode = async (id) => {
    try {
      const response = await fetch(`https://e-arogya.com:8443/api/general/patientapplication/` + id);
      const json = await response.json();
      console.log("Patient Detail Code: " + json[0].strPatientCode);
      if (json[0].strPatientCode) {
        setQueryParamPatientCode(json[0].strPatientCode);
      }
    } catch (error) {
      console.log("Error: " + error);
    }
  }

  //FORM SUBMIT
  const onAddNewStateFormSubmit = (event) => {
    event.preventDefault();

    if (strPatientCode) {
      console.log("strpatientCode: " + strPatientCode + " queryParamPatientCode: " + queryParamPatientCode)
      if (strPatientCode === queryParamPatientCode) {

        if (queryParamPatientId) {
          fetchPatientInfo(queryParamPatientId);
          fetchOtherDetailsOfPatient(queryParamPatientId);
          fetchOrganizationAllPatientCases(queryParamPatientId);
          getPatientVaccineDetails(queryParamPatientId);
          getPatientMedicationDetails(queryParamPatientId);
          getPatientClinicalDetails(queryParamPatientId);
          getPatientSurgeryDetails(queryParamPatientId);
          getPatientImagesDocs(queryParamPatientId);
          setShowPatientDetails(true);
        }


      } else {
        setUpdateMessage(<span style={{ color: "red" }}>Enter a Valid Patient Code</span>);
        setTimeout(() => setUpdateMessage(""), 3000);
        setStrPatientCode("");
        setShowPatientDetails(false);
      }
    } else {
      setUpdateMessage(<span style={{ color: "red" }}>Please Enter Patient Code</span>);
      setTimeout(() => setUpdateMessage(""), 3000);
      setShowPatientDetails(false);
    }

  }

  const fetchPatientInfo = async (id) => {
    await fetch("https://e-arogya.com:8443/api/general/patients/" + id)
      .then(res => res.json())
      .then(data => {
        setPatientData(data[0]);
      })
      .catch(error => {
        //console.log(error);
      });
  }

  const fetchOtherDetailsOfPatient = (id) => {
    fetch("https://e-arogya.com:8443/api/general/patientapplication/" + id)
      .then(res => res.json())
      .then(data => {
        setPatientOtherData(data[0]);
      })
      .catch(error => {
        //console.log(error);
      });
  }

  const fetchOrganizationAllPatientCases = async (id) => {
    let response = await fetch("https://e-arogya.com:8443/api/general/patientappointment/getpatientmedicalcasereportdetails/" + id)
    let data = await response.json();

    setAllPatientCases(data);
  }

  const getPatientVaccineDetails = (id) => {
    fetch("https://e-arogya.com:8443/api/general/patientapplication/patvacc/" + id)
      .then(res => res.json())
      .then(data => {
        setPatientVaccineDetails(data);
      })
      .catch(error => {
        //console.log(error);
      });
  };
  const getPatientMedicationDetails = (id) => {
    fetch("https://e-arogya.com:8443/api/general/patientapplication/patmedi/" + id)
      .then(res => res.json())
      .then(data => {
        setPatientMedicationDetails(data);
      })
      .catch(error => {
        //console.log(error);
      });
  };
  const getPatientClinicalDetails = (id) => {
    fetch("https://e-arogya.com:8443/api/general/patientapplication/patclinic/" + id)
      .then(res => res.json())
      .then(data => {
        setPatientClinicalDetails(data);
      })
      .catch(error => {
        //console.log(error);
      });
  };
  const getPatientSurgeryDetails = (id) => {
    fetch("https://e-arogya.com:8443/api/general/patientapplication/patsurgi/" + id)
      .then(res => res.json())
      .then(data => {
        setPatientSurgeryDetails(data);
      })
      .catch(error => {
        //console.log(error);
      });
  };

  const getPatientImagesDocs = async (id) => {
    try {
      const response = await fetch(`https://e-arogya.com:8443/api/general/patientapplication/patimgdocs/` + id);
      const json = await response.json();

      const vaccimagesonly = json.filter((val, i) => {
        if (val.strDocumentType === "Vaccine") {
          return val;
        }
        return null;
      })
      setPatientDocVaccineImagesDetails(vaccimagesonly);

      const mediimagesonly = json.filter((val, i) => {
        if (val.strDocumentType === "Medication") {
          return val;
        }
        return null;
      })
      setPatientDocMedicationImagesDetails(mediimagesonly);

      const cliniimagesonly = json.filter((val, i) => {
        if (val.strDocumentType === "Clinical") {
          return val;
        }
        return null;
      })
      setPatientDocClinicalImagesDetails(cliniimagesonly);

      const surgiimagesonly = json.filter((val, i) => {
        if (val.strDocumentType === "Surgery") {
          return val;
        }
        return null;
      })
      setPatientDocSurgeryImagesDetails(surgiimagesonly);

    } catch (error) {
      console.log("Error Images Docs: " + error);
    }
  };

  return (

    <div>

      <Navbar />
      {!showPatientDetails ?

        <Form onSubmit={onAddNewStateFormSubmit} >

          <Container fluid>

            <div style={{ marginTop: 100, marginBottom: 100 }} className='container'>

              <Row className="py-5">    

                <Col sm={7}>
                  <div>
                    <div style={{ background: "#fd5252", width: 70, height: 8, borderColor: "#fd5252", borderRadius: 10 }} />
                    <p style={{ fontSize: 35, fontWeight: 700 }}>Get Patient Data</p>

                  </div>
                  <Row style={{ color: "grey" }}>

                    <Col sm={4}>
                      <Form.Group className="mb-3" controlId="formEnterState">
                        <Form.Label><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Enter Patient Code</Form.Label>
                        <Form.Control type="text" value={strPatientCode || ""} onChange={e => { setStrPatientCode(e.target.value) }} required />
                      </Form.Group>
                    </Col>

                    <Col sm={3} >
                      <Form.Group className="mb-3 mt-2" controlId="formEnterState">
                        <Button variant="primary" type="submit" style={{ marginTop: 25 }}> Check </Button>
                      </Form.Group>
                    </Col>

                  </Row>
                  <Row>
                    <Col sm={4} >
                      <div style={{ fontSize: 12, fontWeight: "bold" }}>{updateMessage}</div>
                    </Col>
                  </Row>
                </Col>

                <Col sm={5}>
                  <div>
                    <Image src={MyImage1} alt="check" className="img-fluid" />
                  </div>
                </Col>

              </Row>

            </div>

          </Container>

        </Form>

        :

        <Container style={{ backgroundColor: "#F5F5F5" }}>
          <div style={{ marginTop: 20, marginBottom: 20 }} className='container'>

            <Modal show={showMyImageAttachments} onHide={handleCloseMyImageAttachments} fullscreen={true} >
              <Modal.Header closeButton>
                <Modal.Title style={{ fontSize: 18 }}>Files</Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ fontSize: 14, display: "flex", justifyContent: "center" }}>
                <div>
                  <Image src={`data:image/jpeg;base64,${myImageAttachments}`} />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={handleCloseMyImageAttachments} style={{ fontSize: 12 }}> Ok </Button>
              </Modal.Footer>
            </Modal>
            <Row style={{ marginBottom: 10 }}>
              <Col style={{ marginBottom: 10, marginTop: 10 }}>
                <Image src={MyImage} className="d-inline-block align-top" alt="check" height={100} />
              </Col>
            </Row>
            <Row>
              <Col style={{ backgroundColor: "#02647E", padding: 5, color: "white" }}>PATIENT DETAILS</Col>
            </Row>
            <Row>
              <Col><span style={{ color: "grey" }}>Name:</span> {patientData.strFirstName} {patientData.strLastName}</Col>
              <Col><span style={{ color: "grey" }}>Mobile:</span> {patientData.strmobileno}</Col>
              <Col><span style={{ color: "grey" }}>Age:</span> {patientData.intAge}</Col>
              <Col><span style={{ color: "grey" }}>BirthDate:</span> {patientData.dtmBirthDate}</Col>
            </Row>
            <Row>
              <Col><span style={{ color: "grey" }}>Gender:</span> {patientData.strGender}</Col>
              <Col><span style={{ color: "grey" }}>Blood Group:</span> {patientData.strBloodGroup}</Col>
              <Col><span style={{ color: "grey" }}>Address:</span> {patientData.straddress}</Col>
              <Col><span style={{ color: "grey" }}>City:</span> {patientData.strcityname}</Col>
            </Row>
            <Row>
              <Col style={{ backgroundColor: "#02647E", padding: 5, color: "white" }}>PATIENT OTHER DETAILS</Col>
            </Row>
            <Row>
              <Col><span style={{ color: "grey" }}>Marital Status:</span> {patientOtherData.strMaritalStatus}</Col>
              <Col><span style={{ color: "grey" }}>Religion:</span> {patientOtherData.strReligion}</Col>
              <Col><span style={{ color: "grey" }}>Occupation:</span> {patientOtherData.strOccupation}</Col>
              <Col><span style={{ color: "grey" }}>Allergies:</span> {patientOtherData.strAllergies}</Col>
            </Row>
            <Row>
              <Col><span style={{ color: "grey" }}>Habits:</span> {patientOtherData.strHabits}</Col>
            </Row>
            <Row>
              <Col><span style={{ color: "grey" }}>Diet:</span> {patientOtherData.strDiet}</Col>
            </Row>
            <Row>
              <Col><span style={{ color: "grey" }}>Lifestyle:</span> {patientOtherData.strLifestyle}</Col>
            </Row>
            <Row>
              <Col style={{ backgroundColor: "#02647E", padding: 5, color: "white" }}>INSURANCE DETAILS</Col>
            </Row>
            <Row>
              <Col><span style={{ color: "grey" }}>Insurance Company Name:</span> {patientOtherData.strInsuranceCompany}</Col>
            </Row>
            <Row>
              <Col><span style={{ color: "grey" }}>Insurance Number:</span> {patientOtherData.strInsuranceNumber}</Col>
            </Row>
            <Row>
              <Col><span style={{ color: "grey" }}>Insurance Expiry Date:</span> {patientOtherData.dtmInsuranceExpiry}</Col>
            </Row>
            <Row>
              <Col style={{ backgroundColor: "#02647E", padding: 5, color: "white", marginBottom: 15 }}>VACCINE DETAILS</Col>
            </Row>
            <Row style={{ alignItems: "center", alignContent: "center" }}>
              <Table striped bordered hover size="sm" responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Vaccine Name</th>
                    <th>No. of Dose</th>
                    <th>Dosage Date</th>
                  </tr>
                </thead>
                {patientVaccineDetails.length !== 0 ?
                  <tbody>
                    {
                      patientVaccineDetails.map((data, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{data.strVaccineName}</td>
                            <td>{data.intNoOfDose}</td>
                            <td>{data.dtmDosageDate !== null ? moment(data.dtmDosageDate).format('DD-MMM-YYYY') : null}</td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                  :
                  <tbody>
                    <tr>
                      <td colSpan={4} style={{ textAlign: "center" }}>No Vaccine Details Found</td>
                    </tr>
                  </tbody>
                }
              </Table>
            </Row>
            <Row style={{ alignItems: "center", alignContent: "center" }}>
              {patientDocVaccineImagesDetails.length !== 0 ?
                <div>
                  {patientDocVaccineImagesDetails.map((pat, index) => {
                    return (
                      <div key={"imgDoc" + index} >
                        <Row style={{ alignContent: "center", marginBottom: 5 }}>
                          <Col md={2} style={{ fontSize: 12, color: "grey", fontWeight: "bold" }}>
                            <Image
                              onClick={() => { setShowMyImageAttachments(true); setMyImageAttachments(pat.strPatientDocument); }}
                              src={`data:image/jpeg;base64,${(pat.strPatientDocument)}`}
                              height={75}
                              width={75}
                              style={{ padding: 2, cursor: "pointer" }}
                            />
                          </Col>
                          <Col md={10} style={{ fontSize: 12, color: "grey" }}>
                            {pat.strDocumentRemarks}
                          </Col>
                        </Row>
                      </div>
                    );
                  })
                  }
                </div>
                :
                null
              }
            </Row>

            <Row>
              <Col style={{ backgroundColor: "#02647E", padding: 5, color: "white", marginBottom: 15 }}>MEDICATION DETAILS</Col>
            </Row>
            <Row style={{ alignItems: "center", alignContent: "center" }}>
              <Table striped bordered hover size="sm" responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Disease Name</th>
                    <th>Medicine Name</th>
                    <th>Strength</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                  </tr>
                </thead>
                {patientMedicationDetails.length !== 0 ?
                  <tbody>
                    {
                      patientMedicationDetails.map((data, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{data.strDiseaseName}</td>
                            <td>{data.strMedicineName}</td>
                            <td>{data.strStrength}</td>
                            <td>{data.dtmMedicineStartDate !== null ? moment(data.dtmMedicineStartDate).format('DD-MMM-YYYY') : null}</td>
                            <td>{data.dtmMedicineEndDate !== null ? moment(data.dtmMedicineEndDate).format('DD-MMM-YYYY') : null}</td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                  :
                  <tbody>
                    <tr>
                      <td colSpan={6} style={{ textAlign: "center" }}>No Medication Details Found</td>
                    </tr>
                  </tbody>
                }

              </Table>
            </Row>
            <Row style={{ alignItems: "center", alignContent: "center" }}>
              {patientDocMedicationImagesDetails.length !== 0 ?
                <div>
                  {patientDocMedicationImagesDetails.map((pat, index) => {
                    return (
                      <div key={"imgDoc" + index} >
                        <Row style={{ alignContent: "center", marginBottom: 5 }}>
                          <Col md={2} style={{ fontSize: 12, color: "grey", fontWeight: "bold" }}>
                            <Image
                              onClick={() => { setShowMyImageAttachments(true); setMyImageAttachments(pat.strPatientDocument); }}
                              src={`data:image/jpeg;base64,${(pat.strPatientDocument)}`}
                              height={75}
                              width={75}
                              style={{ padding: 2, cursor: "pointer" }}
                            />
                          </Col>
                          <Col md={10} style={{ fontSize: 12, color: "grey" }}>
                            {pat.strDocumentRemarks}
                          </Col>
                        </Row>
                      </div>
                    );
                  })
                  }
                </div>
                :
                null
              }
            </Row>

            <Row>
              <Col style={{ backgroundColor: "#02647E", padding: 5, color: "white", marginBottom: 15 }}>CLINICAL MEDICATION DETAILS</Col>
            </Row>
            <Row style={{ alignItems: "center", alignContent: "center" }}>
              <Table striped bordered hover size="sm" responsive >
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Disease Name</th>
                    <th>Medicine Name</th>
                    <th>Strength</th>
                    <th>Start Date</th>
                    <th>Reminder Date</th>
                    <th>Notification Time</th>
                  </tr>
                </thead>
                {patientClinicalDetails.length !== 0 ?
                  <tbody>
                    {
                      patientClinicalDetails.map((data, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{data.strLifelongDiseaseName}</td>
                            <td>{data.strLifelongMedicineName}</td>
                            <td>{data.strLifelongStrength}</td>
                            <td>{data.dtmLifelongStartDate !== null ? moment(data.dtmLifelongStartDate).format('DD-MMM-YYYY') : null}</td>
                            <td>{data.dtmLifelongReminderDate !== null ? moment(data.dtmLifelongReminderDate).format('DD-MMM-YYYY') : null}</td>
                            <td>{data.strLifelongNotificationTime}</td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                  :
                  <tbody>
                    <tr>
                      <td colSpan={7} style={{ textAlign: "center" }}>No Clinical Details Found</td>
                    </tr>
                  </tbody>
                }

              </Table>
            </Row>
            <Row style={{ alignItems: "center", alignContent: "center" }}>
              {patientDocClinicalImagesDetails.length !== 0 ?
                <div>
                  {patientDocClinicalImagesDetails.map((pat, index) => {
                    return (
                      <div key={"imgDoc" + index} >
                        <Row style={{ alignContent: "center", marginBottom: 5 }}>
                          <Col md={2} style={{ fontSize: 12, color: "grey", fontWeight: "bold" }}>
                            <Image
                              onClick={() => { setShowMyImageAttachments(true); setMyImageAttachments(pat.strPatientDocument); }}
                              src={`data:image/jpeg;base64,${(pat.strPatientDocument)}`}
                              height={75}
                              width={75}
                              style={{ padding: 2, cursor: "pointer" }}
                            />
                          </Col>
                          <Col md={10} style={{ fontSize: 12, color: "grey" }}>
                            {pat.strDocumentRemarks}
                          </Col>
                        </Row>
                      </div>
                    );
                  })
                  }
                </div>
                :
                null
              }
            </Row>

            <Row>
              <Col style={{ backgroundColor: "#02647E", padding: 5, color: "white", marginBottom: 15 }}>SURGERY DETAILS</Col>
            </Row>
            <Row style={{ alignItems: "center", alignContent: "center" }}>
              <Table striped bordered hover size="sm" responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Surgery Name</th>
                    <th>Hospital Name</th>
                    <th>Doctor's Name</th>
                    <th>Type of Surgery</th>
                    <th>Surgery Date</th>
                  </tr>
                </thead>
                {patientSurgeryDetails.length !== 0 ?
                  <tbody>
                    {
                      patientSurgeryDetails.map((data, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{data.strSurgeryName}</td>
                            <td>{data.strSurgeryHospitalName}</td>
                            <td>{data.strSurgeryDoctorName}</td>
                            <td>{data.strSurgeryType}</td>
                            <td>{data.dtmSurgeryDate !== null ? moment(data.dtmSurgeryDate).format('DD-MMM-YYYY') : null}</td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                  :
                  <tbody>
                    <tr>
                      <td colSpan={6} style={{ textAlign: "center" }}>No Surgery Details Found</td>
                    </tr>
                  </tbody>
                }

              </Table>
            </Row>
            <Row style={{ alignItems: "center", alignContent: "center" }}>
              {patientDocSurgeryImagesDetails.length !== 0 ?
                <div>
                  {patientDocSurgeryImagesDetails.map((pat, index) => {
                    return (
                      <div key={"imgDoc" + index} >
                        <Row style={{ alignContent: "center", marginBottom: 5 }}>
                          <Col md={2} style={{ fontSize: 12, color: "grey", fontWeight: "bold" }}>
                            <Image
                              onClick={() => { setShowMyImageAttachments(true); setMyImageAttachments(pat.strPatientDocument); }}
                              src={`data:image/jpeg;base64,${(pat.strPatientDocument)}`}
                              height={75}
                              width={75}
                              style={{ padding: 2, cursor: "pointer" }}
                            />
                          </Col>
                          <Col md={10} style={{ fontSize: 12, color: "grey" }}>
                            {pat.strDocumentRemarks}
                          </Col>
                        </Row>
                      </div>
                    );
                  })
                  }
                </div>
                :
                null
              }
            </Row>


            <Row>
              <Col style={{ backgroundColor: "#02647E", padding: 5, color: "white" }}>PATIENT CASES</Col>
            </Row>
            <Row style={{ padding: 10 }}>
              {allPatientCases?.map((pc, index) =>
                <div style={{ margin: 10 }}>
                  <Row>
                    <Col style={{ backgroundColor: "#037C6E", padding: 5, color: "white" }}>
                      Case No: {pc.strCaseNo} - Case Register Date: {moment(pc.dtmCaseRegisterDat).format('DD/MM/YYYY')} - {pc.strAppointmentType}
                    </Col>
                  </Row>


                  {pc.strWeight || pc.strHeight || pc.strBmi || pc.strBsa || pc.strPulse ||
                    pc.strSpo || pc.strBloodPressure || pc.strRespiratoryRate || pc.strTemperature ?
                    <div>
                      <Row>
                        <Col>VITALS</Col>
                      </Row>
                      <div style={{ padding: 10 }}>
                        <Row>
                          {pc.strWeight !== " kg" ? <Col><span style={{ color: "grey" }}>Weight:</span> {pc.strWeight}</Col> : null}
                          {pc.strHeight !== " ft" ? <Col><span style={{ color: "grey" }}>Height:</span> {pc.strHeight}</Col> : null}
                          {pc.strBmi ? <Col><span style={{ color: "grey" }}>Bmi:</span> {pc.strBmi}</Col> : null}
                          {pc.strBsa ? <Col><span style={{ color: "grey" }}>Bsa:</span> {pc.strBsa}</Col> : null}
                          {pc.strPulse ? <Col><span style={{ color: "grey" }}>Pulse:</span> {pc.strPulse}</Col> : null}
                        </Row>
                        <Row>
                          {pc.strSpo ? <Col><span style={{ color: "grey" }}>SPO2:</span> {pc.strSpo}</Col> : null}
                          {pc.strBloodPressure ? <Col><span style={{ color: "grey" }}>Blood Pressure:</span> {pc.strBloodPressure}</Col> : null}
                          {pc.strRespiratoryRate ? <Col><span style={{ color: "grey" }}>Respiratory Rate:</span> {pc.strRespiratoryRate}</Col> : null}
                          {pc.strTemperature ? <Col><span style={{ color: "grey" }}>Temperature:</span> {pc.strTemperature}</Col> : null}
                          <Col></Col>
                        </Row>
                      </div>
                    </div>
                    : null
                  }

                  {pc.strPersonalHistory || pc.strMajorIllness || pc.strHospitalization || pc.strMedications ||
                    pc.strCovidHO || pc.strCovidHospitalization || pc.strCovidVaccine || pc.strFamilyHistory ?
                    <div>
                      <Row>
                        <Col>HISTORY</Col>
                      </Row>
                      <div style={{ padding: 10 }}>
                        {pc.strPersonalHistory ?
                          <Row>
                            <Col><span style={{ color: "grey" }}>Personal History:</span> {pc.strPersonalHistory}</Col>
                          </Row>
                          : null
                        }
                        {pc.strMajorIllness ?
                          <Row>
                            <Col><span style={{ color: "grey" }}>Major Illness:</span> {pc.strMajorIllness}</Col>
                          </Row>
                          : null
                        }
                        {pc.strHospitalization ?
                          <Row>
                            <Col><span style={{ color: "grey" }}>Hospitalization:</span> {pc.strHospitalization}</Col>
                          </Row>
                          : null
                        }
                        {pc.strMedications ?
                          <Row>
                            <Col><span style={{ color: "grey" }}>Medications:</span> {pc.strMedications}</Col>
                          </Row>
                          : null
                        }
                        {pc.strCovidHO ?
                          <Row>
                            <Col><span style={{ color: "grey" }}>Covid-19 HO:</span> {pc.strCovidHO}</Col>
                          </Row>
                          : null
                        }
                        {pc.strCovidHospitalization ?
                          <Row>
                            <Col><span style={{ color: "grey" }}>Covid Hospitalization:</span> {pc.strCovidHospitalization}</Col>
                          </Row>
                          : null
                        }
                        {pc.strCovidVaccine ?
                          <Row>
                            <Col><span style={{ color: "grey" }}>Covid Vaccine:</span> {pc.strCovidVaccine}</Col>
                          </Row>
                          : null
                        }
                        {pc.strFamilyHistory ?
                          <Row>
                            <Col><span style={{ color: "grey" }}>Family History:</span> {pc.strFamilyHistory}</Col>
                          </Row>
                          : null
                        }
                      </div>
                    </div>
                    : null
                  }

                  {pc.strComplain ?
                    <div>
                      <Row>
                        <Col>COMPLAINS</Col>
                      </Row>
                      <div style={{ padding: 10 }}>
                        {pc.strComplain ?
                          <Row>
                            <Col><span style={{ color: "grey" }}>Complains:</span> {pc.strComplain}</Col>
                          </Row>
                          : null
                        }
                      </div>
                    </div>
                    : null
                  }

                  {pc.strInvestigation ?
                    <div>
                      <Row>
                        <Col>INVESTIGATIONS</Col>
                      </Row>
                      <div style={{ padding: 10 }}>
                        {pc.strInvestigation ?
                          <Row>
                            <Col><span style={{ color: "grey" }}>Investigation:</span> {pc.strInvestigation}</Col>
                          </Row>
                          : null
                        }
                      </div>
                    </div>
                    : null
                  }

                  {pc.strClinicalExamination ?
                    <div>
                      <Row>
                        <Col>CLINICAL EXAMINATION</Col>
                      </Row>
                      <div style={{ padding: 10 }}>
                        {pc.strClinicalExamination ?
                          <Row>
                            <Col><span style={{ color: "grey" }}>Clinical Examination:</span> {pc.strClinicalExamination}</Col>
                          </Row>
                          : null
                        }
                      </div>
                    </div>
                    : null
                  }

                  {patientData.strGender === "Female" ?
                    <div>
                      {pc.strMenstrualCycle || pc.strPain || pc.dtmLmpDate || pc.strChildren || pc.strAbortion || pc.strTypeOfDelivery ?
                        <div>
                          <Row>
                            <Col>FOR FEMALES ONLY</Col>
                          </Row>
                          <div style={{ padding: 10 }}>
                            {pc.strMenstrualCycle ?
                              <Row>
                                <Col><span style={{ color: "grey" }}>Menstrual Cycle:</span> {pc.strMenstrualCycle}</Col>
                              </Row>
                              : null
                            }
                            {pc.strPain ?
                              <Row>
                                <Col><span style={{ color: "grey" }}>Pain:</span> {pc.strPain}</Col>
                              </Row>
                              : null
                            }
                            {pc.dtmLmpDate ?
                              <Row>
                                <Col><span style={{ color: "grey" }}>LMP Date:</span> {pc.dtmLmpDate}</Col>
                              </Row>
                              : null
                            }
                            {pc.strChildren ?
                              <Row>
                                <Col><span style={{ color: "grey" }}>Children:</span> {pc.strChildren}</Col>
                              </Row>
                              : null
                            }
                            {pc.strAbortion ?
                              <Row>
                                <Col><span style={{ color: "grey" }}>Abortion:</span> {pc.strAbortion}</Col>
                              </Row>
                              : null
                            }
                            {pc.strTypeOfDelivery ?
                              <Row>
                                <Col><span style={{ color: "grey" }}>Type of Delivery:</span> {pc.strTypeOfDelivery}</Col>
                              </Row>
                              : null
                            }
                          </div>
                        </div>
                        : null
                      }
                    </div>
                    : null
                  }

                  {pc.strDiagnosisSearch || pc.strDiagnosis ?
                    <div>
                      <Row>
                        <Col>DIAGNOSIS</Col>
                      </Row>
                      <div style={{ padding: 10 }}>
                        {pc.strDiagnosisSearch ?
                          <Row>
                            <Col><span style={{ color: "grey" }}>Diagnosis:</span> {pc.strDiagnosisSearch}</Col>
                          </Row>
                          : null
                        }
                        {pc.strDiagnosis ?
                          <Row>
                            <Col><span style={{ color: "grey" }}>Diagnose Notes:</span> {pc.strDiagnosis}</Col>
                          </Row>
                          : null
                        }
                      </div>
                    </div>
                    : null
                  }

                  {pc.strDoctorAdvice ?
                    <div>
                      <Row>
                        <Col>ADVICE</Col>
                      </Row>
                      <div style={{ padding: 10 }}>
                        {pc.strDoctorAdvice ?
                          <Row>
                            <Col><span style={{ color: "grey" }}>Advice:</span> {pc.strDoctorAdvice}</Col>
                          </Row>
                          : null
                        }

                      </div>
                    </div>
                    : null
                  }

                  {pc.strMedicationTreatment[0].strMedicineName !== null ?
                    <div>
                      <Row>
                        <Col>MEDICATION AND TREATMENT</Col>
                      </Row>
                      <div style={{ padding: 10 }}>
                        <Table striped bordered hover size="sm" responsive >
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Name</th>
                              <th>Type</th>
                              <th>Duration</th>
                              <th>Frequency</th>
                              <th>Quantity</th>
                              <th>Advise</th>
                            </tr>
                          </thead>
                          <tbody>
                            {pc.strMedicationTreatment.map((as, index) => (
                              <tr>
                                <td>{index + 1}</td>
                                <td>{as.strMedicineName}</td>
                                <td>{as.strMedicineType}</td>
                                <td>{as.strMedicineDuration}</td>
                                <td>{as.strMedicineFrequency}</td>
                                <td>{as.strMedicineQuantity}</td>
                                <td>{as.strMedicineAdvise}</td>
                              </tr>
                            )
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                    : null
                  }

                </div>

              )}
            </Row>
          </div>
        </Container>

      }

      <Footer />

    </div>



  )
}

export default PatientSharableQrCode
