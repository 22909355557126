import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./navcss.css";
import {  Image } from "react-bootstrap";
import MyImage from "../images/cropped-transparant-logo.png";
import { CaretDownFill, CaretUpFill } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { CDBSidebar, CDBSidebarHeader, CDBSidebarMenuItem, CDBSidebarContent, CDBSidebarMenu, CDBListGroup, } from 'cdbreact';

var sidebarStat = false;

function PharmacySidebar() {

    const loginid = localStorage.getItem("loggedInId");
    const navigate = useNavigate();

    const [managePharmMenu, setManagePharmMenu] = useState(false);
    const managePharmacyMenuClicked = () => { setManagePharmMenu(!managePharmMenu); dashclick("Dashboard");}

    useEffect(() => {
        checkLoginId();
    })

    const checkLoginId = () => {
        if (loginid === null || loginid === "") {
            navigate('/login');
        }
    }

    const dashclick = (menuItemName) => {
        if(menuItemName === "Dashboard"){
            sidebarStat = false;
        } else if(menuItemName === "Other"){
            sidebarStat = true;
        }
    };

    return (

        <div>

            <div style={{ display: 'flex', height: '100vh', overflow: 'scroll initial' }}>
                <CDBSidebar textColor="#808080" backgroundColor="#fff" toggled={sidebarStat}>
                    <CDBSidebarHeader prefix={<i className="fa fa-bars" />}>
                        <div style={{ padding: 10 }}>
                            <Link to="/pharmacydashboard"><Image src={MyImage} className="d-inline-block align-top" alt="check" height={60} /></Link>
                        </div>
                    </CDBSidebarHeader>
                    <CDBSidebarContent>
                        <CDBSidebarMenu>
                            <span style={{ fontWeight: "bold" }} onClick={() => {dashclick("Dashboard")}}><Link to="/pharmacydashboard"><CDBSidebarMenuItem icon="th-large" iconSize="sm" textFontSize="12px" >Dashboard</CDBSidebarMenuItem></Link></span>

                            <span onClick={managePharmacyMenuClicked} style={{ fontWeight: "bold" }}>
                                <CDBSidebarMenuItem icon="map-marker-alt" iconSize="sm" textFontSize="12px">
                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                        Manage
                                        {managePharmMenu ? <CaretUpFill size={13} /> : <CaretDownFill size={13} />}
                                    </div>
                                </CDBSidebarMenuItem>
                            </span>
                            {
                                managePharmMenu ?
                                    <CDBListGroup style={{ paddingLeft: 20 }}>
                                        <div style={{ height: 40 }} onClick={() => {dashclick("Other")}}><Link to="/pharmacymanageprescriptions"><CDBSidebarMenuItem icon="circle-small" iconSize="sm" textFontSize="12px" >Prescriptions</CDBSidebarMenuItem></Link></div>
                                    </CDBListGroup>
                                    :
                                    <div></div>
                            }

                            <span style={{ fontWeight: "bold" }} onClick={() => {dashclick("Other")}}><Link to="/pharmacytimings"><CDBSidebarMenuItem icon="calendar-alt" iconSize="sm" textFontSize="12px" >Pharmacy Timings</CDBSidebarMenuItem></Link></span>

                        </CDBSidebarMenu>
                    </CDBSidebarContent>
                </CDBSidebar>
            </div>

        </div>
    );
}

export default PharmacySidebar;





