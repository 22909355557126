import React, { useState, useEffect, useRef, useCallback } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./admincss.css";
import HospitalSidebar from "../Navbar/HospitalSidebar";
import Adminheader from "../Navbar/Adminheader";
import { CDBCard, CDBCardBody, CDBDataTable } from 'cdbreact';
import { Container, Col, Row, Button, Modal, Form, Spinner, Image } from "react-bootstrap";
import { Asterisk } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
// import { API_BASE } from '../../setupProxy';
import Webcam1 from "react-webcam";
import { useNavigate } from "react-router-dom";
import * as moment from 'moment';

const HospitalPatientRecords = () => {

    const intOrganizationID = localStorage.getItem("orgid");
    const roleName = localStorage.getItem("loggedRoleName");

    const navigate = useNavigate();

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [strFirstName, setStrFirstName] = useState("");
    const [strLastName, setStrLastName] = useState("");
    const [strmobileno, setStrmobileno] = useState("");
    const [dtmBirthDate, setDtmBirthdate] = useState("");
    const [intAge, setIntAge] = useState(null);
    const [strGender, setStrGender] = useState("- Gender -");
    const [strBloodGroup, setStrBloodGroup] = useState("- Blood Group -");
    const [straddress, setStrAddress] = useState("");
    const [intcityID, setIntCityID] = useState(null);
    const [strstatus, setStatus] = useState('Active');
    const [cities, setCities] = useState([]);
    const [intPatientID, setIntPatientID] = useState(null);
    const [patients, setPatients] = useState([]);
    const [loading, setLoading] = useState(false);

    const [updateMessage, setUpdateMessage] = useState("");
    const [closeCaseMessage, setCloseCaseMessage] = useState("");
    // const [checkMobile, setCheckMobile] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [addPatientError, setAddPatientError] = useState("");

    const [showDelete, setShowDelete] = useState(false);
    const handleCloseDelete = () => setShowDelete(false);
    const [deleteThis, setDeleteThis] = useState(null);

    // const [showPatientList, setShowPatientList] = useState(false);
    // const handleClosePatientList = () => setShowPatientList(false);
    // const handleShowPatientList = () => setShowPatientList(true);

    // const [patientsFound, setPatientsFound] = useState([]);

    // const [itemPatient, setItemPatient] = useState({ strPatient: "", another: "another" });
    // const { strPatient } = itemPatient;
    // const handleChangePatient = e => {
    //     e.persist();
    //     setItemPatient(prevState => ({ ...prevState, strPatient: e.target.value }));
    // };

    useEffect(() => {
        fetchCities();
        fetchPatients(intOrganizationID);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //FETCH ALL CITIES (FOR DROPDOWN)
    const fetchCities = () => {
        fetch("https://e-arogya.com:8443/api/general/cities")
            .then(res => res.json())
            .then(cities => {
                //.log(cities, "Cities data")
                setCities(cities);
            })
            .catch(error => {
                //console.log(error);
            })
    }

    const webcamRef = useRef(null);
    const [img, setImg] = useState(null);
    const [editImg, setEditImg] = useState(null);
    const [showCaptureImage, setShowCaptureImage] = useState(false);
    const handleShowCaptureImage = () => { setShowCaptureImage(true); setImg(null); setEditImg(null); }
    const handleCloseCaptureImage = () => { setShowCaptureImage(false); setImg(null); setEditImg(null); };
    const handleRetake = () => setImg(null);

    const capturePhoto = useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        setImg(imageSrc);
    }, [webcamRef]);

    const capturePhotoDone = () => {
        if (img !== null) {
            setShowCaptureImage(false);
        } else {
            setErrorMessage(<span style={{ color: "red" }}>Please Capture Image</span>);
            setTimeout(() => setErrorMessage(""), 3000);
        }
    }

    //FETCH ALL PATIENTS
    const fetchPatients = (orgid) => {
        fetch("https://e-arogya.com:8443/api/general/patients/list/" + orgid)
            .then(response => response.json())
            .then(data => {
                let dataFromApi = data.map(pat => {
                    return {
                        id: pat.intPatientID,
                        firstname: pat.strFirstName,
                        lastname: pat.strLastName,
                        mobile: pat.strmobileno,
                        dob: pat.dtmBirthDate,
                        age: pat.intAge + " years",
                        gender: pat.strGender,
                        bloodgroup: pat.strBloodGroup,
                        address: pat.straddress,
                        city: pat.intcityID,
                        cityname: pat.strcityname,
                        status: pat.strstatus
                    }
                })
                setPatients(dataFromApi);
                setLoading(true);
            })
            .catch(error => {
                //console.log(error);
            })
    }

    const clearFields = () => {
        setStrFirstName("");
        setStrLastName("");
        setStrmobileno("");
        setDtmBirthdate("");
        setIntAge(null);
        setStrGender("- Gender -");
        setStrBloodGroup("- Blood Group -");
        setStrAddress("");
        setIntCityID('- Select City -');
        setStatus('Active');
        setImg(null);
        setEditImg(null);
    }

    //FORM SUBMIT
    const onAddNewPatientFormSubmit = (event) => {
        event.preventDefault();

        if (strFirstName === "") {
            setAddPatientError(<span style={{ color: "red" }}>Please Enter First Name</span>);
            setTimeout(() => setAddPatientError(""), 3000);
        } else if (strLastName === "") {
            setAddPatientError(<span style={{ color: "red" }}>Please Enter Last Name</span>);
            setTimeout(() => setAddPatientError(""), 3000);
        } else if (strmobileno === "" || strmobileno.length !== 10 || isNaN(strmobileno)) {
            setAddPatientError(<span style={{ color: "red" }}>Please enter a valid 10 digit mobile number</span>);
            setTimeout(() => setAddPatientError(""), 3000);
        } else if (dtmBirthDate === "") {
            setAddPatientError(<span style={{ color: "red" }}>Please select Birth Date</span>);
            setTimeout(() => setAddPatientError(""), 3000);
        } else if (strGender === "- Gender -" || strGender === "") {
            setAddPatientError(<span style={{ color: "red" }}>Please select Gender</span>);
            setTimeout(() => setAddPatientError(""), 3000);
        } else if (strBloodGroup === "- Blood Group -" || strBloodGroup === "") {
            setAddPatientError(<span style={{ color: "red" }}>Please select Blood Group</span>);
            setTimeout(() => setAddPatientError(""), 3000);
        } else if (straddress === "" || straddress === null) {
            setAddPatientError(<span style={{ color: "red" }}>Please Enter Address</span>);
            setTimeout(() => setAddPatientError(""), 3000);
        } else if (intcityID === "" || intcityID === '- Select City -' || intcityID === null) {
            setAddPatientError(<span style={{ color: "red" }}>Please Select City</span>);
            setTimeout(() => setAddPatientError(""), 3000);
        } else {

            if (intPatientID) {
                const formData = new FormData();
                formData.append('strFirstName', strFirstName);
                formData.append('strLastName', strLastName);
                formData.append('strmobileno', strmobileno);
                formData.append('dtmBirthDate', dtmBirthDate);
                formData.append('strGender', strGender);
                formData.append('strBloodGroup', strBloodGroup);
                formData.append('straddress', straddress);
                formData.append('intcityID', intcityID);
                formData.append('strstatus', strstatus);
                if (img) { formData.append('strPatientPhoto', img); }
                updatePatient(formData, intPatientID);

                // const existingInputs = { strFirstName, strLastName, strmobileno, dtmBirthDate, strGender, strBloodGroup, straddress, intcityID, strstatus }
                // console.log(" " + strFirstName + " " + strLastName + " " + strmobileno + " " + dtmBirthDate + " " + strGender + " " + strBloodGroup + " " + straddress + " " + intcityID + " " + strstatus);
                // updatePatient(existingInputs, intPatientID);
                // setIntPatientID(null);
                // clearFields();
                // handleClose();
            } else {
                const formData = new FormData();
                formData.append('strFirstName', strFirstName);
                formData.append('strLastName', strLastName);
                formData.append('strmobileno', strmobileno);
                formData.append('dtmBirthDate', dtmBirthDate);
                formData.append('strGender', strGender);
                formData.append('strBloodGroup', strBloodGroup);
                formData.append('straddress', straddress);
                formData.append('intcityID', intcityID);
                formData.append('strstatus', strstatus);
                if (img) { formData.append('strPatientPhoto', img); }
                addNewPatient(formData);

                // const toInput = { strFirstName, strLastName, strmobileno, dtmBirthDate, strGender, strBloodGroup, straddress, intcityID, strstatus }
                // console.log(" " + strFirstName + " " + strLastName + " " + strmobileno + " " + dtmBirthDate + " " + strGender + " " + strBloodGroup + " " + straddress + " " + intcityID + " " + strstatus);
                // addNewPatient(toInput);
                // clearFields();
                // handleClose();
            }
        }
    }



    //FETCH PATIENT BY ID (FOR EDIT PATIENT)
    const handleEditClick = (e, id) => {

        e.preventDefault();
        fetch("https://e-arogya.com:8443/api/general/patients/" + id)
            .then(res => res.json())
            .then(data => {
                setIntPatientID(data[0].intPatientID);
                setStrFirstName(data[0].strFirstName);
                setStrLastName(data[0].strLastName);
                setStrmobileno(data[0].strmobileno);
                setDtmBirthdate(data[0].dtmBirthDate);
                setIntAge(data[0].intAge + " years");
                setStrGender(data[0].strGender);
                setStrBloodGroup(data[0].strBloodGroup);
                setStrAddress(data[0].straddress);
                setIntCityID(data[0].intcityID);
                setStatus(data[0].strstatus);
                setEditImg(data[0].strPatientPhoto);
            })
            .catch(error => {
                //console.log(error);
            });
    }

    //ADD NEW PATIENT
    async function addNewPatient(formData) {
        const requestOptions = {
            method: 'POST',
            body: formData
        };

        fetch("https://e-arogya.com:8443/api/general/patients/addnewpatient", requestOptions)
            .then(res => {

                if (res.ok) {
                    setUpdateMessage(<span style={{ color: "green" }}>Patient Added Successfully</span>);
                    setTimeout(() => setUpdateMessage(""), 3000);
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    fetchPatients(intOrganizationID);
                    clearFields();
                    handleClose();
                } else {
                    setUpdateMessage(<span style={{ color: "red" }}>Patient not added. Please try again.</span>);
                    setTimeout(() => setUpdateMessage(""), 3000);
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    clearFields();
                    handleClose();
                }
            })
            .catch(error => {
                setUpdateMessage(<span style={{ color: "red" }}>Patient not added. Please try again.</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
                window.scrollTo({ top: 0, behavior: 'smooth' });
                clearFields();
                handleClose();
            });
    }

    //UPDATE EXISTING PATIENT
    async function updatePatient(formData, intPatientID) {
        const requestOptions = {
            method: 'PUT',
            body: formData
        };

        fetch("https://e-arogya.com:8443/api/general/patients/update/" + intPatientID, requestOptions)
            .then(res => {

                if (res.ok) {
                    setUpdateMessage(<span style={{ color: "green" }}>Patient Updated Successfully</span>);
                    setTimeout(() => setUpdateMessage(""), 3000);
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    fetchPatients(intOrganizationID);
                    setIntPatientID(null);
                    clearFields();
                    handleClose();
                } else {
                    setUpdateMessage(<span style={{ color: "red" }}>Patient not updated. Please try again.</span>);
                    setTimeout(() => setUpdateMessage(""), 3000);
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    setIntPatientID(null);
                    clearFields();
                    handleClose();
                }
            })
            .catch(error => {
                setUpdateMessage(<span style={{ color: "red" }}>Patient not updated. Please try again.</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
                window.scrollTo({ top: 0, behavior: 'smooth' });
                setIntPatientID(null);
                clearFields();
                handleClose();
            });
    }

    //DELETE ORGANIZATION SPECIALITIES

    function handleDeleteClick(e, id) {
        setShowDelete(true);
        setDeleteThis(id);
    }

    const deleteThisFunc = (id) => {
        fetch("https://e-arogya.com:8443/api/general/patients/" + id, { method: 'DELETE' })
            .then(async response => {
                const data = await response.text;

                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }

                setUpdateMessage(<span style={{ color: "green" }}>Patient Deleted Successfully</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
                setDeleteThis(null);
                fetchPatients(intOrganizationID);
            })
            .catch(error => {
                setUpdateMessage(<span style={{ color: "red" }}>Patient Not Deleted. Please try again.</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
            });
    }

    const [showCases, setShowCases] = useState(false);
    const handleCloseCases = () => setShowCases(false);
    const handleShowCases = () => setShowCases(true);
    const [casesFound, setCasesFound] = useState([]);

    const getAllCases = (patid) => {
        fetch("https://e-arogya.com:8443/api/general/patientcase/list/" + intOrganizationID + "/" + patid)
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }
                if (data) {
                    handleShowCases();
                    setCasesFound(data);
                }
            })
            .catch(error => {
                //console.log("Error Occurred: " + error);
            });
    }

    const [showAppointments, setShowAppointments] = useState(false);
    const handleCloseAppointments = () => setShowAppointments(false);
    const handleShowAppointments = () => setShowAppointments(true);
    const [appointmentsFound, setAppointmentsFound] = useState([]);

    const getPatientAllAppointments = (patid) => {
        fetch("https://e-arogya.com:8443/api/general/appointments/patientappointmentlist/" + intOrganizationID + "/" + patid)
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }
                if (data) {
                    //console.log("app: " + JSON.stringify(data));
                    handleShowAppointments();
                    setAppointmentsFound(data);
                }
            })
            .catch(error => {
                //.log("Error Occurred: " + error);
            });
    }

    const handleEditAppointmentCaseData = (appid, caseid, apptype) => {
        fetch("https://e-arogya.com:8443/api/general/patientcase/patientcasedatabyid/" + intOrganizationID + "/" + caseid)
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }
                if (data) {
                    navigate('/hospitalpatientgeneratecase', { state: { appid: appid, patid: data[0].intPatientID, apptype: apptype, caseno: data[0].strCaseNo, casestatus: data[0].strCaseStatus, casetype: data[0].strCaseType, genedit: "Update" } });
                }
            })
            .catch(error => {
                //console.log("Error Occurred: " + error);
            });
    }

    const [showConfirmCloseCase, setShowConfirmCloseCase] = useState(false);
    const handleCloseConfirmCloseCase = () => setShowConfirmCloseCase(false);
    const [closeCaseCaseId, setCloseCaseCaseId] = useState(null);
    const [closeCasePatId, setCloseCasePatId] = useState(null);
    function handleCloseCase(caseid, patid) {
        setShowConfirmCloseCase(true);
        setCloseCaseCaseId(caseid);
        setCloseCasePatId(patid);
    }

    const closeCase = (caseid, patid) => {

        const strCaseStatus = "Closed";
        const existingInputs = { strCaseStatus }

        fetch("https://e-arogya.com:8443/api/general/patientcase/close/" + caseid, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            redirect: "follow",
            body: JSON.stringify(existingInputs)
        })
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();

                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }
                setCloseCaseMessage(<span style={{ color: "green" }}>Patient Case Closed</span>);
                setTimeout(() => setCloseCaseMessage(""), 3000);
                getAllCases(patid);
                setCloseCaseCaseId(null);
                setCloseCasePatId(null);

            })
            .catch(error => {
                setCloseCaseMessage(<span style={{ color: "red" }}>Patient Case Not Closed. Please try again.</span>);
                setTimeout(() => setCloseCaseMessage(""), 3000);
            });
    }

    // const checkMobileNo = () => {
    //     if (strmobileno === "" || strmobileno.length !== 10 || isNaN(strmobileno)) {
    //         setCheckMobile(<span style={{ color: "red" }}>Please enter a valid 10 digit mobile number</span>);
    //         setTimeout(() => setCheckMobile(""), 3000);
    //     } else {
    //         fetch("https://e-arogya.com:8443/patients/checkmobile/" + strmobileno)
    //             .then(async response => {
    //                 const isJson = response.headers.get('content-type')?.includes('application/json');
    //                 const data = isJson && await response.json();
    //                 if (!response.ok) {
    //                     const error = (data && data.message) || response.status;
    //                     return Promise.reject(error);
    //                 }
    //                 if (data) {

    //                     console.log(data);
    //                     setCheckMobile(<span style={{ color: "green" }}>Mobile number exist. Below is the details</span>);
    //                     setTimeout(() => setCheckMobile(""), 3000);

    //                     handleShowPatientList();
    //                     setPatientsFound(data);

    //                     // setStrFirstName(data[0].strFirstName);
    //                     // setStrLastName(data[0].strLastName);
    //                     // setDtmBirthdate(data[0].dtmBirthDate);
    //                     // setStrGender(data[0].strGender);
    //                     // setStrBloodGroup(data[0].strBloodGroup);     
    //                     // setStrAddress(data[0].straddress);
    //                     // setIntCityID(data[0].intcityID);
    //                     // setStatus(data[0].strstatus);

    //                 } else {
    //                     setCheckMobile(<span style={{ color: "red" }}>Mobile number does not exist. Please fill form</span>);
    //                     setTimeout(() => setCheckMobile(""), 3000);


    //                     setStrFirstName("");
    //                     setStrLastName("");
    //                     //setStrmobileno("");
    //                     setDtmBirthdate("");
    //                     setStrGender("- Gender -");
    //                     setStrBloodGroup("- Blood Group -");
    //                     setStrAddress("");
    //                     setIntCityID('- Select City -');
    //                     setStatus('Active');
    //                     setIntPatientID(null);

    //                 }

    //             })
    //             .catch(error => {
    //                 console.log("Error Occurred: " + error);
    //                 setCheckMobile(<span style={{ color: "red" }}>Error Occurred. Please try again.</span>);
    //                 setTimeout(() => setCheckMobile(""), 3000);
    //             });
    //     }
    // }

    // const handleDone = () => {
    //     getSelectedPatientInfo(strPatient);
    // }

    // //FETCH PATIENT BY ID ()
    // const getSelectedPatientInfo = (id) => {
    //     fetch("https://e-arogya.com:8443/patients/" + id)
    //         .then(res => res.json())
    //         .then(data => {
    //             console.log(data, "data")
    //             setStrFirstName(data[0].strFirstName);
    //             setStrLastName(data[0].strLastName);
    //             setStrGender(data[0].strGender);
    //             setStrBloodGroup(data[0].strBloodGroup);
    //             setStrmobileno(data[0].strmobileno);
    //             setStatus(data[0].strstatus);
    //         })
    //         .catch(error => {
    //             console.log(error);
    //         });
    // }

    const data = () => {
        return {
            columns: [
                { label: 'Sr.No.', field: 'srno', },
                {
                    label: 'Name',
                    field: 'name',
                    attributes: {
                        'aria-controls': 'DataTable',
                        'aria-label': 'Name',
                    },
                },
                { label: 'Mobile', field: 'mobile', },
                { label: 'Date of Birth', field: 'dob', },
                { label: 'Age', field: 'age', },
                { label: 'Gender', field: 'gender', },
                { label: 'Blood Group', field: 'bloodgroup', },
                { label: 'Address', field: 'address', },
                { label: 'City', field: 'city', },
                { label: 'Status', field: 'status', },
                { label: '', field: 'edit', },
                { label: '', field: 'delete', },
                { label: '', field: 'viewcase', },
                { label: '', field: 'getpatienthistory', },
                { label: '', field: 'viewappointments', },
                { label: '', field: 'save', },

            ],
            rows: [...patients.map((data1, index) => (
                {
                    srno: index + 1,
                    name: data1.firstname + " " + data1.lastname,
                    mobile: data1.mobile,
                    dob: moment(data1.dob).format('DD-MMM-YYYY'),
                    age: <div>{data1.dob ? data1.age : ""}</div>,
                    gender: data1.gender,
                    bloodgroup: data1.bloodgroup,
                    address: data1.address,
                    city: data1.cityname,
                    status: data1.status,
                    edit: <span onClick={(e) => { handleEditClick(e, data1.id); handleShow(); }}> <Button variant="primary" type="submit" style={{ fontSize: 12 }} size="sm"> Edit </Button> </span>,
                    delete: <span onClick={(e) => handleDeleteClick(e, data1.id)}> <Button variant="danger" type="submit" style={{ fontSize: 12 }} size="sm"> Delete </Button> </span>,
                    viewcase: <Button variant="primary" type="submit" style={{ fontSize: 12, backgroundColor: "steelblue", borderColor: "steelblue" }} size="sm" onClick={() => { getAllCases(data1.id) }}>  View Cases </Button>,
                    getpatienthistory: <Button
                        variant="primary"
                        type="submit"
                        style={{ fontSize: 12, backgroundColor: "#00626D", borderColor: "#00626D" }}
                        size="sm"
                        onClick={() => { navigate('/hospitalgetpatienthistory', { state: { patid: data1.id } }); }}>  
                        Get History 
                        </Button>,
                    viewappointments: <div>
                        {roleName === "Frontend" || roleName === "Hospital Admin" ?
                            <div><Button variant="primary" type="submit" style={{ fontSize: 12, backgroundColor: "#008aad", borderColor: "#008aad" }} size="sm" onClick={() => { getPatientAllAppointments(data1.id) }}>  View Appointments </Button></div>
                            :
                            <div></div>
                        }
                    </div>,
                    save: <div>
                        {roleName === "Frontend" || roleName === "Hospital Admin" ?
                            <div>
                                <Link to="/hospitalpatientappointments" state={{ patid: data1.id, patmobile: data1.mobile, patfname: data1.firstname, patlname: data1.lastname, patgender: data1.gender, patdob: data1.dob }}>
                                    <Button variant="primary" type="submit" style={{ fontSize: 12, backgroundColor: "slateblue", borderColor: "slateblue" }} size="sm"> Book Appointment </Button>
                                </Link>
                            </div>
                            :
                            <div></div>
                        }
                    </div>,

                }
            ))

            ],
        };
    };
    return (

        <div className="main" >

            <HospitalSidebar />

            <div className="page-content">

                <Adminheader />

                <h5>Patient Records</h5>

                <div className="table-content">

                    {/* <Row style={{ fontSize: 12, color: "grey", marginBottom: 20 }}>

                        <Col sm={2} >
                            <Button
                                variant="primary"
                                type="submit"
                                style={{ marginTop: 25, fontSize: 12, fontWeight: "bold" }}
                                onClick={() => {
                                    handleShow();
                                    clearFields();
                                }
                                }> + Add New Patient </Button>
                        </Col>

                    </Row> */}

                    <Modal show={showCaptureImage} onHide={handleCloseCaptureImage} >
                        <Modal.Header>
                            <Modal.Title style={{ fontSize: 18 }}>Capture Photo</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="grid-example">
                            <Container>
                                <Row className="justify-content-md-center">
                                    <Col xs={12} md={8}>
                                        <div style={{ justifyContent: "center", display: "flex" }} >
                                            {img ?
                                                <Image src={img} alt="webcam" />
                                                :
                                                <Webcam1
                                                    audio={false}
                                                    mirrored={true}
                                                    height={300}
                                                    width={300}
                                                    ref={webcamRef}
                                                    screenshotFormat="image/jpeg"
                                                    videoConstraints={{ width: 300, height: 300, facingMode: "user" }}
                                                />
                                            }
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="justify-content-md-center">
                                    <div style={{ fontSize: 12, fontWeight: "bold", justifyContent: "center", display: "flex", marginTop: 20 }}>{errorMessage}</div>
                                </Row>
                                <Row className="justify-content-md-center">
                                    <Col xs={6} md={4}>
                                        <div style={{ justifyContent: "center", display: "flex", marginTop: 20 }} >
                                            <Button variant="primary" onClick={capturePhoto} style={{ fontSize: 12, width: 75 }}> Capture </Button>
                                        </div>
                                    </Col>
                                    <Col xs={6} md={4}>
                                        <div style={{ justifyContent: "center", display: "flex", marginTop: 20 }} >
                                            <Button variant="primary" onClick={handleRetake} style={{ fontSize: 12, width: 75 }}> Retake </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={capturePhotoDone} style={{ fontSize: 12 }}> Done </Button>
                            <Button variant="secondary" onClick={handleCloseCaptureImage} style={{ fontSize: 12 }}> Close </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={showCases} onHide={handleCloseCases} size="xl">
                        <Modal.Header closeButton>
                            <Modal.Title style={{ fontSize: 18 }}>Patient Cases</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ fontSize: 14 }}>
                            <table className="table" style={{ padding: 10 }} >
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>Mobile</th>
                                        <th>Gender</th>
                                        <th>Case Type</th>
                                        <th>Create Date</th>
                                        <th>Case Status</th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {casesFound.map((data, index) => (
                                        <tr key={data.intCaseID}>
                                            <td><Form.Label style={{ fontSize: 12, color: "grey" }} >{index + 1}</Form.Label></td>
                                            <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={data.strFirstName}>{data.strFirstName} {data.strLastName}</Form.Label></td>
                                            <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={data.strmobileno}>{data.strmobileno}</Form.Label></td>
                                            <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={data.strGender}>{data.strGender}</Form.Label></td>
                                            <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={data.strCaseType}>{data.strCaseType}</Form.Label></td>
                                            <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={data.dtmcreatedate}>{moment(data.dtmcreatedate).format('DD-MMM-YYYY')}</Form.Label></td>
                                            <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={data.strCaseStatus}>{data.strCaseStatus}</Form.Label></td>
                                            <td>
                                                {roleName === "Account" || roleName === "Hospital Admin"?
                                                    <div>
                                                        { data.strCaseStatus === "Open" ? 
                                                            <div>
                                                                <Button variant="danger" style={{ fontSize: 12 }} size="sm" onClick={() => { handleCloseCase(data.intCaseID, data.intPatientID) }}> Close this Case </Button>
                                                            </div>
                                                            :
                                                            <div></div>
                                                        }
                                                    </div>
                                                    :
                                                    <div></div>
                                                }
                                            </td>
                                            <td>
                                                <div>
                                                    {roleName === "Account" || roleName === "Hospital Admin" ?
                                                        <div>
                                                            <Link to="/hospitalpatientviewbill" state={{
                                                                patid: data.intPatientID, patmobile: data.strmobileno, patfname: data.strFirstName, patlname: data.strLastName,
                                                                patgender: data.strGender, caseid: data.intCaseID
                                                            }}>
                                                                <Form.Label style={{ fontSize: 12, color: "grey" }} >
                                                                    <Button variant="primary" type="submit" style={{ fontSize: 12, backgroundColor: "steelblue", borderColor: "steelblue" }} size="sm" onClick={() => { }}>
                                                                        View Bill
                                                                    </Button>
                                                                </Form.Label>
                                                            </Link>
                                                        </div>
                                                        :
                                                        <div></div>
                                                    }
                                                </div>
                                            </td>

                                            {/* <td>
                                                <Link to="/hospitalpatientviewbill" state={{
                                                    patid: data.intPatientID, patmobile: data.strmobileno, patfname: data.strFirstName, patlname: data.strLastName,
                                                    patgender: data.strGender, caseid: data.intCaseID
                                                }}>
                                                    <Form.Label style={{ fontSize: 12, color: "grey" }} >
                                                        <Button variant="primary" type="submit" style={{ fontSize: 12, backgroundColor: "steelblue", borderColor: "steelblue" }} size="sm" onClick={() => { }}>
                                                            View Bill
                                                        </Button>
                                                    </Form.Label>
                                                </Link>
                                            </td> */}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div style={{ fontSize: 12, fontWeight: "bold", marginBottom: 10 }}>{closeCaseMessage}</div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleCloseCases} style={{ fontSize: 12 }}> Cancel </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={showAppointments} onHide={handleCloseAppointments} size="xl">
                        <Modal.Header closeButton>
                            <Modal.Title style={{ fontSize: 18 }}>Patient Appointments</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ fontSize: 14 }}>
                            <table className="table" style={{ padding: 10 }} >
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>Appointment Date</th>
                                        <th>Appointment Time</th>
                                        <th>Speciality Name</th>
                                        <th>Visit Type</th>
                                        <th>Appointment Type</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {appointmentsFound.map((data, index) => (
                                        <tr key={data.intAppointmentId}>
                                            <td><Form.Label style={{ fontSize: 12, color: "grey" }} >{index + 1}</Form.Label></td>
                                            <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={data.strFirstName}>{data.strFirstName} {data.strLastName}</Form.Label></td>
                                            <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={data.dtmAppointmentDate}>{moment(data.dtmAppointmentDate).format('DD-MMM-YYYY')}</Form.Label></td>
                                            <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={data.strFromTime}>{data.strFromTime}</Form.Label></td>
                                            <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={data.intSpecialityID}></Form.Label></td>
                                            <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={data.strVisitType}>{data.strVisitType}</Form.Label></td>
                                            <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={data.strAppointmentType}>{data.strAppointmentType}</Form.Label></td>
                                            <td>
                                                {roleName === "Frontend" || roleName === "Hospital Admin" ?
                                                    <div>
                                                        {data.strAppointmentType ?
                                                            <div>
                                                                <Button
                                                                    variant="primary"
                                                                    style={{ fontSize: 12 }}
                                                                    size="sm"
                                                                    onClick={() => { handleEditAppointmentCaseData(data.intAppointmentId, data.intCaseID, data.strAppointmentType) }}>
                                                                    Edit
                                                                </Button>
                                                            </div>
                                                            :
                                                            <div></div>
                                                        }

                                                    </div>
                                                    :
                                                    <div></div>
                                                }

                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleCloseAppointments} style={{ fontSize: 12 }}> Cancel </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={showConfirmCloseCase} onHide={handleCloseConfirmCloseCase}>
                        <Modal.Header closeButton>
                            <Modal.Title style={{ fontSize: 18 }}>Close Case?</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ fontSize: 14 }}>Are you sure you want to close this case?</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleCloseConfirmCloseCase} style={{ fontSize: 12 }}>No</Button>
                            <Button variant="primary" onClick={() => {
                                handleCloseConfirmCloseCase();
                                closeCase(closeCaseCaseId, closeCasePatId);
                            }} style={{ fontSize: 12 }}>Yes</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={showDelete} onHide={handleCloseDelete}>
                        <Modal.Header closeButton>
                            <Modal.Title style={{ fontSize: 18 }}>Delete Patient</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ fontSize: 14 }}>Are you sure you want to delete this Patient?</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleCloseDelete} style={{ fontSize: 12 }}>No</Button>
                            <Button variant="primary" onClick={() => {
                                handleCloseDelete();
                                deleteThisFunc(deleteThis);
                            }} style={{ fontSize: 12 }}>Yes</Button>
                        </Modal.Footer>
                    </Modal>

                    {/* <Modal show={showPatientList} onHide={handleClosePatientList}>
                            <Modal.Header closeButton>
                                <Modal.Title style={{ fontSize: 18 }}>Patient List</Modal.Title>
                            </Modal.Header>
                            <Modal.Body style={{ fontSize: 14 }}>
                                <table className="table" style={{ padding: 10 }} >
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Name</th>
                                            <th>Mobile</th>
                                            <th>Gender</th>
                                            <th>City</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {patientsFound.map((data) => (
                                            <tr key={data.intPatientID}> */}
                    {/* <td><Form.Check
                                                    type="radio"
                                                    id={data.intPatientID}
                                                    name="group2"
                                                    style={{ paddingLeft: 20 }}
                                                    value={data.intPatientID}
                                                    onChange={handleChangePatient}
                                                    checked={strPatient === `${data.intPatientID}`}
                                                />
                                                </td> */}
                    {/* <td></td>
                                                <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={data.strFirstName}>{data.strFirstName} {data.strLastName}</Form.Label></td>
                                                <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={data.strmobileno}>{data.strmobileno}</Form.Label></td>
                                                <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={data.strGender}>{data.strGender}</Form.Label></td>
                                                <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={data.strBloodGroup}>{data.strBloodGroup}</Form.Label></td>
                                                <td><Form.Label style={{ fontSize: 12, color: "grey" }} value={data.strcityname}>{data.strcityname}</Form.Label></td>
                                            </tr>
                                        ))}
                                        <tr>
                                            <td><Form.Check
                                                type="radio"
                                                name="group2"
                                                value="createNewPatient"
                                                style={{ paddingLeft: 20 }}
                                                onChange={handleChangePatient}
                                            />
                                            </td>
                                            <td><Form.Label style={{ fontSize: 12, color: "grey" }} >+ Create New Patient</Form.Label></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table> */}

                    {/* <div style={{ textAlign: "center" }}><Button variant="outline-primary" onClick={() => {createNewPatient()}} style={{ fontSize: 12 }} size="sm">+ Create New Patient</Button></div> */}
                    {/* </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClosePatientList} style={{ fontSize: 12 }}>Cancel</Button>
                                <Button variant="primary" onClick={() => { handleClosePatientList(); handleDone();}} style={{ fontSize: 12 }}>Done</Button>
                            </Modal.Footer>
                        </Modal> */}

                    <Modal size="lg" show={show} onHide={handleClose} aria-labelledby="add-new-patient-modal">

                        <Modal.Header closeButton>
                            <Modal.Title id="add-new-patient-modal" style={{ fontSize: 18 }}>Add New Patient</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>

                            <Container>

                                <Form.Label style={{ fontSize: 12, color: "grey", fontWeight: "bold" }}><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Mobile</Form.Label>

                                <Row>
                                    <Col sm={4}>
                                        <Form.Group className="mb-3" controlId="formEnterState">
                                            <Form.Control type="text" style={{ fontSize: 12 }} placeholder="Mobile" value={strmobileno || ""} onChange={e => { setStrmobileno(e.target.value) }} required />
                                        </Form.Group>
                                    </Col>

                                    {/* <Col sm={4}>
                                        <Button variant="primary" style={{ fontSize: 12 }} size="sm" onClick={checkMobileNo}>Check</Button>
                                    </Col> */}
                                </Row>

                                {/* <div style={{ fontSize: 12, fontWeight: "bold", marginBottom: 10 }}>{checkMobile}</div> */}

                                <Form.Label style={{ fontSize: 12, color: "grey", fontWeight: "bold" }}><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Name</Form.Label>

                                <Row>
                                    <Col sm={4}>
                                        <Form.Group className="mb-3" controlId="formEnterState">
                                            <Form.Control type="text" style={{ fontSize: 12 }} placeholder="First Name" value={strFirstName || ""} onChange={e => { setStrFirstName(e.target.value) }} required />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={4}>
                                        <Form.Group className="mb-3" controlId="formEnterState">
                                            <Form.Control type="text" style={{ fontSize: 12 }} placeholder="Last Name" value={strLastName || ""} onChange={e => { setStrLastName(e.target.value) }} required />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Form.Label style={{ fontSize: 12, color: "grey", fontWeight: "bold" }}><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Date of Birth</Form.Label>

                                <Row>
                                    <Col sm={3}>
                                        <Form.Group className="mb-3" controlId="formEnterState">
                                            <Form.Control type="date" max={new Date().toISOString().substring(0, 10)} style={{ fontSize: 12 }} value={dtmBirthDate || ""} onChange={e => { setDtmBirthdate(e.target.value); setIntAge(null); }} required />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={2}>
                                        <Form.Group className="mb-3" controlId="formEnterState">
                                            <Form.Control type="text" style={{ fontSize: 12 }} value={intAge || ""} onChange={e => { setIntAge(e.target.value) }} disabled />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={3}>
                                        <Form.Group className="mb-3" controlId="formSelectStateStatus">
                                            <Form.Select aria-label="Default select example" style={{ fontSize: 12 }} value={strGender || ""} onChange={e => { setStrGender(e.target.value) }} required >
                                                <option value="- Gender -">- Gender -</option>
                                                <option value="Male">Male</option>
                                                <option value="Female">Female</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={3}>
                                        <Form.Group className="mb-3" controlId="formSelectStateStatus">
                                            <Form.Select aria-label="Default select example" style={{ fontSize: 12 }} value={strBloodGroup || ""} onChange={e => { setStrBloodGroup(e.target.value) }} required >
                                                <option value="- Blood Group -">- Blood Group -</option>
                                                <option value="A+">A+</option>
                                                <option value="A-">A-</option>
                                                <option value="B+">B+</option>
                                                <option value="B-">B-</option>
                                                <option value="AB+">AB+</option>
                                                <option value="AB-">AB-</option>
                                                <option value="O+">O+</option>
                                                <option value="O-">O-</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>

                                </Row>

                                <Row>

                                </Row>

                                <Form.Label style={{ fontSize: 12, color: "grey", fontWeight: "bold" }}><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Address</Form.Label>

                                <Row>
                                    <Col sm={8}>
                                        <Form.Group className="mb-3" controlId="formEnterState">
                                            <Form.Control type="text" style={{ fontSize: 12 }} placeholder="Address" value={straddress || ""} onChange={e => { setStrAddress(e.target.value) }} required />
                                        </Form.Group>
                                    </Col>

                                </Row>

                                <Row>
                                    <Col sm={4}>
                                        <Form.Group className="mb-3" controlId="formSelectStateStatus">
                                            <Form.Select aria-label="Default select example" style={{ fontSize: 12 }} value={intcityID || ""} onChange={e => { setIntCityID(e.target.value) }} required>
                                                <option value="Select">- Select City -</option>
                                                {cities.map((data, key) => <option key={data.intcityid} value={data.intcityid} >{data.strcityname}</option>)}
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Form.Label style={{ fontSize: 12, color: "grey", fontWeight: "bold" }}>Status</Form.Label>

                                <Row>
                                    <Col sm={3}>
                                        <Form.Group className="mb-3" controlId="formSelectStateStatus">
                                            <Form.Select aria-label="Default select example" style={{ fontSize: 12 }} value={strstatus || ""} onChange={e => { setStatus(e.target.value) }} required>
                                                <option value="Active">Active</option>
                                                <option value="Inactive">Inactive</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Form.Label style={{ fontSize: 12, color: "grey", fontWeight: "bold" }}>Patient Photo</Form.Label>

                                <Row>
                                    <Col sm={3}>
                                        <Form.Group className="mb-3" controlId="formSelectStateStatus">
                                            <Button variant="primary" style={{ fontSize: 12 }} onClick={handleShowCaptureImage}> {img ? "Retake" : "Capture"}</Button>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm={3}>
                                        <Form.Group className="mb-3" controlId="formSelectStateStatus">
                                            {/* {intPatientID ?
                                                <div><Image src={atob(img)} width={100} height={100} /></div>
                                                :
                                                <div></div>
                                            } */}
                                            {img ?
                                                <div><Image src={img} width={100} height={100} /></div>
                                                :
                                                <div></div>
                                            }
                                            {editImg ?
                                                <div><Image src={atob(editImg)} width={100} height={100} /></div>
                                                :
                                                <div></div>
                                            }

                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <div style={{ fontSize: 12, fontWeight: "bold" }}>{addPatientError}</div>
                                </Row>

                            </Container>

                        </Modal.Body>

                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose} style={{ fontSize: 12, fontWeight: "bold" }}> Close </Button>

                            {intPatientID ?
                                <Button variant="primary" onClick={onAddNewPatientFormSubmit} type="submit" style={{ fontSize: 12, fontWeight: "bold" }}> Update Patient </Button>
                                :
                                <Button variant="primary" onClick={onAddNewPatientFormSubmit} type="submit" style={{ fontSize: 12, fontWeight: "bold" }}> Add Patient </Button>
                            }
                        </Modal.Footer>
                    </Modal>

                    <div style={{ fontSize: 12, fontWeight: "bold", marginBottom: 10 }}>{updateMessage}</div>

                    <CDBCard style={{ padding: 20, fontSize: 12, color: "grey" }}>
                        <CDBCardBody >
                            {!loading ? (
                                <div style={{ textAlign: "center" }}><Spinner animation="border" size="sm" variant="primary" /></div>
                            ) :
                                <CDBDataTable
                                    responsive
                                    noRecordsFoundLabel="No Records Found"
                                    noBottomColumn={true}
                                    hover
                                    entriesOptions={[10, 25, 50, 100, 200, 500]}
                                    entries={10}
                                    pagesAmount={4}
                                    data={data()}
                                />
                            }
                        </CDBCardBody>
                    </CDBCard>

                </div>

            </div>
        </div>

    );
};

export default HospitalPatientRecords;