import React from "react";
import { Button, Row, Col, Card, Container, CardGroup } from "react-bootstrap";
import { Search, Check2All, PencilSquare, Share } from 'react-bootstrap-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../Navbar/Navbar";
import Footer from "../Navbar/Footer";

function Services() {
  return (
    <div>
      <Navbar />

      <Container fluid>

        <div style={{ marginTop: 100, marginBottom: 100 }} className='container'>

          <Row>
            <Col className="text-center justify-content-center">
              <div style={{ textAlign: "center" }}>
                <div className="about-border"></div>
                <p style={{ fontSize: 35, fontWeight: 700 }}>WHAT WE DO</p>
              </div>
              <div>
                <p style={{ color: "grey" }}>We are bridge between patient and service providers. We find the best services for you.</p>
              </div>
            </Col>
          </Row>

          <CardGroup >

            <Card className="custom-class" style={{ margin: 10, borderRadius: 10 }}>
              <Card.Body>
                <Search color="white" size={60} style={{ marginTop: 30, marginBottom: 30 }} />
                <Card.Title style={{ color: "#fff" }}>SEARCH</Card.Title>
                <Card.Text style={{ color: "#fff", fontWeight: 100 }}>Search health services in your city, area or at desired locations.</Card.Text>
                <Button variant="outline-light" href="#" style={{ marginTop: 10, marginBottom: 10 }}>Read More</Button>
              </Card.Body>
            </Card>

            <Card className="custom-class" style={{ margin: 10, borderRadius: 10 }}>
              <Card.Body>
                <Check2All color="white" size={60} style={{ marginTop: 30, marginBottom: 30 }} />
                <Card.Title style={{ color: "#fff" }}>BOOKING</Card.Title>
                <Card.Text style={{ color: "#fff", fontWeight: 100 }}>Book your self to avail the various services online in easy way.</Card.Text>
                <Button variant="outline-light" href="#" style={{ marginTop: 10, marginBottom: 10 }}>Read More</Button>
              </Card.Body>
            </Card>

            <Card className="custom-class" style={{ margin: 10, borderRadius: 10 }}>
              <Card.Body>
                <Share color="white" size={60} style={{ marginTop: 30, marginBottom: 30 }} />
                <Card.Title style={{ color: "#fff" }}>SHARE</Card.Title>
                <Card.Text style={{ color: "#fff", fontWeight: 100 }}>Share your health records to authenticated persons in securly.</Card.Text>
                <Button variant="outline-light" href="#" style={{ marginTop: 10, marginBottom: 10 }}>Read More</Button>
              </Card.Body>
            </Card>

            <Card className="custom-class" style={{ margin: 10, borderRadius: 10 }}>
              <Card.Body>
                <PencilSquare color="white" size={60} style={{ marginTop: 30, marginBottom: 30 }} />
                <Card.Title style={{ color: "#fff" }}>MANAGE</Card.Title>
                <Card.Text style={{ color: "#fff", fontWeight: 100 }}>Manage patient records in secure way and generate daily reports.</Card.Text>
                <Button variant="outline-light" href="#" style={{ marginTop: 10, marginBottom: 10 }}>Read More</Button>
              </Card.Body>
            </Card>

          </CardGroup>

        </div>

      </Container>

      <Footer />
    </div>
  );
}

export default Services;