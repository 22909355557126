import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./admincss.css";
import HospitalSidebar from "../Navbar/HospitalSidebar";
import LaboratorySidebar from "../Navbar/LaboratorySidebar";
import PharmacySidebar from "../Navbar/PharmacySidebar";
import Sidebar from "../Navbar/Sidebar";
import Adminheader from "../Navbar/Adminheader";
import { Col, Form, Row, Button, InputGroup } from "react-bootstrap";
import { Asterisk, EyeFill, EyeSlashFill } from "react-bootstrap-icons";
import { Base64 } from 'js-base64';
// import { API_BASE } from '../../setupProxy';

function OrganizationChangePassword() {

    const intorganizationid = localStorage.getItem("orgid");
    const intloginid = localStorage.getItem("loggedInId");
    const roleName = localStorage.getItem("loggedRoleName");

    const [strCurrentPassword, setStrCurrentPassword] = useState("");
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [strNewPassword, setStrNewPassword] = useState("");
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [strConfirmPassword, setStrConfirmPassword] = useState("");
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [updateMessage, setUpdateMessage] = useState("");

    const [hospitalAdmin, setHospitalAdmin] = useState(false);
    const [laboratoryAdmin, setLaboratoryAdmin] = useState(false);
    const [pharmacyAdmin, setPharmacyAdmin] = useState(false);

    useEffect(() => {
        checkRoles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const checkRoles = () => {
        if (roleName === "Hospital Admin") {
            setHospitalAdmin(true);
            setLaboratoryAdmin(false);
            setPharmacyAdmin(false);
        } else if (roleName === "Laboratory Admin") {
            setHospitalAdmin(false);
            setLaboratoryAdmin(true);
            setPharmacyAdmin(false);
        } else if (roleName === "Pharmacy Admin") {
            setHospitalAdmin(false);
            setLaboratoryAdmin(false);
            setPharmacyAdmin(true);
        }
    }

    //FORM SUBMIT
    const onChangeOrganizationPasswordFormSubmit = (event) => {
        event.preventDefault();

        if (strCurrentPassword === "" || strNewPassword === "" || strConfirmPassword === "") {
            setUpdateMessage(<span style={{ color: "red" }}>Please enter data</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
        } else if (strCurrentPassword === "") {
            setUpdateMessage(<span style={{ color: "red" }}>Please enter your current password</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
        } else if (strCurrentPassword.length < 6) {
            setUpdateMessage(<span style={{ color: "red" }}>Password is too short.</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
        } else if (strNewPassword === "") {
            setUpdateMessage(<span style={{ color: "red" }}>Please enter your new password</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
        } else if (strNewPassword.length < 6) {
            setUpdateMessage(<span style={{ color: "red" }}>Password is too short.</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
        } else if (strConfirmPassword === "") {
            setUpdateMessage(<span style={{ color: "red" }}>Please enter confirm password</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
        } else if (strConfirmPassword.length < 6) {
            setUpdateMessage(<span style={{ color: "red" }}>Password is too short.</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
        } else if (strCurrentPassword === strNewPassword) {
            setUpdateMessage(<span style={{ color: "red" }}>Your new password is same as current password</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
        } else if (strNewPassword !== strConfirmPassword) {
            setUpdateMessage(<span style={{ color: "red" }}>New password did not matched with confirm password</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
        } else {

            const strfirstname = Base64.encode(strCurrentPassword);
            const strencryptedpassword = Base64.encode(strNewPassword);

            const toInput = { intloginid, intorganizationid, strfirstname, strencryptedpassword };

            fetch("https://e-arogya.com:8443/api/general/changeorganizationpassword", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                redirect: "follow",
                body: JSON.stringify(toInput)
            })
                .then(async response => {
                    const isJson = response.headers.get('content-type')?.includes('application/json');
                    const data = isJson && await response.json();

                    if (data === 0) {
                        setUpdateMessage(<span style={{ color: "red" }}>Invalid Details</span>);
                        setTimeout(() => setUpdateMessage(""), 3000);
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                        clearData();
                    } else if (data === 1) {
                        setUpdateMessage(<span style={{ color: "green" }}>Password Updated Successfully</span>);
                        setTimeout(() => setUpdateMessage(""), 3000);
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                        clearData();
                    }

                })
                .catch(error => {
                    setUpdateMessage(<span style={{ color: "red" }}>Error Occured. Please try again.</span>);
                    setTimeout(() => setUpdateMessage(""), 3000);
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    clearData();
                });

        }
    }

    const clearData = () => {
        setStrCurrentPassword("");
        setShowCurrentPassword(false);
        setStrNewPassword("");
        setShowNewPassword(false);
        setStrConfirmPassword("");
        setShowConfirmPassword(false);
    }

    const toggleCurrentPassword = () => {
        setShowCurrentPassword(!showCurrentPassword);
    };

    const toggleNewPassword = () => {
        setShowNewPassword(!showNewPassword);
    };

    const toggleConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    return (

        <div className="main" >

            {hospitalAdmin ?
                <div><HospitalSidebar /></div>
                :
                <div>
                    {laboratoryAdmin ?
                        <div><LaboratorySidebar /></div>
                        :
                        <div>
                            {pharmacyAdmin ?
                                <div><PharmacySidebar /></div>
                                :
                                <div><Sidebar /></div>
                            }
                        </div>

                    }
                </div>
            }

            <div className="page-content">

                <Adminheader />

                <h5>Change Password</h5>


                <div className="table-content">

                    <Form onSubmit={onChangeOrganizationPasswordFormSubmit}>

                        <div style={{ fontSize: 12, fontWeight: "bold", marginBottom: 10 }}>{updateMessage}</div>


                        <Row style={{ fontSize: 12, color: "grey" }}>
                            <Col sm={4}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label><Asterisk size={6} style={{ marginBottom: 8, marginRight: 5, color: "red" }} />Current Password</Form.Label>
                                    <InputGroup className="mb-2" size="sm">
                                        <Form.Control
                                            type={showCurrentPassword ? "text" : "password"}
                                            value={strCurrentPassword || ""}
                                            onChange={e => { setStrCurrentPassword(e.target.value) }}
                                            style={{ fontSize: 12 }}
                                            required />
                                        <InputGroup.Text onClick={toggleCurrentPassword}>
                                            {showCurrentPassword ? <EyeFill size={15} /> : <EyeSlashFill size={15} />}
                                        </InputGroup.Text>
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row style={{ fontSize: 12, color: "grey" }}>
                            <Col sm={4}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label><Asterisk size={6} style={{ marginBottom: 8, marginRight: 5, color: "red" }} />New Password</Form.Label>
                                    <InputGroup className="mb-2" size="sm">
                                        <Form.Control
                                            type={showNewPassword ? "text" : "password"}
                                            value={strNewPassword || ""}
                                            style={{ fontSize: 12 }}
                                            onChange={e => { setStrNewPassword(e.target.value) }}
                                            required />
                                        <InputGroup.Text onClick={toggleNewPassword}>
                                            {showNewPassword ? <EyeFill size={15} /> : <EyeSlashFill size={15} />}
                                        </InputGroup.Text>
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row style={{ fontSize: 12, color: "grey" }}>
                            <Col sm={4}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label><Asterisk size={6} style={{ marginBottom: 8, marginRight: 5, color: "red" }} />Confirm Password</Form.Label>
                                    <InputGroup className="mb-2" size="sm">
                                        <Form.Control
                                            type={showConfirmPassword ? "text" : "password"}
                                            value={strConfirmPassword || ""}
                                            style={{ fontSize: 12 }}
                                            onChange={e => { setStrConfirmPassword(e.target.value) }}
                                            required />
                                        <InputGroup.Text onClick={toggleConfirmPassword}>
                                            {showConfirmPassword ? <EyeFill size={15} /> : <EyeSlashFill size={15} />}
                                        </InputGroup.Text>
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row style={{ fontSize: 12, color: "grey" }}>

                            <Col sm={2} >
                                <Form.Group className="mb-3" controlId="formSelectStateStatus">
                                    <Button variant="primary" type="submit" style={{ marginTop: 25, fontSize: 12 }}> Reset </Button>
                                </Form.Group>
                            </Col>

                        </Row>

                    </Form>

                </div>

            </div>
        </div>
    );
}

export default OrganizationChangePassword;