import React, { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./admincss.css";
import Adminheader from "../Navbar/Adminheader";
import { Card, Col, Row, CardGroup, Container, Button, Modal } from "react-bootstrap";
import OperatorSidebar from "../Navbar/OperatorSidebar";
import { CDBCard, CDBCardBody, CDBDataTable } from 'cdbreact';
import * as moment from 'moment';
import { useNavigate } from "react-router-dom";
// import { API_BASE } from '../../setupProxy';

function OperatorDashboard() {

    const navigate = useNavigate();

    const intloginid = localStorage.getItem("loggedInId");
    const [totalHospitalEntered, setTotalHospitalEntered] = useState(0);
    const [totalLaboratoryEntered, setTotalLaboratoryEntered] = useState(0);
    const [totalPharmacyEntered, setTotalPharmacyEntered] = useState(0);

    const [orgDetails, setOrgDetails] = useState([]);

    const [hospCard, setHospCard] = useState(true);
    const [labCard, setLabCard] = useState(false);
    const [pharmCard, setPharmCard] = useState(false);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [deleteThis, setDeleteThis] = useState(null);

    const [updateMessage, setUpdateMessage] = useState("");

    useEffect(() => {
        getOperatorDashboardData(intloginid);
        fetchOrganizationEnteredData("Hospital", intloginid)
    }, [intloginid]);

    //FETCH ALL TOTAL DATA
    const getOperatorDashboardData = (loginid) => {
        fetch("https://e-arogya.com:8443/api/general/operatordashboard/" + loginid)
            .then(response => response.json())
            .then(data => {
                setTotalHospitalEntered(data[0].totalHospitalEntered);
                setTotalLaboratoryEntered(data[0].totalLaboratoryEntered);
                setTotalPharmacyEntered(data[0].totalPharmacyEntered);
            })
            .catch(error => {
                //console.log(error);
            })
    }

    //FETCH ALL ORG DETAILS
    const fetchOrganizationEnteredData = (orgtype, intloginid) => {
        fetch("https://e-arogya.com:8443/api/general/organizationbyoperator/list/" + orgtype + "/" + intloginid)
            .then(res => res.json())
            .then(data => {
                let dataFromApi = data.map(org => {

                    return {
                        id: org.intoperatororg,
                        type: org.strorganizationtype,
                        name: org.strorganizationname,
                        logo: org.strlogo,
                        mobile: org.strmobileno,
                        email: org.stremailid,
                        website: org.strwebsite,
                        address: org.straddressline1 + ", " + org.straddressline2 + ", " + org.strarea + ", " + org.strcityname + ", " + org.strstatename,
                        city: org.strcityname,
                        state: org.strstatename,
                        status: org.strstatus,
                        createdon: org.dtmcreatedate
                    }
                })

                setOrgDetails(dataFromApi);
            })
            .catch(error => {
                //console.log(error);
            });
    }


    function handleDeleteClick(e, id) {
        setShow(true);
        setDeleteThis(id);
    }

    const deleteThisFunc = (orgid) => {
        fetch(`https://e-arogya.com:8443/api/general/organizationbyoperator/${orgid}/${intloginid}`, { method: 'DELETE' })
            .then(async response => {
                const data = await response.text;

                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }

                setUpdateMessage(<span style={{ color: "green" }}>Organization Deleted Successfully</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
                setDeleteThis(null);
                if(hospCard){
                    fetchOrganizationEnteredData("Hospital", intloginid); 
                } else if(labCard){
                    fetchOrganizationEnteredData("Laboratory", intloginid);
                } else if(pharmCard){
                    fetchOrganizationEnteredData("Pharmacy", intloginid);
                }
                getOperatorDashboardData(intloginid);
            })
            .catch(error => {
                setUpdateMessage(<span style={{ color: "red" }}>Organization Not Deleted. Please try again.</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
            });
    }

    const data = () => {
        return {
            columns: [
                { label: 'Id', field: 'srno', },
                { label: 'Type', field: 'type', },
                {
                    label: 'Name',
                    field: 'name',
                    attributes: {
                        'aria-controls': 'DataTable',
                        'aria-label': 'Name',
                    },
                },
                { label: 'Mobile', field: 'mobile', },
                { label: 'Email', field: 'email', },
                { label: 'Website', field: 'website', },
                { label: 'Address', field: 'address', },
                { label: 'City', field: 'city', },
                { label: 'State', field: 'state', },
                { label: 'Created On', field: 'createdon', },
                { label: 'Status', field: 'status', },
                { label: '', field: 'edit', },
                { label: '', field: 'delete', },
            ],
            rows: [...orgDetails.map((data1, index) => (
                {   
                    srno: index + 1,
                    type: data1.type,
                    name: data1.name,
                    mobile: data1.mobile,
                    email: data1.email,
                    website: data1.website,
                    address: data1.address,
                    city: data1.city,
                    state: data1.state,
                    createdon: moment(data1.createdon).format('DD-MMM-YYYY'),
                    status: data1.status,
                    edit: <span onClick={(e) => { navigate('/operatororganization', { state: { orgid: data1.id, orgtype: data1.type} }); }}> <Button variant="primary" type="submit" style={{ fontSize: 12 }} size="sm"> Edit </Button> </span>,
                    delete: <span onClick={(e) => handleDeleteClick(e, data1.id)}> <Button variant="danger" type="submit" style={{ fontSize: 12 }} size="sm"> Delete </Button> </span>,

                }
            ))

            ],
        };
    };


    return (

        <div className="main" >

            <OperatorSidebar />

            <div className="body">

                <Adminheader />

                <div className="page-content">

                    <h5>Welcome Operator</h5>

                    <div className="table-content">

                        <Modal show={show} onHide={handleClose}>
                            <Modal.Header closeButton>
                                <Modal.Title style={{ fontSize: 18 }}>Delete {}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body style={{ fontSize: 14 }}>Are you sure you want to delete this Organization?</Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose} style={{ fontSize: 12 }}>No</Button>
                                <Button variant="primary" onClick={() => {
                                    handleClose();
                                    deleteThisFunc(deleteThis);
                                }} style={{ fontSize: 12 }}>Yes</Button>
                            </Modal.Footer>
                        </Modal>

                        <CardGroup>
                            <Container fluid>
                                <Row>
                                    <Col md>
                                        <Card
                                            style={{ margin: 5, borderRadius: 10, backgroundColor: hospCard ? "#f2f2f2" : "#fff" }}
                                            className="cardclass"
                                            onClick={() => {
                                                fetchOrganizationEnteredData("Hospital", intloginid);
                                                setHospCard(true);
                                                setLabCard(false);
                                                setPharmCard(false);
                                            }}>
                                            <Card.Body>
                                                <Card.Text style={{ fontSize: 12, color: "grey" }}>Total Hospital Data Entered</Card.Text>
                                                <Card.Title style={{ paddingLeft: 10 }}>{totalHospitalEntered}</Card.Title>
                                                {/* <Card.Text style={{ fontSize: 12, color: "grey" }}>+1.9% Last week</Card.Text> */}
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col md>
                                        <Card
                                            style={{ margin: 5, borderRadius: 10, backgroundColor: labCard ? "#f2f2f2" : "#fff" }}
                                            className="cardclass"
                                            onClick={() => {
                                                fetchOrganizationEnteredData("Laboratory", intloginid);
                                                setHospCard(false);
                                                setLabCard(true);
                                                setPharmCard(false);
                                            }}>
                                            <Card.Body>
                                                <Card.Text style={{ fontSize: 12, color: "grey" }}>Total Laboratory Data Entered</Card.Text>
                                                <Card.Title style={{ paddingLeft: 10 }}>{totalLaboratoryEntered}</Card.Title>
                                                {/* <Card.Text style={{ fontSize: 12, color: "grey" }}>+18.9% Last week</Card.Text> */}
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col md>
                                        <Card
                                            style={{ margin: 5, borderRadius: 10, backgroundColor: pharmCard ? "#f2f2f2" : "#fff" }}
                                            className="cardclass"
                                            onClick={() => {
                                                fetchOrganizationEnteredData("Pharmacy", intloginid);
                                                setHospCard(false);
                                                setLabCard(false);
                                                setPharmCard(true);
                                            }}>
                                            <Card.Body>
                                                <Card.Text style={{ fontSize: 12, color: "grey" }}>Total Pharmacy Data Entered</Card.Text>
                                                <Card.Title style={{ paddingLeft: 10 }}>{totalPharmacyEntered}</Card.Title>
                                                {/* <Card.Text style={{ fontSize: 12, color: "grey" }}>+1.9% Last week</Card.Text> */}
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </Container>
                        </CardGroup>
                        
                        <div style={{ fontSize: 12, fontWeight: "bold", marginBottom: 10 }}>{updateMessage}</div>

                        <CDBCard style={{ padding: 20, fontSize: 12, color: "grey" }}>
                            <CDBCardBody>
                                <CDBDataTable
                                    responsive
                                    noRecordsFoundLabel="No Records Found"
                                    noBottomColumn={true}
                                    hover
                                    entriesOptions={[10, 25, 50, 100, 200, 500]}
                                    entries={10}
                                    pagesAmount={4}
                                    data={data()}
                                />
                            </CDBCardBody>
                        </CDBCard>

                    </div>

                </div>
            </div>
        </div>
    );
}

export default OperatorDashboard;