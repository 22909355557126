import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../Navbar/Navbar";
import Footer from "../Navbar/Footer";
import { Button, Row, Col, Container, Form, InputGroup, ProgressBar } from "react-bootstrap";
import { EyeFill, EyeSlashFill } from "react-bootstrap-icons";
import { Base64 } from 'js-base64';
import { Asterisk } from "react-bootstrap-icons";
// import { API_BASE } from '../../setupProxy';

function RegiOrgLogin() {

    const navigate = useNavigate();
    let { state } = useLocation();
    //const strorganizationtype = state.type;
    const loginid = state.loginid;

    const [updateMessage, setUpdateMessage] = useState("");

    const [strpassword, setStrPassword] = useState("");
    const [strconfirmpassword, setStrConfirmPassword] = useState("");

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const togglePassword = () => { setShowPassword(!showPassword); };
    const toggleConfirmPassword = () => { setShowConfirmPassword(!showConfirmPassword); };

    const handleSubmit = async (e) => {

        e.preventDefault();

        if (strpassword === "" || strpassword.length < 6) {
            setUpdateMessage(<span style={{ color: "red" }}>Please enter minimum 6 digit password</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
        } else if (strconfirmpassword === "" || strconfirmpassword.length < 6) {
            setUpdateMessage(<span style={{ color: "red" }}>Please enter valid 6 digit confirm password</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
        } else if (strpassword !== strconfirmpassword) {
            setUpdateMessage(<span style={{ color: "red" }}>Password did not matched. Please re-enter password</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
        } else {
            updatePassword(strpassword);
        };

    }

    const updatePassword = (strpassword) => {

        const strencryptedpassword = Base64.encode(strpassword);

        const toInput = { strencryptedpassword };
        fetch("https://e-arogya.com:8443/api/general/login/updatepassword/" + loginid, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            redirect: "follow",
            body: JSON.stringify(toInput)
        })
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();

                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }
                //console.log("Login details for Organization Added");
                navigate('/login');
            })
            .catch(error => {
                setUpdateMessage(<span style={{ color: "red" }}>Error in password update. Please try again.</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
            });
    }

    return (

        <div>

            <Navbar />

            <Container fluid>

                <div style={{ marginTop: 50, marginBottom: 50 }} className='container'>

                    <Row>
                        <Col className="text-center justify-content-center">
                            <div style={{ textAlign: "center" }}>
                                <div className="about-border"></div>
                                <p style={{ fontSize: 35, fontWeight: 700 }}>Register {state.type}</p>
                            </div>
                        </Col>
                    </Row>
                    <div style={{ marginTop: 30, marginBottom: 30 }}></div>
                    <Row>
                        <Col>
                            <div style={{ textAlign: "center" }}>
                                <p style={{ fontSize: 14, fontWeight: 500 }}>Basic Details</p>
                            </div>
                        </Col>
                        <Col>
                            <div style={{ textAlign: "center" }}>
                                <p style={{ fontSize: 14, fontWeight: 500 }}>Location Details</p>
                            </div>
                        </Col>
                        <Col>
                            <div style={{ textAlign: "center" }}>
                                <p style={{ fontSize: 14, fontWeight: 500 }}>Login Details</p>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <ProgressBar variant="success" animated now={100} style={{ height: 5 }} />
                    </Row>
                    <div style={{ marginTop: 30, marginBottom: 30 }}></div>

                    <Row className="py-5 justify-content-center">

                        <Col md={4}>

                            <Form onSubmit={handleSubmit} >

                                <div style={{ textAlign: "center" }}>
                                    <p style={{ fontSize: 18, fontWeight: 700 }}>Login Details</p>
                                </div>

                                <Form.Group className="mb-3" controlId="formEnterState">
                                    <Form.Label style={{ fontSize: 13, fontWeight: "bold" }}><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Password</Form.Label>
                                    <InputGroup className="mb-2">
                                        <Form.Control
                                            style={{ fontSize: 13 }}
                                            type={showPassword ? "text" : "password"}
                                            value={strpassword || ""}
                                            onChange={e => { setStrPassword(e.target.value) }}
                                            required />
                                        <InputGroup.Text onClick={togglePassword}>
                                            {showPassword ? <EyeFill size={15} /> : <EyeSlashFill size={15} />}
                                        </InputGroup.Text>
                                    </InputGroup>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formEnterState">
                                    <Form.Label style={{ fontSize: 13, fontWeight: "bold" }}><Asterisk size={6} style={{ marginBottom: 5, marginRight: 5, color: "red" }} />Confirm Password</Form.Label>
                                    <InputGroup className="mb-2">
                                        <Form.Control
                                            style={{ fontSize: 13 }}
                                            type={showConfirmPassword ? "text" : "password"}
                                            value={strconfirmpassword || ""}
                                            onChange={e => { setStrConfirmPassword(e.target.value) }}
                                            required />
                                        <InputGroup.Text onClick={toggleConfirmPassword}>
                                            {showConfirmPassword ? <EyeFill size={15} /> : <EyeSlashFill size={15} />}
                                        </InputGroup.Text>
                                    </InputGroup>
                                </Form.Group>

                                <Row>
                                    <div style={{ fontSize: 12, fontWeight: "bold", marginBottom: 10, marginTop: 20 }}>{updateMessage}</div>
                                </Row>

                                <div style={{ textAlign: "center" }}>
                                    <Button style={{ marginTop: 20, marginBottom: 20, width: "50%" }} variant="success" type="submit">Done</Button>
                                </div>

                            </Form>

                            <div style={{ textAlign: "center" }}></div>

                            <Row className="align-items-center justify-content-center">

                                <Col xs="auto">
                                    <Form.Label style={{ marginTop: 6 }}> Already have an account? </Form.Label>
                                </Col>

                                <Col xs="auto">
                                    <Link to="/login">
                                        <Button variant="outline-danger" size="sm">Login</Button>
                                    </Link>
                                </Col>

                            </Row>

                        </Col>

                    </Row>

                </div>

            </Container>

            <Footer />

        </div>

    );
}

export default RegiOrgLogin;