import React, { useEffect, useState, useRef } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./admincss.css";
import HospitalSidebar from "../Navbar/HospitalSidebar";
import Adminheader from "../Navbar/Adminheader";
import { CDBCard, CDBCardBody, CDBDataTable } from 'cdbreact';
import { Col, Form, Row, Button, Image, Modal } from "react-bootstrap";
// import { API_BASE } from '../../setupProxy';

const HospitalRegisterNewHospital = () => {

    const intloginid = localStorage.getItem("loggedInId");
    const orgtype = "Hospital";

    const [organization, setOrganization] = useState([]);

    const [files, setFiles] = useState('');
    const [fileSize, setFileSize] = useState("");
    const [imagefile, setImageFile] = useState(null);
    const aRef = useRef(null);

    const [intorganizationid, setIntorganizationid] = useState(null);
    const [strorganizationtype, setStrorganizationtype] = useState("");
    const [strorganizationname, setStrorganizationname] = useState("");
    const [strwebsite, setStrwebsite] = useState("");
    const [strphoneno1, setStrphoneno1] = useState("");
    const [strphoneno2, setStrphoneno2] = useState("");
    const [straddressline1, setStraddressline1] = useState("");
    const [straddressline2, setStraddressline2] = useState("");
    const [strarea, setStrarea] = useState("");
    const [intcityid, setIntcityid] = useState(null);
    const [intstateid, setIntstateid] = useState(null);
    const [strstatus, setStatus] = useState('Active');

    const [cities, setCities] = useState([]);
    const [states, setStates] = useState([]);
    const [filteredCities, setFilteredCities] = useState([]);

    const [updateMessage, setUpdateMessage] = useState("");

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [deleteThis, setDeleteThis] = useState(null);

    useEffect(() => {
        fetchData(orgtype, intloginid);
        fetchCities();
        fetchStates();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchStates = () => {
        fetch("https://e-arogya.com:8443/api/general/states")
            .then(res => res.json())
            .then(states => {
                setStates(states);
            })
            .catch(error => {
                //console.log(error);
            })
    }

    const fetchCities = () => {
        fetch("https://e-arogya.com:8443/api/general/cities")
            .then(res => res.json())
            .then(cities => {
                setCities(cities);
            })
            .catch(error => {
                //console.log(error);
            })
    }

    //FETCH ALL HOSPITALS
    const fetchData = (orgtype, intloginid) => {
        fetch("https://e-arogya.com:8443/api/general/organization/type/" + orgtype + "/" + intloginid)
            .then(res => res.json())
            .then(data => {
                let dataFromApi = data.map(org => {
                    return {
                        id: org.intorganizationid,
                        name: org.strorganizationname,
                        logo: org.strlogo,
                        website: org.strwebsite,
                        address1: org.straddressline1,
                        address2: org.straddressline2,
                        area: org.strarea,
                        phone: org.strphoneno1,
                        type: org.strorganizationtype,
                        status: org.strstatus,
                        cityname: org.strcityname
                    }
                })

                setOrganization(dataFromApi);
            })
            .catch(error => {
                //console.log(error);
            });

    }

    //FORM SUBMIT
    const onAddNewOrgTimingsFormSubmit = (event) => {

        event.preventDefault();

        const formData = new FormData();

        if (strphoneno2) {
            if (strphoneno2.length !== 10 || isNaN(strphoneno2)) {
                setUpdateMessage(<span style={{ color: "red" }}>Please Enter Valid 10 digit Phone 2</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
            }
        }

        if (strorganizationtype === "" || strorganizationtype === "- Select -") {
            setUpdateMessage(<span style={{ color: "red" }}>Please select organization type</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
        } else if (strorganizationname === "") {
            setUpdateMessage(<span style={{ color: "red" }}>Please enter organization name</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
        } else if (strwebsite === "") {
            setUpdateMessage(<span style={{ color: "red" }}>Please enter organization website</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
        } else if (strphoneno1 === "" || strphoneno1.length !== 10 || isNaN(strphoneno1)) {
            setUpdateMessage(<span style={{ color: "red" }}>Please Enter Valid 10 digit Phone 1</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
        } else if (straddressline1 === "") {
            setUpdateMessage(<span style={{ color: "red" }}>Please enter Address Line 1</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
        } else if (straddressline2 === "") {
            setUpdateMessage(<span style={{ color: "red" }}>Please enter Address Line 2</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
        } else if (strarea === "") {
            setUpdateMessage(<span style={{ color: "red" }}>Please enter area</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
        } else if (intcityid === "" || intcityid === null || intcityid === "- Select -") {
            setUpdateMessage(<span style={{ color: "red" }}>Please select city</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
        } else if (intstateid === "" || intstateid === null || intstateid === "- Select -") {
            setUpdateMessage(<span style={{ color: "red" }}>Please select state</span>);
            setTimeout(() => setUpdateMessage(""), 3000);
        } else {

            if (files !== 0) {
                for (let i = 0; i < files.length; i++) {
                    if (files[i].size > 20000) {
                        setFileSize(<span style={{ color: "red" }}>File size exceeded!! Please select filesize less than 20KB.</span>);
                        setTimeout(() => setFileSize(""), 3000);
                        return;
                    }
                    formData.append('files', files[i])
                }
            }

            // console.log(intloginid + " " + strorganizationtype + " " + strorganizationname + " " + strwebsite + " " + strphoneno1 + " " + straddressline1 + " " + straddressline2 + " "
            //     + strarea + " " + intcityid + " " + intstateid + " " + strstatus + " " + files);

            formData.append('intloginid', intloginid);
            formData.append('strorganizationtype', strorganizationtype);
            formData.append('strorganizationname', strorganizationname);
            formData.append('strwebsite', strwebsite);
            formData.append('strphoneno1', strphoneno1);
            if (strphoneno2) {
                formData.append('strphoneno2', strphoneno2);
            }
            formData.append('straddressline1', straddressline1);
            formData.append('straddressline2', straddressline2);
            formData.append('strarea', strarea);
            formData.append('intcityid', intcityid);
            formData.append('strstatus', strstatus);

            if (intorganizationid) {
                updateOrganization(formData, intorganizationid);
                setIntorganizationid(null);
                clearFields();
            } else {
                addNewOrganization(formData);
                clearFields();
            }
        }

    }

    //ADD NEW ORGANIZATION
    async function addNewOrganization(formData) {

        const requestOptions = {
            method: 'POST',
            body: formData
        };
        fetch("https://e-arogya.com:8443/api/general/organization/addneworganization", requestOptions)
            .then(res => {

                if (res.ok) {
                    setUpdateMessage(<span style={{ color: "green" }}>Organization Added Successfully</span>);
                    setTimeout(() => setUpdateMessage(""), 3000);
                    clearFields();
                    fetchData();
                } else {
                    setUpdateMessage(<span style={{ color: "red" }}>Organization Not Added</span>);
                    setTimeout(() => setUpdateMessage(""), 3000);
                }
            })
            .catch(error => {
                setUpdateMessage(<span style={{ color: "red" }}>Organization Not Added. Please try again.</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
            });

    }

    //UPDATE ORGANIZATION
    async function updateOrganization(formData, intorganizationid) {

        const requestOptions = {
            method: 'PUT',
            body: formData
        };
        fetch("https://e-arogya.com:8443/api/general/organization/update/" + intorganizationid, requestOptions)
            .then(res => {

                if (res.ok) {
                    setUpdateMessage(<span style={{ color: "green" }}>Organization Updated Successfully</span>);
                    setTimeout(() => setUpdateMessage(""), 3000);
                    clearFields();
                    fetchData();
                } else {
                    setUpdateMessage(<span style={{ color: "green" }}>Organization Not Updated</span>);
                    setTimeout(() => setUpdateMessage(""), 3000);
                }
            })
            .catch(error => {
                setUpdateMessage(<span style={{ color: "red" }}>Organization Not Updated. Please try again.</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
            });

    }

    //FETCH ORGANIZATION BY ID (FOR EDIT ORGANIZATION)
    const handleEditClick = (e, id) => {
        e.preventDefault();
        fetch("https://e-arogya.com:8443/api/general/organization/" + id)
            .then(res => res.json())
            .then(data => {
                setIntorganizationid(data[0].intorganizationid);
                setStrorganizationtype(data[0].strorganizationtype);
                setStrorganizationname(data[0].strorganizationname);
                setStrwebsite(data[0].strwebsite);
                setStrphoneno1(data[0].strphoneno1);
                setStrphoneno2(data[0].strphoneno2);
                setStraddressline1(data[0].straddressline1);
                setStraddressline2(data[0].straddressline2);
                setStrarea(data[0].strarea);
                setIntstateid(data[0].intstateid);
                setImageFile(data[0].strlogo);
                setStatus(data[0].strstatus);
                let cityList = [];
                for (let i = 0; i < cities.length; i++) {
                    if (cities[i].intstateid === (data[0].intstateid)) {
                        //intstateid is equal
                        cityList.push(cities[i]);
                    }
                }
                setIntcityid(data[0].intcityid);
                setFilteredCities(cityList);
            })
            .catch(error => {
                //console.log(error);
            });

    }

    //DELETE ORGANIZATION
    function handleDeleteClick(e, id) {
        setShow(true);
        setDeleteThis(id);
    }

    const deleteThisFunc = (id) => {
        fetch("https://e-arogya.com:8443/api/general/organization/" + id, { method: 'DELETE' })
            .then(async response => {
                const data = await response.text;

                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }

                setUpdateMessage(<span style={{ color: "green" }}>Organization Deleted Successfully</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
                setDeleteThis(null);
                setIntorganizationid(null);
                clearFields();
                fetchData();
            })
            .catch(error => {
                setUpdateMessage(<span style={{ color: "red" }}>Organization Not Deleted. Please try again.</span>);
                setTimeout(() => setUpdateMessage(""), 3000);
            });
    }

    const clearFields = () => {
        setStrorganizationtype("- Select -");
        setStrorganizationname("");
        setStrwebsite("");
        setStrphoneno1("");
        setStrphoneno2("");
        setStraddressline1("");
        setStraddressline2("");
        setStrarea("");
        setIntstateid('- Select -');
        setIntcityid('- Select -');
        setStatus('Active');
        setFiles([]);
        setImageFile(null);
        aRef.current.value = null;
    }

    const data = () => {
        return {
            columns: [
                { label: '#', field: 'srno', },
                {
                    label: 'Hospital Name',
                    field: 'name',
                    attributes: {
                        'aria-controls': 'DataTable',
                        'aria-label': 'Name',
                    },
                },
                { label: 'Website', field: 'website', },
                { label: 'Logo', field: 'logo', },
                { label: 'Address', field: 'address', },
                { label: 'Area', field: 'area', },
                { label: 'City', field: 'cityname', },
                { label: 'Phone', field: 'phone', },
                { label: 'Type', field: 'type', },
                { label: 'Status', field: 'status', },
                { label: '', field: 'edit', },
                { label: '', field: 'delete', },

            ],
            rows: [...organization.map((data, i) => (
                {
                    srno: i + 1,
                    name: data.name,
                    website: data.website,
                    address: data.address1 + ", " + data.address2,
                    // logo: <Image src={`data:image/jpeg;base64,${atob(data.logo)}`} width={75} height={75} className="img-fluid" />,
                    logo: <Image src={`data:image/jpeg;base64,${(data.logo)}`} width={75} height={75} className="img-fluid" />,
                    area: data.area,
                    cityname: data.cityname,
                    phone: data.phone,
                    type: data.type,
                    status: data.status,
                    edit: <span onClick={(e) => { handleEditClick(e, data.id); }}>
                        <Button variant="primary" type="submit" style={{ fontSize: 12 }} size="sm">
                            Edit
                        </Button>
                    </span>,
                    delete: <span onClick={(e) => handleDeleteClick(e, data.id)}>
                        <Button variant="danger" type="submit" style={{ fontSize: 12 }} size="sm">
                            Delete
                        </Button>
                    </span>
                }
            ))

            ],
        };
    };

    return (

        <div className="main" >

            <HospitalSidebar />

            <div className="page-content">

                <Adminheader />

                <h5>Register New Hospital</h5>
                <p style={{ fontSize: 12, color: "grey", marginBottom: 50 }}>Manage Hospitals to Appear in Application</p>

                <div className="table-content">

                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title style={{ fontSize: 18 }}>Delete Organization</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ fontSize: 14 }}>Are you sure you want to delete this Organization?</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose} style={{ fontSize: 12 }}>No</Button>
                            <Button variant="primary" onClick={() => {
                                handleClose();
                                deleteThisFunc(deleteThis);
                            }} style={{ fontSize: 12 }}>Yes</Button>
                        </Modal.Footer>
                    </Modal>

                    <Form onSubmit={onAddNewOrgTimingsFormSubmit}>

                        <Row style={{ fontSize: 12, color: "grey" }}>

                            <Col sm={2}>
                                <Form.Group className="mb-3" controlId="formSelectStateStatus">
                                    <Form.Label>Organization Type</Form.Label>
                                    <Form.Select aria-label="Default select example" style={{ fontSize: 12 }} value={strorganizationtype || ""} onChange={e => { setStrorganizationtype(e.target.value) }} required >
                                        <option value="- Select -">- Select -</option>
                                        <option value="Hospital">Hospital</option>
                                        <option value="Laboratory">Laboratory</option>
                                        <option value="Pharmacy">Pharmacy</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>

                            <Col sm={2}>
                                <Form.Group className="mb-3" controlId="formEnterState">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control type="text" style={{ fontSize: 12 }} value={strorganizationname || ""} onChange={e => { setStrorganizationname(e.target.value) }} required />
                                </Form.Group>
                            </Col>

                            <Col sm={2}>
                                <Form.Group className="mb-3" controlId="formEnterState">
                                    <Form.Label>Website</Form.Label>
                                    <Form.Control type="" style={{ fontSize: 12 }} value={strwebsite || ""} onChange={e => { setStrwebsite(e.target.value) }} required />
                                </Form.Group>
                            </Col>

                            <Col sm={2}>
                                <Form.Group className="mb-3" controlId="formEnterState">
                                    <Form.Label>Phone 1</Form.Label>
                                    <Form.Control type="text" style={{ fontSize: 12 }} value={strphoneno1 || ""} onChange={e => { setStrphoneno1(e.target.value) }} required />
                                </Form.Group>
                            </Col>

                            <Col sm={2}>
                                <Form.Group className="mb-3" controlId="formEnterState">
                                    <Form.Label>Phone 2</Form.Label>
                                    <Form.Control type="text" style={{ fontSize: 12 }} value={strphoneno2 || ""} onChange={e => { setStrphoneno2(e.target.value) }} />
                                </Form.Group>
                            </Col>

                        </Row>

                        <Row style={{ fontSize: 12, color: "grey" }}>

                            <Col sm={2}>
                                <Form.Group className="mb-3" controlId="formEnterState">
                                    <Form.Label>Address Line 1</Form.Label>
                                    <Form.Control type="text" style={{ fontSize: 12 }} value={straddressline1 || ""} onChange={e => { setStraddressline1(e.target.value) }} required />
                                </Form.Group>
                            </Col>

                            <Col sm={2}>
                                <Form.Group className="mb-3" controlId="formEnterState">
                                    <Form.Label>Address Line 2</Form.Label>
                                    <Form.Control type="text" style={{ fontSize: 12 }} value={straddressline2 || ""} onChange={e => { setStraddressline2(e.target.value) }} required />
                                </Form.Group>
                            </Col>

                            <Col sm={2}>
                                <Form.Group className="mb-3" controlId="formEnterState">
                                    <Form.Label>Area</Form.Label>
                                    <Form.Control type="text" style={{ fontSize: 12 }} value={strarea || ""} onChange={e => { setStrarea(e.target.value) }} required />
                                </Form.Group>
                            </Col>

                            <Col sm={2}>
                                <Form.Group className="mb-3" controlId="formSelectStateStatus">
                                    <Form.Label>State</Form.Label>
                                    <Form.Select
                                        aria-label="Default select example"
                                        style={{ fontSize: 12 }}
                                        value={intstateid || ""}
                                        onChange={e => {
                                            setIntstateid(e.target.value);
                                            //fetchCitiesById(e.target.value) 

                                            let cityList = [];
                                            for (let i = 0; i < cities.length; i++) {
                                                if (cities[i].intstateid === parseInt(e.target.value)) { //parseInt because the value received (e.target.value) is in string
                                                    //intstateid is equal
                                                    cityList.push(cities[i]);
                                                }
                                            }

                                            setFilteredCities(cityList);

                                        }}
                                        required
                                    >
                                        <option value="- Select -">- Select -</option>
                                        {states.map((data, key) => <option key={data.intstateid} value={data.intstateid} >{data.strstatename}</option>)}
                                    </Form.Select>
                                </Form.Group>
                            </Col>

                            <Col sm={2}>
                                <Form.Group className="mb-3" controlId="formSelectStateStatus">
                                    <Form.Label>City</Form.Label>
                                    <Form.Select
                                        aria-label="Default select example"
                                        style={{ fontSize: 12 }}
                                        value={intcityid || ""}
                                        onChange={e => { 
                                            setIntcityid(e.target.value); 
                                        }}
                                        required
                                    >
                                        <option value="- Select -">- Select -</option>
                                        {filteredCities.map((data1, key) => <option key={data1.intcityid} value={data1.intcityid}>{data1.strcityname}</option>)}
                                    </Form.Select>
                                </Form.Group>
                            </Col>

                        </Row>

                        <Row style={{ fontSize: 12, color: "grey" }}>

                            <Col sm={2}>
                                <Form.Group className="mb-3" controlId="formSelectStateStatus">
                                    <Form.Label>Status</Form.Label>
                                    <Form.Select aria-label="Default select example" style={{ fontSize: 12 }} value={strstatus || ""} onChange={e => { setStatus(e.target.value) }} >
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>

                            <Col sm={4}>
                                <Form.Group className="mb-3" controlId="formEnterState">
                                    <Form.Label>Logo</Form.Label>
                                    <Form.Control
                                        ref={aRef}
                                        type="file"
                                        style={{ fontSize: 12 }}
                                        onChange={e => {
                                            setFiles(e.target.files);
                                            const reader = new FileReader();
                                            reader.addEventListener("load", () => {
                                                setImageFile(reader.result);
                                            });
                                            reader.readAsDataURL(e.target.files[0]);
                                        }}
                                        accept="image/png, image/gif, image/jpeg"
                                    />
                                </Form.Group>
                            </Col>

                            {imagefile ?
                                <Col sm={2}>
                                    <Form.Label>Logo Preview</Form.Label>
                                    <div><Image src={intorganizationid ? `data:image/jpeg;base64,${(imagefile)}` : imagefile} width={50} height={50} /></div>
                                </Col>
                                :
                                <Col sm={2}>
                                    <div></div>
                                </Col>
                            }


                            <Col sm={2} >
                                <Form.Group className="mb-3" controlId="formSelectStateStatus">
                                    {intorganizationid ?
                                        <Button variant="primary" type="submit" style={{ marginTop: 25, fontSize: 12 }}> Update </Button>
                                        :
                                        <Button variant="primary" type="submit" style={{ marginTop: 25, fontSize: 12 }}> Add </Button>
                                    }
                                </Form.Group>
                            </Col>

                        </Row>

                    </Form>

                    <div style={{ fontSize: 12, fontWeight: "bold", marginBottom: 10 }}>{fileSize}</div>
                    <div style={{ fontSize: 12, fontWeight: "bold", marginBottom: 10 }}>{updateMessage}</div>

                    <CDBCard style={{ padding: 20, fontSize: 12, color: "grey" }}>
                        <CDBCardBody>
                            <CDBDataTable
                                responsive
                                noRecordsFoundLabel="No Records Found"
                                noBottomColumn={true}
                                hover
                                entriesOptions={[10, 25, 50, 100, 200, 500]}
                                entries={10}
                                pagesAmount={4}
                                data={data()}

                            />
                        </CDBCardBody>
                    </CDBCard>

                </div>
            </div>
        </div>

    );
};

export default HospitalRegisterNewHospital;